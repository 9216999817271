import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {PolicyContractDetailsComponent} from './policy-contract-details.component';
import {PolicyContractVersionSearchComponent} from './policy-contract-version-search.component';
import {PolicyContractCompanyListComponent, PolicyTabLayoutComponent} from './components';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CompanyModule} from '../company/company.module';
import {DocumentModule} from '../document/document.module';
import {PolicyContractViewService} from './services/policy-contract-view.service';
import {PolicyContractService, PolicyMonitoringVersionService} from '../../bonding_shared';
import {EmbeddablePolicySearchComponent} from './components/embeddable-policy-search.component';
import {PolicyContractTechnicalDetailsComponent} from './policy-contract-technical-details.component';
import {PolicyContractSharedModule} from './components/shared/policy-contract-shared.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BrokerContractSharedModule} from '../broker-contract/components/shared/broker-contract-shared.module';
import {PolicyCessionModule} from '../policy-cession/policy-cession.module';
import {PolicyContractPreviewComponent} from './policy-contract-preview.component';

import {CrPolicyProductComponent} from './components/products/cr-policy-product.component';

import {PolicyContractDataSectionComponent} from './components/sections/policy-contract-data-section.component';
import {PremiumSectionComponent} from './components/sections/premium-section.component';
import {IndemnificationSectionComponent} from './components/sections/indemnification-section.component';
import {InsuredsSectionComponent} from './components/sections/insureds-section.component';
import {BankAccountSectionComponent} from './components/sections/bank-account-section.component';
import {OtherStandardConditionsSectionComponent} from './components/sections/other-standard-conditions-section.component';
import {TerminationSectionComponent} from './components/sections/termination-section.component';
import {CredendoCountriesSectionComponent} from './components/sections/cr/credendo-countries-section.component';
import {CountriesSectionComponent} from './components/sections/countries-section.component';
import {CountryGroupsSectionComponent} from './components/sections/country-groups-section.component';
import {SpecialRiskSectionComponent} from './components/sections/special-risk-section.component';
import {BonusMalusSectionComponent} from './components/sections/bonus-malus-section.component';
import {TechnicalSectionComponent} from './components/sections/technical-section.component';
import {DocumentsSectionComponent} from './components/sections/documents-section.component';
import {SpecialClausesCommentSectionComponent} from './components/sections/special-clauses-comment-section.component';
import {FlInsurerSectionComponent} from './components/sections/cr/fl-insurer-section.component';
import {RiskAssessmentSectionComponent} from './components/sections/cr/risk-assessment-section.component';
import {CollectionCompaniesSectionComponent} from './components/sections/cr/collection-companies-section.component';
import {TreatiesSectionComponent} from './components/sections/cr/treaties-section.component';
import {CaptiveProductComponent, ExcessOfLossProductComponent, TopUpProductComponent} from './components/products/cr';
import {SalesRepSharedModule} from '../sales-rep/components/shared/sales-rep-shared.module';
import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {CustomFormModule} from '../../common/custom-form/custom-form.module';
import {PolicyLimitListModule} from '../policy-limit-list/policy-limit-list.module';
import {TabViewModule} from 'primeng/tabview';
import {BonusMalusRequestDetailsComponent} from './components/premium-return/bonus-malus-request-details.component';
import {PolicyContractInfoComponent} from './components/shared/policy-contract-info.component';
import {BonusMalusRequestListComponent} from './components/premium-return/bonus-malus-request-list.component';
import {InquirySharedModule} from '../inquiry/shared/inquiry-shared.module';
import {PolicyMonitoringVersionDetailsComponent} from './policy-monitoring-version-details.component';
import {
  PolicyContractDetailsGuard,
  PolicyContractPreviewGuard,
  PolicyContractTechnicalDetailsGuard,
  PolicyContractVersionSearchGuard,
} from './_guards';
import {PolicyCoInsurersSectionComponent} from './components/sections/cr/policy-co-insurers-section.component';
import {CrSingleRiskProductComponent} from './components/products/cr/cr-single-risk-product.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanyModule,
    CompanySharedModule,
    BrokerContractSharedModule,
    SalesRepSharedModule,
    DocumentModule,
    PolicyContractSharedModule,
    PolicyCessionModule,
    BsDropdownModule,
    BondSharedModule,
    PolicyLimitListModule,
    CustomFormModule,
    TabViewModule,
    InquirySharedModule,
  ],
  declarations: [
    PolicyContractDetailsComponent,
    PolicyContractVersionSearchComponent,
    PolicyContractTechnicalDetailsComponent,
    PolicyContractCompanyListComponent,
    EmbeddablePolicySearchComponent,
    OtherStandardConditionsSectionComponent,
    TerminationSectionComponent,
    PolicyContractPreviewComponent,
    PolicyTabLayoutComponent,
    PolicyMonitoringVersionDetailsComponent,

    // PRODUCT CONTAINERS
    CrPolicyProductComponent,
    ExcessOfLossProductComponent,
    TopUpProductComponent,
    CaptiveProductComponent,
    CrSingleRiskProductComponent,

    // SECTIONS
    FlInsurerSectionComponent,
    PolicyCoInsurersSectionComponent,
    RiskAssessmentSectionComponent,
    CollectionCompaniesSectionComponent,
    TreatiesSectionComponent,

    PolicyContractDataSectionComponent,
    PremiumSectionComponent,
    IndemnificationSectionComponent,
    InsuredsSectionComponent,
    BankAccountSectionComponent,
    CredendoCountriesSectionComponent,
    CountriesSectionComponent,
    CountryGroupsSectionComponent,
    SpecialRiskSectionComponent,
    SpecialClausesCommentSectionComponent,
    DocumentsSectionComponent,
    TechnicalSectionComponent,
    BonusMalusSectionComponent,
    PolicyContractInfoComponent,
    BonusMalusRequestDetailsComponent,
    BonusMalusRequestListComponent,
  ],
  exports: [
    PolicyContractDetailsComponent,
    EmbeddablePolicySearchComponent,
    PolicyContractInfoComponent,
    BonusMalusRequestDetailsComponent,
    BonusMalusRequestListComponent,
  ],
  providers: [
    PolicyContractViewService,
    PolicyContractDetailsGuard,
    PolicyContractPreviewGuard,
    PolicyContractService,
    PolicyContractTechnicalDetailsGuard,
    PolicyContractVersionSearchGuard,
    PolicyMonitoringVersionService,
  ],
})
export class PolicyModule {}
