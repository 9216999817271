import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  DictionaryBaseDto,
  LimitBaseDto,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  LocalDateRange,
} from '../../../bonding_shared/model/dtos';
import {LimitService} from '../../../bonding_shared/services/limit.service';
import {LimitCategory, LimitStatus} from '../../../bonding_shared/model/dictionary-ids';

export class LimitSearchCriteria {
  dataProvider: SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>;
  showRequest = false;

  constructor(private _limitService: LimitService, private portalMode: boolean) {
    this.dataProvider = new SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>(_limitService);
    this.initCriteria();
    this.dataProvider.textSearch = false;
  }

  initCriteria() {
    this.dataProvider.searchCriteria.criteria = <LimitRequestCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.limit = <LimitBaseDto>{};
    this.dataProvider.searchCriteria.criteria.last = true;
    this.dataProvider.searchCriteria.criteria.policyLimitListOwnerIdsAll = [];
    this.dataProvider.searchCriteria.criteria.creationDateRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.decisionValidityRange = <LocalDateRange>{};
    if (!this.portalMode) {
      this.dataProvider.searchCriteria.sumBy = ['amount', '#lastDecision.amount'];
      this.dataProvider.searchCriteria.sumGroupBy = 'currency.id';
    } else {
      this.dataProvider.searchCriteria.criteria.activeProduct = true;
    }
    this.dataProvider.searchCriteria.criteria.limit.businessStatus = <DictionaryBaseDto>{id: LimitStatus.ACTIVE};
    this.dataProvider.searchCriteria.criteria.last = true;
    this.dataProvider.searchCriteria.criteria.limit.category = <DictionaryBaseDto>{id: LimitCategory.POLICY};
  }

  setPolicyMasterPolicyId(masterPolicyContractId: number) {
    this.dataProvider.searchCriteria.criteria.masterPolicyContractId = masterPolicyContractId;
  }
  setBuyerId(buyerId: number) {
    this.dataProvider.searchCriteria.criteria.buyerCompanyId = buyerId;
  }
  setInsuredId(insuredId: number) {
    this.dataProvider.searchCriteria.criteria.insuredId = insuredId;
  }

  cleanCriteria() {
    this.initCriteria();
  }
}
