import {Component} from '@angular/core';
import {Version} from '../../model/index';
import {AppConfigService, VersionInfoService} from '../../services/index';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'version-summary',
  templateUrl: './version-summary.component.pug',
})
export class VersionSummaryComponent {
  frontendVersion: Version;
  backendVersion: Version;

  constructor(http: HttpClient, versionInfoService: VersionInfoService, protected appService: AppConfigService) {
    this.getFrontendGitRevInfo(http).subscribe((frontendVersion) => (this.frontendVersion = frontendVersion));
    versionInfoService.getVersion().subscribe((backendVersion) => (this.backendVersion = backendVersion));
  }

  private getFrontendGitRevInfo(http: HttpClient): Observable<Version> {
    return http.get('./assets/git-rev-info.json', {responseType: 'text'}).pipe(
      map((res) => <Version>JSON.parse(res)),
      filter((res) => res && res.gitBranch && res.gitBranch.charAt(0) !== '$')
    );
  }
}
