import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {RepoDocumentElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../../bonding_shared';

@Injectable()
export class RepositoryDocumentsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAnyRight(state.url, [
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_ALL,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_POLICIES,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_ZSS,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_CLAIMS,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_COMPANIES,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_BONDING,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_LIMITS,
      RepoDocumentElementaryRight.REPO_DOCUMENT_READ_FINANCE,
    ]);
  }
}
