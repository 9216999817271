import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import {RouterService} from '../../../../bonding_shared/services';
import {CompanyRawDto} from '../../../../bonding_shared';

/**
 * Created by wilk on 10.04.2017.
 */

const COMPANY_SECTION_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CompanySectionComponent),
  multi: true,
};

@Component({
  selector: 'company-section',
  template: `
    <selectable-object-section
      *ngIf="!hidden"
      [title]="labelKey ? (labelKey | translate) : label"
      [clearable]="value && clearable && !readOnly"
      [openSelectorEmitter]="openSelectorEmitter"
      (clearObjectEmitter)="onClear()"
      [searchable]="!readOnly"
      [invalid]="invalid"
    >
      <company-info
        [company]="value"
        [companyId]="companyId"
        [showLink]="showLink"
        [rawCompany]="rawCompany"
        [showSegment]="showSegment"
        [showSegmentVip]="showSegmentVip"
        [showNationalId]="showNationalId"
        [showVatNumber]="showVatNumber"
        [showType]="showType"
        [showRating]="showRating"
        [showInsuranceRating]="showInsuranceRating"
        [showLimitRating]="showLimitRating"
        [ratingOnDate]="ratingOnDate"
        [showCountryRating]="showCountryRating"
        [fullDetails]="fullDetails"
        [showResponsible]="showResponsible"
        [showBondingManager]="showBondingManager"
        [showCompanyGroup]="showCompanyGroup"
        [showExpectsFinancialData]="showExpectsFinancialData"
        [clientIdForInternalNumber]="clientIdForInternalNumber"
        [showInternalNumber]="showInternalNumber"
        [showPkd]="showPkd"
        [showNaceCodes]="showNaceCodes"
        [showIndustry]="showIndustry"
        [thirdPartyId]="thirdPartyId"
        [showCompanyGroupHead]="showCompanyGroupHead"
        [showPhysicalPerson]="showPhysicalPerson"
        [showPartnerNo]="showPartnerNo"
        [showPhone]="showPhone"
        [showStatNumber]="showStatNumber"
        [showLimitExposure]="showLimitExposure"
        [viewId]="viewId"
        [parentObjectId]="parentObjectId"
        (internalNumberClicked)="internalNumberClicked.emit()"
      ></company-info>
      <error-message *ngIf="control" [control]="control" [show]="showErrors"></error-message>
      <ng-content></ng-content>
      <ng-container *ngIf="showCompanyRaw">
        <div class="bon-row"></div>
        <form-row label="thirdParty.firstCompanyRawTitle"></form-row>
        <company-info
          [company]="firstCompanyRaw"
          [rawCompany]="true"
          [thirdPartyId]="thirdPartyId"
          [showRawCompanyStatus]="false"
          [showCompanyId]="false"
          [fullDetails]="true"
        ></company-info>
      </ng-container>
    </selectable-object-section>
  `,
  providers: [COMPANY_SECTION_CONTROL_VALUE_ACCESSOR],
})
export class CompanySectionComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() readOnly = false;
  @Input() clearable = true;
  @Input() hidden = false;

  @Input() showLink = false;
  @Input() control: AbstractControl;
  @Input() showErrors: boolean;
  @Input() invalid = false;

  @Input() fullDetails = false;
  @Input() showSegment = false;
  @Input() showSegmentVip = false;
  @Input() showNationalId = true;
  @Input() showVatNumber = true;
  @Input() showType = true;
  @Input() showRating = false;
  @Input() showInsuranceRating = false;
  @Input() showLimitRating = false;
  @Input() showCountryRating = false;
  @Input() showResponsible = false;
  @Input() showCompanyGroup = true;
  @Input() showExpectsFinancialData = false;
  @Input() showPkd = true;
  @Input() showNaceCodes = false;
  @Input() showIndustry = false;
  @Input() showInternalNumber = false;
  @Input() showCreditStatus = false;
  @Input() showPhysicalPerson = true;
  @Input() showPartnerNo = true;

  @Input() openSelectorEmitter: Subject<boolean>;
  @Output() clearObjectEmitter = new EventEmitter<boolean>();

  @Input() rawCompany = false;
  @Input() clientIdForInternalNumber: number;
  @Input() thirdPartyId: number;
  @Input() physicalPersonByLegalForm = false;

  @Input() companyId: number;
  @Input() showBondingManager = false;
  @Input() ratingOnDate: Date;
  @Input() showPhone = false;
  @Input() showCompanyGroupHead = true;
  @Input() firstCompanyRaw: CompanyRawDto;
  @Input() showFirstCompanyRaw = false;
  @Input() showStatNumber = true;
  @Input() showLimitExposure = false;

  @Input() viewId: number;
  @Input() parentObjectId: number;

  @Output() internalNumberClicked = new EventEmitter<void>();

  value: any;
  private onChangeListeners: Function;
  private onTouchedListeners: Function;

  constructor(public router: RouterService) {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onClear() {
    if (!this.readOnly) {
      this.value = undefined;
      this.onChangeListeners(this.value);
    }
    this.clearObjectEmitter.emit(true);
  }

  get showCompanyRaw() {
    return this.showFirstCompanyRaw && this.firstCompanyRaw && this.firstCompanyRaw.manualCreation;
  }
}
