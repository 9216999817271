import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SectionSelector} from '../../../bonding_shared/components/details-view/section-selector';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {CompanyCriteriaDto, ThirdPartyBaseDto} from '../../../bonding_shared/model';
import {Country} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'thirdparty-section-selector',
  template: `
    <ng-template #section let-openEmitter="openEmitter" let-object="object" let-disabled="disabled">
      <company-section
        [labelKey]="sectionLabel"
        [ngModel]="getCompany(propertyValue(object))"
        [name]="property"
        [clearable]="false"
        [openSelectorEmitter]="openEmitter"
        [showLink]="showLink && !!propertyValue(object)?.company"
        [rawCompany]="!propertyValue(object)?.company"
        [readOnly]="disabled"
        [fullDetails]="fullDetails"
        [showType]="!hideCompanyType"
        [showLimitRating]="showLimitRating"
        [showResponsible]="showResponsible"
        [showBondingManager]="showBondingManager"
        [showExpectsFinancialData]="showExpectsFinancialData"
        [showInternalNumber]="showInternalNumber"
        [showPartnerNo]="showPartnerNo"
        [clientIdForInternalNumber]="clientIdForInternalNumber"
        [showCountryRating]="showCountryRating"
        [showCompanyGroupHead]="showCompanyGroupHead"
        [showSegment]="showSegment"
        [thirdPartyId]="propertyValue(object)?.id"
        [firstCompanyRaw]="getFirstCompanyRaw(propertyValue(object))"
        [showFirstCompanyRaw]="showFirstCompanyRaw"
        [showPkd]="showPkd"
        [showLimitExposure]="showLimitExposure"
        [viewId]="viewId"
        [parentObjectId]="parentObjectId"
      >
      </company-section>
    </ng-template>
    <ng-template #selector let-openEmitter="openEmitter" let-onChange="onChange">
      <company-selector-extended
        (selectItem)="onChange($event.thirdParty)"
        [titleKeys]="selectorLabel"
        [open]="openEmitter"
        [initialCriteria]="criteria"
        (createCompanyClicked)="createCompanyClicked.emit()"
        [fixedCountry]="fixedCountry"
        [closeable]="closeable"
        [companyRawFormEnabled]="companyRawFormEnabled"
        [externalSearch]="externalSearch"
        [infoMsgKey]="infoMsgKey"
        [showPersonalData]="showPersonalData"
        [companyCreationPossible]="companyCreationPossible"
        [newCompanyAllFields]="newCompanyAllFields"
      ></company-selector-extended>
    </ng-template>
  `,
  providers: [{provide: SectionSelector, useExisting: ThirdPartySectionSelectorComponent}],
})
export class ThirdPartySectionSelectorComponent extends SectionSelector {
  @Input() showInternalNumber: boolean;
  @Input() clientIdForInternalNumber: number;
  @Input() criteria: CompanyCriteriaDto;
  @Output() createCompanyClicked = new EventEmitter<void>();
  @Input() fullDetails: boolean;
  @Input() hideCompanyType = true;
  @Input() showResponsible;
  @Input() showExpectsFinancialData: boolean;
  @Input() searchModeSwitchable = true;
  @Input() thirdParty: ThirdPartyBaseDto;
  @Input() fixedCountry = false;
  @Input() orderReportTitleKey: string;
  @Input() closeable = false;
  @Input() externalSearch = true;
  @Input() companyRawFormEnabled = false;
  @Input() showBondingManager = false;
  @Input() infoMsgKey: string;
  @Input() companyCreationPossible = true;
  @Input() showLimitRating = false;
  @Input() showLink = true;
  @Input() showPersonalData = true;
  @Input() showCountryRating = false;
  @Input() showPartnerNo = true;
  @Input() showCompanyGroupHead = true;
  @Input() showSegment = false;
  @Input() showFirstCompanyRaw = false;
  @Input() showPkd = true;
  @Input() showLimitExposure = false;
  @Input() newCompanyAllFields = false;

  @Input() viewId: number;
  @Input() parentObjectId: number;

  get countryId() {
    return this.criteria ? this.criteria.address.country.id : undefined;
  }

  readonly Country = Country;

  getCompany(tp: ThirdPartyBaseDto) {
    return BusinessUtils.getThirdPartyCompany(tp).company;
  }

  getFirstCompanyRaw(tp: ThirdPartyBaseDto) {
    if (!tp) {
      return undefined;
    }
    return tp.firstCompanyRaw;
  }
}
