/**
 * Created by siminski on 08.08.2016.
 */
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {AppConfigService, DocumentService, TemplateService} from '../../bonding_shared/services/index';
import {DictionaryBaseDto, DictionaryDto, DocumentTemplateDto, TemplateDto} from '../../bonding_shared/model/dtos';
import {TemplateDetailsGuiService} from './services/index';
import {RouterService} from '../../bonding_shared/services/router-service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {IMultiSelectSettings} from '../../bonding_shared/components/multiselect-dropdown/multiselect-dropdown';
import {DictionaryService} from '../../bonding_shared/services/dictionary.service';
import {BusinessObjectType, DocumentType} from '../../bonding_shared/model/dictionary-ids';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {BackendError} from '../../bonding_shared/model';
import {DtoClass} from '../../bonding_shared/model/classes';

@Component({
  selector: 'template-details',
  templateUrl: 'template-details.component.html',
})
export class TemplateDetailsComponent extends DetailsView implements OnInit {
  dev: boolean;
  template = <TemplateDto>{};
  serverErrors: BackendError;

  additionalValuesBoType: DictionaryBaseDto;
  additionalValuesBoId: number;

  get content(): string {
    if (this.isDocumentTemplate(this.template)) {
      return this.template.content;
    }
  }

  set content(c: string) {
    if (this.isDocumentTemplate(this.template)) {
      this.template.content = c;
    }
  }

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
  };

  public canSave: boolean;
  public bondTypes: DictionaryDto[];
  public bondSubTypes: DictionaryBaseDto[];
  public dictionarySelectors: DictionaryDto[] = [];
  public dictionarySubSelectors: DictionaryBaseDto[] = [];

  private bondTypesById: {[key: number]: DictionaryDto};

  isDocumentTemplate(template): template is DocumentTemplateDto {
    return template.clazz === DtoClass.DocumentTemplateDto;
  }

  constructor(
    public router: RouterService,
    private route: ActivatedRoute,
    private templateService: TemplateService,
    private cd: ChangeDetectorRef,
    public guiService: TemplateDetailsGuiService,
    protected growlService: GrowlService,
    private dictionaryService: DictionaryService,
    private documentService: DocumentService,
    private appService: AppConfigService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.saveButton.disabled = !this.canSave;
    this.cancelButton.hidden = false;
    this.dev = appService.envCode === 'DEV';
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.dictionaryService.getDictionary('BondType').subscribe((bondTypes) => {
      this.serverErrors = undefined;
      this.setBondTypes(bondTypes);
      this.readTemplate(+params['id']);
    });
  }

  private setBondTypes(bondTypes: DictionaryDto[]) {
    this.bondTypes = bondTypes;
    this.bondTypesById = {};
    for (const dict of bondTypes) {
      this.bondTypesById[dict.id] = dict;
    }
  }

  onSave() {
    this.inProgress = true;
    this.serverErrors = undefined;

    this.mergeSelectedTypesAndSubTypesToTemplate();

    this.templateService.save(this.template).subscribe({
      next: (template) => {
        this.template = template;
        this.growlService.notice('Template is saved!');
        this.inProgress = false;
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  onCancel() {
    super.onCancel(this.route);
  }

  onTest() {
    const additionalValuesBoTypeId = this.additionalValuesBoType && this.additionalValuesBoType.id;
    this.documentService
      .generateFromTemplate(
        this.template,
        this.guiService.businessObjectId,
        additionalValuesBoTypeId,
        this.additionalValuesBoId
      )
      .subscribe({
        next: (documentId) => {
          if (additionalValuesBoTypeId) {
            this.router.toDocumentDetailsWithAdditionalValues(
              documentId,
              additionalValuesBoTypeId,
              this.additionalValuesBoId
            );
          } else {
            this.router.toDocumentDetails(documentId);
          }
        },
        error: (error) => (this.serverErrors = error),
      });
  }

  onEditorModeChange(mode: string) {
    this.canSave = mode === 'wysiwyg';
    this.updateCanSave();
    this.cd.detectChanges();
  }

  updateCanSave() {
    this.saveButton.disabled = !this.canSave || !this.template.editionAllowed;
  }

  onTypeSelect() {
    this.refreshSubTypesBasedOnSelectedBondType();
    this.removeSelectedSubtypesNoExistingInAllSubTypes();
  }

  private removeSelectedSubtypesNoExistingInAllSubTypes() {
    const newDictionarySubSelectors: DictionaryBaseDto[] = [];
    if (this.dictionarySubSelectors) {
      for (const bondSubType of this.dictionarySubSelectors) {
        if (this.bondSubTypes.find((dict) => dict.id === bondSubType.id)) {
          newDictionarySubSelectors.push(bondSubType);
        }
      }
    }
    this.dictionarySubSelectors = newDictionarySubSelectors;
  }

  private refreshSubTypesBasedOnSelectedBondType() {
    const bondTypes = this.dictionarySelectors;
    this.bondSubTypes = [];
    if (bondTypes && bondTypes.length > 0) {
      for (const bondType of bondTypes) {
        this.bondSubTypes.push(...bondType.relatedDictionaries['BondSubtype']);
      }
    }
  }

  private readTemplate(id: number) {
    if (id > 0) {
      this.templateService.getTemplate(id).subscribe({
        next: (template) => this.setTemplate(template),
        error: (error) => (this.serverErrors = error),
      });
    } else {
      this.template = <DocumentTemplateDto>{};
      this.template.clazz = DtoClass.DocumentTemplateDto;
    }
  }

  private setTemplate(template: TemplateDto) {
    this.template = template;
    this.mergeTemplateToSelectedTypesAndSubTypes(template);
    this.refreshSubTypesBasedOnSelectedBondType();
    this.updateCanSave();
  }

  private mergeTemplateToSelectedTypesAndSubTypes(template: TemplateDto) {
    this.dictionarySelectors = [];
    this.dictionarySubSelectors = [];
    if (!this.isBondTypesSelector()) {
      return;
    }
    if (template.dictionarySelectors && template.dictionarySelectors.length > 0) {
      for (const bondTypeOrSub of template.dictionarySelectors) {
        if (bondTypeOrSub.id in this.bondTypesById) {
          // istype
          this.dictionarySelectors.push(this.bondTypesById[bondTypeOrSub.id]);
        } else if (this.isBondSubtypesSelector()) {
          // is subtype
          this.dictionarySubSelectors.push(bondTypeOrSub);
        }
      }
    }
  }

  private mergeSelectedTypesAndSubTypesToTemplate() {
    if (!this.isBondTypesSelector()) {
      return;
    }
    const simpleDictSelectors = this.dictToSimpleDict(this.dictionarySelectors);
    if (this.isBondSubtypesSelector()) {
      this.template.dictionarySelectors = [...simpleDictSelectors, ...this.dictionarySubSelectors];
    } else {
      this.template.dictionarySelectors = simpleDictSelectors;
    }
  }

  private dictToSimpleDict(dicts: DictionaryDto[]): DictionaryBaseDto[] {
    return this.clone(dicts).map((dict) => {
      delete dict.properties;
      delete dict.relatedDictionaries;
      return dict;
    });
  }

  private clone<T>(node: T): T {
    return JSON.parse(JSON.stringify(node));
  }

  isBondSubtypesSelector() {
    return this.template?.type?.id === DocumentType.BOND;
  }

  isBondTypesSelector() {
    return this.template?.relatedTo?.id === BusinessObjectType.BOND_VERSION;
  }
}
