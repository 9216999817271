<a-table [pagination]="true" [pageSize]="pageSize" [selection]="false">
  <column labelKey="invoice.shared.list.type" property="invoiceType" sort="invoiceType" dictionary="InvoiceType"></column>
  <column labelKey="invoice.shared.list.number" sort="number" [link]="true" (linkClick)="router.toInvoiceDetails(categoryId, $event.id)" initialsort="down">
    <ng-template let-item="item" let-edition="false">{{item.number ? item.number : 'invoice.shared.list.empty' | translate}}</ng-template>
  </column>
  <column labelKey="invoice.shared.list.status" property="status" sort="status" dictionary="InvoiceStatus"></column>
  <column labelKey="invoice.shared.list.clientName" property="clientName" sort="clientName" [link]="true" (linkClick)="router.toCompanyPreview($event.client.id)"></column>
  <column *ngIf="showContractNumber" labelKey="invoice.shared.list.contractNumber" property="contractNumber" sort="contractNumber"></column>
  <column labelKey="invoice.shared.list.subtype" property="subtypes" sort="subtypes"></column>
  <column [labelKey]="'invoice.shared.list.netAmount'" property="netAmount" sort="netAmount" type="number"></column>
  <column labelKey="invoice.shared.list.currency" property="currency" sort="currency" dictionary="Currency" dictlabel="code"></column>
  <column labelKey="invoice.shared.list.issueDate" property="issueDate" sort="issueDate" type="date"></column>
  <column labelKey="invoice.shared.list.dueDate" property="dueDate" sort="dueDate" type="date"></column>
  <column labelKey="invoice.shared.list.dateFrom" property="dateFrom" sort="dateFrom" type="date"></column>
  <column labelKey="invoice.shared.list.dateTo" property="dateTo" sort="dateTo" type="date"></column>
  <column labelKey="invoice.shared.list.businessUnit" property="businessUnit.name"></column>
</a-table>
