import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  DictionaryBaseDto,
  IMultiSelectSettings,
  InvoiceCriteriaDto,
  InvoicePortalSearchResultDto,
  LocalDateRange,
  LoggedUserService,
  PolicyContractIdDto,
  SearchCriteria,
  SearchDataProvider,
  SettingsService,
} from '../../bonding_shared';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {PortalInvoiceService, PortalPolicyContractService, PortalRouterService} from '../services';
import {BusinessObjectType, InvoiceStatus} from '../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute, Params} from '@angular/router';
import {InvoiceUtils} from '../../bonding_shared/utils/invoice-utils';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {TranslateService} from '@ngx-translate/core';
import {ClientInvoiceListComponent} from './components/client-invoice-list.component';

@Component({
  selector: 'client-invoice-search',
  templateUrl: './client-invoice-search.component.html',
})
export class ClientInvoiceSearchComponent extends SearchView implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    InvoiceCriteriaDto,
    InvoicePortalSearchResultDto
  >;

  @ViewChild(ClientInvoiceListComponent, {static: true}) public invoiceListComponent: ClientInvoiceListComponent;

  public businessObjectTypeId: number;
  public policyContracts: PolicyContractIdDto[];
  public dataProvider: SearchDataProvider<InvoiceCriteriaDto, InvoicePortalSearchResultDto>;

  public readonly PredefinedFilter = PredefinedFilter;

  public readonly statusMultiselectSettings: IMultiSelectSettings = <IMultiSelectSettings>{
    dynamicTitleMaxItems: 6,
  };

  public readonly availableStatusIds = [
    InvoiceStatus.TO_BE_PAID,
    InvoiceStatus.OVERDUE,
    InvoiceStatus.BALANCED,
    InvoiceStatus.WRITTEN_OFF,
    InvoiceStatus.IN_COLLECTION,
    InvoiceStatus.PAID,
  ];

  public readonly settledStatuses = [
    <DictionaryBaseDto>{id: InvoiceStatus.PAID},
    <DictionaryBaseDto>{id: InvoiceStatus.BALANCED},
    <DictionaryBaseDto>{id: InvoiceStatus.WRITTEN_OFF},
  ];

  public readonly unsettledStatuses = [
    <DictionaryBaseDto>{id: InvoiceStatus.TO_BE_PAID},
    <DictionaryBaseDto>{id: InvoiceStatus.OVERDUE},
    <DictionaryBaseDto>{id: InvoiceStatus.IN_COLLECTION},
  ];

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public router: PortalRouterService,
    private settingsService: SettingsService,
    private appConfigService: AppConfigService,
    private injector: Injector,
    public invoiceService: PortalInvoiceService,
    private policyContractService: PortalPolicyContractService,
    public loggedUserService: LoggedUserService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  protected initializeView(params: Params) {
    this.businessObjectTypeId = +params['businessObjectTypeId'];
    const businessObjectId: number = +params['businessObjectId'];
    const businessObjectNumber: string = params['businessObjectNumber'];

    this.dataProvider = new SearchDataProvider<InvoiceCriteriaDto, InvoicePortalSearchResultDto>(this.invoiceService);
    this.initSearchCriteria();

    InvoiceUtils.fillContractLinkCriteria(
      this.dataProvider.searchCriteria.criteria,
      this.businessObjectTypeId,
      businessObjectId
    );

    console.log('this.settingsService.bondingViews:' + this.settingsService.bondingViews);
    console.log('this.settingsService.policyViews:' + this.settingsService.policyViews);

    if (this.businessObjectTypeId) {
      this.dataProvider.searchCriteria.criteria.businessObjectNumber = businessObjectNumber;
      this.initPolicyContracts(this.businessObjectTypeId, businessObjectId);
    } else {
      const linkTypeId = this.getLinkTypeFromAppContext();
      if (linkTypeId) {
        this.dataProvider.searchCriteria.criteria.contractLink.linkType = <DictionaryBaseDto>{
          id: linkTypeId,
        };
      }
    }
    this.cd.detectChanges();
  }

  initSearchCriteria() {
    this.dataProvider.searchCriteria = <SearchCriteria<InvoiceCriteriaDto>>{
      criteria: <InvoiceCriteriaDto>{
        contractLink: this.dataProvider.searchCriteria?.criteria?.contractLink,
        statuses: [],
      },
    };
    this.dataProvider.searchCriteria.criteria.issueDateRange = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.dueDateRange = <LocalDateRange>{};
  }

  initPolicyContracts(businessObjectTypeId: number, businessObjectId: number) {
    if (businessObjectTypeId === BusinessObjectType.POLICY && businessObjectId) {
      this.policyContractService.getPeers(businessObjectId).subscribe((result) => {
        if (result.length > 0) {
          this.policyContracts = [
            <PolicyContractIdDto>{
              masterPolicyId: result[0].masterPolicyId,
              policyYearFormatted: this.translateService.instant('common.all'),
            },
            ...result,
          ];
        } else {
          this.policyContracts = [];
        }
      });
    }
  }

  private getLinkTypeFromAppContext(): number {
    if (this.settingsService.bondingViews) {
      return BusinessObjectType.CONTRACT;
    } else if (this.settingsService.policyViews) {
      return BusinessObjectType.POLICY;
    }
    return undefined;
  }

  applyFilter(filter: PredefinedFilter) {
    this.initSearchCriteria();
    switch (filter) {
      case PredefinedFilter.CLEARED:
        this.dataProvider.searchCriteria.criteria.statuses.push(...this.settledStatuses);
        break;
      case PredefinedFilter.UNCLEARED:
        this.dataProvider.searchCriteria.criteria.statuses.push(...this.unsettledStatuses);
        break;
      default:
        const f: never = filter;
    }
    this.searchView.search();
  }
}

enum PredefinedFilter {
  CLEARED,
  UNCLEARED,
}
