<details-view [title]="'Template'" [objectName]="'Template'" [versionListHidden]="true" [fixedBlockHidden]="false" [parentComponent]="self">
  <div class="details-content">
    <form>
      <div class="bon-header">Template data</div>
<!--      todo: set all fields readonly-->
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <input-row label="Id" [(ngModel)]="template.id" readonly="true" name="id"></input-row>
          <input-row label="Name" [(ngModel)]="template.name" name="name" readonly="true" ></input-row>
          <dict-row label="Type" [(ngModel)]="template.type" dictionary="GeneratedDocumentType" name="type" [disabled]="true"></dict-row>
          <dict-row label="Language" [(ngModel)]="template.language" dictionary="Language" name="language" [disabled]="true"></dict-row>
          <checkbox-row label="Auto select" [(ngModel)]="template.autoselect" [ngModelOptions]="{standalone: true}" [disabled]="true"></checkbox-row>
        </div>
        <div class="bon-card-inner">
          <dict-row label="Related to" [(ngModel)]="template.relatedTo" dictionary="BusinessObjectType" name="relatedTo" [disabled]="true"></dict-row>
          <ng-container *ngIf="isBondTypesSelector()">
            <div class="bon-row">
              <label class="bon-label" translate="common.bondTypes"></label>
              <div class="bon-input-no-underline">
                <ss-multiselect-dropdown [(ngModel)]="dictionarySelectors" name="dictionarySelectors"
                                         [options]="bondTypes" (changeSelection)="onTypeSelect()"
                                         [settings]="multiSelectSettings" [disabled]="true">
                </ss-multiselect-dropdown>
              </div>
            </div>
            <div class="bon-row" *ngIf="isBondSubtypesSelector()">
              <label class="bon-label">Bond subtypes</label>
              <div class="bon-input-no-underline">
                <ss-multiselect-dropdown [(ngModel)]="dictionarySubSelectors" name="dictionarySubSelectors"
                                         [options]="bondSubTypes" *ngIf="bondSubTypes && bondSubTypes.length > 0"
                                         [settings]="multiSelectSettings" [disabled]="true">
                </ss-multiselect-dropdown>
              </div>
            </div>
          </ng-container>
          <div class="bon-row">
            <label class="bon-label">Business unit</label>
            <business-unit-selector class="bon-input-size" [(ngModel)]="template.businessUnit"
                                    name="businessUnit" nullLabel="-" [disabled]="true">
            </business-unit-selector>
          </div>
        </div>
        <div class="bon-card-inner">
          <input-row label="File name" [(ngModel)]="template.fileName" readonly="true" name="fileName"></input-row>
          <div [expandableSection]="false" *ngIf="template.dictionarySelectors && template.dictionarySelectors.length > 0">
            <div class="bon-header" style="margin-left: 0; padding-left: 0"><span translate="template.dictionarySelectors"></span></div>
            <div class="bon-card-group expandableContent" style="margin-left: 0; padding-left: 0">
              <a-table [items]="template.dictionarySelectors" [editable]="false" [selection]="false" [sortable]="false">
                <column labelKey="dictionary.dictName" property="dictName" title="Dictionary type"></column>
                <column labelKey="dictionary.name" property="name"></column>
              </a-table>
            </div>
          </div>
          <div [expandableSection]="false" *ngIf="template.clients && template.clients.length > 0">
            <div class="bon-header" style="margin-left: 0; padding-left: 0"><span translate="common.clients"></span></div>
            <div class="bon-card-group expandableContent" style="margin-left: 0; padding-left: 0">
              <a-table *ngIf="template.clients" [items]="template.clients" [editable]="false" [selection]="false" [sortable]="false">
                <column labelKey="company.common.name" property="registrationName"></column>
                <column labelKey="common.address.address">
                  <ng-template let-item="item" let-edition="false">{{item.address | address: false}}</ng-template>
                </column>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dev" class="bon-header">Additional values</div>
      <div *ngIf="dev" class="bon-card-group">
        <div class="bon-card-inner">
          <dict-row label="Business object type" dictionary="BusinessObjectType" [(ngModel)]="additionalValuesBoType" name="additionalValuesBoType"></dict-row>
        </div>
        <div class="bon-card-inner">
          <input-row label="Business object Id" [(ngModel)]="additionalValuesBoId" type="number" name="additionalValuesBoId"></input-row>
        </div>
        <div class="bon-card-inner"></div>
      </div>
      <div *ngIf="isDocumentTemplate(template)">
        <div class="bon-header">Template content</div>
        <div class="bon-card-group">
          <div class="bon-card-inner">
            <div class="bon-row">
              <html-editor #editor [(ngModel)]="content" (modeChange)="onEditorModeChange($event)" name="content"
                           [height]="480" [readonly]="!template.editionAllowed">
              </html-editor>
            </div>
            <input-row maxlength="9" label="Test business object id (i.e bond version id)" [(ngModel)]="guiService.businessObjectId" name="businessObjectId"></input-row>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="additional-buttons">
    <button type="button" (click)="onTest()" class="bon-btn-warning"
            [disabled]="!canSave || !guiService.businessObjectId || !(template?.relatedTo?.id)">Test</button>
  </div>
</details-view>
