import {Section} from './section';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DictionaryDto} from '../../../../bonding_shared/model';
import {IMultiSelectSettings} from '../../../../bonding_shared/components/multiselect-dropdown';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {AppConfigService, DictionaryService} from '../../../../bonding_shared/services';

@Component({
  selector: 'country-groups-section',
  templateUrl: './country-groups-section.component.pug',
})
export class CountryGroupsSectionComponent extends Section implements OnInit {
  self = this;

  public insuredCountryGroupsOptions: DictionaryDto[];

  @Output() countryTurnoverChanged = new EventEmitter();

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    showCheckAll: true,
    showUncheckAll: true,
  };

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected dictionaryService: DictionaryService,
    public appService: AppConfigService
  ) {
    super(policyContractViewService, appService);
  }

  ngOnInit(): void {
    this.dictionaryService.getDictionary('EconomicCountryGroup').subscribe((data) => {
      for (const group of data) {
      }
      this.insuredCountryGroupsOptions = data;
    });
  }

  emitCountryTurnoverChanged() {
    this.countryTurnoverChanged.emit();
  }
}
