import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {ServiceNoteElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../../bonding_shared';

@Injectable()
export class ServiceNotesGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAnyRight(state.url, [
      ServiceNoteElementaryRight.SERVICE_NOTE_READ_ALL,
      ServiceNoteElementaryRight.SERVICE_NOTE_READ_POLICIES,
      ServiceNoteElementaryRight.SERVICE_NOTE_READ_CLAIMS,
      ServiceNoteElementaryRight.SERVICE_NOTE_READ_COMPANIES,
      ServiceNoteElementaryRight.SERVICE_NOTE_READ_BONDING,
      ServiceNoteElementaryRight.SERVICE_NOTE_READ_FINANCE,
    ]);
  }
}
