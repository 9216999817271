import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExternalProviderRelationDto} from '../model';
import {AbstractAuditedRelatedService} from './abstract-audited-related.service';

@Injectable()
export class ClaimExternalProvidersService extends AbstractAuditedRelatedService<ExternalProviderRelationDto[]> {
  protected midUrl = 'externalProviders';
  protected url = this.urlPrefix + 'claimVersion';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }
}
