import {ClientLimitSearchComponent} from './client-limit-search.component';
import {LimitDetailsComponent} from '../../bonding/limit/limit-details.component';
import {ClientLimitCoverPlusViewComponent} from './client-limit-cover-plus-view.component';

export const ClientLimitRoutes = [
  {
    path: 'limit-details/:id',
    component: LimitDetailsComponent,
    data: {module: 'limits'},
  },
  {
    path: 'client-limit-search',
    component: ClientLimitSearchComponent,
    data: {module: 'limits'},
  },
  {
    path: 'limit-details/:id/:insuredId/:policyId/:buyerId/:typeId',
    component: LimitDetailsComponent,
    data: {module: 'limits'},
  },
  {
    path: 'client-limit-cover-plus-view/:id',
    component: ClientLimitCoverPlusViewComponent,
    data: {module: 'limits'},
  },
];
