<ng-container *ngIf="!value?.relatedToId">
  <div class="bon-row">
    <label class="bon-label" translate>shared.businessObjectSelector.relatedTo</label>
    <dict-combo
      class="bon-input-size"
      [formControl]="relatedTo"
      nullLabel=" "
      dictionary="BusinessObjectType"
      [codeRegexp]="codeRegExp"
      parentDictionary="ApplicationModule"
      [parentDictionaryEntryId]="parentDictionaryEntryId"
      [allForEmptyParent]="true"
      [profileId]="profileId">
    </dict-combo>
  </div>
  <div class="bon-row" *ngIf="relatedTo?.value?.id">
    <label class="bon-label" translate>shared.businessObjectSelector.searchPhrase</label>
    <input
      type="string"
      [formControl]="searchPhrase"
      (keyup.enter)="executeSearch()"
      class="bon-input">
    <span>
        <i class="fa fa-search" (click)="executeSearch()" title="Search"></i>
      </span>
    <span class="warning" *ngIf="tooManyResults == true" translate>shared.businessObjectSelector.tooManyResults</span>
    <backend-error [errors]="serverErrors"></backend-error>
  </div>
  <div *ngIf="businessObjectsResults && businessObjectsResults.length > 0"
       class="bon-row">
    <label class="bon-label" translate>shared.businessObjectSelector.available</label>
    <div class="bon-input-no-underline">
      <item-combo
        [formControl]="bo"
        [items]="businessObjectsResults"
        nullLabel=" "
        key="relatedToId">
        <ng-template let-item="item">{{item.preciseDescription}}</ng-template>
      </item-combo>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="value?.relatedToId">
  <business-object-details [showLabel]="showLabel" [disabled]="disabled" [value]="value" [showChange]="businessObjectsResults && businessObjectsResults.length > 0"
                           [showRemove]="true"
                           (changeValue)="change()" (removeValue)="remove()"></business-object-details>
</ng-container>
