import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {DictionaryProfile} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'indemnification-section',
  templateUrl: './indemnification-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class IndemnificationSectionComponent extends Section {
  self = this;
  readonly DictionaryProfile = DictionaryProfile;
  constructor(
    protected policyContractViewService: PolicyContractViewService,
    appService: AppConfigService,
    protected translateService: TranslateService
  ) {
    super(policyContractViewService, appService);
  }

  updateRiskThresholdValidity() {
    if (this.policyForm.get('riskThreshold')) {
      this.policyForm.get('riskThreshold').updateValueAndValidity();
    }
  }
}
