import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContactNoteService} from '../../bonding_shared/services/contact-note.service';
import {
  CompanyBaseDto,
  CompanyRawDto,
  CompanySimpleDto,
  ContactNoteCriteriaDto,
  ContactNoteDto,
  ContactNoteReasonDto,
  ContactPersonCriteriaDto,
  ContactPersonDto,
  DictionaryBaseDto,
  LocalDateRange,
  Page,
  SearchCriteria,
  SearchResult,
  UserDto,
} from '../../bonding_shared/model/dtos';
import {CompanyService} from '../../bonding_shared/services/company.service';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {RouterService} from '../../bonding_shared/services/router-service';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {
  AppConfigService,
  BusinessObjectService,
  FeatureService,
  SearchDataProvider,
  SettingsService,
} from '../../bonding_shared/services';
import {ComboItem} from '../../bonding_shared/components/combos';
import {UserNamePipe} from '../../bonding_shared/pipes/user-name.pipe';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  ApplicationModule,
  ContactNoteElementaryRight,
  ElementaryRight,
  Feature,
} from '../../bonding_shared/model/dictionary-ids';
import {ContactNoteDetailsGuard} from '../contactNote/components/shared/_guards/contact-note-details.guard';

@Component({
  selector: 'company-crm',
  templateUrl: './company-crm.component.pug',
})
export class CompanyCRMComponent extends DetailsView implements OnInit {
  public companyId: number;
  contactPersonCriteria: ContactPersonCriteriaDto = <ContactPersonCriteriaDto>{};
  contactNotes: ContactNoteDto[];
  dataProvider: SearchDataProvider<ContactNoteCriteriaDto, ContactNoteDto>;
  totalCount = 0;
  pageSize = 5;
  pageSizes: ComboItem[] = [
    new ComboItem('5', '5'),
    new ComboItem('10', '10'),
    new ComboItem('25', '25'),
    new ComboItem('100', '100'),
  ];

  page = 1;

  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ElementaryRight = ElementaryRight;

  hiddenModules = new Set([ApplicationModule.ADMINISTRATION]);
  filterContactReasonByBusinessObjectType = false;
  parentDictionaryForContactReason: string;

  constructor(
    public appService: AppConfigService,
    private contactNoteService: ContactNoteService,
    private companyService: CompanyService,
    private loggedUserService: LoggedUserService,
    private _route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService,
    private businessObjectService: BusinessObjectService,
    public contactNoteDetailsGuard: ContactNoteDetailsGuard,
    public _settings: SettingsService,
    featureService: FeatureService
  ) {
    super(growlService);
    this.filterContactReasonByBusinessObjectType = featureService.get(
      Feature.FILTER_CONTACT_REASON_BY_BUSINESS_OBJECT_TYPE
    );
  }

  ngOnInit(): void {
    if (this.filterContactReasonByBusinessObjectType) {
      this.parentDictionaryForContactReason = 'BusinessObjectType';
    }
    this.selectorNameList = ['ContactPerson', 'Company'];
    this.initializeSelectorEmitters(true);
    console.log('CompanyCRMComponent :: ngOnInit');
    this.dataProvider = new SearchDataProvider<ContactNoteCriteriaDto, ContactNoteDto>(this.contactNoteService);
    this.dataProvider.searchCriteria = <SearchCriteria<ContactNoteCriteriaDto>>{
      criteria: <ContactNoteCriteriaDto>{
        company: <CompanyBaseDto>{},
        contactDateRange: <LocalDateRange>{},
        contactNoteReason: <ContactNoteReasonDto>{},
      },
    };
    this.dataProvider.searchCriteria.criteria.applicationModule = <DictionaryBaseDto>{
      id: this._settings.getActiveModuleId(),
    };
    this.initSearchWithCriteria();
  }

  search() {
    this.dataProvider
      .search({start: 0, count: this.pageSize, countOnly: false})
      .subscribe((res) => this.resultsReceived(res));
  }

  initSearchWithCriteria() {
    this._route.queryParams.subscribe((params) => {
      if (params['companyId'] && params['companyId'] > 0) {
        this.companyId = params['companyId'];
        this.dataProvider.searchCriteria.criteria.companyId = this.companyId;
        this.companyService.getCompanySimple(this.companyId).subscribe({
          next: (company) => {
            this.dataProvider.searchCriteria.criteria.company = company;
            this.onCompanySelect(company);
            this.search();
          },
          error: (error) => console.log(error),
        });
      }
      if (params['boType'] && params['boType'] > 0 && params['boId'] && params['boId'] > 0) {
        this.businessObjectService.getDto(params['boType'], params['boId']).subscribe((bo) => {
          this.dataProvider.searchCriteria.criteria.contactNoteReason.businessObject = bo;
          this.search();
        });
      } else {
        this.search();
      }
    });
  }

  resultsReceived(res: SearchResult<ContactNoteDto>) {
    this.contactNotes = res.result;
    this.totalCount = res.size;
  }

  onSelectedContactNote(c: ContactNoteDto) {
    if (c) {
      this.router.toContactNoteDetails(c.id, c.company.id);
    }
  }

  onChangePageSize(event: any) {
    this.dataProvider
      .search({start: 0, count: this.pageSize, countOnly: false})
      .subscribe((res) => this.resultsReceived(res));
  }

  onUserChange(item: UserDto) {
    this.dataProvider.searchCriteria.criteria.createdBy = item;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  paginationPageChanged(event: PageChangedEvent) {
    this.page = event.page;
    this.dataProvider.search(this.getPage()).subscribe((res) => this.resultsReceived(res));
  }

  getPage(): Page {
    const page = <Page>{};
    page.count = this.pageSize;
    page.start = (this.page - 1) * this.pageSize;
    return page;
  }

  onCompanySearchBoxClear() {
    this.dataProvider.searchCriteria.criteria.company = null;
    this.dataProvider.searchCriteria.criteria.contactPerson = null;
    this.companyId = null;
    this.contactPersonCriteria = <ContactPersonCriteriaDto>{};
  }

  onCompanySelect(c: CompanySimpleDto | CompanyRawDto) {
    this.dataProvider.searchCriteria.criteria.company = c;
    this.contactPersonCriteria = <ContactPersonCriteriaDto>{};
    this.contactPersonCriteria.companyId = c.id;
    this.companyId = c.id;
  }

  onContactPersonSelect(cp: ContactPersonDto) {
    this.dataProvider.searchCriteria.criteria.contactPerson = cp;
  }

  create() {
    this.router.toContactNoteDetails(0);
  }

  get applicationModuleDisabled(): boolean {
    return !this.loggedUserService.hasRight(ElementaryRight.CRM_MODULE_CHANGE);
  }

  get getBOParentId(): number {
    if (
      this.dataProvider.searchCriteria.criteria &&
      this.dataProvider.searchCriteria.criteria.applicationModule &&
      this.dataProvider.searchCriteria.criteria.applicationModule.id
    ) {
      return this.dataProvider.searchCriteria.criteria.applicationModule.id;
    }
    return this._settings.getActiveModuleId();
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
