<search-view objectName="Task" customTitle="searchView.taskSearch" (searchExecuted)="searchStarted()" [atable]="taskListCmp.taskTable" newObjectLabelKey="task.search.newTask"
             [dataProvider]="taskSearchService.searchDataProvider" [searchModeSwitchable]="false" [performInitialSearch]="false">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner bon-label-150">
      <id-row labelKey="task.search.criteria.id" [(ngModel)]="criteria.id" name="id" ></id-row>
      <input-row labelKey="task.search.criteria.title" [(ngModel)]="criteria.title" name="title"></input-row>
      <dict-row labelKey="task.search.criteria.status" [(ngModel)]="criteria.status" dictionary="TaskStatus" name="status" nullLabelKey="task.search.all.status"></dict-row>
      <dict-row labelKey="task.search.criteria.type" *ngIf="appConfig.credendo" [(ngModel)]="criteria.type" dictionary="TaskType" name="type" nullLabelKey="task.search.all.type"></dict-row>
      <checkbox-row labelKey="task.search.onlyMine" (changeState)="onlyMineChanged($event)" [(ngModel)]="criteria.assignedToMe" name="onlyMine" hooverMsg="task.search.onlyMineTooltip" hooverColor="blue"></checkbox-row>
      <checkbox-row labelKey="task.search.createdByMe" [(ngModel)]="criteria.createdByMe" name="createdByMe" hooverMsg="task.search.createdByMeTooltip" hooverColor="blue"></checkbox-row>
    </div>

    <div class="bon-card-inner">
      <business-object-selector [(ngModel)]="criteria.businessObject" [showLabel]="true"
         name="businessObject" [codeRegExp]="relatedToRegExp"
         (ngModelChange)="businessObjectChanged($event)"></business-object-selector>
      <ng-container *ngIf="allowBOVersions()">
        <checkbox-row labelKey="task.search.includeVersions" [(ngModel)]="criteria.includeBoVersions" name="includeVersions"></checkbox-row>
      </ng-container>
      <date-range-row labelKey="task.search.criteria.dueDate" [value]="criteria.dueDateRange"></date-range-row>
    </div>

    <div class="bon-card-inner">
      <div class="bon-row" *ngIf="!criteria.assignedToMe">
        <label class="bon-label" translate>task.search.criteria.businessUnit</label>
        <business-unit-id-selector class="bon-input-size" [(ngModel)]="criteria.businessUnit" (changeItem)="buChanged($event)"
                                   name="businessUnit" nullLabel="{{ 'task.search.all.bu' | translate }}"></business-unit-id-selector>
      </div>
      <form-row labelKey="task.search.criteria.user" *ngIf="!criteria.assignedToMe">
        <item-combo class="bon-input-size" [items]="usersForBu" [(ngModel)]="criteria.user"
                   nullLabel="{{ 'task.search.all.user' | translate }}" name="assignedTo">
          <ng-template let-item="item">{{item.familyName + ' ' + item.name}}</ng-template>
        </item-combo>
      </form-row>
      <form-row labelKey="task.search.criteria.group" *ngIf="!criteria.assignedToMe">
        <item-combo class="bon-input-size" [items]="groupsForBu" [(ngModel)]="criteria.userGroup"
                    nullLabel="{{ 'task.search.all.group' | translate }}">
          <ng-template let-item="item">{{ item.name | translate}}</ng-template>
        </item-combo>
      </form-row>
    </div>
  </div>
  <div class="additional-buttons">
    <button *hasRight="'TASK_MASS_ASSIGNMENT'" class="bon-btn-success" type="button" [disabled]="! ( taskListCmp?.taskTable?.items?.length  > 0 ) && !taskSearchExecuting " (click)="massAssignPopupOpen()" translate>task.search.massAssign</button>
    <button class="bon-btn-success" type="button" [disabled]="taskListCmp?.taskTable?.items?.length < 1" (click)="massClosurePopupOpen()" translate>task.search.massClosure.button</button>
    <button class="bon-btn-success" type="button" [disabled]="! ( taskListCmp?.taskTable?.items?.length  > 0 ) " (click)="generateReport()" btnIcon="fa-file-text-o" translate>task.search.historyReport</button>
  </div>
  <div class="search-results">
    <task-list [pageSize]="20" [dataProvider]="taskSearchService.searchDataProvider"></task-list>
  </div>
</search-view>

<form-dialog size="md" [parentComponent]="self">
  <form dialogForm form #ngForm="ngForm">
    <div class="bon-card-group">
      <div class="bon-card-inner" style="width: 50%;" ngModelGroup="dates" #datesModel="ngModelGroup" userOrGroup>
        <form-row labelKey="task.search.massUser">
          <item-combo class="bon-input-size" [items]="usersForMassAssign" [(ngModel)]="massAssignUser"
                      nullLabel=" " name="user">
            <ng-template let-item="item">{{item.familyName + ' ' + item.name}}</ng-template>
          </item-combo>
        </form-row>
        <form-row labelKey="task.search.massGroup">
          <item-combo class="bon-input-size" [items]="groupsForMassAssign" [(ngModel)]="massAssignGroup" name="userGroup"
                      nullLabel=" ">
            <ng-template let-item="item">{{item.name}}</ng-template>
          </item-combo>
        </form-row>
        <span class="inline invalid" *ngIf="datesModel.hasError('missingAssignee') && showErrors" translate>task.data.missingAssignee</span>
      </div>
    </div>
  </form>
</form-dialog>

<confirm-dialog #massClosure></confirm-dialog>
