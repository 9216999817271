import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BondingContractExposureDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  ComboItem,
  RouterService,
  SearchDataProvider,
  VersionPhase,
} from '../../../../bonding_shared';
import {TranslateService} from '@ngx-translate/core';
import {BondGuiService} from '../../services';

@Component({
  selector: 'filterable-bond-version-list',
  templateUrl: './filterable-bond-version-list.component.pug',
})
export class FilterableBondVersionListComponent implements AfterViewInit {
  @ViewChild(ATableComponent, {static: true}) bondTable: ATableComponent<BondVersionSimpleDto>;
  @Input() pageSize = 20;
  @Input() title = 'common.bonds';
  @Input() hiddenClientColumn = false;
  @Input() hiddenBeneficiaryColumn = false;
  @Input() hiddenContractColumn = false;
  @Input() hiddenCreationDateColumn = false;
  @Input() showCompanyLinks = true;
  @Input() showBondLink = true;
  @Input() showExposure = false;
  @Input() exposure: BondingContractExposureDto;
  @Input() contractVersionNumber: string;

  @Input() set dataProvider(dp: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>) {
    if (!dp) {
      return;
    }
    dp.searchCriteria.criteria.versionPhase = 'ACTIVE';
    this.bondTable.pagination = true;
    this.bondTable.dataProvider = dp;
    this.filter = FilterItem[FilterItem.ACTIVE];
    this.applyFilter();
  }

  filters: ComboItem[];
  filter: String = FilterItem[FilterItem.ACTIVE];

  constructor(
    public router: RouterService,
    private translateService: TranslateService,
    public appService: AppConfigService,
    private bondGuiService: BondGuiService
  ) {
    this.filters = [
      new ComboItem(FilterItem[FilterItem.REQUEST], translateService.instant('common.requests')),
      new ComboItem(FilterItem[FilterItem.ACTIVE], translateService.instant('common.activeBonds')),
    ];
    this.filters.push(new ComboItem(FilterItem[FilterItem.BOND], translateService.instant('common.bonds')));
  }

  ngAfterViewInit(): void {
    this.bondTable.search();
  }

  onChange(item: string) {
    this.applyFilter();
  }

  applyFilter() {
    switch (this.filter) {
      case FilterItem[FilterItem.REQUEST]:
        this.setCriteria(false, 'LAST', undefined);
        break;
      case FilterItem[FilterItem.BOND]:
        this.setCriteria(true, 'LAST', undefined);
        break;
      case FilterItem[FilterItem.ACTIVE]:
        this.setCriteria(true, 'ACTIVE', undefined);
        break;
      case FilterItem[FilterItem.BOND_EXPIRED]:
        this.setCriteria(true, 'LAST', true);
        break;
      default:
        this.setCriteria(undefined, undefined, undefined);
        break;
    }
    this.bondTable.pagination = true;
    this.bondTable.search();
  }

  setCriteria(activated: boolean, versionPhase: VersionPhase, expired: boolean) {
    (<SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>>(
      this.bondTable.dataProvider
    )).searchCriteria.criteria.activated = activated;
    (<SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>>(
      this.bondTable.dataProvider
    )).searchCriteria.criteria.versionPhase = versionPhase;
    (<SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>>(
      this.bondTable.dataProvider
    )).searchCriteria.criteria.expired = expired;
  }

  emptyList(): boolean {
    return !this.bondTable.items || this.bondTable.items.length < 1;
  }

  toBondSearch() {
    this.bondGuiService.initializeCriteria();
    this.bondGuiService.searchDataProvider.searchCriteria.criteria.contractNumber = this.contractVersionNumber;
    this.router.toBondSearch();
  }
}

export enum FilterItem {
  REQUEST,
  BOND,
  ACTIVE,
  BOND_EXPIRED,
}
