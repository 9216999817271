import {Component, OnInit} from '@angular/core';
import {
  AppConfigService,
  BackendError,
  CompanyGroupDto,
  CompanyGroupVersionDto,
  CompanyWithExposureDto,
  GroupService,
  RouterService,
  StringUtils,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'group-preview',
  templateUrl: 'group-preview.component.pug',
})
export class GroupPreviewComponent implements OnInit {
  public group: CompanyGroupDto;
  public groupVersion: CompanyGroupVersionDto;
  public errors: BackendError;

  constructor(
    private appConfigService: AppConfigService,
    private groupService: GroupService,
    private activatedRoute: ActivatedRoute,
    public routerService: RouterService
  ) {}

  public get insuranceExposureSum(): number {
    return _.sumBy(
      this.groupVersion.groupCompanies,
      (groupCompany: CompanyWithExposureDto) => groupCompany.insuranceExposure
    );
  }

  public get insuranceExposureSumWithCurrency(): String {
    return (
      StringUtils.formatNumber(this.insuranceExposureSum) +
      ' ' +
      this.groupVersion?.groupCompanies[0]?.exposureCurrency?.code
    );
  }

  public get bondExposureSum(): number {
    return _.sumBy(this.groupVersion.groupCompanies, (groupCompany: CompanyWithExposureDto) => groupCompany.exposure);
  }

  public get bondExposureSumWithCurrency(): String {
    return (
      StringUtils.formatNumber(this.bondExposureSum) +
      ' ' +
      this.groupVersion?.groupCompanies[0]?.exposureCurrency?.code
    );
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => this.initializeView(params));
  }

  private initializeView(params: Params): void {
    const id = params['id'];
    this.loadGroup(id);
  }

  private loadGroup(groupId: number) {
    this.groupService.getById<CompanyGroupDto>(groupId).subscribe(
      (group) => this.onNewGroup(group),
      (error) => (this.errors = error)
    );
  }

  private onNewGroup(group: CompanyGroupDto): void {
    this.group = group;
    console.log(group);
    this.groupVersion = group.companyGroupVersions[group.companyGroupVersions.length - 1];
  }
}
