import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormGroup} from '@angular/forms';
import {
  AddressAutocompleteService,
  AddressDto,
  AppConfigService,
  AutoprolongationDto,
  BinaryDownloaderService,
  BondDto,
  BondValueDto,
  BondVersionCriteriaDto,
  BondVersionDto,
  BondVersionOutputDto,
  BondVersionService,
  BondVersionSimpleDto,
  CacheService,
  ClaimService,
  CompanyPortalDto,
  CompanySimpleDto,
  ConfirmDialogComponent,
  ContractBaseDto,
  ContractBondDto,
  ContractService,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  DictionaryBaseDto,
  DictionaryDto,
  DictionaryService,
  DocumentDescriptionDto,
  DocumentService,
  FeatureService,
  GrowlService,
  LifecycleDropdownSimpleComponent,
  LoggedUserService,
  PropertyService,
  RelatedObject,
  RouterService,
  SearchDataProvider,
  SettableObjectType,
  StateTransitionDto,
  StatisticsDto,
  StringUtils,
  TemplateSimpleDto,
  View,
} from '../../bonding_shared';
import {BondGuiService} from './services';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppProperty,
  BOND_ACTIVATED,
  BondingContractDocumentType,
  BondingElementaryRight,
  BondStatus,
  BondSubType,
  BondType,
  BusinessObjectType,
  ContactNoteElementaryRight,
  ContractType,
  ElementaryRight,
  Feature,
  RepoDocumentCategory,
  RepoDocumentElementaryRight,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {BondSubtypeSelectorComponent} from './components/bond-subtype-selector.component';
import {Observable} from 'rxjs';
import {CompanyViewParams} from '../company';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {GroupedItem} from '../../bonding_shared/components/item-dropdown/item-grouped-dropdown.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'bond-details',
  templateUrl: './bond-details.component.pug',
  providers: [AddressAutocompleteService],
})
export class BondDetailsComponent extends DetailsView implements OnInit {
  showLinkedClaims = false;
  showProductCode = false;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  readonly FINAL_PREVIEW_ENDPOINT = 'bondVersion/genFinalPreview/';
  readonly PREVIEW_ENDPOINT = 'portal/bondVersion/preview/';
  readonly BondingElementaryRight = BondingElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly BondType = BondType;
  readonly BondStatus = BondStatus;
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];
  urlPrefix: string;
  bond: BondVersionDto;
  bondVersions: BondVersionSimpleDto[];

  @ViewChild('deleteConfirm', {static: true}) confirmDialog: ConfirmDialogComponent;
  @ViewChild('newContractVersionConfirm', {static: true}) newContractVersionDialog: ConfirmDialogComponent;
  @ViewChild('lifecycleDropdown', {static: true}) lifecycleDropdown: LifecycleDropdownSimpleComponent;

  @ViewChild('bondSubtype')
  set bondSubtypeSelector(cmp: BondSubtypeSelectorComponent) {
    if (cmp) {
      this._bondSubtypeSelector = cmp;
    }
  }

  get bondSubtypeSelector(): BondSubtypeSelectorComponent {
    return this._bondSubtypeSelector;
  }

  _bondSubtypeSelector: BondSubtypeSelectorComponent;

  @ViewChild('formComponent')
  set formComponent(cmp: any) {
    if (cmp) {
      this._formComponent = cmp;
    }
  }

  _formComponent: any;
  get formComponent() {
    return this._formComponent;
  }

  showDetails = false;

  selectedCompany: CompanySimpleDto;
  selectedContractBond: ContractBondDto;
  selectedContractVersion: ContractVersionDto = <ContractVersionDto>{contract: <ContractBaseDto>{}};
  selectedBeneficiary: CompanySimpleDto | CompanyPortalDto;

  templates: TemplateSimpleDto[];
  templatesGrouped: GroupedItem[] = [];

  contractCriteria: ContractVersionCriteriaDto;

  tempContractVersion: ContractVersionDto; // seems redundant, could be replaced with selectedContractVErsion?

  bondIssueDate: Date;
  productCode: string;

  public bondSubtypes: {[key: number]: DictionaryDto} = {};

  specialTextUploadFile: DocumentDescriptionDto = null;
  getCustomTransitions: (obj) => Observable<StateTransitionDto[]>;
  public statistics: StatisticsDto;
  beneficiaryChanged = false;

  readonly BusinessObjectType = BusinessObjectType;
  readonly BondingContractDocumentType = BondingContractDocumentType;

  constructor(
    private documentService: DocumentService,
    private _route: ActivatedRoute,
    public guiService: BondGuiService,
    public router: RouterService,
    private dictService: DictionaryService,
    protected contractService: ContractService,
    protected growlService: GrowlService,
    private binaryDownloader: BinaryDownloaderService,
    public apiService: AppConfigService,
    public bondService: BondVersionService,
    private propertyService: PropertyService,
    private cacheService: CacheService,
    private loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    public claimService: ClaimService,
    private featureService: FeatureService,
    private translateService: TranslateService
  ) {
    super(growlService);
    this.urlPrefix = this.apiService.backendUrl;
    this.selectorNameList = [
      RelatedObject.CONTRACT,
      RelatedObject.CLIENT,
      RelatedObject.BENEFICIARY,
      RelatedObject.BOND_TYPE,
      RelatedObject.BUYER,
    ];
    this.initializeSelectorEmitters(true);
    this.initializeContractSearchCriteria();
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));

    this.dictService.getDictionary('BondSubtype').subscribe((entries) => {
      for (const entry of entries) {
        this.bondSubtypes[entry.id] = entry;
      }
    });
    this.form = this.ngForm.form;
    this.form.setControl('executionAddress', new UntypedFormGroup({}));
  }

  initializeView(params: Params, force?: boolean) {
    this.objectNotFound = false;
    const id = +params['id'];
    const clientId = +params['clientId'];
    const contractId = +params['contractId'];
    const typeId = +params['typeId'];
    if (this.bond && this.bond.id === id && !force) {
      return;
    }
    this.guiService.clearCache();
    this.initializeBond(id, clientId, contractId, typeId);
    if (this.portal && id === 0) {
      this.onSelectClient(this.loggedUserService.getLoggedUserData().company);
    }
  }

  get bidBondInPortal() {
    return false;
  }

  initializeBond(id: number, clientId: number, contractId: number, typeId: number) {
    this.serverErrors = undefined;
    if (!this.bond) {
      this.bond = <BondVersionDto>{};
      this.bond.bond = <BondDto>{};
      this.bond.executionAddress = <AddressDto>{};
      this.bond.values = [];
      this.bond.values[0] = <BondValueDto>{};
      this.bond.autoprolongation = <AutoprolongationDto>{};
      this.bond.autoprolongation.autoprolongate = false;
      this.bond.output = <BondVersionOutputDto>{};
    }
    if (id > 0) {
      this.getBondVersion(id);
    } else if (id === 0) {
      this.initCompany(clientId);
      this.initContractVersionForContract(contractId, typeId);
      this.selectedBeneficiary = undefined;
      this.selectedContractBond = undefined;
      this.bond.subtype = undefined;
      this.showDetails = false;
    } else {
      this.objectNotFound = true;
      this.showDetails = false;
    }
  }

  initializeContractSearchCriteria() {
    this.contractCriteria = <ContractVersionCriteriaDto>{};
    this.contractCriteria.presentNow = true;
    this.contractCriteria.status = <DictionaryDto>{};
    this.contractCriteria.activated = true;
  }

  setBond(bond: BondVersionDto) {
    this.bond = bond;
    this.initCompany(bond.clientId);
    this.initContractVersion(bond.contractId, bond.type.id);

    this.setBeneficiary();
    // retrieve creation warnings from cache after page is reloaded after bond creation
    if (this.guiService.justCreatedBond && this.guiService.justCreatedBond.id === bond.id) {
      this.serverErrors = this.guiService.justCreatedBond.warnings;
    } else {
      this.serverErrors = this.bond.warnings;
    }
    this.guiService.justCreatedBond = undefined;
    this.loadTemplates();
    this.handleButtons();
    this.refreshBondVersionList();

    this.specialTextUploadFile = null;
    if (this.bond && this.bond.subtype.id === BondSubType.TECHNICAL_USER_SPECIAL_TEXT) {
      this.bondService.getSpecialTextDto(bond).subscribe((doc) => {
        this.specialTextUploadFile = doc;
      });
    }
    this.setGetCustomTransitions();
    this.showDetails = true;
    this.beneficiaryChanged = false;
    if (this.featureService.get(Feature.SHOW_BOND_LINKED_CLAIMS)) {
      this.claimService
        .getBondClaims(this.bond.bond.id)
        .subscribe((result) => (this.showLinkedClaims = result.length !== 0));
    }
    this.showProductCode = this.featureService.get(Feature.SHOW_BOND_PRODUCT_CODE);
  }

  get autoselectBondProductSymbol(): boolean {
    return this.featureService.get('bond.autoselectBondProductSymbol');
  }

  get bondType(): DictionaryBaseDto {
    return this.selectedContractBond ? this.selectedContractBond.bondType : this.bond.type;
  }

  private setGetCustomTransitions() {
    const service = this.guiService.bondService;
    // method is not custom, but this way allows to reload transitions when setting bond
    this.getCustomTransitions = service.getTransitionsForObject.bind(service);
  }

  setBeneficiary() {
    if (this.bond.beneficiaryId) {
      this.guiService.getBeneficiary(this.bond.beneficiaryId).subscribe((b) => (this.selectedBeneficiary = b));
    } else {
      this.selectedBeneficiary = this.bond.beneficiaryRaw;
    }
  }

  initContractVersion(contractId: number, bondTypeId: number) {
    if (contractId && contractId > 0) {
      this.guiService.getContractVersion(contractId).subscribe(
        (c) => (this.tempContractVersion = c),
        () => console.log('error on guiService.getContractVersion'),
        () => this.afterContractVersionReceived(this.tempContractVersion, bondTypeId)
      );
    }
  }

  initContractVersionForContract(contractId: number, bondType: number) {
    if (contractId && contractId > 0) {
      this.guiService.findLastAllowedVersionForNewBond(contractId).subscribe((c) => {
        if (c) {
          this.tempContractVersion = c;
          this.afterContractVersionReceived(this.tempContractVersion, bondType);
        } else {
          this.selectedContractVersion = undefined;
        }
      });
    } else {
      this.selectedContractVersion = undefined;
    }
  }

  afterContractVersionReceived(c: ContractVersionDto, bondTypeId: number) {
    this.setContractVersion(c);
    if (this.selectedContractVersion && bondTypeId && bondTypeId > 0) {
      const contractBond = this.guiService.getContractBondTypeById(this.selectedContractVersion.bondTypes, bondTypeId);
      if (contractBond) {
        const beneficiaryInitialized = !!(this.bond.beneficiaryId || this.bond.beneficiaryRaw?.registrationName);
        this.onSelectBondType(contractBond, !beneficiaryInitialized);
      }
    }
  }

  setContractVersion(c: ContractVersionDto) {
    if (c) {
      this.selectedContractVersion = c;
      this.bond.contractVersionId = c.id;
      if (this.portal && this.featureService.get(Feature.INTRANET_BOND_DETAILS_IN_PORTAL)) {
        // getContractVersionsByContractId
        this.bond.contractVersionId = this.selectedContractVersion.id;
      }
      this.refreshStatistics();
    }
  }

  private refreshStatistics() {
    const c: ContractVersionDto = this.selectedContractVersion;
    if (c && c.contract && c.contract.id > 0) {
      this.guiService.getContractStatistics(c.contract.id).subscribe((s) => (this.statistics = s));
    }
  }

  private refreshDocumentsList() {
    if (this.formComponent.documentListComponent) {
      this.formComponent.documentListComponent.refresh();
    }
  }

  initCompany(clientId: number) {
    if (clientId > 0) {
      this.guiService.getCompany(clientId).subscribe((c) => {
        this.selectedCompany = c;
        this.contractCriteria.clientId = clientId;
      });
    } else {
      this.selectedCompany = undefined;
    }
  }

  relatedObjectsSet() {
    return (
      this.selectedBeneficiary &&
      this.selectedCompany &&
      this.selectedContractBond &&
      this.selectedContractVersion &&
      this.bond.output &&
      (!this.bond.output.showBuyer || this.bond.buyer)
    );
  }

  saveBondWithStatusChange() {
    this.saveBond();
  }

  onSave() {
    this.saveBond();
  }

  saveBond() {
    this.clearErrors();
    this.inProgress = true;
    this.updateCustomTemplate();
    if (this.isSysCurr(this.bond.currency)) {
      this.bond.values[0].valueInSysCurr = this.bond.values[0].value;
    }
    if (!this.form.valid || !this.relatedObjectsSet()) {
      this.showFormError();
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      this.inProgress = false;
      return;
    }
    this.guiService.setRelatedObjectIdsInBondVersion(
      this.bond,
      this.selectedCompany,
      this.selectedContractVersion,
      this.selectedContractBond,
      this.selectedBeneficiary
    );

    this.serverErrors = undefined;
    const edition: boolean = this.bond.id > 0;
    this.guiService.saveBondVersion(this.bond).subscribe(
      (bond) => {
        this.afterBondSaved(bond, edition);
        this.inProgress = false;
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  afterBondSaved(bond: BondVersionDto, edition: boolean) {
    this.beneficiaryChanged = false;
    this.showSavedMsg();
    this.serverErrors = bond.warnings;
    if (edition) {
      this.bond = bond;
      this.refreshBondVersionList();
      this.handleButtons();
      this.refreshStatistics();
      this.refreshDocumentsList();
      this.setGetCustomTransitions();
      this.loadTemplates();
    } else {
      // page is reloaded with proper id in URL
      this.guiService.justCreatedBond = bond;
      this.router.toBondDetails(bond.id);
    }
  }

  onSubtypeSelect(subtype: DictionaryBaseDto) {
    const subtypeId = subtype ? subtype.id : null;
    if (!this.showDetails) {
      this.guiService
        .getInitialVersion(
          this.selectedCompany.id,
          this.selectedContractBond.bondType.id,
          subtypeId,
          this.selectedContractVersion.contract.businessUnit.id,
          this.selectedContractVersion.id
        )
        .subscribe((bond) => {
          this.bond = bond;
          this.loadTemplates();
          this.showDetails = true;
          this.handleButtons();
          this.setUnderwritingType(subtype);
          this.setBondProductSymbol();
        });
    } else {
      this.bond.subtype = subtype;
      this.loadTemplates();
      this.setUnderwritingType(subtype);
      this.updateBondOutput();
      this.setBondProductSymbol();
    }
    if (this.showDetails && this.formComponent.bondCustomFieldsComponent) {
      this.formComponent.bondCustomFieldsComponent.resetCustomFields();
    }
  }

  setBondProductSymbol(): void {
    if (this.autoselectBondProductSymbol) {
      this.bondService
        .getAutoselectedBondProductSymbol(
          this.selectedContractBond?.bondType?.id,
          this.selectedContractVersion?.contract?.type?.id
        )
        .subscribe({
          next: (ps) => (this.bond.productSymbol = ps),
          error: (error) => console.log('setBondProductSymbol' + error),
        });
    }
  }

  updateBondOutput() {
    this.inProgress = true;
    this.bondService
      .getBondOutput(this.bondType?.id, this.bond?.subtype?.id, this.selectedContractVersion?.id)
      .subscribe({
        next: (o) => {
          this.bond.output = o;
        },
        error: (error) => this.handleServerError(error),
        complete: () => (this.inProgress = false),
      });
  }

  canChangeRelatedObject() {
    return this.guiService.isNotActiveBond(this.bond) && !this.guiService.isAnnex(this.bond);
  }

  canChangeBuyer() {
    return this.canChangeRelatedObject() || (!this.bond.activated && !this.bond.buyer);
  }

  canDeleteAnnex(bondVersion: BondVersionDto): boolean {
    // bond in future or status == BOND_REQUEST
    const canDelete =
      bondVersion &&
      bondVersion.id > 0 &&
      bondVersion.last &&
      (bondVersion.validFrom > new Date() || bondVersion.status.id === BondStatus.BOND_REQUEST);
    if (canDelete || !bondVersion || !bondVersion.id) {
      this.deleteButton.title = 'Delete';
    } else if (!bondVersion.last) {
      this.deleteButton.title = 'Only the last bond version can be deleted';
    } else {
      this.deleteButton.title = 'Bond version other than bond request can be deleted only if it is from the future';
    }
    return canDelete;
  }

  onSelectBondVersion(version: BondVersionSimpleDto) {
    this.getBondVersion(version.id);
  }

  deleteAnnex(shouldDelete: boolean) {
    if (shouldDelete) {
      this.guiService.deleteBondVersion(this.bond.id).subscribe(
        () => this.afterDeleteAnnex(this.bond.bond.id, 'Bond has been deleted'),
        (error) => (this.serverErrors = error)
      );
    }
  }

  afterDeleteAnnex(bondId: number, infoAfterSuccess: string) {
    // this.bondVersionListComponent.search();
    this.refreshBondVersionList();
    this.setLastBondVersion(bondId, infoAfterSuccess);
  }

  setLastBondVersion(bondId: number, infoAfterSuccess: string) {
    this.guiService.getLastBondVersionForBond(bondId).subscribe({
      next: (bv) => this.afterLastBondVersionFound(bv, infoAfterSuccess),
      error: () => this.growlService.error('Error getting previous bond version'),
    });
  }

  afterLastBondVersionFound(bv: BondVersionDto, infoAfterSuccess: string) {
    if (infoAfterSuccess) {
      this.growlService.notice(infoAfterSuccess);
    }
    if (!bv) {
      // the only bond version is deleted, go to bond-creation view
      this.router.toBondDetails(0);
    } else {
      this.setBond(bv);
    }
  }

  goToContract(id: number) {
    this.router.toContractPreview(id);
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.bond.id);
  }

  onPrintGroupedSelected(template: GroupedItem) {
    this.router.toDocumentDetailsNew(template.id, this.bond.id, template.params);
  }

  isSysCurr(currency: DictionaryBaseDto) {
    return currency.code === this.SYSTEM_CURRENCY_CODE;
  }

  onCreateNewVersion() {
    this.guiService.newBondVersionForBond(this.bond.bond.id).subscribe(
      (bond) => {
        this.setBond(bond);
      },
      (error) => this.handleServerError(error)
    );
  }

  onDelete() {
    const number = this.bond.bond.number ? this.bond.bond.number : this.bond.bond.requestNumber;
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'Delete confirmation',
      'Are you sure you want to delete annex ' + number + '/' + this.bond.versionNumber + ' ?'
    );
    confirmationPromise.then((result) => {
      // when pressed Yes
      this.deleteAnnex(result);
    });
  }

  onCancel() {
    this.closeAllSelectors();
    super.onCancel(this._route);
  }

  onSelectClient(client: CompanySimpleDto) {
    this.selectedCompany = client;
    this.contractCriteria.clientId = client.id;
    this.selectedContractVersion = null;
    this.selectedContractBond = null;
    this.openSelectorEmitters['Contract'].next(true);
  }

  onSelectContract(contractVersion: ContractVersionDto) {
    this.frontendErrors = [];
    this.setContractVersion(contractVersion);
    if (contractVersion.suspended) {
      this.frontendErrors.push({msgKey: 'bond.errorMessage.contractSuspended', warning: false});
      this.growlService.error('bond.errorMessage.contractSuspended');
    } else {
      this.openSelectorEmitters['BondType'].next(true);
      this.selectedContractBond = null;
    }
  }

  onSelectBondType(bondType: ContractBondDto, subtypeReset = true) {
    this.bond.type = bondType.bondType;
    this.selectedContractBond = bondType;
    this.openSelectorEmitters['BondType'].next(false);
    // To avoid double events. If subtype is not null event is generated by subtype combo.
    if (!this.bond.subtype) {
      this.loadTemplates();
    }
    if (this.lifecycleDropdown) {
      this.lifecycleDropdown.reloadTransitions();
    }
  }

  handleButtons() {
    this.deleteButton.disabled = !this.canDeleteAnnex(this.bond);
    this.newVersionButton.disabled = this.newVersionCreationBlocked();
    this.hideButtons(false);
    this.newVersionButton.hidden = this.bidBondInPortal;
    this.deleteButton.hidden = !this.bond.removable;
    if (this.portal) {
      this.saveButton.hidden = this.bond.id && this.bond.id > 0;
      this.cancelButton.hidden = this.bond.id && this.bond.id > 0;
      this.deleteButton.hidden = true;
    }
  }

  newVersionCreationBlocked() {
    return (
      !this.bond ||
      !this.bond.id ||
      !this.bond.lastActivated ||
      !this.bond.bond ||
      !this.bond.bond.newVersionCreationPossible
    );
  }

  get disabled() {
    return this.bond.id && (!this.bond.output || !this.bond.output.editable);
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.bondType);
    dictionarySelectors.push(this.bond.subtype);
    const getGenericAppendixTemplate =
      this.bond.status && this.appService.credendo && BOND_ACTIVATED.includes(this.bond.status.id);
    this.guiService
      .getBondTemplates(this.bond.language, dictionarySelectors, getGenericAppendixTemplate, false)
      .subscribe((result) => {
        const templates = [...result];
        if (this.bond.customTemplate) {
          templates.push(this.bond.customTemplate);
        }
        if (templates.length > 0) {
          this.templates = templates;
          this.templatesGrouped = [];
          templates.forEach((t) => {
            const parentItem = <GroupedItem>{name: t.name, children: []};
            parentItem.children.push(<GroupedItem>{
              name: 'Internal',
              id: t.id,
              params: [{key: 'copyFor', value: RepoDocumentCategory.INTERNAL}],
            });
            parentItem.children.push(<GroupedItem>{
              name: 'For beneficiary',
              id: t.id,
              params: [{key: 'copyFor', value: RepoDocumentCategory.BENEFICIARY}],
            });
            this.templatesGrouped.push(parentItem);
          });
        }
      });
  }

  private refreshBondVersionList() {
    if (this.bond.bond.id) {
      this.guiService.getBondVersions(this.bond.bond.id).subscribe(
        (searchResult) => {
          this.bondVersions = searchResult.result;
          this.handleButtons();
        },
        (error) => (this.serverErrors = <any>error)
      );
    }
  }

  public getBondVersion(versionId: number) {
    this.guiService.getBondVersion(versionId).subscribe(
      (bond) => this.setBond(bond),
      (error) => (this.objectNotFound = true)
    );
  }

  private setUnderwritingType(subtype: DictionaryBaseDto) {
    if (subtype && this.bondSubtypes) {
      const subtypeDetail = this.bondSubtypes[subtype.id];
      const underwritingTypes = subtypeDetail.relatedDictionaries['UnderwritingType'];
      if (underwritingTypes.length > 0) {
        this.bond.underwritingType = underwritingTypes[0];
      }
    }
  }

  isBooster(): boolean {
    if (this.selectedContractVersion?.contract?.type) {
      return this.selectedContractVersion.contract.type.id === ContractType.BOOSTER;
    }
    if (this.bond && this.bond.contractType) {
      return this.bond.contractType.id === ContractType.BOOSTER;
    }
    return false;
  }

  showSpecialTextUpload(): boolean {
    // arc: potencial expression check problem
    const cs = this.bondSubtypeSelector;
    return !!(cs && cs.showSpecialTextUpload());
  }

  onSpecialTextUploadSelect(doc: DocumentDescriptionDto) {
    const cs = this.bondSubtypeSelector;
    if (!cs) {
      return;
    }
    cs.onSpecialTextUploadSelect(doc);
  }

  private updateCustomTemplate() {
    this.bondSubtypeSelector.updateCustomTemplate(this.bond);
  }

  createCompanyForBeneficiary() {
    this.cacheService.setObject(
      SettableObjectType.NEW_COMPANY,
      BusinessUtils.companyFromCompanyPortalDto(this.bond.beneficiaryRaw)
    );
    this.router.toCompanyDetailsWithParams(<CompanyViewParams>{
      returnViewId: View.BOND_DETAILS.id,
      returnObjectId: this.bond.id,
      readFromCache: true,
    });
  }

  canChangeBeneficiary() {
    return (
      this.canChangeRelatedObject() ||
      this.bond.beneficiaryRaw ||
      this.loggedUserService.hasRight(ElementaryRight.BOND_BENEFICIARY_CHANGE)
    );
  }

  get subtypeSelectVisible(): boolean {
    return true;
  }

  get newClaimButtonVisible(): boolean {
    const bondCreated = !!this.bond?.id;
    return bondCreated;
  }

  // filters only active bonds types (selectable at bond creation)
  activeBondTypes(): ContractBondDto[] {
    return this.selectedContractVersion?.bondTypes?.filter((bt) => bt.active);
  }

  get newVersionCreationPossible(): boolean {
    return this.bond?.bond?.newVersionCreationPossible;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  afterExclusiveStateTransition(object: BondVersionDto) {
    this.bond = object;
  }
}
