import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  BackendError,
  CessionVersionDto,
  PolicyCessionVersionService,
  RouterService,
} from '../../bonding_shared';
import {
  CessionType,
  PolicyElementaryRight,
  ServiceContactElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-cession-preview',
  templateUrl: 'policy-cession-preview.component.pug',
})
export class PolicyCessionPreviewComponent implements OnInit {
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly PolicyElementaryRight = PolicyElementaryRight;

  cessionVersion: CessionVersionDto;
  errors: BackendError;
  objectNotFound = false;

  constructor(
    private cessionVersionService: PolicyCessionVersionService,
    private route: ActivatedRoute,
    public router: RouterService,
    public appService: AppConfigService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const id = params['id'];
    this.loadCession(id);
  }

  loadCession(cessionVersionId: number) {
    this.cessionVersionService.getCessionVersion(cessionVersionId).subscribe(
      (c) => {
        this.cessionVersion = c;
      },
      (error) => (this.errors = error)
    );
  }

  get showFullDetails(): boolean {
    return (
      this.cessionVersion &&
      this.cessionVersion.cession.type &&
      this.cessionVersion.cession.type.id &&
      this.cessionVersion.cession.type.id !== CessionType.CHANGE_OF_THE_MAIN_INSURED
    );
  }
}
