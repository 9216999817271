import {CompanyFinancialDataType} from '../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../bonding_shared';

export class CompanyFinancialDataRatioCategory {
  private _categories_FIDAT: number[] = [
    CompanyFinancialDataType.RATIOS_AND_INDICATORS_CR,
    CompanyFinancialDataType.LIQUIDITY_AND_WORKING_CAPITAL_CR,
    CompanyFinancialDataType.PROFITABILITY_CR,
    CompanyFinancialDataType.CASH_FLOW_MEASURES_CR,
    CompanyFinancialDataType.SOLVENCY_CR,
    CompanyFinancialDataType.DEBT_COVERAGE_CR,
    CompanyFinancialDataType.ACTIVITY_RATIOS_CR,
  ];

  private _names: string[] = [];

  constructor() {
    this._names[CompanyFinancialDataType.LIQUIDITY] = 'company.financialData.ratioCategory.liquidity';
    this._names[CompanyFinancialDataType.PROFITABILITY] = 'company.financialData.ratioCategory.profitability';
    this._names[CompanyFinancialDataType.LIABILITY] = 'company.financialData.ratioCategory.liability';
    this._names[CompanyFinancialDataType.ROTATION] = 'company.financialData.ratioCategory.rotation';
    this._names[CompanyFinancialDataType.OTHERS] = 'company.financialData.ratioCategory.others';
    this._names[CompanyFinancialDataType.RATIOS_AND_INDICATORS_CR] =
      'company.financialData.ratioCategory.ratiosAndIndicators';
    this._names[CompanyFinancialDataType.LIQUIDITY_AND_WORKING_CAPITAL_CR] =
      'company.financialData.ratioCategory.liquidityAndWorkingCapital';
    this._names[CompanyFinancialDataType.PROFITABILITY_CR] = 'company.financialData.ratioCategory.profitability';
    this._names[CompanyFinancialDataType.CASH_FLOW_MEASURES_CR] =
      'company.financialData.ratioCategory.cashFlowMeasures';
    this._names[CompanyFinancialDataType.SOLVENCY_CR] = 'company.financialData.ratioCategory.solvency';
    this._names[CompanyFinancialDataType.DEBT_COVERAGE_CR] = 'company.financialData.ratioCategory.debtCoverage';
    this._names[CompanyFinancialDataType.ACTIVITY_RATIOS_CR] = 'company.financialData.ratioCategory.activityRatios';
  }

  categorize(ratioParentId: number, ratioCategoryId: number): boolean {
    return ratioParentId === ratioCategoryId;
  }

  get categories_FIDAT(): number[] {
    return this._categories_FIDAT;
  }

  get names(): string[] {
    return this._names;
  }
}
