import {NgModule} from '@angular/core';
import {CompanyInfoComponent} from './company-info.component';
import {EmbeddableCompanySearchComponent} from './embeddable-company-search.component';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CompanySectionComponent} from './company-section.component';
import {ContactPersonSectionComponent} from './contact-person-section.component';
import {ContactPersonSelectorSimpleComponent} from './contact-person-selector-simple.component';
import {CompanySegmentInfoComponent} from './company-segment-info.component';
import {CompanyTypeDirective} from './directives/company-type.directive';
import {CompanySectionSelectorComponent} from './company-section-selector.component';
import {TaskSharedModule} from '../../../task/shared/task-shared.module';
import {CompanyPhysicalPersonInfoComponent} from './company-physical-person-info.component';
import {CompanyRatingsComponent} from './company-ratings.component';
import {OrderReportButtonComponent} from './order-report-button.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    TaskSharedModule,
    BsDropdownModule,
  ],
  declarations: [
    CompanyInfoComponent,
    CompanySegmentInfoComponent,
    EmbeddableCompanySearchComponent,
    CompanySectionComponent,
    CompanySectionSelectorComponent,
    ContactPersonSectionComponent,
    ContactPersonSelectorSimpleComponent,
    CompanyPhysicalPersonInfoComponent,
    CompanyTypeDirective,
    CompanyRatingsComponent,
    OrderReportButtonComponent,
  ],
  exports: [
    CompanyInfoComponent,
    CompanySegmentInfoComponent,
    EmbeddableCompanySearchComponent,
    CompanySectionComponent,
    CompanySectionSelectorComponent,
    ContactPersonSectionComponent,
    ContactPersonSelectorSimpleComponent,
    CompanyTypeDirective,
    CompanyRatingsComponent,
    OrderReportButtonComponent,
  ],
})
export class CompanySharedModule {}
