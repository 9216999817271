import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  BackendError,
  BondDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  ContractBondCriteriaDto,
  ContractBondExtendedDto,
  ContractCompanyDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
  DictionaryBaseDto,
  InvoiceCriteriaDto,
  InvoiceDto,
  SearchCriteria,
  StatisticsDto,
} from '../../bonding_shared/model';
import {PortalRouterService} from '../services/portal-router.service';
import {
  BondVersionService,
  ContractVersionService,
  FeatureService,
  FormatService,
  InvoiceService,
  LoggedUserService,
  SearchDataProvider,
} from '../../bonding_shared/services';
import {ClientBondGuiService, ContractBondPortalService} from '../clientbond/services';
import {ContractVersionListComponent} from '../../bonding/bonding-contract/contract/components/shared/contract-version-list.component';
import {
  BusinessObjectType,
  COMPANY_TYPE_BROKERS,
  ContractCompanyRole,
  ContractStatus,
  ContractType,
  ElementaryRight,
  Feature,
  InvoiceCategory,
  RepoDocumentElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {InvoiceListComponent} from '../../bonding/invoice/components/shared/invoice-list.component';
import {HttpParams} from '@angular/common/http';
import {ReportDefinition} from '../../bonding_shared/model/report-ids';
import {BusinessReportPortalService} from '../services/portal-business-report.service';

@Component({
  selector: 'common-contract-preview',
  templateUrl: './common-contract-preview.component.pug',
})
export class CommonContractPreviewComponent implements OnInit {
  @ViewChild(ContractVersionListComponent, {static: true})
  contractVersionListComponent: ContractVersionListComponent;

  @Input() versionListHidden = true;
  contractVersion: ContractVersionDto;
  bonds: BondVersionSimpleDto[];
  statistics: StatisticsDto;
  serverErrors: BackendError;
  bondDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  bondTypeDataProvider: SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>;
  validFromStr: string;
  validToStr: string;
  isClient: boolean;
  coinsureds: ContractCompanyDto[];
  contractVersionDP: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;

  @ViewChild(InvoiceListComponent, {static: true}) invoiceList: InvoiceListComponent;

  readonly ContractType = ContractType;
  readonly ContractStatus = ContractStatus;
  readonly BusinessObjectType = BusinessObjectType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  constructor(
    private contractService: ContractVersionService,
    private bondService: BondVersionService,
    private contractBondService: ContractBondPortalService,
    private route: ActivatedRoute,
    public router: PortalRouterService,
    public guiService: ClientBondGuiService,
    private _loggedUserService: LoggedUserService,
    private formatService: FormatService,
    private invoiceService: InvoiceService,
    public loggedUserService: LoggedUserService,
    private portalBusinessReportService: BusinessReportPortalService,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const contractVersionId = +params['contractVersionId'];
    this.isClient = this._loggedUserService.hasRight(ElementaryRight.CLIENT_BONDS);
    console.log('onInit: contractVersionId = ' + contractVersionId);
    this.contractService.getContractVersion(contractVersionId).subscribe({
      next: (contract) => this.afterContractReceived(contract),
      error: (error) => (this.serverErrors = error),
    });
    this.loadStatistics(contractVersionId);
  }

  afterContractReceived(contract: ContractVersionDto) {
    this.setContractVersion(contract);
    console.log('contract version loaded:: versionId = ' + this.contractVersion.id);
    this.coinsureds = this.contractVersion.contractCompanies.filter((c) => c.role.id === ContractCompanyRole.CLIENT);
    if (this.isClient) {
      this.createBondDataProvider(contract.contract.id);
    }

    this.createBondTypeDataProvider(this.contractVersion.id);
    if (this.contractVersion.validFrom !== undefined) {
      this.validFromStr = this.formatService.formatDate(this.contractVersion.validFrom);
    } else {
      this.validFromStr = '';
    }

    if (this.contractVersion.validTo !== undefined) {
      this.validToStr = this.formatService.formatDate(this.contractVersion.validTo);
    } else {
      this.validToStr = '';
    }
  }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.contractVersion);
  }

  setContractVersion(c: ContractVersionDto) {
    this.contractVersion = c;
    this.setContractVersionDataProvider(c.contract.id);
  }

  loadStatistics(id: number) {
    return this.contractService.getContractStatistics(id).subscribe((s) => (this.statistics = s));
  }

  createBondDataProvider(contractId: number) {
    this.bondDataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this.bondService);
    this.bondDataProvider.searchCriteria = <SearchCriteria<BondVersionCriteriaDto>>{};
    this.bondDataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.bondDataProvider.searchCriteria.criteria.bond = <BondDto>{};
    this.bondDataProvider.searchCriteria.criteria.contractId = contractId;
  }

  createBondTypeDataProvider(contractVersionId: number) {
    this.bondTypeDataProvider = new SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>(
      this.contractBondService
    );
    this.bondTypeDataProvider.searchCriteria = <SearchCriteria<ContractBondCriteriaDto>>{};
    this.bondTypeDataProvider.searchCriteria.criteria = <ContractBondCriteriaDto>{};
    this.bondTypeDataProvider.searchCriteria.criteria.contractVersionId = contractVersionId;
  }

  onSelectContractVersion(contractVersion: ContractVersionDto) {
    console.log('onSelectContractVersion :: validFrom: ', typeof contractVersion.validFrom, contractVersion.validFrom);
    this.contractService.getContractVersion(contractVersion.id).subscribe((cv) => {
      this.afterContractReceived(cv);
      if (this.isClient) {
        this.router.toClientContractPreview(this.contractVersion.id);
      } else {
        this.router.toBrokerPortfolioContractVersionPreview(this.contractVersion.id);
      }
    });
  }

  updateButtons() {}

  setContractVersionDataProvider(contractId: number) {
    this.contractVersionDP = BusinessUtils.createContractVersionDataProvider(this.contractService);
    this.contractVersionDP.searchCriteria.criteria.contract.id = contractId;
  }

  downloadReportForBroker() {
    let params = new HttpParams();
    params = params.append('brokerId', this.loggedUserService.getLoggedUserData().company.id + '');
    this.serverErrors = undefined;
    this.portalBusinessReportService.generate(
      ReportDefinition.BROKER_REPORT,
      `report_${this.loggedUserService.getLoggedUserData().company.id}.xlsx`,
      params,
      (errorMsg) => (this.serverErrors = errorMsg)
    );
  }

  public get broker(): boolean {
    return (
      this.loggedUserService.getLoggedUserData().company.companyType &&
      COMPANY_TYPE_BROKERS.indexOf(this.loggedUserService.getLoggedUserData().company.companyType.id) >= 0
    );
  }

  // export to Excel information about company, solution for Credendo broker
  showDownloadReportForBroker(): boolean {
    return this.featureService.get(Feature.COMPANY_EXPORT_REPORT) && this.broker;
  }
}
