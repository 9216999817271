import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TopNavModule} from '../bonding_shared/components/topnav';
import {SidebarModule} from '../bonding_shared/components/sidebar';
import {BondingComponent} from './bonding.component';
import {VersionSummaryModule} from '../bonding_shared/components/version-summary';
import {
  AppConfigService,
  BatchService,
  BinaryDownloaderService,
  BiService,
  BondVersionService,
  BrokerContractRelationService,
  BrokerContractVersionService,
  BusinessObjectService,
  BusinessParamService,
  BusinessPropertiesService,
  BusinessReportService,
  BusinessUnitService,
  CacheService,
  ClaimService,
  ClientCompanyService,
  CompanyAbstractService,
  CompanyFinancialDataService,
  CompanyRatingService,
  CompanyService,
  ContactNoteService,
  ContactPersonService,
  ContractService,
  ContractVersionService,
  CountryRatingService,
  DashboardService,
  DictionaryBaseService,
  DictionaryService,
  DocumentService,
  EventService,
  ExternalProviderVersionService,
  FormatService,
  GeoDictBaseService,
  GeoDictService,
  GroupService,
  HolidayService,
  ImageService,
  ImportService,
  InquiryService,
  InvoiceCoreService,
  InvoiceItemService,
  InvoicePaymentService,
  InvoiceService,
  LimitDecisionService,
  LimitService,
  MasterPolicyContractService,
  NumberFormatService,
  OfferService,
  PolicyClauseService,
  PolicyContractService,
  PolicyContractVersionService,
  PolicyLimitListService,
  PropertyService,
  ReportExecutionService,
  ReportService,
  RepositoryService,
  RouterService,
  SalesLeadService,
  SalesRepRelationVersionService,
  SalesRepVersionService,
  SettingsService,
  TaskService,
  TemplateService,
  ThirdPartyService,
  TreatyVersionService,
  UserService,
  VersionInfoService,
} from '../bonding_shared/services';
import {AuthenticationGuard} from './authentication-guard';
import {TaskModule} from './task/task.module';
import {SharedComponentsModule} from '../bonding_shared/components/shared-components.module';
import {ReportModule} from './report/report.module';
import {DocumentModule} from './document/document.module';
import {DashboardModule} from './home/home.module';
import {CompanyModule} from './company/company.module';
import {BondModule} from './bond/bond.module';
import {ContractModule} from './bonding-contract/contract/contract.module';
import {TemplateDetailsGuiService} from './document/services';
import {BrokerContractGuiService} from './broker-contract/services/broker-contract-gui.service';
import {BrokerContractModule} from './broker-contract/broker-contract.module';
import {BusinessReportModule} from './business-report/business-report.module';
import {VersionInfoModule} from './version-info/version-info.module';
import {TreatyModule} from './treaty/treaty.module';
import {CountryRatingModule} from './country-rating/country-rating.module';
import {PolicyModule} from './policy/policy.module';
import {PolicyContractGuiService} from './policy/services/policy-contract-gui.service';
import {LimitModule} from './limit/limit.module';
import {InvoiceGuiService} from './invoice/services/invoice-gui.service';
import {InvoiceModule} from './invoice/invoice.module';
import {InvoiceItemGuiService} from './invoice-item/services/invoice-item-gui.service';
import {InvoiceItemModule} from './invoice-item/invoice-item.module';
import {ClaimModule} from './claim/claim.module';
import {DocumentGuiService} from './document/services/document-gui.service';
import {UserModule} from './user/user.module';
import {UserGuiService} from './user/services/user-gui.service';
import {InquiryModule} from './inquiry/inquiry.module';
import {SalesLeadModule} from './sales-lead/sales-lead.module';
import {ImportModule} from './import/import.module';
import {GroupModule} from './group/group.module';
import {RepositoryDocumentsModule} from './repository-documents/repository-documents.module';
import {GroupGuiService} from './group/services/group-gui.service';
import {BusinessObjectInfoModule} from './business-object-info/business-object-info.module';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AdministrationModule} from './administration/administration.module';
import {ServiceContactsModule} from './service-contacts/service-contacts.module';
import {ServiceNotesModule} from './service-notes/service-notes.module';
import {SalesRepModule} from './sales-rep/sales-rep.module';
import {ThirdPartyModule} from './thirdparty/thirdparty.module';
import {InvoicePaymentModule} from './invoice-payment/invoice-payment.module';
import {InvoicePaymentGuiService} from './invoice-payment/services/invoice-payment-gui.service';
import {EventModule} from './event/event.module';
import {PolicyLimitListModule} from './policy-limit-list/policy-limit-list.module';
import {ExternalProviderModule} from './external-provider/external-provider.module';
import {ClientCompanyModule} from './client-company/client-company.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from '../bonding_shared/utils/error.interceptor';
import {BatchModule} from './batch/batch.module';
import {ReportExecutionModule} from './report-execution/report-execution.module';
import {BonusMalusRequestService} from '../bonding_shared/services/bonus-malus-request.service';
import {ClaimDocumentsService} from '../bonding_shared/services/claim-documents.service';
import {ClaimCalculationService} from '../bonding_shared/services/claim-calculation.service';
import {ClaimIndemnificationVersionService} from '../bonding_shared/services/claim-indemnification-version.service';
import {ClaimTreatOrderService} from '../bonding_shared/services/claim-treat-order.service';
import {CompanyRatingGuard} from './company/company-rating.guard';
import {NaceCodeService} from '../bonding_shared/services/naceCode.service';
import {BusinessLogService} from '../bonding_shared/services/business-log.service';
import {FINoteService} from '../bonding_shared/services/fi-note.service';
import {FINoteModule} from './fi-note/fi-note.module';
import {FINoteGuiService} from './fi-note/services/fi-note-gui.service';
import {FINoteNumberingBagService} from '../bonding_shared/services/fi-note-numbering-bag.service';
import {BondingContractInquiryModule} from './bonding-contract/inquiry/bonding-contract-inquiry.module';
import {BondingContractInquiryService} from '../bonding_shared/services/bonding-contract-inquiry.service';
import {CurrencyExchangeRateService} from '../bonding_shared/services/currency-exchange-rate.service';
import {ClaimCalculationDocumentsService} from '../bonding_shared/services/claim-calculation-documents.service';
import {ClaimRecoveriesService} from '../bonding_shared/services/claim-recoveries.service';
import {ClaimExternalProvidersService} from '../bonding_shared/services/claim-external-providers.service';
import {BrokerContractRepresentativeService} from '../bonding_shared/services/broker-contract-representative.service';
import {BondingContractOfferService} from '../bonding_shared/services/bonding-contract-offer.service';
import {LimitBusinessParamsService} from '../bonding_shared/services/limit-business-params.service';
import {InquiryAbstractService} from '../bonding_shared/services/inquiry-abstract.service';
import {InvoiceMassService} from '../bonding_shared/services/invoice-mass.service';
import {InvoiceItemMassService} from '../bonding_shared/services/invoice-item-mass.service';
import {SalesRegionService} from '../bonding_shared/services/sales-region.service';
import {InquiryPbgService} from '../bonding_shared/services/inquiry-pbg.service';
import {ClaimLawsuitService} from '../bonding_shared/services/claim-lawsuit.service';
import {InvoiceNumberingBagService} from '../bonding_shared/services/invoice-numbering-bag.service';
import {CompanyExternalProvidersService} from '../bonding_shared/services/company-external-providers.service';
import {BondingContractRatesService} from '../bonding_shared/services/bonding-contract-rates.service';
import {BiReportModule} from './bi-report/bi-report.module';
import {BIReportService} from '../bonding_shared/services/bi-report.service';
import {ClaimSimplifiedCalculationService} from '../bonding_shared/services/claim-simplified-calculation.service';
import {HRModule} from './hr/hr.module';
import {ClaimRepaymentScheduleVersionService} from '../bonding_shared/services/claim-repayment-schedule-version.service';
import {PolicyClauseTypeService} from '../bonding_shared/services/policy-clause-type.service';
import {ZipCodeFormatService} from '../bonding_shared/services/zip-code-format.service';
import {UserResponsibleService} from '../bonding_shared/services/user-responsible.service';

@NgModule({
  imports: [
    AdministrationModule,
    CommonModule,
    RouterModule,
    TopNavModule,
    SidebarModule,
    VersionSummaryModule,
    TaskModule,
    ReportModule,
    BiReportModule,
    BusinessReportModule,
    ReportExecutionModule,
    ImportModule,
    DocumentModule,
    DashboardModule,
    CompanyModule,
    BondModule,
    LimitModule,
    ClaimModule,
    SharedComponentsModule,
    ContractModule,
    BrokerContractModule,
    VersionInfoModule,
    PolicyModule,
    InvoiceModule,
    InvoiceItemModule,
    FINoteModule,
    InvoicePaymentModule,
    UserModule,
    TreatyModule,
    CountryRatingModule,
    InquiryModule,
    CountryRatingModule,
    SalesLeadModule,
    GroupModule,
    RepositoryDocumentsModule,
    BusinessObjectInfoModule,
    ServiceContactsModule,
    SalesRepModule,
    ServiceNotesModule,
    ThirdPartyModule,
    PolicyLimitListModule,
    ServiceNotesModule,
    ExternalProviderModule,
    EventModule,
    ClientCompanyModule,
    BatchModule,
    BondingContractInquiryModule,
    HRModule,
  ],
  declarations: [BondingComponent],
  exports: [BondingComponent],
  providers: [
    VersionInfoService,
    SettingsService,
    AppConfigService,
    AuthenticationGuard,
    BiService,
    BusinessPropertiesService,
    DictionaryBaseService,
    DictionaryService,
    UserService,
    ReportService,
    BusinessParamService,
    BusinessReportService,
    ReportExecutionService,
    TaskService,
    TemplateService,
    DocumentService,
    DocumentGuiService,
    RouterService,
    BinaryDownloaderService,
    NumberFormatService,
    ZipCodeFormatService,
    ContractVersionService,
    BondVersionService,
    CurrencyExchangeRateService,
    CompanyService,
    {
      provide: CompanyAbstractService,
      useClass: CompanyService,
    },
    ClientCompanyService,
    GroupService,
    GroupGuiService,
    DashboardService,
    ContractService,
    ContractVersionService,
    TemplateDetailsGuiService,
    BrokerContractVersionService,
    BrokerContractGuiService,
    BrokerContractRepresentativeService,
    PropertyService,
    RepositoryService,
    PolicyContractService,
    PolicyContractVersionService,
    PolicyContractGuiService,
    MasterPolicyContractService,
    BusinessUnitService,
    SalesRegionService,
    LimitBusinessParamsService,
    LimitService,
    LimitDecisionService,
    ClaimService,
    InvoiceCoreService,
    InvoiceService,
    InvoiceItemMassService,
    InvoiceMassService,
    InvoiceGuiService,
    InvoiceItemService,
    InvoiceItemGuiService,
    InvoiceNumberingBagService,
    InvoicePaymentService,
    InvoicePaymentGuiService,
    FINoteService,
    FINoteGuiService,
    FINoteNumberingBagService,
    CompanyRatingService,
    UserGuiService,
    CompanyRatingService,
    BusinessObjectService,
    TreatyVersionService,
    CountryRatingService,
    ImportService,
    CompanyFinancialDataService,
    ContactNoteService,
    ContactPersonService,
    InquiryService,
    {
      provide: InquiryAbstractService,
      useClass: InquiryService,
    },
    InquiryPbgService,
    SalesLeadService,
    ImageService,
    BrokerContractRelationService,
    BsModalService,
    GeoDictService,
    GeoDictBaseService,
    CacheService,
    SalesRepVersionService,
    SalesRepRelationVersionService,
    ClaimTreatOrderService,
    ClaimDocumentsService,
    ClaimCalculationService,
    ClaimCalculationDocumentsService,
    ClaimLawsuitService,
    ClaimRecoveriesService,
    ClaimRepaymentScheduleVersionService,
    ClaimIndemnificationVersionService,
    ClaimSimplifiedCalculationService,
    ClaimExternalProvidersService,
    CompanyExternalProvidersService,
    ThirdPartyService,
    HolidayService,
    EventService,
    ExternalProviderVersionService,
    PolicyLimitListService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    OfferService,
    PolicyClauseService,
    PolicyClauseTypeService,
    BatchService,
    BonusMalusRequestService,
    CompanyRatingGuard,
    NaceCodeService,
    BusinessLogService,
    FormatService,
    BondingContractInquiryService,
    BondingContractOfferService,
    BondingContractRatesService,
    BIReportService,
    UserResponsibleService,
  ],
})
export class BondingModule {}
