import {AfterViewInit, Component, Input, Renderer2, ViewChild} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {PolicyContractVersionDto} from '../../../bonding_shared/model';
import {PolicyContractViewSection, PolicyContractViewService} from '../services/policy-contract-view.service';
import {PolicyElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../bonding_shared';

@Component({
  selector: 'policy-tab-layout',
  templateUrl: './policy-tab-layout.component.pug',
})
export class PolicyTabLayoutComponent implements AfterViewInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  @ViewChild(TabView, {static: true}) private tabView: TabView;

  viewSection = PolicyContractViewSection;

  public _policy: PolicyContractVersionDto;
  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
  }

  constructor(
    private renderer: Renderer2,
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService
  ) {}

  ngAfterViewInit() {
    this.renderer.removeClass(this.tabView.el.nativeElement.firstChild, 'ui-widget');
  }

  isTabVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    if (this._policy && this._policy.contractType && this._policy.contractType.id) {
      return this.policyContractViewService.isSectionVisible(
        this._policy.contractType.id,
        viewSection,
        defaultVisibility
      );
    }
  }
}
