import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, ParamMap, Router, RouterStateSnapshot} from '@angular/router';
import {LoggedUserService} from '../../../../../bonding_shared';
import {ContactNoteElementaryRight, ElementaryRight} from '../../../../../bonding_shared/model/dictionary-ids';
import {AbstractGuard} from '../../../../../bonding_shared/components/abstract-guard';

@Injectable()
export class ContactNoteDetailsGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAnyRight(state.url, [
      ContactNoteElementaryRight.CONTACT_NOTE_READ_ALL,
      ContactNoteElementaryRight.CONTACT_NOTE_READ_POLICIES,
      ContactNoteElementaryRight.CONTACT_NOTE_READ_CLAIMS,
      ContactNoteElementaryRight.CONTACT_NOTE_READ_COMPANIES,
      ContactNoteElementaryRight.CONTACT_NOTE_READ_BONDING,
      ContactNoteElementaryRight.CONTACT_NOTE_READ_FINANCE,
      ContactNoteElementaryRight.CONTACT_NOTE_READ_LIMITS,
    ]);
  }
}
