import {Component, OnInit, ViewChild} from '@angular/core';
import {ClaimGuiService} from './services/claim-gui.service';
import {
  AppConfigService,
  BackendError,
  ComboItem,
  DictionaryDto,
  DictionaryService,
  IMultiSelectSettings,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared';
import {ClaimListComponent} from './components/shared/claim-list.component';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {ClaimElementaryRight, ClaimRiskType, Feature} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'claim-search',
  templateUrl: './claim-search.component.pug',
})
export class ClaimSearchComponent extends SearchView implements OnInit {
  @ViewChild(ClaimListComponent, {static: true}) claimList: ClaimListComponent;
  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];
  serverErrors: BackendError;
  readonly ClaimElementaryRight = ClaimElementaryRight;
  readonly statusMultiselectSettings = <IMultiSelectSettings>{
    dynamicTitleMaxItems: 1,
    showUncheckAll: true,
    minWidth: '300px',
    maxHeight: '310px',
  };
  readonly Feature = Feature;

  claimRelatedPartyTypes: DictionaryDto[];
  selectorsInitialized = false;
  mine = false;

  ngOnInit() {
    this.dictionaryService.getDictionary('ClaimRelatedPartyType').subscribe({
      next: (partyType) => this.initSelectors(partyType),
      error: (error) => {
        console.error('Server error or warning !', error);
        this.initSelectors([]);
      },
    });
  }

  private initSelectors(claimRelatedPartyTypes: DictionaryDto[]) {
    this.claimRelatedPartyTypes = claimRelatedPartyTypes;
    this.selectorNameList = this.claimRelatedPartyTypes
      .map((type) => type.code)
      .concat(['Risk', 'Policy', 'Contract', 'MainBeneficiary', 'CurrentCreditor', 'Bond']);
    this.initializeSelectorEmitters(true);
    this.selectorsInitialized = true;
  }

  constructor(
    public guiService: ClaimGuiService,
    public router: RouterService,
    public loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private dictionaryService: DictionaryService
  ) {
    super();
  }

  get criteria() {
    return this.guiService.dataProvider.searchCriteria.criteria;
  }

  get isCreditInsurance(): boolean {
    return this.criteria.claim.riskType && this.criteria.claim.riskType.id === ClaimRiskType.CREDIT_INSURANCE;
  }

  get isBond(): boolean {
    return this.criteria.claim.riskType && this.criteria.claim.riskType.id === ClaimRiskType.SURETY;
  }

  get isPortal(): boolean {
    return this.loggedUserService.portal;
  }

  mineChanged(p: boolean) {
    if (p) {
      this.criteria.claimOfficer = this.loggedUserService.getLoggedUserData();
    } else {
      this.criteria.claimOfficer = null;
    }
  }
}
