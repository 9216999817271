/**
 * Created by wilk on 14.11.2016.
 */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {ATableComponent} from '../../aku-table';
import {AppConfigService, LoggedUserService, RouterService, SearchDataProvider} from '../../../services';
import {BackendError, ErrorReason, SearchResult} from '../../../model';
import {ComboItem} from '../../combos';
import {NgForm, NgModel} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {StringUtils} from '../../../utils';

@Component({
  selector: 'search-view',
  templateUrl: './search-view.component.pug',
})
export class SearchViewComponent<C, R> implements AfterViewInit, OnInit {
  @ContentChild(ATableComponent, {static: true}) resultList: ATableComponent<R>;

  @Input() customTitle: string;
  @Input() labelKey: string;
  @Input() newObjectLabelKey: string;
  @Input() showHeader = true;

  @Input() set atable(t: ATableComponent<R>) {
    this.resultList = t;
  }

  @Input() dataProvider: SearchDataProvider<C, R>;

  // to show proper labels
  @Input() objectName: string;

  // basic/advanced search mode switchable
  _searchModeSwitchable = true;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    return this._searchModeSwitchable;
  }

  // Indicates if to show the New button
  private _newButton = true;

  @Input() set newButton(newButton: boolean) {
    this._newButton = newButton;
    this.updateCanCreate();
  }

  private _createRight: string;

  @Input() set createRight(createRight: string) {
    this._createRight = createRight;
    this.updateCanCreate();
  }

  public canCreate = true;

  // Indicates if to disable the New button
  @Input() newButtonDisabled = false;

  // Indicates if to show the New button
  @Input() newButtonVisible = true;

  // Indicates if to show the search button
  @Input() searchButtonVisible = true;

  // Handles new action. If false, new action should ne handled using newExecuted event
  @Input() newHandled = true;

  // Name of router function triggered when New button clicked
  // By default the function name is derived automatically from object name e.g. toBondDetails
  @Input() newObjectRedirectFunctionName: string;

  @Input() searchHeader = 'searchView.criteria.title';
  @Input() redHeader = false;
  @Input() enableSearchViewError = false;
  @Input() performInitialSearch;
  @Input() internalSeachText = true;

  @Output() searchExecuted = new EventEmitter<boolean>();
  @Output() newExecuted = new EventEmitter<boolean>();

  @ContentChildren(NgModel) public models: QueryList<NgModel>;
  @ViewChild(NgForm, {static: true}) form: NgForm;

  showErrors = false;
  frontendErrorMsg: string;
  pageSize = '20';

  // TODO: create number-combo component, use it here and change type of pageSize
  pageSizes: ComboItem[] = [new ComboItem('20', '20'), new ComboItem('50', '50'), new ComboItem('100', '100')];

  constructor(
    public router: RouterService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {}

  ngOnInit() {
    if (this.performInitialSearch === undefined) {
      this.performInitialSearch = this.appService.performInitialSearch;
    }
    this.resultList.inProgressChange.subscribe((inProgress: boolean) => {
      if (this.dataProvider) {
        this.dataProvider.inProgress = inProgress;
        this.cd.detectChanges();
      }
    });

    this.resultList.dataProviderSearchFinished.subscribe((searchResult: SearchResult<R>) => {
      this.onSearchFinished(searchResult);
    });
  }

  ngAfterViewInit() {
    const ngContentModels = this.models.toArray();
    ngContentModels.forEach((model) => {
      this.form.addControl(model);
    });

    if (this.performInitialSearch) {
      this.newSearch();
    }
  }

  newProviderSearch(dataProvider) {
    this.dataProvider = dataProvider;
    this.newSearch();
  }

  newSearch() {
    if (this.dataProvider.inProgress) {
      console.log('Search is in progress');
      return;
    }
    if (this.form && !this.form.valid) {
      console.log('Search form has errors', this.form);
      StringUtils.logFormInvalidFieldsRecursive(this.form.form);
      this.showErrors = true;
      return;
    }
    this.showErrors = false;
    this.clearErrors();

    if (this.dataProvider && this.dataProvider.textSearch) {
      if (!this.dataProvider.textSearchCriteria.text || this.dataProvider.textSearchCriteria.text.length < 1) {
        return;
      } else if (this.dataProvider.textSearchCriteria.text.length < 3) {
        this.frontendErrorMsg = this.translateService.instant('errorMessage.notEnoughCharacters');
        return;
      }
    }

    if (!this.resultList.dataProvider) {
      this.resultList.dataProvider = new SearchDataProvider<C, R>(this.dataProvider.getService());
    }
    (<SearchDataProvider<C, R>>this.resultList.dataProvider).copyData(this.dataProvider);

    this.resultList.search(parseInt(this.pageSize, null));
  }

  search() {
    this.newSearch();
    this.searchExecuted.emit(true);
  }

  get searchDisabled(): boolean {
    if (this.dataProvider && this.dataProvider.inProgress) {
      return true;
    }
    if (
      this.dataProvider &&
      this.dataProvider.textSearch &&
      (!this.dataProvider.textSearchCriteria.text || this.dataProvider.textSearchCriteria.text.length < 1)
    ) {
      return true;
    }
    return false;
  }

  create() {
    if (this.newHandled) {
      // redirect to object creation view
      if (this.newObjectRedirectFunctionName) {
        (<any>this.router)[this.newObjectRedirectFunctionName]();
      } else {
        // debugger;
        (<any>this.router)['to' + this.objectName.replace(' ', '') + 'Details'](0);
      }
    }
    this.newExecuted.emit(true);
  }

  totalCount(): number {
    if (this.resultList) {
      return this.resultList.totalCount;
    }
    return 0;
  }

  get serverErrors(): BackendError {
    if (this.resultList) {
      return this.resultList.errorMessage;
    }
    return undefined;
  }

  set serverErrors(errors: BackendError) {
    this.resultList.errorMessage = errors;
  }

  onChangePageSize(event: any) {
    console.log('page size:' + event);
    this.newSearch();
  }

  switchSearchMode() {
    this.clearErrors();
    this.dataProvider.switchSearchMode();
  }

  private clearErrors() {
    this.frontendErrorMsg = undefined;
    if (this.resultList) {
      this.resultList.errorMessage = undefined;
    }
  }

  private addWarning(message: string) {
    const errorReason = <ErrorReason>{};
    errorReason.severity = 'WARNING';
    errorReason.message = message;
    if (!Array.isArray(this.resultList.errorMessage)) {
      this.resultList.errorMessage = [];
    }
    this.resultList.errorMessage.push(errorReason);
  }

  private onSearchFinished(searchResult: SearchResult<R>) {
    if (!searchResult.result || searchResult.result.length === 0) {
      console.log('dataProviderSearchFinished', searchResult.result.length);
      this.addWarning(this.translateService.instant('No result was found.'));
    }
  }

  public searchTranslation(): string {
    return this.getTranslation('searchView.criteria.searchButton');
  }

  protected getTranslation(key: string): string | any {
    if (this.translateService) {
      return this.translateService.instant(key);
    }
    return key;
  }

  private updateCanCreate(): void {
    this.canCreate = this._newButton && (!this._createRight || this.loggedUserService.hasRight(this._createRight));
  }
}
