import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  BondingContractInquiryVersionSimpleDto,
  CompanyBaseDto,
  LocalDateRange,
  ProductLimitListCoOwnerDto,
  ProductLimitListVersionCriteriaDto,
  ProductLimitListVersionDto,
  ProductLimitListVersionSimpleDto,
  ThirdPartyBaseDto,
} from '../../../bonding_shared/model/dtos';
import {RouterService, View} from '../../../bonding_shared/services/router-service';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {
  AppConfigService,
  CacheService,
  LoggedUserService,
  SearchDataProvider,
  SettableObjectType,
} from '../../../bonding_shared/services/index';
import {PolicyLimitListService} from '../../../bonding_shared/services/policy-limit-list.service';
import {
  BusinessObjectType,
  PolicyElementaryRight,
  ProductLimitListVersionStatus,
  ServiceContactElementaryRight,
} from '../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {StringUtils} from '../../../bonding_shared/utils';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';

@Component({
  selector: 'policylimitlist-details',
  templateUrl: './policylimitlist-details.component.pug',
})
export class PolicyLimitListDetailsComponent extends DetailsView implements OnInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;

  portalMode = false;
  policyLimitList: ProductLimitListVersionDto;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  @ViewChild('versions') versionsTable: ATableComponent<ProductLimitListVersionSimpleDto>;

  self = this;
  potentialCoOwners: CompanyBaseDto[] = [];

  versionsDataProvider: SearchDataProvider<ProductLimitListVersionCriteriaDto, ProductLimitListVersionSimpleDto>;

  constructor(
    private _route: ActivatedRoute,
    public policyLimitListService: PolicyLimitListService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    private cacheService: CacheService,
    protected growlService: GrowlService,
    public appService: AppConfigService
  ) {
    super(growlService);

    this.versionsDataProvider = BusinessUtils.createPolicyLimitListDataProvider(this.policyLimitListService);

    this.portalMode = this.loggedUserService.portal;

    this.initializeSelectorEmittersByNames(true, ['Owner', 'Factorer']);
  }

  ngOnInit() {
    this._route.params.subscribe((params) => this.initializeView(params));
    this.form = this.ngForm.form;
  }

  initializeView(params: Params) {
    console.log('initializeView:', params);
    const id = +params['id'];
    this.clearErrors();
    this.policyLimitList = undefined;
    if (id > 0) {
      this.loadVersion(+params.id, true);
    } else {
      this._route.queryParams.subscribe((q: PolicyLimitListViewParams) => {
        if (q.readFromCache && this.cacheService.policyLimitList) {
          this.setPolicyLimitList(this.cacheService.policyLimitList);
        } else if (+q.masterPolicyId > 0) {
          this.policyLimitListService.initialVersion(+q.masterPolicyId).subscribe({
            next: (pll) => this.setPolicyLimitList(pll),
            error: () => (this.objectNotFound = true),
          });
        } else {
          this.objectNotFound = true;
        }
      });
    }
  }

  setPolicyLimitList(pllv: ProductLimitListVersionDto, refreshVersionList = false) {
    this.updateErrors(pllv.warnings);
    this.cacheService.policyLimitList = pllv;
    this.policyLimitList = pllv;
    if (refreshVersionList) {
      this.refreshVersionList();
    }
    this.refreshPotentialCoOwners();
    this.handleButtons();
  }

  onSave() {
    this.clearErrors();
    this.inProgress = true;
    if (this.formValidates()) {
      this.policyLimitListService.save(this.policyLimitList).subscribe({
        next: (tp) => {
          this.afterSave(tp);
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  afterSave(pll: ProductLimitListVersionDto) {
    this.policyLimitList = pll;
    this.showSavedMsg();
    this.clearErrors();
    this.handleButtons();
    this.router.toPolicyLimitListDetails(pll.id);
  }

  handleButtons() {
    this.saveButton.hidden = !this.policyLimitList || this.readOnly;
    this.cancelButton.hidden = this.saveButton.hidden || this.readOnly;
    this.deleteButton.hidden =
      !this.policyLimitList ||
      !this.policyLimitList.status ||
      this.policyLimitList.limitList.main ||
      this.policyLimitList.status.id !== ProductLimitListVersionStatus.DEACTIVATED ||
      this.policyLimitList.versionNumber !== 1 ||
      !this.policyLimitList.limitList.masterPolicyContract;
  }

  onCancel() {
    super.onCancel(this._route);
  }

  onDelete() {
    this.policyLimitListService.deleteObj(this.policyLimitList.id).subscribe({
      next: () => this.router.toPolicyLimitListSearchFromPolicy(this.policyLimitList.limitList.masterPolicyContract.id),
      error: (error) => this.handleServerError(error),
    });
  }

  get masterPolicy() {
    return this.policyLimitList && this.policyLimitList.limitList.masterPolicyContract;
  }

  showFactorer() {
    return (
      this.policyLimitList &&
      this.policyLimitList.policyVersion &&
      !this.policyLimitList.limitList.main &&
      BusinessUtils.isFactoringPolicyType(this.masterPolicy.productType.id)
    );
  }

  getCompany(tp: ThirdPartyBaseDto) {
    return BusinessUtils.getThirdPartyCompany(tp).company;
  }

  createFactorerClicked() {
    this.router.toCompanyDetailsNew(View.POLICY_LIMIT_LIST_DETAILS.id, SettableObjectType.POLICY_LIMIT_LIST_FACTORER);
  }

  getOwnerAddress(co: ProductLimitListCoOwnerDto) {
    return co.company && StringUtils.prettyAddress(co.company.address, true);
  }

  refreshPotentialCoOwners() {
    this.potentialCoOwners = this.policyLimitList.potentialCoOwners.filter(
      (pc) => this.policyLimitList.coOwners.filter((o) => o.company && o.company.id === pc.id).length < 1
    );
  }
  addCoOwner(co: ProductLimitListCoOwnerDto) {
    if (this.policyLimitList.coOwners.length < 2) {
      co.fees = true;
      co.contribution = true;
    }
    this.refreshPotentialCoOwners();
  }

  onSelectVersion(bcv: BondingContractInquiryVersionSimpleDto) {
    this.clearErrors();
    this.loadVersion(bcv.id, false);
  }

  loadVersion(versionId: number, refreshVersionList: boolean) {
    this.policyLimitListService.getById<ProductLimitListVersionDto>(versionId).subscribe({
      next: (pll) => this.setPolicyLimitList(pll, refreshVersionList),
      error: (error) => this.handleServerError(error),
    });
  }

  private refreshVersionList() {
    this.versionsDataProvider.searchCriteria.criteria.limitList.id = this.policyLimitList.limitList.id;
    if (this.versionsTable) {
      this.versionsTable.search();
    }
  }

  validity(v: any): LocalDateRange {
    return BusinessUtils.validity(v.validFrom, v.validTo);
  }

  get readOnly() {
    return this.policyLimitList && this.policyLimitList.readOnly;
  }

  coOwnerDelatable(item: ProductLimitListCoOwnerDto): boolean {
    return !item.contribution && !item.fees;
  }

  toServiceContacts() {
    this.router.toServiceContacts({
      boTypeId: BusinessObjectType.LIMIT_LIST,
      boId: this.policyLimitList.limitList.id,
      returnBoTypeId: BusinessObjectType.LIMIT_LIST_VERSION,
      returnBoId: this.policyLimitList.id,
    });
  }
}

export class PolicyLimitListViewParams {
  // string when reading
  readFromCache?: string | boolean;
  masterPolicyId?: number | string;
}
