import {Component, OnInit, ViewChild} from '@angular/core';
import {
  ClaimIndemnificationInvoiceDto,
  ClaimIndemnificationVersionBaseDto,
  ClaimIndemnificationVersionDto,
  ClaimVersionDto,
  DictionaryBaseDto,
  PolicyContractIdDto,
} from '../../bonding_shared/model';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  ClaimService,
  GrowlService,
  RouterService,
  TemplateService,
} from '../../bonding_shared/services';
import {NgForm, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  ClaimElementaryRight,
  ClaimIndemnificationStatus,
  ClaimRiskType,
} from '../../bonding_shared/model/dictionary-ids';
import {ClaimIndemnificationVersionService} from '../../bonding_shared/services/claim-indemnification-version.service';
import {TemplateSimpleDto} from '../../bonding_shared/model/dtos';
import {NumberUtils} from '../../bonding_shared/utils/number-utils';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';

@Component({
  selector: 'claim-indemnification',
  templateUrl: 'claim-indemnification.component.pug',
})
export class ClaimIndemnificationComponent extends DetailsView implements OnInit {
  version = <ClaimIndemnificationVersionDto>{parent: {claim: {}, calculation: {}}};
  claimVersion: ClaimVersionDto;
  versions: ClaimIndemnificationVersionBaseDto[];
  allIndemnifications: ClaimIndemnificationVersionBaseDto[];
  indemnificationTemplates: TemplateSimpleDto[];
  isIndemnificationFromCalculation: boolean;
  availablePolicyYears: PolicyContractIdDto[];
  selectedPolicyYear: PolicyContractIdDto;
  totalToBePaidAmount: number;
  totalToBePaidGoodwillAmount: number;
  newVersionCreation: boolean;
  public readonly TODAY = new Date();

  readonly boType = BusinessObjectType.CLAIM_INDEMNIFICATION_VERSION;
  readonly ClaimElementaryRight = ClaimElementaryRight;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  public constructor(
    private _route: ActivatedRoute,
    public service: ClaimIndemnificationVersionService,
    protected claimService: ClaimService,
    public router: RouterService,
    protected growlService: GrowlService,
    protected templateService: TemplateService,
    protected appService: AppConfigService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.hidden = false;
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.initializeSelectorEmittersByNames(true, ['Beneficiary']);
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  onPolicyYearChange(policyYear: PolicyContractIdDto) {
    this.service.getInitialVersion(this.version.parent.claim.id, policyYear.id).subscribe((x) => this.setVersion(x));
  }

  onCreateNewVersion() {
    this.service.getNewVersion(this.version.parent.id).subscribe((x) => {
      this.setVersion(x);
      this.resetInvoiceToBePaidAmounts(x.indemnificationReturn);
    });
  }

  initializeView(params: Params) {
    super.initializeView(params);
    this.serverErrors = undefined;
    if (params['versionId']) {
      this.service.getById<ClaimIndemnificationVersionDto>(+params['versionId']).subscribe((x) => {
        this.setVersion(x);
        this.loadBottomTables();
      });
      this.newVersionCreation = false;
    } else {
      this.newInd();
      this.newVersionCreation = true;
    }
  }

  newInd() {
    this._route.queryParams.subscribe((params) => {
      this.service.getInitialVersion(+params['claimId']).subscribe((x) => this.setVersion(x));
    });
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      this.service.save(this.version).subscribe({
        next: (saved) => {
          this.afterObjectSaved();
          if (this.version.id) {
            this.setVersion(saved);
            this.loadBottomTables();
          } else {
            this.router.toClaimIndemnification(saved.id);
          }
        },
        error: (error) => {
          this.serverErrors = error;
          this.inProgress = false;
        },
      });
    }
  }

  onCancel() {
    super.onCancel(this._route);
  }

  loadBottomTables() {
    this.getVersions(this.version.parent.id);
    this.getAllIndemnifications(this.version.parent.claim.id);
  }

  getVersions(parentId: number) {
    this.service.getVersions(parentId).subscribe((c) => (this.versions = c));
  }

  getAllIndemnifications(claimId: number) {
    this.service.getLastVersions(claimId).subscribe((c) => (this.allIndemnifications = c));
  }

  setVersion(claimIndemnification: ClaimIndemnificationVersionDto) {
    this.version = claimIndemnification;
    this.objectNotFound = false;
    this.inProgress = false;
    this.isIndemnificationFromCalculation = !!this.version.parent.calculation;
    if (this.isIndemnificationFromCalculation && !this.isSuretyRiskClaim) {
      this.refreshTotals();
      this.selectedPolicyYear = claimIndemnification.policyContract;
      this.service
        .getCalculationPolicyYears(this.version.parent.calculation.id)
        .subscribe((x) => this.setPolicyYears(x));
    }
    this.handleButtons();
    this.fetchClaimVersion(this.version.parent.claim.id);
    this.serverErrors = claimIndemnification.warnings;
    this.showErrors = true;
  }

  setPolicyYears(policyContractIds: PolicyContractIdDto[]) {
    this.availablePolicyYears = policyContractIds;
  }

  refreshTotals() {
    this.totalToBePaidAmount = this.version.invoices.reduce((a, b) => a + b.toBePaidApprovedForPaymentAmount, 0);
    this.totalToBePaidGoodwillAmount = this.version.invoices.reduce((a, b) => a + b.toBePaidGoodwillAmount, 0);
  }

  indemnificationReturnChanged(value: boolean) {
    this.resetInvoiceToBePaidAmounts(value);
    this.version.correctedNoteNumber = '';
  }

  resetInvoiceToBePaidAmounts(indemnificationReturn: boolean) {
    this.version.invoices.forEach((invoice) => {
      invoice.toBePaidApprovedForPaymentAmount = indemnificationReturn
        ? 0
        : this.calculateMaxToBePaidApprovedForPayment(invoice);
      invoice.toBePaidGoodwillAmount = indemnificationReturn ? 0 : this.calculateMaxToBePaidGoodwillAmount(invoice);
    });
  }

  public claimIndemnificationInvoiceToBePaidApprovedForPaymentValidator(
    invoice: ClaimIndemnificationInvoiceDto
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      const amountMaxValue = this.calculateMaxToBePaidApprovedForPayment(invoice);
      const amountMinValue = NumberUtils.negate(invoice.paidApprovedForPaymentAmount);
      if (control.value > amountMaxValue) {
        return {lessOrEqThanValue: {requiredValue: amountMaxValue, actualValue: control.value}};
      }
      if (control.value < amountMinValue) {
        return {greaterOrEqualThanValue: {requiredValue: amountMinValue, actualValue: control.value}};
      }
      return Validators.required(control);
    };
  }

  public claimIndemnificationInvoiceToBePaidGoodwillAmountValidator(
    invoice: ClaimIndemnificationInvoiceDto
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      const amountMaxValue = this.calculateMaxToBePaidGoodwillAmount(invoice);
      const amountMinValue = NumberUtils.negate(invoice.paidGoodwillAmount);
      if (control.value > amountMaxValue) {
        return {lessOrEqThanValue: {requiredValue: amountMaxValue, actualValue: control.value}};
      }
      if (control.value < amountMinValue) {
        return {greaterOrEqualThanValue: {requiredValue: amountMinValue, actualValue: control.value}};
      }
      return Validators.required(control);
    };
  }

  calculateMaxToBePaidApprovedForPayment(invoice: ClaimIndemnificationInvoiceDto): number {
    const calculatedToBePaidApprovedForPayment = NumberUtils.minus(
      invoice.approvedForPaymentAmount,
      invoice.paidApprovedForPaymentAmount
    );
    return calculatedToBePaidApprovedForPayment > 0 ? calculatedToBePaidApprovedForPayment : 0;
  }

  calculateMaxToBePaidGoodwillAmount(invoice: ClaimIndemnificationInvoiceDto): number {
    const calculatedToBePaidGoodwillAmount = NumberUtils.minus(invoice.goodwillAmount, invoice.paidGoodwillAmount);
    return calculatedToBePaidGoodwillAmount > 0 ? calculatedToBePaidGoodwillAmount : 0;
  }

  handleButtons() {
    this.newVersionButton.hidden = !this.version.id;
    this.newVersionButton.disabled = false;
    this.saveButton.disabled = false;
    this.cancelButton.disabled = !this.version.id;
    this.loadTemplates();
  }

  isEditable() {
    return this.isFormEditable || this.isDateEditable();
  }

  get isFormEditable(): boolean {
    return (
      !this.version.id ||
      (this.isLast() && this.version.status.id === ClaimIndemnificationStatus.PREPARATION_FOR_PAYMENT)
    );
  }

  get isIbanDisabled(): boolean {
    return !(this.isFormEditable && (!this.appService.credendo || this.version.otherBeneficiary));
  }

  isDateEditable() {
    return this.version.id && this.isLast() && this.version.status.id === ClaimIndemnificationStatus.SENT_FOR_PAYMENT;
  }

  isLast() {
    return this.version.last;
  }

  getLastVersionId() {
    return this.versions.find((x) => x.last).id;
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.version.parent.claim.riskType);

    this.templateService
      .findByType(undefined, BusinessObjectType.CLAIM_INDEMNIFICATION, undefined, dictionarySelectors)
      .subscribe((result) => {
        this.indemnificationTemplates = result;
      });
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.version.parent.id);
  }

  otherBeneficiaryCheckboxOnChange(otherBeneficiaryCheckboxSelected: boolean) {
    if (!otherBeneficiaryCheckboxSelected) {
      // restore initial values
      this.closeAllSelectors();
      this.version.beneficiary = this.version.initialBeneficiary;
      this.version.iban = this.version.initialIban;
      this.version.bic = this.version.initialBic;
    }
  }

  get isSuretyRiskClaim(): boolean {
    return this.isGivenRiskType(ClaimRiskType.SURETY);
  }

  get isCreditInsuranceClaim(): boolean {
    return this.isGivenRiskType(ClaimRiskType.CREDIT_INSURANCE);
  }

  private isGivenRiskType(expectedRiskTypeId: number): boolean {
    return DictionaryUtils.equalsDictAndId(this.version.parent.claim.riskType, expectedRiskTypeId);
  }

  get minPaymentAmount(): number {
    return this.version.indemnificationReturn && this.version.paidBefore ? -1 * this.version.paidBefore : 0;
  }

  private fetchClaimVersion(claimId: number) {
    this.claimService
      .getLastVersionByClaimId(claimId)
      .subscribe((claimVersion: ClaimVersionDto) => (this.claimVersion = claimVersion));
  }
}
