import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {BrokerContractDto, BrokerContractStatsDto, BrokerContractVersionCriteriaDto} from '../model';
import {HttpClient} from '@angular/common/http';
import {BinaryDownloaderService} from './binary-downloader.service';

@Injectable()
export class BrokerContractVersionService extends AbstractService {
  protected url = this.urlPrefix + 'brokerContractVersion';
  protected brokerContractUrl = this.urlPrefix + 'brokerContract';

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getStats(id: number): Observable<BrokerContractStatsDto> {
    return this.get<BrokerContractStatsDto>(this.brokerContractUrl + '/stats/' + id);
  }

  getBrokerContract(brokerContractId: number): Observable<BrokerContractDto> {
    return this.get<BrokerContractDto>(this.brokerContractUrl + '/' + brokerContractId);
  }

  initialVersion<T>(companyId: number, businessUnitId: number): Observable<T> {
    return this.get<T>(this.url + '/initialVersion/' + companyId + '/' + businessUnitId);
  }

  export(
    criteria: BrokerContractVersionCriteriaDto,
    outputFileName: string,
    errorCallback: (errMsg: string) => void,
    completeCallback: (f: File) => void
  ) {
    console.log('export ', criteria);
    this.binaryDownloader.download(
      'POST',
      this.url + '/export/',
      'application/json',
      'application/octet-stream',
      outputFileName,
      criteria,
      errorCallback,
      completeCallback
    );
  }
}
