<a-table [items]="addresses" [editable]="editable" [selection]="false" [sortable]="false" [permEditMode]="oneTypeMode"
         [formModel]="form" [groupName]="controlName" [showAllErrors]="showErrors" [deleteButton]="editable && !oneTypeMode"
         [addButton]="editable && !oneTypeMode" [autoUpdateValidators]="'reset'" (addItem)="addAddress.emit($event)">
  <column property="type" dictionary="AddressType" [required]="commonFieldsRequired" nullLabel=""
          [dictHiddenIds]="hiddenAddressTypes" *ngIf="!(oneTypeMode || hideType)" labelKey="common.type"></column>
  <column property="street" [required]="commonFieldsRequired" maxlength="160" cellWidth="15%" labelKey="common.address.street"></column>
  <column property="streetNumber" maxlength="40" labelKey="common.address.streetNumber"></column>
  <column property="zipCode" type="custom" maxlength="20" labelKey="common.address.zipCode"
          [customValidatorSupplier]="getZipCodePatternValidator()">
    <ng-template let-edition="edition" let-item="item" let-control="formControlModel">
      <input *ngIf="edition && item" class="bon-table-input" type="text" [(ngModel)]="item.zipCode"
             auto-complete [source]="postcodesSupplier(item)" accept-user-input="false" min-chars="2"
             (ngModelChange)="onPostcodeChange(item); control.patchValue(item)" />
      <span *ngIf="!edition && item">{{item.zipCode}}</span>
    </ng-template>
  </column>
  <column property="town" type="custom" [required]="commonFieldsRequired" maxlength="60" cellWidth="15%" labelKey="common.address.town">
    <ng-template let-edition="edition" let-item="item" let-control="formControlModel">
      <input *ngIf="edition && item" class="bon-table-input" type="text" [(ngModel)]="item.town"
            auto-complete [source]="townsSupplier(item)" accept-user-input="true" min-chars="2"
            (ngModelChange)="onTownChange(item);control.patchValue(item)"/>
      <span *ngIf="!edition && item">{{item.town}}</span>
    </ng-template>
  </column>
  <column property="province" labelKey="common.address.province" type="custom" [required]="provinceRequired">
    <ng-template let-edition="edition" let-item="item" let-control="formControlModel">
      <input *ngIf="edition && item" class="bon-table-input" type="text" [(ngModel)]="item.province"
             auto-complete [source]="provinceSupplier(item)" accept-user-input="true" min-chars="2"
             (ngModelChange)="onProvinceChange(item);control.patchValue(item)"/>
      <span *ngIf="!edition && item">{{item.province}}</span>
    </ng-template>
  </column>
  <column *ngIf="provinceCodeVisible" property="provinceCode" labelKey="common.address.provinceCode" (cellChange)="onProvinceCodeChange($event.item)">
  </column>
  <column property="country" dictionary="Country" [required]="commonFieldsRequired" [dictionaryProfileId]="countryDictionaryProfileId" (cellChange)="countryChange.emit($event.item.country)"
    labelKey="common.country" nullLabel="" [editable]="isCountryEditable"></column>
  <column property="poBox" maxlength="20" labelKey="common.address.poBox" *ngIf="!poBoxHidden"></column>
</a-table>
