import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BondVersionDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryDto,
  DocumentDescriptionDto,
  TemplateSimpleDto,
  ContractBaseDto,
} from '../../../bonding_shared/model/dtos';
import {BondSubType, Feature} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryService} from '../../../bonding_shared/services/dictionary.service';
import {RepositoryService} from '../../../bonding_shared/services/repository.service';
import {BondGuiService} from '../services/bond-gui.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService, BondVersionService, FeatureService} from '../../../bonding_shared/services';

@Component({
  selector: 'bond-subtype-selector',
  templateUrl: 'bond-subtype-selector.component.html',
})
export class BondSubtypeSelectorComponent implements OnInit {
  public _bond: BondVersionDto;
  private _selectedCompany: CompanySimpleDto;
  private _selectedContract: ContractBaseDto;
  public showBondSubType: boolean;

  @Input() disabled: boolean;
  @Input() parentBondTypeId: number;
  @Input() canIssue: boolean;
  @Input() showErrors: boolean;
  @Input() customTemplate: TemplateSimpleDto;
  @Input() bondTypeName: string;

  @Input() set selectedCompany(company: CompanySimpleDto) {
    this._selectedCompany = company;
  }

  @Input() set selectedContract(contract: ContractBaseDto) {
    this._selectedContract = contract;
  }

  get selectedCompany() {
    return this._selectedCompany;
  }

  get selectedContract() {
    return this._selectedContract;
  }

  @Input() set bond(bond: BondVersionDto) {
    if (this._bond && bond && this._bond.id === bond.id) {
      return;
    }
    this._bond = bond;
    this.initCustomTemplate(bond);
  }

  get bond() {
    return this._bond;
  }

  @Output() subtypeSelect = new EventEmitter<DictionaryBaseDto>();

  customTemplates: TemplateSimpleDto[];
  selectedCustomTemplate: TemplateSimpleDto;
  modeCreationKeyItems: VL[] = [
    {value: 'Standard', label: ''},
    {value: 'UserTemplate', label: ''},
    {value: 'SpecialText', label: ''},
  ];
  modeCreationKey: string = null;
  specialTextUploadFile: DocumentDescriptionDto = null;
  subtypeInitiated = false;

  private subtypes: DictionaryDto[] = null;
  private openedSpecialTextUpload = false;

  constructor(
    private dictService: DictionaryService,
    private repositoryService: RepositoryService,
    public guiService: BondGuiService,
    private translateService: TranslateService,
    private bondVersionService: BondVersionService,
    public appService: AppConfigService,
    private featureService: FeatureService
  ) {
    this.showBondSubType = featureService.get(Feature.BOND_SHOW_SUBTYPE);
    this.modeCreationKeyItems.forEach((vl) =>
      this.translateService.get('bond.mode.creation.' + vl.value).subscribe((tr) => (vl.label = tr))
    );
  }

  ngOnInit() {
    const b = this.bond;
    if (b) {
      this.initCustomTemplate(b);
      this.subtypeInitiated = !!b.subtype;
    }
    this.dictService.getDictionary('BondSubtype').subscribe((result) => {
      this.subtypes = result;
    });
  }

  ngAfterViewInit() {
    this.loadCustomTemplates();
  }

  initCustomTemplate(bond: BondVersionDto) {
    const customTemplate: TemplateSimpleDto = bond.customTemplate;

    if (bond.subtype && (customTemplate || bond.subtype.id === BondSubType.TECHNICAL_USER_TEMPLATE)) {
      this.modeCreationKey = 'UserTemplate';
      this.resetState(customTemplate, null);
    } else if (bond.subtype && bond.subtype.id === BondSubType.TECHNICAL_USER_SPECIAL_TEXT) {
      this.modeCreationKey = 'SpecialText';
      this.resetState(null, this.specialTextUploadFile);

      this.bondVersionService.getSpecialTextDto(bond).subscribe((doc) => {
        this.specialTextUploadFile = doc;
      });
    } else {
      this.modeCreationKey = 'Standard';
      this.resetState();
    }
  }

  private findMod(key: string) {
    return this.modeCreationKeyItems.find((vl) => vl.value === key);
  }

  updateCustomTemplate(bond: BondVersionDto) {
    if (this.modeCreationKey === 'UserTemplate') {
      if (this.selectedCustomTemplate !== undefined && this.selectedCustomTemplate !== null) {
        bond.customTemplate = <TemplateSimpleDto>{};
        bond.customTemplate.id = this.selectedCustomTemplate.id;
        bond.specialTextRepositoryId = null;
      }
    } else if (this.modeCreationKey === 'SpecialText') {
      bond.customTemplate = null;
      bond.specialTextRepositoryId = this.specialTextUploadFile.repositoryId;
    } else {
      bond.customTemplate = null;
      bond.specialTextRepositoryId = null;
    }
  }

  onSubtypeChange(subtype: DictionaryBaseDto) {
    this.subtypeInitiated = true;
    this.subtypeSelect.emit(subtype);
  }

  showSpecialTextUpload(): boolean {
    return this.modeCreationKey === 'SpecialText' && this.openedSpecialTextUpload && !this.disabled;
  }

  onSpecialTextUploadSelect(doc: DocumentDescriptionDto) {
    this.openedSpecialTextUpload = false;
    this.resetState(null, doc);
    this.onSubtypeSelectById(undefined, BondSubType.TECHNICAL_USER_SPECIAL_TEXT);
  }

  private onSubtypeSelectById(customTemplate: TemplateSimpleDto, subtypeId: number) {
    if (subtypeId) {
      const st = this.subtypes.find((i) => i.id === subtypeId);
      if (customTemplate) {
        this.guiService.setCustomTemplate(customTemplate);
      }
      this.subtypeSelect.emit(st);
      this.subtypeInitiated = true;
    } else {
      this.subtypeSelect.emit(null);
    }
  }

  onChangeCustomTemplate(ct: TemplateSimpleDto) {
    if (ct) {
      this.onSubtypeSelectById(ct, BondSubType.TECHNICAL_USER_TEMPLATE);
    }
  }

  onChangeModeCreationKey() {
    this.resetState();
    this.openedSpecialTextUpload = true;
  }

  private resetState(sct: TemplateSimpleDto = null, stf: DocumentDescriptionDto = null) {
    this.selectedCustomTemplate = sct;
    this.specialTextUploadFile = stf;
  }

  private loadCustomTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    const companyId: number = this.selectedCompany ? this.selectedCompany.id : undefined;
    const contractId: number = this.selectedContract ? this.selectedContract.id : undefined;
    this.guiService.getClientBondOwnTemplates(dictionarySelectors, companyId, contractId).subscribe((result) => {
      this.customTemplates = result;
    });
  }

  onFileListOpen() {
    this.openedSpecialTextUpload = true;
  }

  getSpecialTextFile() {
    if (this.specialTextUploadFile) {
      if (this.bond.id) {
        this.bondVersionService.getSpecialTextContent(this.bond, this.specialTextUploadFile.name);
      } else {
        this.bondVersionService.getSpecialTextContentFromContract(
          this.bond.contractVersionId,
          this.specialTextUploadFile.repositoryId,
          this.specialTextUploadFile.name
        );
      }
    }
  }
}

interface VL {
  value: string;
  label: string;
}
