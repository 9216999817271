import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BIReportCriteriaDto,
  BIReportSimpleDto,
  CompanyDto,
  DocumentService,
  DocumentSimpleDto,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
} from '../../../bonding_shared';
import {BIReportService} from '../../../bonding_shared/services/bi-report.service';

@Component({
  selector: 'bi-report-list',
  templateUrl: 'bi-report-list.component.html',
})
export class BiReportListComponent implements AfterViewInit {
  @ViewChild(ATableComponent, {static: true}) public reportTable: ATableComponent<BIReportSimpleDto>;
  private _company: CompanyDto;
  private initialized = false;
  @Input() showId = false;
  @Input() showCompany = false;
  @Input() showCompanyRaw = false;
  @Input() showThirdParty = false;
  @Input() showCountry = false;
  @Input() performInitialSearch = true;

  @Input()
  dataProvider: SearchDataProvider<BIReportCriteriaDto, BIReportSimpleDto>;

  public get company() {
    return this._company;
  }

  @Input()
  public set company(company: CompanyDto) {
    this.initializeDataProvider();
    this._company = company;
    if (this.initialized) {
      this.search();
    }
  }

  constructor(
    public router: RouterService,
    private documentService: DocumentService,
    private biReportService: BIReportService,
    protected loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {}

  initializeDataProvider() {
    if (!this.dataProvider) {
      this.dataProvider = new SearchDataProvider<BIReportCriteriaDto, BIReportSimpleDto>(this.biReportService);
      this.dataProvider.searchCriteria.criteria = <BIReportCriteriaDto>{};
    }
  }

  public ngAfterViewInit(): void {
    if (this.performInitialSearch) {
      this.search();
    }
    this.initialized = true;
  }

  public search() {
    if (this.company) {
      this.dataProvider.searchCriteria.criteria.company = this.company;
    }
    this.reportTable.search();
  }

  public gotoDocumentDetail(document: DocumentSimpleDto): void {
    if (this.documentService.isText(document.contentType)) {
      this.router.toDocumentDetails(document.id);
    } else {
      this.documentService.downloadContent(document.id, document.contentType, document.extension);
    }
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  isEmpty(): boolean {
    if (!this.reportTable) {
      return true;
    }
    return this.reportTable.totalCount < 1;
  }
}
