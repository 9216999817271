import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {HttpClient} from '@angular/common/http';
import {
  BondingContractInquiryVersionDto,
  MasterPolicyContractForLimitDto,
  ProductLimitListForLimitDto,
  ProductLimitListVersionDto,
  StateTransitionDto,
} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class PolicyLimitListService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'productLimitListVersion';
  }

  public initialVersion(masterPolicyId: number): Observable<ProductLimitListVersionDto> {
    return this.get<ProductLimitListVersionDto>(this.url + '/initialVersion/' + masterPolicyId);
  }

  getMasterPolicy(masterPolicyId: number): Observable<MasterPolicyContractForLimitDto> {
    return this.get<MasterPolicyContractForLimitDto>(this.url + '/masterPolicy/' + masterPolicyId);
  }

  getListForLimit(listId: number): Observable<ProductLimitListForLimitDto> {
    return this.get<ProductLimitListForLimitDto>(this.url + '/listForLimit/' + listId);
  }

  getTransitions(bv: BondingContractInquiryVersionDto): Observable<Array<StateTransitionDto>> {
    if (bv && bv.status) {
      return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + bv.status.id);
    }
    return Observable.create([]);
  }
}
