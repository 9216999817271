import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BusinessObjectDto,
  DictionaryBaseDto,
  DocumentCriteriaDto,
  DocumentService,
  DocumentSimpleDto,
  FeatureService,
  LocalDateTimeRange,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
  SearchResult,
  TemplateSimpleDto,
} from '../../../bonding_shared';
import {BusinessObjectType, Feature, UserRole} from '../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'document-list',
  templateUrl: 'document-list.component.html',
})
export class DocumentListComponent implements AfterViewInit {
  @ViewChild(ATableComponent, {static: true}) documentTable: ATableComponent<DocumentSimpleDto>;
  @Input() pagination = false;
  @Input() documentLink = true;
  @Input() companyLabelKey = 'documentList.company';
  @Input() showPack = false;
  @Input() initSearch = false;
  @Input() hiddenColumns = [];
  @Input() selectMain = false;
  @Input() selection = false;
  @Input() excludedTypesIds = [];
  @Input() showCertificate = false;

  @Output() selectDocument = new EventEmitter<DocumentSimpleDto>();
  @Output() documentsLoaded = new EventEmitter<number>();

  @Input() searchDataProvider: SearchDataProvider<DocumentCriteriaDto, DocumentSimpleDto>;

  get criteria(): DocumentCriteriaDto {
    return this.searchDataProvider.searchCriteria.criteria;
  }

  set criteria(c: DocumentCriteriaDto) {
    this.searchDataProvider.searchCriteria.criteria = c;
  }

  @Input() set relatedToType(_relatedToType: string) {
    this._relatedToType = undefined;
    if (_relatedToType) {
      this._relatedToType = (<any>BusinessObjectType)[_relatedToType.toUpperCase()];
    }
  }

  @Input() set relatedToId(relatedToId: number) {
    this._relatedToId = relatedToId;
    this.search();
  }

  private _relatedToId: number;
  private _relatedToType: BusinessObjectType;
  private pendingSearch = false;
  private fullyInitialized = false;
  public showShortNameIfExists: boolean; // show short company name instead of registration name when it exists on search view
  constructor(
    public router: RouterService,
    private documentService: DocumentService,
    public appService: AppConfigService,
    private featureService: FeatureService,
    private loggedUserService: LoggedUserService
  ) {
    this.showShortNameIfExists = featureService.get(Feature.COMPANY_SHOW_SHORT_NAME_IF_EXISTS);
    this.searchDataProvider = new SearchDataProvider<DocumentCriteriaDto, DocumentSimpleDto>(documentService);
    this.criteria = <DocumentCriteriaDto>{};
    this.criteria.businessObject = <BusinessObjectDto>{};
    this.criteria.template = <TemplateSimpleDto>{};
    this.criteria.creationDateRange = <LocalDateTimeRange>{};
    this.criteria.lastSentDateRange = <LocalDateTimeRange>{};
  }

  ngAfterViewInit() {
    this.fullyInitialized = true;
    if ((this.pendingSearch || this.initSearch) && this._relatedToId) {
      this.search();
    }
  }

  search() {
    if (this.fullyInitialized && this.documentTable) {
      if (this._relatedToId) {
        this.criteria.businessObject.relatedToId = this._relatedToId;
      }
      if (this._relatedToType) {
        this.criteria.businessObject.relatedTo = <DictionaryBaseDto>{id: this._relatedToType};
      }
      this.documentTable.search();
      this.pendingSearch = false;
    } else {
      this.pendingSearch = true;
    }
  }

  getBinaryContentUrl(document: DocumentSimpleDto): string {
    return this.documentService.getBinaryContentUrl(document.id, document.contentType, document.extension);
  }

  gotoDocumentDetail(document: DocumentSimpleDto) {
    if (!this.documentLink) {
      return;
    }
    if (this.documentService.isText(document.contentType)) {
      this.router.toDocumentDetails(document.id);
    } else {
      this.documentService.downloadContent(document.id, document.contentType, document.extension);
    }
  }

  isDocumentLinkFunc(): (t: DocumentSimpleDto) => boolean {
    const isSuper = this.loggedUserService.hasLoggedUserRole(UserRole.SUPER_USER);
    return (document: DocumentSimpleDto) => {
      if (!this.documentLink || !document) {
        return false;
      }
      if (!isSuper) {
        return !document.lastSentDate;
      }
      return true;
    };
  }

  refresh() {
    if (this._relatedToId) {
      this.search();
    }
  }

  onSelectItem(item: DocumentSimpleDto) {
    this.selectDocument.emit(item);
    this.documentTable.items.forEach((i) => (i.repository = false));
    item.repository = true;
  }

  searchFinished($event: SearchResult<DocumentSimpleDto>) {
    this.documentsLoaded.emit($event.size);
  }

  isHidden(doc: DocumentSimpleDto): boolean {
    return this.excludedTypesIds.includes(doc.type.id);
  }
}
