/**
 * Created by wilk on 15.05.2017.
 */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {TextSearchCriteria} from '../../../bonding_shared/components/search/model/text-search-criteria';
import {InquiryService} from '../../../bonding_shared/services/inquiry.service';
import {
  CompanyCriteriaDto,
  CompanySimpleDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {AppConfigService} from '../../../bonding_shared/services';

@Component({
  selector: 'inquiry-selector-simple',
  templateUrl: './inquiry-selector-simple.component.pug',
})
export class InquirySelectorSimpleComponent {
  @Input() title: string;
  @Input() labelKey: string;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * to be able to switch between advanced/basic search mode
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    return this._searchModeSwitchable;
  }

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  @Input() set criteria(c: PolicyInquiryVersionCriteriaDto) {
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = c;
  }

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  @Input() set showDuplicates(show: boolean) {
    this.dataProvider.textSearchCriteria.duplicate = show;
  }

  @Output() select = new EventEmitter<CompanySimpleDto>();

  dataProvider: SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>;

  constructor(private service: InquiryService, private appService: AppConfigService) {
    this.dataProvider = new SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(service);
    this.dataProvider.searchCriteria.criteria = <PolicyInquiryVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.client = <CompanyCriteriaDto>{};
    this.dataProvider.textSearch = false;
  }

  onSelectCompany(c: CompanySimpleDto) {
    // TODO: perhaps a-table should be corrected: when page is changed in a-table empty object is emitted
    if (c !== undefined && c !== null) {
      this.select.emit(c);
      this.open.next(false);
    }
  }
}
