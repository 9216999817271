import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ClientLimitSearchComponent} from './client-limit-search.component';
import {LimitSharedModule} from '../../bonding/limit/shared/limit-shared.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../bonding/policy/components/shared/policy-contract-shared.module';
import {LimitModule} from '../../bonding/limit/limit.module';
import {ClientLimitCoverPlusViewComponent} from './client-limit-cover-plus-view.component';
import {SliderModule} from 'primeng/slider';
import {CompanyExtendedSelectorModule} from '../../bonding/company/components/shared/extended-selectors/company-extended-selector.module';
import {ClientPolicySharedModule} from '../policy/shared/client-policy-shared.module';
import {PolicyLimitListSharedModule} from '../../bonding/policy-limit-list/shared/policy-limit-list-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    LimitSharedModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    LimitModule,
    CompanyExtendedSelectorModule,
    ClientPolicySharedModule,
    SliderModule,
    PolicyLimitListSharedModule,
  ],
  declarations: [ClientLimitSearchComponent, ClientLimitCoverPlusViewComponent],
})
export class ClientLimitModule {}
