<nav class="navbar topnav-navbar navbar-fixed-top">
    <button id="sidebar-toggler" class="hidden-md-up" type="button" (click)="sidebarToggler()">
        &#9776;
    </button>
    <a class="hidden-md-up theme-name" href="Javascript:void(0);" (click)="gotoHome()"><strong>{{appConfigService.displayName}}</strong></a>
    <div class="collapse navbar-toggleable-sm">
        <a class="menu-logo" href="Javascript:void(0); "(click)="gotoHome()">
          <strong>{{appConfigService.displayName}}</strong>
        </a>
        <ul class="nav navbar-nav pull-left navbar-right" [style.margin-top.px]="'0'">
            <li class="nav-item profile">
                <failed-login-alert></failed-login-alert>
            </li>
        </ul>
        <ul class="nav navbar-nav pull-right navbar-right" [style.margin-top.px]="'0'">
          <li *ngIf="appConfigService.oauth2" class="nav-item profile" style="width: 175px;">
            <session-timer (timeUp)="gotoLogin()"></session-timer>
          </li>
          <li class="nav-item profile">
            <user-agenda></user-agenda>
          </li>
          <li class="nav-item profile">
            <div class="btn-group" dropdown>
              <a dropdownToggle>
                <span class="hidden-md-down">{{'module.title' | translate}}:&nbsp; {{_settings.activeModuleName | translate}}</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="lang">
                <ng-container *ngFor="let module of _settings.getAccessibleModules()">
                  <li role="menuitem">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="_settings.viewOn(module.id)">
                      {{module.name | translate}}
                    </a>
                  </li>
                  <li class="divider dropdown-divider"></li>
                </ng-container>
                <li role="menuitem" *ngIf="_settings.getAccessibleModules().length > 1" ><a class="dropdown-item" href="javascript:void(0)" (click)="_settings.allAccessibleViewsOn()" translate>module.all</a></li>
              </ul>
            </div>
          </li>
          <li *ngIf="isAbsent()" class="nav-item profile">
            <span style="color: red" >{{ 'ABSENT' | translate }}</span>
          </li>
            <li class="nav-item profile">
                <div class="btn-group" dropdown>
                    <a dropdownToggle>
                        <img src="assets/images/flat-avatar.png" class="topnav-img" alt=""><span class="hidden-md-down" title="{{ userRolesShort && userRoles }}">{{ userName }} ({{ userRolesShort || userRoles }})</span>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="lang">
                        <li role="menuitem" *ngIf="!appConfigService.oauth2"><a class="dropdown-item" href="javascript:void(0)" (click)="goToPassChange()" translate="changePassword.title"></a></li>
                        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)" (click)="changeUserAvailability()" translate="changeAbsence.title"></a></li>
                        <li *ngIf="isAbsent()"
                            role="menuitem"><a class="dropdown-item" href="javascript:void(0)" (click)="setToPresent()" translate="changeAbsence.present"></a></li>
                        <li role="menuitem" *ngIf="!appConfigService.oauth2"><a class="dropdown-item" href="javascript:void(0)" (click)="gotoLogin()" translate>Logout</a></li>
                    </ul>
                </div>
            </li>
          <li class="nav-item profile" *ngIf="appConfigService.helpLink">
            <div class="btn-group" dropdown>
              <a dropdownToggle>
                <img src="assets/images/icons/info-32-blue.gif" class="topnav-img" alt="">
                <!-- <span class="hidden-md-down">Help</span> -->
              </a>
              <ul class="dropdown-menu" aria-labelledby="lang">
                <li *ngFor="let pdf of availableHelps" role="menuitem">
                  <a (click)="downloadSpec(pdf.url, pdf.fileName)">{{pdf.nameKey | translate}}</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item profile">
            <user-language></user-language>
          </li>

        </ul>
    </div>
</nav>
<ng-container *ngIf="appConfigService.credendo">
  <password-expiring></password-expiring>
</ng-container>
<change-password></change-password>
<change-absence></change-absence>
<front-reload-warning></front-reload-warning>
