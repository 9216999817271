import {
  AbstractService,
  AppConfigService,
  BinaryDownloaderService,
  LoggedUserService,
} from '../../bonding_shared/services';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenerateReportIfc} from '../../bonding_shared/services/abstract-service-interfaces';

@Injectable()
export class BusinessReportPortalService extends AbstractService implements GenerateReportIfc {
  protected url = this.urlPrefix + 'portal/businessReport';

  constructor(
    http: HttpClient,
    apiService: AppConfigService,
    loggedUserService: LoggedUserService,
    private binaryDownloaderService: BinaryDownloaderService
  ) {
    super(http, apiService, loggedUserService);
  }

  generate(
    reportDefinitionId: number,
    outputFileName: string,
    httpParams: HttpParams,
    errorCallback?: (errMsg: string) => void,
    completeCallback?: (file: File) => void
  ) {
    const url = this.url + '/generate/' + reportDefinitionId + '/report.xlsx?';
    this.binaryDownloaderService.download(
      'GET',
      url + this.buildUrlParams(httpParams),
      'application/octet-stream',
      'application/octet-stream',
      outputFileName,
      undefined,
      errorCallback,
      completeCallback
    );
  }
}
