import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExternalProviderGuiService} from './services/external-provider-gui.service';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BusinessUnitModule} from '../business-unit/business-unit.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    BusinessUnitModule,
  ],
  declarations: [],
  exports: [],
  providers: [ExternalProviderGuiService],
})
export class ExternalProviderModule {}
