import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {CompanySharedModule} from '../../bonding/company/components/shared/company-shared.module';
import {PolicyContractSharedModule} from '../../bonding/policy/components/shared/policy-contract-shared.module';
import {PolicyContractViewService} from '../../bonding/policy/services/policy-contract-view.service';
import {BrokerContractModule} from '../../bonding/broker-contract/broker-contract.module';
import {InquirySharedModule} from '../../bonding/inquiry/shared/inquiry-shared.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    PolicyContractSharedModule,
    BrokerContractModule,
    InquirySharedModule,
  ],
  declarations: [],
  providers: [PolicyContractViewService],
})
export class ClientPolicyModule {}
