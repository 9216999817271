import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {
  AppConfigService,
  BondVersionDto,
  BondVersionService,
  CellChangeEvent,
  CessionVersionDto,
  ClaimFullDto,
  ClaimInvoiceDto,
  ClaimService,
  ClaimVersionBaseDto,
  ClaimVersionDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  DocumentService,
  FormDialogComponent,
  GrowlService,
  LimitRequestSimpleDto,
  LimitService,
  LoggedUserService,
  PolicyCessionVersionService,
  PolicyContractCoInsurerCompanyDto,
  PolicyContractIdDto,
  PolicyContractVersionDto,
  PolicyContractVersionService,
  RecoveryDto,
  RouterService,
  TemplateService,
  TemplateSimpleDto,
  UserDto,
  UserIdDto,
  UserRange,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {ClaimGuiService} from './services/claim-gui.service';
import {Button} from '../../bonding_shared/components/details-view/button';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {
  BusinessObjectType,
  ClaimElementaryRight,
  ClaimRiskType,
  ClaimStatus,
  ClaimVersionType,
  ContactNoteElementaryRight,
  DocumentType,
  ElementaryRight,
  LimitCategory,
  PolicyContractCompanyRole,
  PolicyContractType,
  PolicyContractVersionStatus,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {ClaimSelectorsComponent} from './components/claim-selectors.component';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {flatMap, map, shareReplay, tap, toArray} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Cacheable} from 'ts-cacheable';
import * as moment from 'moment';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {Location} from '@angular/common';
import {ClaimRecoveriesService} from '../../bonding_shared/services/claim-recoveries.service';
import {GroupedItem} from '../../bonding_shared/components/item-dropdown/item-grouped-dropdown.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'claim-details',
  templateUrl: './claim-details.component.pug',
})
export class ClaimDetailsComponent extends DetailsView implements AfterViewInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild(ClaimSelectorsComponent, {static: true}) selectors: ClaimSelectorsComponent;
  @ViewChild('changeClaimOfficer') changeClaimOfficerDialog: FormDialogComponent;

  claimVersion = ClaimGuiService.emptyVersion();
  limitRequest: LimitRequestSimpleDto;
  templatesGrouped: GroupedItem[] = [];
  recoveryTemplate: TemplateSimpleDto;
  possibleBeneficiaries: PossibleBeneficiary[] = [];
  recovery: RecoveryDto = <RecoveryDto>{};
  bond: BondVersionDto;
  policyContractVersion: PolicyContractVersionDto;
  readonly DateUtils = DateUtils;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ClaimElementaryRight = ClaimElementaryRight;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  versions: ClaimVersionBaseDto[];
  readonly BusinessObjectTypeCLAIM = BusinessObjectType.CLAIM;
  allowEditSelectedFields = false;
  readonly ClaimVersionType = ClaimVersionType;
  readonly canCreateNewVersion: boolean;
  private _currentClaimOfficer = this.claimVersion.claimOfficer;
  private _newClaimOfficer: UserDto;
  readonly claimUserRange: UserRange = 'claim';

  get claim(): ClaimFullDto {
    return this.claimVersion.claim;
  }

  set claim(value: ClaimFullDto) {
    this.claimVersion.claim = value;
  }

  createRightFunc(claimVersion: ClaimVersionDto): string {
    if (claimVersion) {
      return claimVersion?.claim?.id
        ? ClaimElementaryRight.CLAIM_CREATE_NEW_VERSION
        : ClaimElementaryRight.CLAIM_CREATE_INITIAL;
    }
    return undefined;
  }

  constructor(
    private _route: ActivatedRoute,
    public claimService: ClaimService,
    public guiService: ClaimGuiService,
    private templateService: TemplateService,
    public router: RouterService,
    private loggedUserService: LoggedUserService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    private dictService: DictionaryBaseService,
    private cessionService: PolicyCessionVersionService,
    private policyVersionService: PolicyContractVersionService,
    public documentService: DocumentService,
    private location: Location,
    private bondService: BondVersionService,
    private limitService: LimitService,
    private claimRecoveriesService: ClaimRecoveriesService
  ) {
    super(growlService);
    this.newVersionButton = new Button('claim.newVersionButton', this.onCreateNewVersion.bind(this), true, false);
    this.deleteButton = undefined;
    this.canCreateNewVersion = loggedUserService.hasRight(ClaimElementaryRight.CLAIM_CREATE_NEW_VERSION);
    this.initializeSelectors();
  }

  ngAfterViewInit() {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.clearErrors();
    if (params['id']) {
      this.loadClaimVersion(+params['id']);
    } else {
      this.claimVersion = ClaimGuiService.emptyVersion();
      if (params['claimRiskTypeId']) {
        this.claimVersion.claim.riskType = <DictionaryBaseDto>{id: +params['claimRiskTypeId']};
      }
      this.hideButtons(true);
      setTimeout(() => this.selectors.newClaim());
    }
  }

  initializeSelectors() {
    this.selectorNameList = ['ExternalServiceProvider'];
    this.initializeSelectorEmitters(true);
  }

  onSave() {
    this.clearErrors();
    if (this.formValidates()) {
      this.inProgress = true;
      this.saveClaimVersion();
    }
  }

  loadVersions(claimId: number) {
    this.claimService.getVersions(claimId, null, false).subscribe({
      next: (versions) => {
        this.versions = versions;
        this.loadTemplates();
        this.hideButtons(false);
        this.handleButtons();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  handleButtons() {
    this.newVersionButton.hidden = !this.claim?.id;
    this.newVersionButton.disabled = !this.claimVersion || !this.claimVersion.id || !this.claimVersion.last;
    this.saveButton.hidden = !this.claim;
    this.saveButton.disabled = !this.claimVersion || (this.claimVersion.id && !this.claimVersion.last);
    this.cancelButton.hidden = !this.claim;
  }

  saveClaimVersion() {
    this.claimService.save(this.claimVersion).subscribe(
      (claimVersion) => {
        this.setClaimVersion(claimVersion);
        this.inProgress = false;
        this.allowEditSelectedFields = false;
        this.showSavedMsg();
        if (this.claimVersion.occurenceDateOutsideOfPolicy) {
          this.addFrontendWarning('Occurence date not contained in selected policy year');
        }
        if (this.claimVersion.firstInvoiceDateOutsideOfPolicy) {
          this.addFrontendWarning('First invoice date not contained in selected policy year');
        }
        this.router.toClaimDetails(claimVersion.id);
      },
      (error) => this.handleServerError(error)
    );
  }

  calculateClaimVersion() {
    if (this.policyContractVersion?.claimSpecialConditions) {
      this.addFrontendWarning('Some non-standard condition exists in the policy. Calculation must be checked manually');
    }
    this.serverErrors = undefined;
    if (this.formValidates()) {
      this.claimService.calculateClaimVersion(this.claimVersion).subscribe(
        (claimVersion) => {
          this.claimVersion = claimVersion;
          this.inProgress = false;
          this.claimVersion.reserveAmount = this.claimVersion.calculatedReserveAmount;
        },
        (error) => this.handleServerError(error)
      );
    }
  }

  onCreateNewVersion() {
    this.showErrors = false;
    this.claimService.getNewClaimVersion(this.claim.id).subscribe((claimVersion) => {
      this.setClaimVersion(claimVersion);
    });
  }

  onSelectClaimVersion(claimVersion: ClaimVersionDto) {
    this.router.toClaimDetails(claimVersion.id);
  }

  onCancel() {
    if (!this.claim.id) {
      this.location.back();
    } else {
      super.onCancel(this._route);
    }
  }

  onPrintSelected(template: GroupedItem): void {
    const businessObjectId = this.claimVersion.id;
    this.router.toDocumentDetailsNewOperations(0, template.id, businessObjectId, template.typeId);
  }

  onClaimRiskTypeChanged(claimRiskType: DictionaryBaseDto) {
    this.claimVersion = ClaimGuiService.emptyVersion();
    this.claimVersion.claim.riskType = claimRiskType;
    this.hideButtons(true);
  }

  showHideSpecialRiskAlert() {
    if (!!this.claim.risk && this.policyContractVersion?.risks.some((risk) => risk.company.id === this.claim.risk.id)) {
      this.addFrontendWarning('claim.details.riskAlert');
    }
  }

  loadClaimVersion(versionId: number) {
    this.claimService.getClaimVersion(versionId).subscribe(
      (claimVersion) => {
        this.setClaimVersion(claimVersion);
        this.loadRecovery(claimVersion.claim.id);
      },
      (error) => {
        this.objectNotFound = true;
      }
    );
  }

  loadRecovery(claimId: number) {
    if (!this.credendo) {
      return;
    }
    this.claimRecoveriesService.getFromRevision(claimId).subscribe((recovery) => {
      this.recovery = recovery;
    });
  }

  setClaimVersion(claimVersion: ClaimVersionDto) {
    this.claimVersion = claimVersion;
    this._currentClaimOfficer = this.claimVersion.claimOfficer;
    if (!!claimVersion.claim.policyContractVersion) {
      this.policyVersionService
        .getPolicyContractVersion(claimVersion.claim.policyContractVersion.id)
        .subscribe((pcv) => {
          this.policyContractVersion = pcv;
          this.setupPossibleBeneficiaries(pcv);
        });
    }
    if (this.claim.id) {
      this.loadVersions(this.claim.id);
    }
    this.objectNotFound = false;
    this.handleButtons();

    this.serverErrors = claimVersion.warnings;
    this.showErrors = true;

    this.showHideSpecialRiskAlert();
    if (this.creditLimitLinkVisible) {
      this.loadLimitRequest();
    }
    const bv = claimVersion.claim.bondVersion;
    if (!!bv) {
      this.getBondVersion(bv.id);
    }
  }

  private loadLimitRequest() {
    const riskCompany = this.claimVersion.claim?.thirdParty
      ? BusinessUtils.getThirdPartyCompany(this.claim?.thirdParty)?.company
      : this.claim?.risk;
    if (!riskCompany) {
      return;
    }
    const criteria = BusinessUtils.createLimitRequestSearchCriteria();
    criteria.criteria.buyerCompanyId = riskCompany.id;
    criteria.criteria.masterPolicyContractId = this.claimVersion.claim?.policyContract?.masterPolicyId;
    criteria.criteria.policyClientOrSubinsuredId = this.claimVersion.claim?.mainBeneficiary?.id;
    criteria.criteria.limit.category = <DictionaryBaseDto>{id: LimitCategory.POLICY};
    this.limitService.searchByCriteria(criteria).subscribe({
      next: (response) => (this.limitRequest = <LimitRequestSimpleDto>response.result[0]),
      error: (error) => this.handleServerError(error),
    });
  }

  private loadTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.claim.riskType);
    if (this.credendo && this.claim.policyContractVersion !== undefined) {
      dictionarySelectors.push(this.claim.policyContractVersion.contractType);
    }

    this.templatesGrouped = [];
    this.templateService
      .findByType(
        undefined,
        this.credendo ? BusinessObjectType.CLAIM_VERSION : BusinessObjectType.CLAIM,
        undefined,
        dictionarySelectors,
        undefined,
        undefined,
        this.claim.businessUnit.id,
        true
      )
      .subscribe((result) => {
        if (result !== undefined && result !== null) {
          result = result.filter((t) => t.type.id !== DocumentType.CLAIM_CONFIRMATION_REGISTRATION_BALANCE);
          result.forEach((r) => {
            const templateGrouped = this.templatesGrouped.find((e) => e.name === r.type.name);
            if (templateGrouped) {
              if (!templateGrouped.children) {
                templateGrouped.children = [];
              }
              templateGrouped.children.push(<GroupedItem>{name: r.name, id: r.id, typeId: r.type.id});
            } else {
              this.templatesGrouped.push(<GroupedItem>{
                name: r.type.name,
                typeId: r.type.id,
                children: [<GroupedItem>{name: r.name, id: r.id, typeId: r.type.id}],
              });
            }
          });
        }
      });

    this.templateService
      .findByType(
        DocumentType.CLAIM_CONFIRMATION_REGISTRATION_BALANCE,
        BusinessObjectType.CLAIM,
        null,
        dictionarySelectors
      )
      .subscribe((result) => {
        if (result && result.length > 0) {
          this.recoveryTemplate = result[0];
        }
      });
  }

  setupPossibleBeneficiaries(pcv: PolicyContractVersionDto) {
    if (pcv) {
      this.dictService
        .getDictionaryEntry('PolicyContractCompanyRole', PolicyContractCompanyRole.CLIENT)
        .subscribe((dict) => {
          const client = <PossibleBeneficiary>pcv.client;
          client.role = dict.name;
          client.iban = pcv.insuredIban;
          client.bic = pcv.insuredBic;

          this.possibleBeneficiaries = [
            client,
            ...pcv.subinsured
              .filter((s) => !s.silentInsurance)
              .map((c) => {
                const res = <PossibleBeneficiary>c.company;
                res.role = c.role.name;
                res.iban = c.insuredIban;
                res.bic = c.insuredBic;
                return res;
              }),
          ];
          const masterPolicyContractId = pcv.policyContract.masterPolicyContract.id;
          this.getCessionBeneficiaries(masterPolicyContractId).subscribe((cessionBeneficiaries) => {
            if (masterPolicyContractId === pcv.policyContract.masterPolicyContract.id) {
              this.possibleBeneficiaries = this.possibleBeneficiaries.concat(cessionBeneficiaries);
            }
          });
        });
    } else {
      this.possibleBeneficiaries = [];
    }
  }

  @Cacheable()
  private getCessionBeneficiaries(masterPolicyContractId: number): Observable<PossibleBeneficiary[]> {
    const dataProvider = BusinessUtils.createPolicyCessionDataProvider(this.cessionService);
    dataProvider.searchCriteria.criteria.cession.masterPolicyContract.id = masterPolicyContractId;
    const translateBeneficiary = (cv: CessionVersionDto): Observable<PossibleBeneficiary> =>
      this.translateService
        .get('claim.cessionBeneficiary')
        .pipe(map((t) => <PossibleBeneficiary>{...cv.cession.assignee, role: t, iban: cv.iban, bic: cv.bic}));
    return dataProvider.search(null, null).pipe(
      tap((list) =>
        console.log('search all master policy cession versions', {criteria: dataProvider.searchCriteria, result: list})
      ),
      flatMap((list) => list.result),
      flatMap((c) => translateBeneficiary(c)),
      toArray(),
      shareReplay()
    );
  }

  bankInfoReadOnly(): boolean {
    return this.isCreditInsuranceType() || this.claimVersion.bankAccountReadonly;
  }

  isDisabled(): boolean {
    return (
      this.claimVersion && this.claimVersion.status.id !== ClaimStatus.UNDER_ASSESSMENT && !this.hasFieldsUpdateRight
    );
  }

  onAddClaimInvoice(ci: ClaimInvoiceDto) {
    ci.currency = this.policyContractVersion?.currency;
  }

  isSuretyType(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.SURETY);
  }

  isCreditInsuranceType(): boolean {
    return this.isExpectedRiskType(ClaimRiskType.CREDIT_INSURANCE);
  }

  private isExpectedRiskType(...expectedRiskTypes: number[]): boolean {
    return this.claim.riskType && DictionaryUtils.in(this.claim.riskType, ...expectedRiskTypes);
  }

  get creditLimitLinkVisible() {
    return this.isSingleRiskOrTurnover();
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  invoiceIssueDateChanged(event: CellChangeEvent<ClaimInvoiceDto>) {
    if (!event.item.deliveryDispatchDate) {
      event.item.deliveryDispatchDate = event.value;
    }
    event.item.customExchangeRateDate = moment(event.value).subtract(1, 'd').toDate();
  }

  isInitialized() {
    return this.claimVersion.status;
  }

  get readonly(): boolean {
    return this.claimVersion.readonly;
  }

  get saveCancelButtonsHidden(): boolean {
    if (this.credendo) {
      return false;
    }
    return this.readonly;
  }

  get isNewVersion(): boolean {
    return !(this.claimVersion && this.claimVersion.id);
  }

  get isInitialVersionCreation(): boolean {
    return this.isNewVersion && this.isFirstClaimVersion;
  }

  get commentsReadonly(): boolean {
    return this.claimVersion.commentsReadonly;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get intranet(): boolean {
    return !this.loggedUserService.portal;
  }

  reloadBankAccount() {
    if (this.claimVersion.beneficiary) {
      this.onSelectBeneficiary(this.possibleBeneficiaries.find((b) => b.id === this.claimVersion.beneficiary.id));
    }
  }

  onSelectBeneficiary(beneficiary: PossibleBeneficiary) {
    if (this.claim.policyContractVersion.status.id !== PolicyContractVersionStatus.ACTIVATED_LOCKED) {
      this.growlService.error('claim.policyNotLocked');
    } else {
      this.claimVersion.iban = beneficiary.iban;
      this.claimVersion.bic = beneficiary.bic;
    }
  }

  toServiceContacts() {
    this.router.toServiceContacts({boTypeId: BusinessObjectType.CLAIM, boId: this.claimVersion.claim.id});
  }

  get hasFieldsUpdateRight(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.CLAIM_FIELDS_EDITION);
  }

  onAllowEdit() {
    this.allowEditSelectedFields = !this.allowEditSelectedFields;
  }

  onEditPolicy(c: PolicyContractVersionDto) {
    this.policyVersionService.getPolicyContractVersion(c.id).subscribe((pcv) => {
      const policyContract = <PolicyContractIdDto>{
        id: pcv.policyContract.id,
        policyYear: pcv.policyContract.policyYear,
        masterPolicyId: pcv.policyContract.masterPolicyId,
        number: pcv.policyContract.number,
        policyYearFormatted: pcv.policyContract.policyYearFormatted,
      };
      this.claimVersion.claim.policyContractVersion = pcv;
      this.claimVersion.claim.policyContract = policyContract;
      this.setupPossibleBeneficiaries(pcv);
    });
  }

  changeStatus(status: DictionaryBaseDto) {
    this.claimVersion.status = status;
  }

  private getBondVersion(versionId: number) {
    this.bondService.getBondVersion(versionId).subscribe((bond) => (this.bond = bond));
  }

  get isFirstClaimVersion(): boolean {
    return !this.claim.id || this.claimVersion.versionNumber === 1;
  }

  get areDataEditable(): boolean {
    return !this.readonly;
  }

  isInvoicesAddButtonVisible(): boolean {
    return !this.readonly;
  }

  isInvoicesDeleteButtonVisible(): boolean {
    return !this.readonly;
  }

  isLifecycleDropdownVisible(): boolean {
    return this.claimVersion.last;
  }

  openChangeClaimOfficer() {
    this.changeClaimOfficerDialog.openAndExecuteOnConfirm(() => this.saveChangeClaimOfficer());
  }

  saveChangeClaimOfficer() {
    this.claimVersion.claimOfficer = this.newClaimOfficer;
    this.saveClaimVersion();
  }

  get currentClaimOfficer(): UserIdDto {
    return this._currentClaimOfficer;
  }

  get newClaimOfficer(): UserDto {
    return this._newClaimOfficer;
  }

  set newClaimOfficer(user: UserDto) {
    this._newClaimOfficer = user;
  }

  get isGeneralInformationVisible(): boolean {
    return true;
  }

  isSingleRiskOrTurnover() {
    return this.isCreditInsuranceAndProductType(PolicyContractType.SINGLE_RISK, PolicyContractType.TURNOVER);
  }

  private isCreditInsuranceAndProductType(...expectedProductIds: number[]) {
    return (
      this.isCreditInsuranceType() &&
      this.claim.policyContractVersion.policyContract.masterPolicyContract &&
      expectedProductIds.includes(this.claim.policyContractVersion.policyContract.masterPolicyContract.productType.id)
    );
  }

  get minInvoiceNumber(): number {
    return this.isSingleRiskOrTurnover() ? 0 : 1;
  }

  get policyCoInsurers(): PolicyContractCoInsurerCompanyDto[] {
    return this.policyContractVersion?.coInsurers;
  }

  get policyCoInsurersVisible(): boolean {
    const coInsurers = this.policyCoInsurers;
    return coInsurers && coInsurers.length > 0;
  }

  get vatInsured(): boolean {
    return !!this.policyContractVersion?.vatInsured;
  }

  get isBondSubTypeEditable(): boolean {
    return this.isInitialVersionCreation;
  }
}

type PossibleBeneficiary = CompanySimpleDto & {role: string; iban: string; bic: string};
