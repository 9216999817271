import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {PolicyVersionSelectorSimpleComponent} from './policy-version-selector-simple.component';
import {PolicyVersionListComponent} from './policy-version-list.component';
import {InstalmentsWizardComponent} from './instalments-wizard.component';
import {PolicyContractVersionListComponent} from './policy-contract-version-list.component';
import {PolicyInquiryVersionListComponent} from './policy-inquiry-version-list.component';
import {CompanySharedModule} from '../../../company/components/shared/company-shared.module';
import {PolicyContractSectionSelectorComponent} from './policy-contract-section-selector.component';
import {PolicyContractSectionComponent} from './policy-contract-section.component';
import {MasterPolicyInfoComponent} from './master-policy-info.component';
import {PolicyAndInquiryVersionSelectorComponent} from './policy-and-inquiry-version-selector/policy-and-inquiry-version-selector.component';
import {PolicyLossRatioComponent} from './policy-loss-ratio.component';
import {BondSharedModule} from '../../../bond/components/shared/bond-shared.module';
import {PolicyMonitoringListComponent} from './policy-monitoring-list.component';
import {CommonCoInsurersSectionComponent} from './sections/common-co-insurers-section.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
    BondSharedModule,
  ],
  declarations: [
    PolicyVersionSelectorSimpleComponent,
    PolicyVersionListComponent,
    PolicyInquiryVersionListComponent,
    InstalmentsWizardComponent,
    PolicyContractVersionListComponent,
    PolicyContractSectionComponent,
    PolicyContractSectionSelectorComponent,
    MasterPolicyInfoComponent,
    PolicyAndInquiryVersionSelectorComponent,
    PolicyLossRatioComponent,
    PolicyMonitoringListComponent,
    CommonCoInsurersSectionComponent,
  ],
  exports: [
    PolicyVersionSelectorSimpleComponent,
    PolicyVersionListComponent,
    PolicyInquiryVersionListComponent,
    InstalmentsWizardComponent,
    PolicyContractVersionListComponent,
    PolicyContractSectionComponent,
    PolicyContractSectionSelectorComponent,
    MasterPolicyInfoComponent,
    PolicyAndInquiryVersionSelectorComponent,
    PolicyLossRatioComponent,
    PolicyMonitoringListComponent,
    CommonCoInsurersSectionComponent,
  ],
})
export class PolicyContractSharedModule {}
