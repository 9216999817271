import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ThirdPartyBaseDto} from '../../../bonding_shared/model';
import {LoggedUserService, RouterService} from '../../../bonding_shared/services';
import {Subject} from 'rxjs';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {StringUtils} from '../../../bonding_shared/utils';

@Component({
  selector: 'thirdparty-editable-list',
  templateUrl: './thirdparty-editable-list.component.pug',
})
export class ThirdPartyEditableListComponent {
  @Input() portalMode = false;

  @Input() items: ThirdPartyBaseDto[];
  @Input() editable = false;
  @Input() openEmitter: Subject<boolean>;
  @Output() itemsCountChange = new EventEmitter<number>();

  constructor(public loggedUserService: LoggedUserService, public router: RouterService) {}

  getThirdPartyCompany(tp: ThirdPartyBaseDto) {
    return BusinessUtils.getThirdPartyCompany(tp);
  }

  onCompanyNameClick(tp: ThirdPartyBaseDto) {
    const c = this.getThirdPartyCompany(tp);
    if (!c.raw) {
      this.router.toCompanyPreview(c.company.id);
    }
  }

  getThirdPartyAddress(tp: ThirdPartyBaseDto) {
    return StringUtils.prettyAddress(this.getThirdPartyCompany(tp).company.address, false);
  }
}
