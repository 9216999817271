import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {
  BondBaseDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  SearchResult,
} from '../../../../bonding_shared/model/dtos';
import {BondVersionService} from '../../../../bonding_shared/services/bond-version.service';
import {TextSearchCriteria} from '../../../../bonding_shared/components/search/model/text-search-criteria';
import {Subject} from 'rxjs';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {RouterService} from '../../../../bonding_shared/services';
import {AppConfigService} from '../../../../bonding_shared/services';

@Component({
  selector: 'bond-version-selector-simple',
  templateUrl: './bond-version-selector-simple.component.pug',
})
export class BondVersionSelectorSimpleComponent implements OnInit {
  @Input() title: string;
  @Input() labelKey: string;
  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  /**
   * to be able to switch between advanced/basic search mode
   * TODO: advanced search mode not implemented yet
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    return this._searchModeSwitchable;
  }
  /**
   * If data providers gave one row it is selected by default.
   */
  @Input() autoSelectOne = false;

  /**
   *
   */
  @Input() autoSelectId: number = null;

  @Input() searchOnOpen = true;

  @Input() showBondLink = false;

  /**
   * don't use together with textCriteria param
   */
  @Input() set onlyLastVersion(ol: boolean) {
    this.dataProvider.textSearchCriteria.last = ol;
    this.dataProvider.searchCriteria.criteria.last = ol;
  }

  /**
   * don't use together with textCriteria param
   */
  @Input() set onlyActiveVersion(ol: boolean) {
    this.dataProvider.textSearchCriteria.active = ol;
    this.dataProvider.searchCriteria.criteria.presentNow = ol;
  }

  @Input() set criteria(c: BondVersionCriteriaDto) {
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria = c;
  }

  /**
   * don't use together with onlyLastVersion or onlyActiveVersion param
   */
  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  /**
   *  To show only contract properties, not contract version properties
   */
  @Input() onlyContractFields = false;

  @Output() selectItem = new EventEmitter<BondVersionSimpleDto>();

  @Input() set remoteDataProvider(dp: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>) {
    this.dataProvider = dp;
  }

  pageSize = 10;

  dataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;

  constructor(private service: BondVersionService, public router: RouterService, private appService: AppConfigService) {
    this.dataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this.service);
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = new TextSearchCriteria();
    this.dataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.bond = <BondBaseDto>{};
  }

  ngOnInit() {
    if (this.autoSelectId) {
      this.pageSize = null; // if autoselect then no paging because it can break autoselect
    }
  }

  onChange(bond: BondVersionSimpleDto) {
    this.selectItem.emit(bond);
    this.open.next(false);
  }

  onSearchBondsFinished(searchResult: SearchResult<BondVersionSimpleDto>): void {
    const bonds = searchResult.result;
    if (bonds) {
      if (this.autoSelectId) {
        const autoSelectedBond = bonds.find((cv) => cv.id === this.autoSelectId);
        if (autoSelectedBond) {
          this.onChange(autoSelectedBond);
          return;
        }
      }
      if (this.autoSelectOne && searchResult.size === 1) {
        this.onChange(bonds[0]);
      }
    }
  }

  isRequest(bondVersion: BondVersionSimpleDto) {
    return BusinessUtils.isRequest(bondVersion.status.id);
  }
}
