import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {BackendError, DictionaryBaseDto, DictionaryDto} from '../../bonding_shared/model';
import {
  AppConfigService,
  CompanyService,
  GrowlService,
  ImageService,
  LoggedUserService,
  RepositoryService,
  RouterService,
} from '../../bonding_shared/services';
import {
  BusinessObjectType,
  COMPANY_TYPE_BROKERS,
  CompanyType,
  RepoDocumentElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {RepoDocumentListComponent} from './repo-document-list.component';

@Component({
  selector: 'repository-documents',
  templateUrl: './repository-documents.component.pug',
})
export class RepositoryDocumentsComponent extends DetailsView implements OnInit {
  readonly CompanyType = CompanyType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  viewParams: RepositoryDocumentsParams;
  documentType: DictionaryBaseDto;
  documentTypeName: string;
  selectedFiles: File[];
  files: any;
  logoLoaded = false;
  logoURL: string;
  isBroker = false;
  brokerType: number;
  uploadImage = false;
  serverErrors: BackendError;
  documentTypes: DictionaryDto[];

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  allowedLogoTypes = 'image/png';
  @ViewChild('documentList') repositoryDocumentTable: RepoDocumentListComponent;
  @ViewChild('documentListForVersion') repositoryDocumentForVersionTable: RepoDocumentListComponent;

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.queryParams.subscribe((params: RepositoryDocumentsParams) => this.initializeView(params));
  }

  constructor(
    public router: RouterService,
    private repositoryService: RepositoryService,
    protected growlService: GrowlService,
    private _route: ActivatedRoute,
    private imageService: ImageService,
    protected companyService: CompanyService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {
    super(growlService);
  }

  initializeView(params: RepositoryDocumentsParams) {
    this.viewParams = params;
    console.log(
      'BO TYPE ID = ' +
        this.viewParams.boTypeId +
        '     BO ID = ' +
        this.viewParams.boId +
        ' | RETURN BO TYPE ID = ' +
        this.viewParams.returnBoTypeId +
        '     RETURN BO ID = ' +
        this.viewParams.returnBoId +
        '  |  SHOW RETURN BO DOCUMENTS = ' +
        this.viewParams.showReturnBoDocuments
    );
    this.setupBroker(+this.viewParams.boTypeId);
    this.repositoryService.documentTypeName(+this.viewParams.boTypeId).subscribe((n) =>
      setTimeout(() => {
        this.documentTypeName = n;
      })
    );
  }

  showLogoSection() {
    return this.isBroker;
  }

  onLogoLoaded() {
    this.logoLoaded = true;
  }

  onLogoError() {
    this.uploadImage = true;
  }

  changeLogo(event: any) {
    event.currentTarget.nextElementSibling.click();
  }

  onLogoChange(event: any) {
    const logo = event.srcElement.files[0];
    if (logo !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(logo);
      reader.onload = (e: any) => {
        const logoBuffer: any = e.target.result;
        const blob = new Blob([logoBuffer], {type: 'application/octet-stream'});
        this.repositoryService.uploadLogo(+this.viewParams.boId, logo.type, logo.size, blob).subscribe(
          (repositoryDocument) => {
            this.logoURL = logoBuffer;
            this.logoLoaded = true;
            this.uploadImage = false;
            this.growlService.notice('Logo upload successful');
            // this.logoURL = 'data:image/gif;base64,' + ;
          },
          (error) => {
            this.showErrorMsg();
          }
        );
      };
    }
  }

  get titleKey() {
    return 'documents.title';
  }

  upload(index = 0) {
    if (
      this.selectedFiles &&
      this.selectedFiles.length > index &&
      this.noEmptyFiles(this.selectedFiles) &&
      this.allowedType(this.selectedFiles)
    ) {
      this.inProgress = true;
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFiles[index]);
      reader.onload = (e: any) => {
        this.send(e.target.result, index);
      };
    }
  }

  get uploadAllowed() {
    return (
      this.loggedUserService.hasRight(RepoDocumentElementaryRight.REPO_DOCUMENT_WRITE_ALL) ||
      (this.viewParams.rightToWrite && this.loggedUserService.hasRight(this.viewParams.rightToWrite))
    );
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  send(buffer: any, index: number) {
    const blob = new Blob([buffer], {type: 'application/octet-stream'});
    if (this.ngForm.form.invalid) {
      this.showErrors = true;
      this.inProgress = false;
      return;
    }
    this.showErrors = false;
    this.serverErrors = undefined;
    this.repositoryService
      .upload(
        +this.viewParams.boId,
        this.selectedFiles[index].name,
        this.selectedFiles[index].type,
        this.selectedFiles[index].size,
        +this.viewParams.boTypeId,
        this.documentType.id,
        blob,
        this.viewParams.categoryId ? +this.viewParams.categoryId : undefined
      )
      .subscribe({
        next: (repositoryDocument) => {
          if (index === this.selectedFiles.length - 1) {
            this.ngForm.form.reset();
            this.showUploadedMsg();
            this.repositoryDocumentTable.refreshDocumentList();
          } else {
            this.upload(index + 1);
          }
        },
        error: (error) => {
          this.selectedFiles = undefined;
          this.serverErrors = error;
          this.ngForm.form.reset();
          this.showErrorMsg();
          this.repositoryDocumentTable.refreshDocumentList();
        },
      });
  }

  get returnBoId(): number {
    return (this.viewParams.returnBoId && +this.viewParams.returnBoId) || +this.viewParams.boId;
  }

  get returnBoTypeId(): number {
    return (this.viewParams.returnBoTypeId && +this.viewParams.returnBoTypeId) || +this.viewParams.boTypeId;
  }

  clear() {
    this.selectedFiles = undefined;
    this.ngForm.form.reset();
    this.inProgress = false;
  }

  showUploadedMsg() {
    this.growlService.notice('common.fileUploadedMessage');
    this.inProgress = false;
  }

  showErrorMsg() {
    this.growlService.error('common.fileUploadErrorMessage');
    this.inProgress = false;
  }

  private setupBroker(businessObjectTypeId: number) {
    if (businessObjectTypeId === BusinessObjectType.COMPANY) {
      this.companyService.getCompanySimple(+this.viewParams.boId).subscribe((company) => {
        if (
          company.companyType &&
          company.companyType.id &&
          COMPANY_TYPE_BROKERS.indexOf(company.companyType.id) >= 0
        ) {
          this.isBroker = true;
          this.brokerType = company.companyType.id;
          this.logoURL = this.imageService.getCompanyLogoUrl(+this.viewParams.boId);
        }
      });
    }
  }

  get hiddenTypeIds() {
    return RepoDocumentListComponent.hiddenDocumentTypeIds;
  }

  noEmptyFiles(selectedFiles: File[]): boolean {
    let result = true;
    selectedFiles.forEach((file) => {
      // empty *.txt equal 0, empty *.docx, Excel etc. are not equal 0
      if (file.size === 0) {
        const msgParams = {};
        msgParams['fileName'] = file.name;
        this.growlService.error('common.fileSizeIsZero', null, msgParams);
        result = false;
      }
    });
    return result;
  }

  allowedType(selectedFiles: File[]): boolean {
    // file type for msg = ""
    // file type for eml = "message/rfc822"
    // for the above reason, I check if the type is contained in the name
    if (
      !this.viewParams.allowedFileTypes ||
      selectedFiles.some((file) =>
        this.viewParams.allowedFileTypes.split(',').includes(file.name.substring(file.name.lastIndexOf('.')))
      )
    ) {
      return true;
    } else {
      this.growlService.error('Allowed file types: ' + this.viewParams.allowedFileTypes);
      return false;
    }
  }
}

export class RepositoryDocumentsParams {
  // string when reading
  boTypeId: string | number; // businessObjectTypeId
  boId: string | number; // businessObjectId
  categoryId?: string | number; // RepoDocumentCategory dictionary
  returnBoId?: string | number; // returnBusinessObjectId
  returnBoTypeId?: string | number; // returnBusinessObjectTypeId
  showReturnBoDocuments?: boolean | string; // showReturnBusinessObjectDocuments
  rightToRead: RepoDocumentElementaryRight;
  rightToWrite: RepoDocumentElementaryRight;
  allowedFileTypes: string;
}
