import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BondValueDto, BondVersionDto, ClaimDocumentDto} from '../../../bonding_shared/model/dtos';
import {AppProperty, ClaimDocumentStatus} from '../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  BusinessPropertiesService,
  CurrencyExchangeRateService,
  PropertyService,
} from '../../../bonding_shared/services';
import {ATableComponent} from '../../../bonding_shared/components/aku-table';
import {BackendError} from '../../../bonding_shared';

@Component({
  selector: 'bond-value-list',
  templateUrl: 'bond-value-list.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondValueListComponent {
  @ViewChild(ATableComponent) bondValuesTable: ATableComponent<BondValueDto>;

  @Input() showErrors = false;
  @Input() values: BondValueDto[];
  @Input() title;
  @Input() bondCurrency;
  @Input() showSystemCurrency = true;
  @Input() showReason = false;
  @Input() showValidTo = false;
  @Input() firstValueDeletable = false;
  @Input() listName;
  @Input() editable = true;
  @Input() bondVersion: BondVersionDto;
  @Output() validityPeriodChanged = new EventEmitter<boolean>();

  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];
  self = this;

  constructor(
    public form: NgForm,
    public businessProperties: BusinessPropertiesService,
    private propertyService: PropertyService,
    private currencyExchangeRateService: CurrencyExchangeRateService,
    public appService: AppConfigService
  ) {}

  isValueDeletable(v: BondValueDto): boolean {
    // the first value is not deletable
    return this.firstValueDeletable || this.values[0] !== v;
  }

  isLastValue(v: BondValueDto): boolean {
    return this.values[this.values.length - 1] === v;
  }

  valueChange(v: BondValueDto) {
    this.updateMultiValueInSysCurr(v);
  }

  validToValidator(v: BondValueDto): ValidatorFn {
    return (control) => {
      return null;
    };
  }

  updateMultiValueInSysCurr(v: BondValueDto) {
    v.valueInSysCurr =
      this.bondCurrency.code === this.SYSTEM_CURRENCY_CODE
        ? v.value
        : this.currencyExchangeRateService.calculate(v.value, this.bondCurrency.id);
    const idx = this.bondValuesTable.getItemIndex(v);
    const controlIdx = this.showReason ? 3 : 2;
    (<UntypedFormGroup>(<UntypedFormGroup>this.form.control.controls[this.listName]).controls[idx]).controls[
      controlIdx
    ].setValue(v.valueInSysCurr);
  }

  updateAllValuesInSysCurr() {
    this.values.forEach((v) => this.updateMultiValueInSysCurr(v));
  }
}
