import {Component, OnInit} from '@angular/core';
import {
  BusinessUnitDto,
  LocalDateRange,
  PolicyContractDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  SalesRegionDto,
} from '../../bonding_shared/model';
import {
  AppConfigService,
  BusinessUnitService,
  DictionaryService,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared/services';
import {PolicyContractGuiService} from './services/policy-contract-gui.service';
import {ComboItem} from '../../bonding_shared/components/combos';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {SalesRegionService} from '../../bonding_shared/services/sales-region.service';
import {TranslateService} from '@ngx-translate/core';
import {Feature} from '../../bonding_shared/model/dictionary-ids';
import {UserResponsibleService} from '../../bonding_shared/services/user-responsible.service';

@Component({
  selector: 'policy-contract-version-search',
  templateUrl: './policy-contract-version-search.component.pug',
})
export class PolicyContractVersionSearchComponent extends SearchView implements OnInit {
  dataProvider: SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionDto>;
  businessUnits: BusinessUnitDto[];
  salesRegions: SalesRegionDto[];
  versionPhases: ComboItem[] = [new ComboItem('ACTIVE', 'Current'), new ComboItem('LAST', 'Last')];
  policyYearVersionPhases: ComboItem[] = [/*new ComboItem('ACTIVE', 'Current'), */ new ComboItem('LAST', 'Last')];
  readonly Feature = Feature;

  constructor(
    public routerService: RouterService,
    private businessUnitService: BusinessUnitService,
    private loggedUserService: LoggedUserService,
    private service: PolicyContractGuiService,
    private salesRegionService: SalesRegionService,
    private dictService: DictionaryService,
    public appService: AppConfigService,
    private translateService: TranslateService,
    private userResponsibleService: UserResponsibleService
  ) {
    super();
    this.dataProvider = service.searchDataProvider;
    this.selectorNameList = ['Broker'];
    this.versionPhases = [
      new ComboItem('ACTIVE', this.translateService.instant('Current')),
      new ComboItem('LAST', this.translateService.instant('Last')),
    ];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    this.businessUnitService.getBusinessUnits().subscribe((data) => {
      this.businessUnits = data;
    });
    this.salesRegionService.getAll().subscribe((data) => {
      this.salesRegions = data;
    });
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  // TODO: shouldn't it be somehere outside this component, PolicyContractGuiService?
  // it might be useful for others, but it also might be not, so if not please delete this comment. ;-)
  initializeCriteria() {
    this.dataProvider.searchCriteria.criteria = <PolicyContractVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.policyContract = <PolicyContractDto>{};
    this.dataProvider.searchCriteria.criteria.issueDate = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.versionValidFrom = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.versionValidTo = <LocalDateRange>{};
    this.dataProvider.searchCriteria.criteria.policyStart = <LocalDateRange>{};
    this.dataProvider.textSearch = false;
    this.dataProvider.searchCriteria.criteria.versionPhase = 'LAST';
    this.dataProvider.searchCriteria.criteria.policyYearVersionPhase = 'LAST';
    if (this.appService.credendo) {
      this.dataProvider.searchCriteria.criteria.businessUnitId =
        this.loggedUserService.getLoggedUserData().businessUnit.id;
    }
    if (this.portal) {
      this.dataProvider.searchCriteria.criteria.findOneVersionPerPolicy = true;
    }

    this.dataProvider.searchCriteria.criteria.userResponsible =
      this.userResponsibleService.getUserResponsibleSearchCriteria();
  }

  onBuChange(buId: number) {
    if (buId && this.dataProvider.searchCriteria.criteria.contractType) {
      this.dictService.getDictionaryFiltered('PolicyContractType', buId, undefined).subscribe((entries) => {
        if (
          entries.filter((dict) => dict.id === this.dataProvider.searchCriteria.criteria.contractType.id).length === 0
        ) {
          this.dataProvider.searchCriteria.criteria.contractType = undefined;
        }
      });
    }
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
