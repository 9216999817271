import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {LoggedUserService} from './logged-user.service';
import {AppConfigService} from './app-config.service';
import {forkJoin, Observable} from 'rxjs';
import {
  BusinessUnitIdDto,
  CompanyIdDto,
  ContractIdDto,
  DictionaryBaseDto,
  PolicyContractVersionDto,
  SearchCriteria,
  SortBy,
  TemplateCriteriaDto,
  TemplateDto,
  TemplateSimpleDto,
} from '../model/dtos';
import {
  BusinessObjectType,
  DocumentType,
  GeneratedDocumentType,
  POLICY_CONTRACT_TERMINATION_STATUSES,
} from '../model/dictionary-ids';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TemplateService extends AbstractService {
  protected url = this.urlPrefix + 'template';

  dictionarySelectors: DictionaryBaseDto[] = [];

  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  getTemplate(id: number): Observable<TemplateDto> {
    const url = this.url + '/';
    return this.get<TemplateDto>(url + id);
  }

  isEditionAllowed(id: number): Observable<boolean> {
    const url = this.url + '/editionAllowed/';
    return this.get<boolean>(url + id);
  }

  findByType(
    documentType?: DocumentType | GeneratedDocumentType,
    relatedToType?: BusinessObjectType,
    languageId?: number,
    dictionarySelectors?: DictionaryBaseDto[],
    clientId?: number,
    contractId?: number,
    businessUnitId?: number,
    includeNullBusinessUnit?: boolean,
    allSelectorsMandatory?: boolean,
    countryId?: number
  ): Observable<TemplateSimpleDto[]> {
    const criteria = <TemplateCriteriaDto>{};

    if (documentType) {
      criteria.type = <DictionaryBaseDto>{};
      criteria.type.id = documentType;
    }
    if (relatedToType) {
      criteria.relatedTo = <DictionaryBaseDto>{};
      criteria.relatedTo.id = relatedToType;
    }
    if (languageId) {
      criteria.language = <DictionaryBaseDto>{id: languageId};
    }
    if (countryId) {
      criteria.country = <DictionaryBaseDto>{id: countryId};
    }
    if (businessUnitId) {
      criteria.businessUnit = <BusinessUnitIdDto>{};
      criteria.businessUnit.id = businessUnitId;
    }
    // do not change to "if (clientId)", backend search logic is different for empty object and empty id
    if (clientId !== undefined) {
      criteria.client = <CompanyIdDto>{};
      criteria.client.id = clientId;
    }
    if (contractId) {
      criteria.contract = <ContractIdDto>{};
      criteria.contract.id = contractId;
    }
    if (dictionarySelectors != null) {
      criteria.dictionarySelectors = dictionarySelectors;
    }
    criteria.allSelectorsMandatory = allSelectorsMandatory !== false; /*only false when explicitly false*/
    criteria.ignoreNotProvidedSelectors = true;
    criteria.includeNullBusinessUnit = includeNullBusinessUnit;

    const searchCriteria = <SearchCriteria<TemplateCriteriaDto>>{};
    searchCriteria.criteria = criteria;
    searchCriteria.sortBy = <SortBy>{};
    searchCriteria.sortBy.column = 'name';

    return this.searchByCriteria<TemplateCriteriaDto, TemplateSimpleDto>(searchCriteria).pipe(
      map((searchResult) => searchResult.result)
    );
  }

  getClientBondOwnTemplates(
    dictionarySelectors: DictionaryBaseDto[],
    clientId: number,
    contractId: number
  ): Observable<TemplateSimpleDto[]> {
    return this.findByType(
      DocumentType.BOND,
      BusinessObjectType.BOND_VERSION,
      undefined,
      dictionarySelectors,
      clientId,
      contractId
    );
  }

  getWordingTemplates(pcv: PolicyContractVersionDto): Observable<TemplateSimpleDto[][]> {
    this.dictionarySelectors = [];
    if (pcv.policyContract.masterPolicyContract.productType) {
      this.dictionarySelectors.push(pcv.policyContract.masterPolicyContract.productType);
    }
    if (pcv.globalConditionsOfInsurance) {
      this.dictionarySelectors.push(pcv.globalConditionsOfInsurance);
    }
    const templateDataObservables: Observable<TemplateSimpleDto[]>[] = [];
    if (POLICY_CONTRACT_TERMINATION_STATUSES.includes(pcv.status.id)) {
      templateDataObservables.push(this.getWordingTemplateObs(DocumentType.POLICY_TERMINATION, false));
    } else {
      templateDataObservables.push(this.getWordingTemplateObs(DocumentType.POLICY));
      templateDataObservables.push(this.getWordingTemplateObs(DocumentType.POLICY_COVER_LETTER, false));
      templateDataObservables.push(this.getWordingTemplateObs(DocumentType.POLICY_DECISION_SHEET, false));
    }
    return forkJoin(templateDataObservables);
  }

  private getWordingTemplateObs(typeId: number, allSelectorsMandatory?: boolean): Observable<TemplateSimpleDto[]> {
    return this.findByType(
      typeId,
      BusinessObjectType.POLICY_VERSION,
      null,
      this.dictionarySelectors,
      null,
      null,
      null,
      null,
      allSelectorsMandatory
    );
  }
}
