import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ClaimDocumentsDto} from '../model';
import {AbstractAuditedRelatedService} from './abstract-audited-related.service';

@Injectable()
export class ClaimDocumentsService extends AbstractAuditedRelatedService<ClaimDocumentsDto> {
  protected url;
  protected midUrl = 'documents';

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + 'claim';
  }
}
