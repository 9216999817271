import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  QueryList,
  SkipSelf,
  ViewChildren,
} from '@angular/core';
import {
  CompanyIdDto,
  CompanySimpleDto,
  CustomFieldValueDto,
  DictionaryBaseDto,
  DictionaryDto,
} from '../../bonding_shared/model/index';
import {CustomFieldValueType, DictionaryPropertyType} from '../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  CompanyService,
  DictionaryService,
  FormatService,
  LoggedUserService,
} from '../../bonding_shared/services/index';
import {TranslateService} from '@ngx-translate/core';
import {ListEmitters} from '../../bonding_shared/components/details-view/list-emitters';
import {AbstractControl, ControlContainer, NgModelGroup} from '@angular/forms';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {IMultiSelectSettings} from '../../bonding_shared/components/multiselect-dropdown';
import {StringUtils} from '../../bonding_shared/utils';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {ComboItem} from '../../bonding_shared';
import {AdDirective} from './ad.directive';
import {CredendoLolCustomFieldComponent} from './credendo-lol-custom-field.component';

@Component({
  selector: 'custom-form-simple',
  templateUrl: './custom-form-simple.component.pug',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class CustomFormSimpleComponent extends ListEmitters implements OnInit, AfterViewInit {
  @Input() fields: DictionaryDto[];
  @Input() showErrors = false;
  @Input() sectionHeader: string;
  @Input() formColumnsCount: 1 | 2 | 3 = 2;
  @Input() disabled = false;
  @Input() businessObjectTypeId: number;

  @ViewChildren(AdDirective) adHosts: QueryList<AdDirective>;

  _values: CustomFieldValueDto[];
  columns: CustomFieldValueDto[][] = [];
  fieldAttributes: {[fieldId: number]: FieldMetadata} = {};
  CustomFieldValueType = CustomFieldValueType;
  currencyProfileId: number;

  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    selectionLimit: 1000,
    numSelectedOff: true,
    showToggleCheckAll: true,
  };

  getComboValues(code: string): ComboItem[] {
    switch (code) {
      case 'LDC_SELF_RETENTION':
        return [
          new ComboItem('5', '5'),
          new ComboItem('10', '10'),
          new ComboItem('15', '15'),
          new ComboItem('20', '20'),
          new ComboItem('25', '25'),
        ];
      case 'LDC_PERCENTAGE_OF_PAYMENT_ADVANCED':
        return [new ComboItem('10', '10'), new ComboItem('20', '20'), new ComboItem('30', '30')];
      case 'LDC_PERCENTAGE_OF_PAYMENT_SCHEDULED':
        return [
          new ComboItem('20', '20'),
          new ComboItem('25', '25'),
          new ComboItem('30', '30'),
          new ComboItem('40', '40'),
        ];
      case 'LDC_MONTHS':
        return [new ComboItem('3', '3'), new ComboItem('6', '6'), new ComboItem('12', '12')];
    }
  }

  @Input() set values(values: CustomFieldValueDto[]) {
    this._values = values;
    this.setFormLayout();
  }

  constructor(
    protected translateService: TranslateService,
    private dictionaryService: DictionaryService,
    private companyService: CompanyService,
    private parentModel: NgModelGroup,
    private formatService: FormatService,
    private appService: AppConfigService,
    private resolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  ngOnInit(): void {
    console.log('fields', this.fields);
    if (this.fields) {
      this.fields.forEach((fd) => this.initSimpleField(fd));
      this.additionalInitializations();
    }
  }

  ngAfterViewInit() {
    if (this.fields) {
      this.loadComponent();
    }
  }

  additionalInitializations() {
    this.initializeSelectorEmitters(true);
    this.setFormLayout();
    this.setCurrencyProfileId();
  }

  initSimpleField(field: DictionaryDto) {
    this.fieldAttributes[field.id] = new FieldMetadata();

    // This is generic component, we don't put here logic specific for the related business object !!

    // Field properties that are conditional (for example the same field is optional in inquiry and required in contract)
    // are recalculated and overridden on backend in methods:
    // PolicyClauseManager.recalculateProperties, BondingCollateralManager.recalculateProperties

    this.fieldAttributes[field.id].required =
      field.properties[DictionaryPropertyType.CUSTOM_FIELD__OPTIONAL] !== 'true';

    this.fieldAttributes[field.id].hidden =
      field.properties[DictionaryPropertyType.CUSTOM_FIELD___HIDDEN] === 'true' ||
      (this.portal && field.properties[DictionaryPropertyType.CUSTOM_FIELD_HIDDEN_IN_PORTAL] === 'true');
    if (this.fieldAttributes[field.id].hidden) {
      return;
    }
    this.fieldAttributes[field.id].dictName = field.properties[DictionaryPropertyType.CUSTOM_FIELD___DICT_NAME];
    this.fieldAttributes[field.id].defaultValue = field.properties[DictionaryPropertyType.CUSTOM_FIELD___DEFAULT_VALUE];
    this.fieldAttributes[field.id].disabled =
      !!field.properties[DictionaryPropertyType.CUSTOM_FIELD___VIRTUAL_DEFINITION] ||
      (field.properties[DictionaryPropertyType.CUSTOM_FIELD___DISABLED] &&
        field.properties[DictionaryPropertyType.CUSTOM_FIELD___DISABLED].toLowerCase() === 'true');
    this.fieldAttributes[field.id].min = +field.properties[DictionaryPropertyType.CUSTOM_FIELD___MIN_VALUE];
    this.fieldAttributes[field.id].max = +field.properties[DictionaryPropertyType.CUSTOM_FIELD___MAX_VALUE];
    const defaultValue = this.fieldAttributes[field.id].defaultValue;
    const existingValues = this._values.filter((v) => v.field.id === field.id);
    if (existingValues.length === 0) {
      this._values.push(<CustomFieldValueDto>{field: DictionaryUtils.toBasDto(field), value: defaultValue});
    }
    const value = this._values.filter((v) => v.field.id === field.id)[0];
    this.additionalInitializationsForValue(value);
  }

  setFormLayout() {
    const fValues = this._values.filter(
      (v) => this.fieldAttributes[v.field.id] && !this.fieldAttributes[v.field.id].hidden
    );
    const rest = fValues.length % this.formColumnsCount;
    const roundUp = rest === 0 ? 0 : 1;
    const median = (fValues.length - rest) / this.formColumnsCount + roundUp;
    if (this.formColumnsCount === 1) {
      this.columns[0] = fValues;
    } else if (this.formColumnsCount === 2) {
      this.columns[0] = fValues.slice(0, median);
      this.columns[1] = fValues.slice(median);
    } else if (this.formColumnsCount === 3) {
      this.columns[0] = fValues.slice(0, median);
      this.columns[1] = fValues.slice(median, 2 * median);
      this.columns[2] = fValues.slice(2 * median);
    }
  }

  additionalInitializationsForValue(v: CustomFieldValueDto) {
    switch (v.field.parentId) {
      case CustomFieldValueType.DICTIONARY:
        this.initDictionary(v);
        break;
      case CustomFieldValueType.COMBO:
        this.initCombo(v);
        break;
      case CustomFieldValueType.BOOLEAN:
        this.initBoolean(v);
        break;
      case CustomFieldValueType.COMPANY:
        this.initCompany(v);
        break;
      case CustomFieldValueType.MULTISELECT:
        this.initMultiselect(v);
        break;
      case CustomFieldValueType.DATE:
        this.fieldAttributes[v.field.id].dateValue = v.value ? DateUtils.toDate(v.value) : undefined;
        break;
    }
  }

  initCompany(v: CustomFieldValueDto) {
    const selectorName = 'company' + v.field.id;
    this.selectorNameList.push(selectorName);
    this.fieldAttributes[v.field.id].selectorName = selectorName;
    console.log('initCompany', v);
    if (v.value) {
      this.companyService.getCompanyIdDto(+v.value).subscribe((c) => {
        this.fieldAttributes[v.field.id].company = c;
        console.log('initCompany2', c);
      });
    }
  }

  initDictionary(v: CustomFieldValueDto) {
    if (v.value) {
      this.fieldAttributes[v.field.id].dictionary = <DictionaryBaseDto>{id: +v.value};
    }
  }

  initCombo(v: CustomFieldValueDto) {
    this.fieldAttributes[v.field.id].comboValues = this.getComboValues(v.field.code);
  }

  initBoolean(v: CustomFieldValueDto) {
    if (!v.value) {
      v.value = 'false';
    }
  }

  initMultiselect(v: CustomFieldValueDto) {
    if (v.value) {
      const items = JSON.parse(v.value) as DictionaryBaseDto[];
      this.fieldAttributes[v.field.id].multiselect = items;
      this.fieldAttributes[v.field.id].multiselectPresentation = items.map((d) => d.name).join(', ');
    }
  }

  changeDate(date: Date, v: CustomFieldValueDto) {
    v.value = this.formatService.formatDate(date);
  }

  setDictionary(v: CustomFieldValueDto, dict: DictionaryBaseDto) {
    v.value = dict ? dict.id + '' : undefined;
  }

  changeSelection(v: CustomFieldValueDto) {
    v.value = JSON.stringify(this.fieldAttributes[v.field.id].multiselect);
  }

  setCompany(v: CustomFieldValueDto, company: CompanySimpleDto) {
    this.fieldAttributes[v.field.id].company = company;
    v.value = company ? company.id + '' : undefined;
    console.log('setCompany', company);
  }

  booleanValue(v: string | boolean): boolean {
    return v !== 'false' && !!v;
  }

  shortCode(code: string): string {
    return StringUtils.shortCode(code);
  }

  checkCVaFA(v: CustomFieldValueDto): boolean {
    return v.field.parentId === CustomFieldValueType.DICTIONARY && this.fieldAttributes[v.field.id].dictName !== null;
  }

  getDictionaryLabel(v: CustomFieldValueDto) {
    return this.fieldAttributes[v.field.id].dictName === 'Currency' ? 'code' : 'name';
  }

  loadComponent() {
    let changes = false;
    for (const adHost of this.adHosts) {
      if (adHost.adHost.field.parentId === CustomFieldValueType.CREDENDOLOL) {
        const componentRef = this.createComponent(adHost);
        componentRef.instance.fieldValue = adHost.adHost;
        componentRef.instance.parent = this;
        changes = true;
      }
    }
    if (changes) {
      this.cd.detectChanges();
    }
  }

  private createComponent(adHost: AdDirective) {
    const viewContainerRef = adHost.viewContainerRef;
    viewContainerRef.clear();

    const factory: ComponentFactory<CredendoLolCustomFieldComponent> = this.resolver.resolveComponentFactory(
      CredendoLolCustomFieldComponent
    );
    const componentRef: ComponentRef<any> = viewContainerRef.createComponent(factory);
    return componentRef;
  }

  private getProfileId(v: CustomFieldValueDto) {
    if (this.fieldAttributes[v.field.id].dictName === 'Currency') {
      return this.currencyProfileId;
    } else {
      return null;
    }
  }

  private setCurrencyProfileId() {
    this.currencyProfileId = null;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}

export class FieldMetadata {
  dictName: string;
  defaultValue: string;
  min: number;
  max: number;
  disabled: boolean;
  hidden: boolean;
  required: boolean;
  company: CompanyIdDto;
  dictionary: DictionaryBaseDto;
  selectorName: string;
  control: AbstractControl;
  multiselect: any[];
  multiselectPresentation: string;
  comboValues: ComboItem[];
  dateValue: Date;
}
