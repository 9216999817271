import {Component, Input, OnInit, Optional} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {ContractVersionService} from '../../bonding_shared/services/contract-version.service';
import {BusinessObjectType} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {
  BondingContractInquiryVersionDto,
  BondVersionDto,
  BrokerContractRelationVersionDto,
  BrokerContractVersionDto,
  BusinessObjectDto,
  ClaimVersionDto,
  ContractVersionDto,
  InvoiceDto,
  LimitDecisionDto,
  PolicyContractDto,
  PolicyContractVersionDto,
  PolicyInquiryBaseDto,
  PolicyInquiryVersionDto,
  ProductLimitListVersionDto,
} from '../../bonding_shared/model';
import {BrokerContractVersionService} from '../../bonding_shared/services/broker-contract-version.service';
import {PolicyContractVersionService} from '../../bonding_shared/services/policy-contract-version.service';
import {InquiryService} from '../../bonding_shared/services/inquiry.service';
import {
  AppConfigService,
  BondVersionService,
  BrokerContractRelationService,
  ClaimService,
  CompanyRatingService,
  PolicyContractService,
  PolicyLimitListService,
} from '../../bonding_shared/services';
import {BondGuiService} from '../bond/services';
import {LimitService} from '../../bonding_shared/services/limit.service';
import {LimitDecisionService} from '../../bonding_shared/services/limit-decision.service';
import {InvoiceService} from '../../bonding_shared/services/invoice.service';
import {BusinessObjectService} from '../../bonding_shared/services/business-object.service';
import {BondingContractInquiryService} from '../../bonding_shared/services/bonding-contract-inquiry.service';

@Component({
  selector: 'business-object-info',
  templateUrl: './business-object-info.component.pug',
})
export class BusinessObjectInfoComponent implements OnInit {
  @Input() preview = true;

  _typeId: number;
  _objectId: number;

  @Input() set typeId(typeId) {
    this._typeId = typeId;
  }

  @Input() set objectId(objectId) {
    this._objectId = objectId;
  }

  get typeId() {
    return this._typeId;
  }

  get objectId() {
    return this._objectId;
  }

  contractVersion: ContractVersionDto;
  brokerContractVersion: BrokerContractVersionDto;
  brokerContractRelationVersion: BrokerContractRelationVersionDto;
  policyContractVersion: PolicyContractVersionDto;
  policy: PolicyContractDto;
  bondingContractInquiryVersion: BondingContractInquiryVersionDto;
  inquiry: PolicyInquiryVersionDto;
  inquiryParent: PolicyInquiryBaseDto;
  bondVersion: BondVersionDto;
  limitDecision: LimitDecisionDto;
  invoice: InvoiceDto;
  claimVersion: ClaimVersionDto;
  limitListVersion: ProductLimitListVersionDto;
  businessObject: BusinessObjectDto;

  readonly BusinessObjectType = BusinessObjectType;

  constructor(
    public router: RouterService,
    private appService: AppConfigService,
    @Optional() private contractVersionService: ContractVersionService,
    @Optional() private policyContractVersionService: PolicyContractVersionService,
    private policyContractService: PolicyContractService,
    private inquiryService: InquiryService,
    @Optional() private bondingInquiryService: BondingContractInquiryService,
    @Optional() private brokerContractService: BrokerContractVersionService,
    @Optional() private brokerContractRelationService: BrokerContractRelationService,
    @Optional() private bondVersionService: BondVersionService,
    @Optional() private limitService: LimitService,
    @Optional() private limitDecisionService: LimitDecisionService,
    @Optional() private bondingContractInquiryService: BondingContractInquiryService,
    @Optional() private invoiceService: InvoiceService,
    @Optional() private bondGuiService: BondGuiService,
    @Optional() private claimService: ClaimService,
    @Optional() private businessObjectService: BusinessObjectService,
    @Optional() private policyLimitListService: PolicyLimitListService,
    @Optional() private companyRatingService: CompanyRatingService
  ) {}

  ngOnInit(): void {
    if (this.typeId === BusinessObjectType.CONTRACT_VERSION) {
      this.contractVersionService.getContractVersion(this.objectId).subscribe((ver) => (this.contractVersion = ver));
    } else if (this.typeId === BusinessObjectType.BROKER_CONTRACT_VERSION) {
      this.brokerContractService
        .getById<BrokerContractVersionDto>(this.objectId)
        .subscribe((ver) => (this.brokerContractVersion = ver));
    } else if (this.typeId === BusinessObjectType.BROKER_CONTRACT_RELATION) {
      this.brokerContractRelationService
        .getLastRelationVersion(this.objectId)
        .subscribe((ver) => (this.brokerContractRelationVersion = ver));
    } else if (this.typeId === BusinessObjectType.POLICY || this.typeId === BusinessObjectType.POLICY_VERSION) {
      this.policyContractVersionService
        .getPolicyContractVersion(this.objectId)
        .subscribe((ver) => (this.policyContractVersion = ver));
    } else if (this.typeId === BusinessObjectType.POLICY_INQUIRY_VERSION) {
      this.inquiryService.getById<PolicyInquiryVersionDto>(this.objectId).subscribe((ver) => (this.inquiry = ver));
    } else if (this.typeId === BusinessObjectType.POLICY_INQUIRY) {
      this.inquiryService.getParentById(this.objectId).subscribe((ver) => (this.inquiryParent = ver));
    } else if (this.typeId === BusinessObjectType.BOND_VERSION) {
      this.bondVersionService.getBondVersion(this.objectId).subscribe((ver) => (this.bondVersion = ver));
    } else if (this.typeId === BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION) {
      this.bondingInquiryService
        .getById<BondingContractInquiryVersionDto>(this.objectId)
        .subscribe((ver) => (this.bondingContractInquiryVersion = ver));
    } else if (this.typeId === BusinessObjectType.INVOICE) {
      this.invoiceService.getById<InvoiceDto>(this.objectId).subscribe((ver) => (this.invoice = ver));
    } else if (this.typeId === BusinessObjectType.LIMIT_DECISION) {
      this.limitDecisionService.getById<LimitDecisionDto>(this.objectId).subscribe((ver) => (this.limitDecision = ver));
    } else if (this.typeId === BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION) {
      this.bondingContractInquiryService
        .getById<BondingContractInquiryVersionDto>(this.objectId)
        .subscribe((ver) => (this.bondingContractInquiryVersion = ver));
    } else if (this.typeId === BusinessObjectType.CLAIM_VERSION) {
      this.claimService.getClaimVersion(this.objectId).subscribe((ver) => (this.claimVersion = ver));
    } else if (this.typeId === BusinessObjectType.LIMIT_LIST_VERSION) {
      this.policyLimitListService
        .getById<ProductLimitListVersionDto>(this.objectId)
        .subscribe((d) => (this.limitListVersion = d));
    } else if (this.typeId) {
      this.businessObjectService.getDto(this.typeId, this.objectId).subscribe((x) => (this.businessObject = x));
    }
  }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.contractVersion);
  }

  get bondNumber(): string {
    if (this.bondVersion.id && this.bondVersion.id > 0) {
      if (!BondGuiService.isBondRequest(this.bondVersion, false)) {
        return this.bondVersion.bond.number + '/' + this.bondVersion.versionNumber;
      } else {
        return this.bondVersion.bond.requestNumber + '/' + this.bondVersion.versionNumber;
      }
    }
    return undefined;
  }

  toBrokerContractRelationDetails() {
    const rel = this.brokerContractRelationVersion.brokerRelation;
    this.router.toBrokerContractRelationDetails(
      this.brokerContractRelationVersion.id,
      rel.businessObject.relatedToId,
      rel.businessObject.relatedTo.id,
      rel.brokerContract.id
    );
  }

  get inquiryLabel(): string {
    return 'salesRep.relationDetails.policyInquiry';
  }
}
