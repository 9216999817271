import {Component, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../validators';
import {FormDialogComponent} from '../form-dialog';
import {FormDialogParent} from '../form-dialog/FormDialogParent';
import {AppConfigService, GrowlService, UserService} from '../../services';
import {ChangePasswordDto} from '../../model';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.pug',
})
export class ChangePasswordComponent extends FormDialogParent {
  @ViewChild(FormDialogComponent, {static: true}) changePasswordDialog: FormDialogComponent;

  inProgress = false;

  constructor(
    private userService: UserService,
    private growlService: GrowlService,
    public appService: AppConfigService
  ) {
    super();
    const passValidator = CustomValidators.credendoPasswordStrength;
    this.form = new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl('', Validators.required),
        newPassword: new UntypedFormControl('', Validators.compose([Validators.required, passValidator])),
        retypePassword: new UntypedFormControl('', Validators.required),
      },
      CustomValidators.passwordConfirmValidation
    );
  }

  changePassword() {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    const oldPassword = this.form.get('oldPassword').value;
    const newPassword = this.form.get('newPassword').value;
    const changePassDto: ChangePasswordDto = <ChangePasswordDto>{oldPassword: oldPassword, newPassword: newPassword};
    this.inProgress = true;
    this.form.disable();
    this.userService.changePassword(changePassDto).subscribe({
      next: () => this.growlService.notice('Your password has been successfully changed'),
      error: (error) => {
        if (error.message === 'INVALID_CREDENTIALS') {
          this.form.get('oldPassword').setErrors({incorrectPassword: true});
          this.showErrors = true;
        } else {
          this.changePasswordDialog.showErrors = true;
          this.changePasswordDialog.errors = error;
        }
        this.inProgress = false;
        this.form.enable();
      },
      complete: () => {
        this.changePasswordDialog.hide();
        this.inProgress = false;
        this.form.enable();
      },
    });
  }

  openChangePasswordDialog() {
    this.changePasswordDialog.open('changePassword.title', {ignoreBackdropClick: true}).then((result) => {
      if (result) {
        this.changePassword();
      }
      this.showErrors = false;
      this.form.reset();
    });
  }

  onOldPasswordInput() {
    this.form.get('oldPassword').setErrors(null);
  }
}
