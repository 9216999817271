import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  GrowlService,
  ImportService,
  LimitService,
  LoggedUserService,
  SettingsService,
} from '../../bonding_shared/services';
import {
  DictionaryBaseDto,
  FileImportDto,
  LimitRequestCriteriaDto,
  PortalUserContextDto,
} from '../../bonding_shared/model';
import {
  ApplicationModule,
  DictionaryProfile,
  ImportType,
  LimitRequestStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {ImportListComponent} from './import-list.component';
import {ParameterListComponent} from '../../bonding_shared/components/parameter-list/parameter-list.component';
import {PortalUserService} from '../../portal/services/portal-user.service';

export const MGA_PRODUCT_PARAM_NAME = 'product';

@Component({
  selector: 'import',
  templateUrl: 'import.component.html',
})
export class ImportComponent extends DetailsView implements OnInit {
  selectedFile: File;

  // TODO: LIMIT_REQUESTS_FOR_MASS_DECISION is hidden here because mass decision requests are handled by a separate view
  // perhaps this view will be extended to handle also mass decisions
  hiddenImportTypes: Set<number> = new Set([ImportType.LIMIT_REQUESTS_FOR_MASS_DECISION]);

  import: FileImportDto = <FileImportDto>{};

  parameterValues: any = {};
  params: ImportViewParams;
  regExp: RegExp;
  @ViewChild(ImportListComponent, {static: true}) fileList: ImportListComponent;
  metadata = null;
  portalUserCtx: PortalUserContextDto;

  @ViewChild(NgForm, {static: true})
  set parentForm(form: NgForm) {
    if (form) {
      this.form = form.form;
    }
  }

  @ViewChild(ParameterListComponent, {static: true}) parameterList: ParameterListComponent;

  constructor(
    private route: ActivatedRoute,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private limitService: LimitService,
    private importService: ImportService,
    @Optional() private userService: PortalUserService,
    private loggedUserService: LoggedUserService,
    private settingService: SettingsService,
    public appService: AppConfigService
  ) {
    super(growlService);
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => this.initializeView(params));
    if (this.settingService.getActiveModuleId() === ApplicationModule.ADMINISTRATION) {
      this.regExp = /^(?:BANK_HOLIDAYS)$/;
    } else {
      this.regExp = undefined;
    }
    if (this.portal && this.userService) {
      this.userService.getPortalUserContext().subscribe((ctx) => {
        this.portalUserCtx = ctx;
        for (const typeId of ctx.hiddenImportTypes) {
          this.hiddenImportTypes.add(+typeId);
        }
      });
    }
  }

  initializeView(params: ImportViewParams) {
    this.params = params;
    const importTypeId = +params.typeId;
    if (importTypeId) {
      this.form.value.type = <DictionaryBaseDto>{id: importTypeId};
    }
    this.updateParameterDefinitions();
    this.generateFileOnInit(params);
    this.initMetadata();
  }

  private initMetadata() {
    const cessionVersionId = this.parameterValues[this.parameterList.CESSION_VERSION_PARAM_NAME];
    if (cessionVersionId) {
      this.metadata = {cessionVersionId: cessionVersionId};
    }
  }

  save() {
    this.inProgress = true;
    this.serverErrors = undefined;
    if (!this.form.valid || !this.selectedFile) {
      this.showFormError();
      this.inProgress = false;
      return;
    }

    this.import.fileName = this.selectedFile.name;
    this.import.type = this.form.value.type;

    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (e: any) => {
      this.import.content = e.target.result.split(',')[1];
      this.sendFile();
    };
  }

  cancel() {
    this.showErrors = false;
    this.selectedFile = undefined;
    this.serverErrors = undefined;
    this.inProgress = false;
    this.form.get('filesInput').setValue('');
    this.import = <FileImportDto>{};
    if (!this.params.typeId) {
      this.form.get('type').setValue('');
      this.parameterList.parameterDefinitions = undefined;
    }
  }

  private sendFile() {
    this.import.parameters = this.buildParameters();
    this.importService.upload(this.import).subscribe({
      next: (result) => {
        console.log('Uploaded file:', result);
        this.showSavedMsg();
        this.cancel();
        // to delay refresh until list params are updated by angular
        setTimeout(() => this.fileList.refreshFileTable(), 0);
      },
      error: (error) => {
        console.log('Uploaded file error:', error);
        this.cancel();
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  get importTypeProfileId() {
    return undefined;
  }

  buildParameters(): {[index: string]: any} {
    const params = {};
    for (const parameterDefinition of this.parameterList.parameterDefinitions) {
      const parameterValue = this.parameterValues[parameterDefinition.name];
      if (parameterValue != null) {
        params[parameterDefinition.name] =
          parameterDefinition.name === this.parameterList.CESSION_VERSION_PARAM_NAME
            ? +parameterValue
            : this.parameterList.parse(parameterValue);
      }
    }
    return params;
  }

  generateEmptyTemplate() {
    if (!this.form.value.type) {
      return;
    }
    this.importService.generateTemplate(
      this.form.value.type.id,
      this.translateService.instant('import.fileName'),
      (e) => this.downloadErrorCallback(e),
      (f) => console.log('generate template completed')
    );
  }

  updateParameterDefinitions(): void {
    this.inProgress = true;
    this.parameterList.parameterDefinitions = undefined;
    this.parameterValues = {};
    if (this.params.cessionVersionId) {
      this.parameterValues[this.parameterList.CESSION_VERSION_PARAM_NAME] = +this.params.cessionVersionId;
    }
    if (this.form.value.type) {
      this.importService.getParameters(this.form.value.type.id).subscribe({
        next: (definitions) => (this.parameterList.parameterDefinitions = definitions),
        error: (error) => (this.serverErrors = error),
        complete: () => (this.inProgress = false),
      });
    }
  }

  generateFileOnInit(params: ImportViewParams) {
    if (+params.typeId === ImportType.LIMIT_REQUESTS_AUTO_DECISION && params.companyId) {
      this.generateFileForLimitAutoDecision(params);
    }
  }

  generateFileForLimitAutoDecision(params: ImportViewParams) {
    console.log('generate file for autodecision: buyerId = ' + params.companyId);
    const criteria = <LimitRequestCriteriaDto>{
      buyerCompanyId: +params.companyId,
      status: {id: LimitRequestStatus.PENDING},
    };
    this.limitService.export(
      criteria,
      'limits.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      (file) => {
        if (file) {
          console.log('got file ' + file.name);
          this.selectedFile = file;
        }
      }
    );
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}

export class ImportViewParams {
  // string when reading
  typeId?: number | string;
  companyId?: number | string;
  cessionVersionId?: number | string;
}
