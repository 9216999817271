import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  DictionaryBaseDto,
  PolicyContractBaseDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionSimpleDto,
  PolicyInquiryBaseDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  SearchResult,
  ThirdPartyBaseDto,
} from '../../../../../bonding_shared/model/dtos';
import {forkJoin as observableForkJoin, Observable, Subject} from 'rxjs';
import {PolicyContractVersionService} from '../../../../../bonding_shared/services/policy-contract-version.service';
import {RouterService} from '../../../../../bonding_shared/services/router-service';
import {PolicyAndInquirySearchDataProvider} from './policy-and-inquiry-search-data-provider';
import {
  AppConfigService,
  DictionaryBaseService,
  InquiryService,
  LoggedUserService,
} from '../../../../../bonding_shared/services';
import {BusinessUtils} from '../../../../../bonding_shared/utils/business-utils';
import {NumberUtils} from '../../../../../bonding_shared/utils/number-utils';
import {PolicyContractType} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-and-inquiry-version-selector',
  templateUrl: './policy-and-inquiry-version-selector.component.pug',
})
export class PolicyAndInquiryVersionSelectorComponent implements OnInit {
  @Input() showLinks = true;

  @Input() open: Subject<boolean>;

  @Input() oneResultAutoSelection = false;

  @Input() set fixedClient(fc: boolean) {
    this.dataProvider.fixedClient = fc;
  }

  @Input() set fixedMasterPolicyId(id: number) {
    this.dataProvider.fixedMasterPolicyId = id;
  }

  /**
   * not implemented
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    return this._searchModeSwitchable;
  }

  @Input() set policyInitialCriteria(c: PolicyContractVersionCriteriaDto) {
    this.dataProvider.policyCriteria.criteria = c;
    if (!c.policyContract) {
      this.dataProvider.policyCriteria.criteria.policyContract = <PolicyContractBaseDto>{};
    }
  }

  @Input() set inquiryInitialCriteria(c: PolicyInquiryVersionCriteriaDto) {
    this.dataProvider.inquiryCriteria.criteria = c;
    if (!c.policyInquiry) {
      this.dataProvider.inquiryCriteria.criteria.policyInquiry = <PolicyInquiryBaseDto>{};
    }
  }

  @Input() showBU = true;
  @Input() showClient = true;
  @Input() showStatus = false;
  @Input() showBusinessStatus = true;
  @Input() showYear = true;
  @Input() onlyPolicyMode = false;
  policyInquirySwitch = true;

  @Output() selectInquiry = new EventEmitter<PolicyInquiryVersionSimpleDto>();
  @Output() selectPolicy = new EventEmitter<PolicyContractVersionSimpleDto>();
  @Output() emptySearchResult = new EventEmitter<boolean>();

  dataProvider: PolicyAndInquirySearchDataProvider;
  inquirySearchResults = false;
  policyContractTypes: DictionaryBaseDto[];
  inquiryProductTypes: DictionaryBaseDto[];

  constructor(
    public router: RouterService,
    private policyService: PolicyContractVersionService,
    private dictionaryBaseService: DictionaryBaseService,
    private inquiryService: InquiryService,
    private loggedUserService: LoggedUserService,
    public appConfigService: AppConfigService
  ) {
    this.dataProvider = new PolicyAndInquirySearchDataProvider(policyService, inquiryService);
  }

  ngOnInit() {
    const policyProductObs: Observable<DictionaryBaseDto[]> = this.portal
      ? this.policyService.getClientProducts()
      : this.dictionaryBaseService.getDictionaryBase('PolicyContractType');
    const inquiryProductObs: Observable<DictionaryBaseDto[]> =
      this.onlyPolicyMode || !this.portal
        ? this.dictionaryBaseService.getDictionaryBase('InquiryProductType')
        : this.inquiryService.getClientProducts();

    observableForkJoin(policyProductObs, inquiryProductObs).subscribe((res) => {
      this.policyContractTypes = <DictionaryBaseDto[]>res[0];
      this.inquiryProductTypes = <DictionaryBaseDto[]>res[1];
      let switchMode: 'policy' | 'inquiry' | 'both' = 'policy';
      if (!this.dataProvider.fixedMasterPolicyId && !this.onlyPolicyMode && this.inquiryProductTypes.length > 0) {
        switchMode = this.policyContractTypes.length < 1 ? 'inquiry' : 'both';
      }
      this.policyInquirySwitch = switchMode === 'both';
      this.dataProvider.commonCriteria.inquirySearch = switchMode === 'inquiry';
      NumberUtils.deleteItemFirstOccurrence(
        this.policyContractTypes,
        this.policyContractTypes.filter((t) => t.id === PolicyContractType.CAPTIVE)[0]
      );
    });
  }

  searchFinished(result: SearchResult<PolicyContractVersionSimpleDto | PolicyInquiryVersionSimpleDto>) {
    this.inquirySearchResults = this.dataProvider.commonCriteria.inquirySearch;
    if (result.size < 1) {
      this.emptySearchResult.emit(true);
    } else if (result.size === 1 && this.oneResultAutoSelection) {
      this.emitItem(result.result[0]);
    }
  }

  emitItem(item: PolicyContractVersionSimpleDto | PolicyInquiryVersionSimpleDto) {
    if (this.inquirySearchResults) {
      this.selectInquiry.emit(<PolicyInquiryVersionSimpleDto>item);
    } else {
      this.selectPolicy.emit(<PolicyContractVersionSimpleDto>item);
    }
    this.open.next(false);
  }

  getClientName(tp: ThirdPartyBaseDto) {
    return BusinessUtils.getThirdPartyCompany(tp).company
      ? BusinessUtils.getThirdPartyCompany(tp).company.registrationName
      : '';
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
