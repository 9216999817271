import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DocumentListComponent} from './index';
import {
  ATableComponent,
  BusinessObjectDto,
  BusinessObjectService,
  BusObjPolicyVersionRefDto,
  DocumentCriteriaDto,
  DocumentService,
  DocumentSimpleDto,
  IdsDto,
  RouterService,
} from '../../bonding_shared';
import {
  BusinessObjectType,
  ContractStatus,
  DocumentProcessStatus,
  DocumentProcessType,
  PolicyContractVersionOrigin,
  PolicyContractVersionStatus,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'documents-pack',
  templateUrl: 'documents-pack.component.pug',
})
export class DocumentsPackComponent implements OnInit, AfterViewInit {
  @ViewChild(DocumentListComponent, {static: true}) documentList: DocumentListComponent;

  businessObject: BusinessObjectDto = <BusinessObjectDto>{};
  readonly zipOutputFileName = 'documents.zip';

  public showPack = true;
  public generatePack = false;
  public sendPack = false;
  public uploadPack = false;

  private selectedDocumentId: number;

  docSendActions = [
    {id: 1, name: 'searchView.documentsPack.autenti'},
    {id: 2, name: 'searchView.documentsPack.client'},
  ];

  get criteria(): DocumentCriteriaDto {
    return this.documentList.criteria;
  }

  get inProgress(): boolean {
    return this.documentList?.searchDataProvider.inProgress;
  }

  set inProgress(inProgress: boolean) {
    this.documentList.searchDataProvider.inProgress = inProgress;
  }

  constructor(
    private businessObjectService: BusinessObjectService,
    public documentService: DocumentService,
    public routerService: RouterService,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  ngAfterViewInit(): void {
    this.search();
  }

  initializeView(params: Params) {
    this.businessObjectService.getDto(params['boTypeId'], params['boId']).subscribe((bo) => {
      this.businessObject = bo;
      this.documentList.criteria.businessObject = this.businessObject;
      this.documentList.criteria.byPacks = true;
      this.documentList.selectMain = true;
      this.handleButtons();
    });
  }
  private handleButtons(): void {
    let noButtonsForYou = true;
    switch (this.businessObject.relatedTo.id) {
      case BusinessObjectType.POLICY_VERSION:
        noButtonsForYou = [
          PolicyContractVersionStatus.REQUEST_REJECTED,
          PolicyContractVersionStatus.TERMINATION_REQUEST,
          PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
          PolicyContractVersionStatus.TERMINATION_REJECTED,
          PolicyContractVersionStatus.TERMINATION_ACTIVATED,
        ].includes(this.businessObject.reference.statusId);
        break;
      case BusinessObjectType.CONTRACT:
        noButtonsForYou = this.businessObject.reference.statusId !== ContractStatus.CONTRACT_REQUEST_ACCEPTED;
        break;
      case BusinessObjectType.BOND_VERSION:
        noButtonsForYou = false;
        this.docSendActions = [{id: 1, name: 'searchView.documentsPack.autenti'}];
        break;
    }
    this.generatePack = !noButtonsForYou;
    this.sendPack = !noButtonsForYou;
    this.uploadPack = !noButtonsForYou;
  }

  getDocumentListTable(): ATableComponent<DocumentSimpleDto> {
    if (this.documentList) {
      return this.documentList.documentTable;
    }
    return <ATableComponent<DocumentSimpleDto>>{};
  }

  getIdList() {
    const idList = <IdsDto>{};
    idList.ids = this.documentList.documentTable.items.map(({id}) => id);
    return idList;
  }

  generateDocumentsPack() {
    this.inProgress = true;
    this.documentService
      .generateDocumentsPack(this.businessObject.relatedTo.id, this.businessObject.relatedToId)
      .subscribe({
        complete: () => {
          this.search();
        },
      });
  }

  wordingDocumentsPack() {
    this.inProgress = true;
    const ids: number[] = this.getMainDocumentIds();
    this.documentService
      .wordingDocumentsPack(this.businessObject.relatedTo.id, this.businessObject.relatedToId, ids[0])
      .subscribe({
        next: (v) => v,
        complete: () => {
          this.search();
        },
      });
  }

  onDocumentsPackSend(id: number) {
    switch (id) {
      case 1:
        this.sendDocumentsPackToAutenti();
        break;
      case 2:
        this.sendDocumentsPackToClient();
        break;
      default:
        console.log('unknown action');
    }
  }

  sendDocumentsPackToAutenti() {
    this.inProgress = true;
    this.documentService
      .sendDocumentsPackToAutenti(
        this.businessObject.relatedTo.id,
        this.businessObject.relatedToId,
        this.getUniquePackIds()
      )
      .subscribe({
        complete: () => {
          this.search();
        },
      });
  }

  sendDocumentsPackToClient() {
    this.inProgress = true;
    this.documentService
      .sendDocumentsPackToClient(
        this.businessObject.relatedTo.id,
        this.businessObject.relatedToId,
        this.getUniquePackIds()
      )
      .subscribe({
        complete: () => {
          this.search();
        },
      });
  }

  private getUniquePackIds(): number[] {
    if (this.documentList?.searchDataProvider) {
      const dataProvider = this.documentList.searchDataProvider;
      if (dataProvider.lastSearchResult) {
        return dataProvider.lastSearchResult.result
          .map((d) => d.pack.id)
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    }
    return <number[]>{};
  }

  private getMainDocumentIds(): number[] {
    if (this.documentList?.searchDataProvider) {
      const dataProvider = this.documentList.searchDataProvider;
      if (dataProvider.lastSearchResult) {
        return dataProvider.lastSearchResult.result
          .filter((d) => d.main)
          .map((d) => d.id)
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    }
    return <number[]>{};
  }

  public onDocumentSelect(document: DocumentSimpleDto) {
    console.log('SELECT doc = ' + document);
    this.selectedDocumentId = document.id;
    console.log('SELECT doc id = ' + this.selectedDocumentId);
  }

  private search() {
    if (this.documentList) {
      this.documentList.search();
    }
  }

  private getSearchResult(): DocumentSimpleDto[] {
    if (this.documentList && this.documentList.documentTable) {
      return this.documentList.documentTable.items;
    }
    return <DocumentSimpleDto[]>{};
  }

  public onlyFinishedDocuments(): boolean {
    let docs = this.getProcessedDocuments(DocumentProcessType.WORDING);
    if (docs && docs.length > 0) {
      docs = docs.filter((d) => d.process && d.process.status.id === DocumentProcessStatus.FINISHED);
    }
    return docs ? docs.length > 0 && !this.existsProcessedDocuments() : false;
  }

  public isGeneratePackAccessible(): boolean {
    let businessCondition = false;
    switch (this.businessObject.relatedTo.id) {
      case BusinessObjectType.POLICY_VERSION:
        businessCondition =
          this.isVersionInStatus(PolicyContractVersionStatus.REQUEST) || this.isProlongationWithChanges;
        break;
      case BusinessObjectType.CONTRACT:
        businessCondition = this.isVersionInStatus(ContractStatus.CONTRACT_REQUEST_ACCEPTED);
        break;
      case BusinessObjectType.BOND_VERSION:
        businessCondition = true;
    }
    return !this.inProgress && businessCondition && !this.isPackInProcess(DocumentProcessType.WORDING);
  }

  public get isProlongationWithChanges(): boolean {
    return (
      this.isVersionInStatus(PolicyContractVersionStatus.PROLONGATION_REQUEST_ACCEPTED) &&
      (<BusObjPolicyVersionRefDto>this.businessObject.reference).originId !== PolicyContractVersionOrigin.POLICY_VERSION
    );
  }

  private isVersionInStatus(statusId: number): boolean {
    return this.businessObject.reference.statusId === statusId;
  }

  public isWordingPossible(): boolean {
    let businessCondition = false;
    switch (this.businessObject.relatedTo.id) {
      case BusinessObjectType.POLICY_VERSION:
        businessCondition =
          this.isVersionInStatus(PolicyContractVersionStatus.REQUEST_ACCEPTED) || this.isProlongationWithChanges;
        break;
      case BusinessObjectType.CONTRACT:
        businessCondition = this.isVersionInStatus(ContractStatus.CONTRACT_REQUEST_ACCEPTED);
        break;
      case BusinessObjectType.BOND_VERSION:
        businessCondition = true;
    }
    return (
      !this.inProgress &&
      !this.isPackInProcess(DocumentProcessType.WORDING) &&
      businessCondition &&
      this.getSearchResult()?.length > 0
    );
  }

  public isSendingPossible(): boolean {
    let businessCondition = false;
    switch (this.businessObject.relatedTo.id) {
      case BusinessObjectType.POLICY_VERSION:
        businessCondition = this.isVersionInStatus(PolicyContractVersionStatus.ISSUED);
        break;
      case BusinessObjectType.CONTRACT:
        businessCondition = this.isVersionInStatus(ContractStatus.CONTRACT_REQUEST_ACCEPTED);
        break;
      case BusinessObjectType.BOND_VERSION:
        businessCondition = true;
    }
    return (
      !this.inProgress &&
      !this.isPackInProcess(DocumentProcessType.WORDING) &&
      !this.isPackInProcess(DocumentProcessType.AUTENTI) &&
      !this.isPackInProcess(DocumentProcessType.CLIENT) &&
      businessCondition &&
      this.getSearchResult()?.length > 0
    );
  }

  public existsProcessedDocuments(): boolean {
    let docs = this.getProcessedDocuments(DocumentProcessType.WORDING);
    if (docs && docs.length > 0) {
      docs = docs.filter(
        (d) =>
          d.process &&
          (d.process.status.id === DocumentProcessStatus.STARTED ||
            d.process.status.id === DocumentProcessStatus.PROCESSING)
      );
    }
    return docs ? docs.length > 0 : false;
  }

  private isPackInProcess(typeId: number): boolean {
    return (
      this.getProcessedDocuments(typeId)?.filter((d) => {
        const statusId = d.process?.status?.id;
        return statusId === DocumentProcessStatus.STARTED || statusId === DocumentProcessStatus.PROCESSING;
      })?.length > 0
    );
  }

  private wasPackInProcessNoError(typeId: number): boolean {
    return (
      this.getProcessedDocuments(typeId)?.filter((d) => {
        const statusId = d.process?.status?.id;
        return (
          statusId === DocumentProcessStatus.STARTED ||
          statusId === DocumentProcessStatus.PROCESSING ||
          statusId === DocumentProcessStatus.FINISHED
        );
      })?.length > 0
    );
  }

  private getProcessedDocuments(typeId: number): DocumentSimpleDto[] {
    const docs = this.getSearchResult();
    if (docs && docs.length > 0) {
      return docs.filter((d) => {
        // console.log('DOCUMENT PROCESS ID = ' + d.process?.type?.id);
        return d.process?.type?.id === typeId;
      });
    }
    return docs;
  }
}
