import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SimpleSelectorComponent} from '../../../../../bonding_shared/components/search';
import {
  AddressDto,
  ClientCompanyBaseDto,
  CompanyBaseDto,
  CompanyCriteriaDto,
  CompanyDto,
  CompanyExtendedSearchParamsDto,
  DictionaryBaseDto,
  PhysicalPersonDto,
  SearchResult,
} from '../../../../../bonding_shared/model';
import {Country, Field} from '../../../../../bonding_shared/model/dictionary-ids';
import {
  AddressAutocompleteService,
  AppConfigService,
  DictionaryService,
  LoggedUserService,
  ThirdPartyService,
} from '../../../../../bonding_shared/services';
import {BusinessUtils} from '../../../../../bonding_shared/utils/business-utils';
import {CompanySelectorThirdPartyAwareAbstractComponent} from '../../../../../bonding_shared/components/company-selector';
import {BiService} from '../../../../../bonding_shared/services/bi.service';

@Component({
  selector: 'company-selector-shared',
  templateUrl: './company-selector-shared.component.pug',
  providers: [AddressAutocompleteService],
})
export class CompanySelectorSharedComponent extends CompanySelectorThirdPartyAwareAbstractComponent implements OnInit {
  @ViewChild(SimpleSelectorComponent, {static: true}) simpleSelector: SimpleSelectorComponent<
    CompanyCriteriaDto,
    CompanyDto
  >;
  _companySearchInternalIdKey = 'company.search.internalId';
  searchRunning = false;
  searchDone = false;
  Field = Field;
  readonly Country = Country;

  @Input() pagination = true;
  @Input() hideCountry: boolean;
  @Input() hideTown = false;
  @Input() closeable = true;
  @Input() fixedCountry: false;
  @Input() showExternalId = false;
  @Input() showPesel = true;
  @Input() fixedFiltersVisibility = false;
  @Input() searchOnOpen = false;
  @Input() searchButtonTitle = 'common.button.search';
  @Output() searchStart = new EventEmitter<void>();
  @Output() searchFinish = new EventEmitter<SearchResult<CompanyDto>>();
  @Output() searchDoneEvent = new EventEmitter<SearchResult<CompanyDto>>();
  @Output() pageChangeEvent = new EventEmitter<void>();
  @Output() criteriaChangedEvent = new EventEmitter<any>();
  @Output() countryChangedEvent = new EventEmitter<any>();
  @Input() pageSize = 10;

  get companySearchInternalIdKey(): string {
    return this._companySearchInternalIdKey;
  }

  @Input() set companySearchInternalIdKey(value: string) {
    if (value) {
      this._companySearchInternalIdKey = value;
    }
  }

  @Input() set biSource(value: DictionaryBaseDto) {
    this.searchParams.biSourceId = value ? value.id : undefined;
  }

  get searchParams(): CompanyExtendedSearchParamsDto {
    return this.dataProvider.searchCriteria.params;
  }

  @Input() set initialCriteria(c: CompanyCriteriaDto) {
    if (!c.address) {
      c.address = <AddressDto>{};
    }
    if (!c.clientCompany) {
      const company = this.loggedUserService.getLoggedUserData().company;
      c.clientCompany = <ClientCompanyBaseDto>{};
      if (company) {
        c.clientCompany.client = <CompanyBaseDto>{id: company.id};
      }
    }
    if (!c.physicalPerson) {
      c.physicalPerson = {} as PhysicalPersonDto;
    }
    this.dataProvider.searchCriteria.criteria = c;
  }

  ngOnInit(): void {
    if (!this.dataProvider.searchCriteria.criteria.clientCompany) {
      this.dataProvider.searchCriteria.criteria.clientCompany = <ClientCompanyBaseDto>{};
    }
    // TODO: to be done in separate task
    // this.initUSProvinces();
  }

  constructor(
    protected biService: BiService,
    protected dictService: DictionaryService,
    public apiService: AppConfigService,
    private loggedUserService: LoggedUserService,
    thirdPartyService: ThirdPartyService
  ) {
    super(biService, thirdPartyService, apiService);
    this.dataProvider.textSearch = false;
  }

  onSearchClicked() {
    this.searchRunning = true;
    this.searchStart.emit();
  }

  onSearchDone(result: SearchResult<CompanyDto>) {
    this.searchRunning = false;
    this.searchDone = true;
    this.searchDoneEvent.emit(result);
    this.searchFinish.emit(result);
  }

  onPageChange() {
    this.pageChangeEvent.emit();
  }

  onSearchError(error: any) {
    this.searchRunning = false;
    this.searchFinish.emit();
  }

  onCriteriaChanged(event: KeyboardEvent) {
    console.log('Criteria have just been changed! ', event);
    if (event.code !== 'Enter') {
      this.criteriaChangedEvent.emit(event);
    }
  }

  onCountryChanged(event: any) {
    console.log('Country has just been changed! ' + event);
    this.countryChangedEvent.emit(event);
  }

  getExternalId(c: CompanyDto) {
    return c && c.identifiers ? BusinessUtils.findExternalId(c.identifiers, undefined) : undefined;
  }

  getCountry() {
    return this.dataProvider.searchCriteria.criteria.address.country;
  }

  // TODO: to be done in separate task
  // initUSProvinces() {
  //   this.usProvinces = [];
  //   this.geoDictService.getPostCodeGeoDict(Country.US).subscribe(
  //     (postCodes) => {
  //       this.usProvinces = postCodes
  //         .filter((postCode) => postCode.province && postCode.province.taxRates.length > 0)
  //         .map((postCode) => postCode.province);
  //     }
  //   );
  // }

  get country(): DictionaryBaseDto {
    return this.dataProvider.searchCriteria.criteria.address.country;
  }

  get portal() {
    return this.loggedUserService.portal;
  }
}
