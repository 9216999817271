import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {
  AddressAutocompleteService,
  AppConfigService,
  CompanyDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  DictionaryService,
  FeatureService,
  PolicyContractSubinsuredCompanyDto,
  PolicyContractVersionDto,
  RouterService,
  TreatyBaseDto,
  TurnoverByProvinceDto,
} from '../../../../bonding_shared';
import {Feature, FrontingType, PremiumSubinsured} from '../../../../bonding_shared/model/dictionary-ids';
import {InstalmentsWizardComponent} from '..';
import {ControlContainer, FormControl, NgForm, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'insureds-section',
  templateUrl: './insureds-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  providers: [AddressAutocompleteService],
})
export class InsuredsSectionComponent extends Section implements OnInit {
  self = this;
  readonly FrontingType = FrontingType;
  @Input() profiterCompanies: CompanySimpleDto[] = [];

  turnoverOnRegionLevel = false;

  @ViewChild('instalmentsWizard', {static: true}) instalmentsWizardComponent: InstalmentsWizardComponent;
  @ViewChild('calendar', {static: true}) public calendar: NgForm;
  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected dictionaryService: DictionaryService,
    public router: RouterService,
    private geoDictService: AddressAutocompleteService,
    private featureService: FeatureService
  ) {
    super(policyContractViewService, appService);
    this.turnoverOnRegionLevel = featureService.get(Feature.TURNOVER_ON_REGION_LEVEL);
  }

  ngOnInit(): void {
    this.updateInsuredsValidity();
  }

  @Input() set policy(policy: PolicyContractVersionDto) {
    this._policy = policy;
    this.updateInsuredsValidity();
    this.initializeTurnoversByProvince(this.policy.clientTurnoversByProvince, this.policy.clientTaxCountry, false);
    for (const subinsured of this.policy.subinsured) {
      this.initializeTurnoversByProvince(subinsured.turnoversByProvince, subinsured.taxCountry, false);
    }
  }

  get policy() {
    return this._policy;
  }

  onSelectFronter(fronter: CompanyDto) {
    this.policy.frontingCompany = fronter;
  }

  onFrontingChanged(frontingType: DictionaryBaseDto) {
    let newPSDictId = PremiumSubinsured.SUBINSURED; // NO_FRONTING

    if (this.frontingOfType(FrontingType.FRONTING)) {
      newPSDictId = PremiumSubinsured.FRONTING;
      this.policy.subinsured.forEach((sub) => {
        sub.silentInsurance = true;
        this.clearSilentInsuranceData(sub);
      });
    } else {
      this.policy.frontingCompany = undefined;
    }
    if (this.frontingOfType(FrontingType.FRONTING_ASSUMED)) {
      newPSDictId = PremiumSubinsured.MAIN_INSURED_UNGROUPPED;

      if (!this.policyForm.contains('frontingTreaty')) {
        this.policyForm.addControl('frontingTreaty', new FormControl(this.policy.frontingTreaty, Validators.required));
      }
    } else {
      if (this.policyForm.contains('frontingTreaty')) {
        this.policyForm.removeControl('frontingTreaty');
      }
      this.policy.frontingTreaty = undefined;
    }

    this.dictionaryService.getDictionaryEntry('PremiumSubinsured', newPSDictId).subscribe((ps) => {
      this.policy.premiumOnSubinsuredLevel = ps;
    });
  }

  get frontingTreaty(): TreatyBaseDto {
    return this.policyForm.get('frontingTreaty').value;
  }

  removeSubinsued(removedItem: PolicyContractSubinsuredCompanyDto) {
    console.log('remove subinsured & reduce profiter companies');
    this.policy.subinsured = this.policy.subinsured.filter((entry) => entry.company.id !== removedItem.company.id);
  }

  addSubinsured(company: CompanySimpleDto) {
    console.log('add subinsured & extend profiter companies');
    this.policy.subinsured.push(this.subinsuredFactory(company, this.frontingOfType(FrontingType.FRONTING)));
  }

  copyTurnoverButtonHidden(): boolean {
    const {
      premiumOnSubinsuredLevel,
      insuredTurnover,
      insuredTurnoverDomestic,
      insuredTurnoverExport,
      clientTurnover,
      clientTurnoverDomestic,
      clientTurnoverExport,
    } = this.policy;
    return (
      (premiumOnSubinsuredLevel !== undefined && premiumOnSubinsuredLevel.id === PremiumSubinsured.SUBINSURED) ||
      (insuredTurnover === clientTurnover &&
        insuredTurnoverDomestic === clientTurnoverDomestic &&
        insuredTurnoverExport === clientTurnoverExport)
    );
  }

  subinsuredFactory: any = (company: CompanySimpleDto, silentInsurance: boolean) => {
    const subinsured = <PolicyContractSubinsuredCompanyDto>{
      role: <DictionaryBaseDto>{id: 28000002, name: 'Subinsured'},
      premiumInstalementCalendar: [],
      taxCountry: company.address.country,
      company: company,
      silentInsurance: silentInsurance,
      turnoversByProvince: [],
    };
    this.initializeTurnoversByProvince(subinsured.turnoversByProvince, subinsured.taxCountry, true);
    return subinsured;
  };

  copyTurnover() {
    this.policy.clientTurnover = this.policy.insuredTurnover;
    this.policy.clientTurnoverDomestic = this.policy.insuredTurnoverDomestic;
    this.policy.clientTurnoverExport = this.policy.insuredTurnoverExport;
    if (this.policyForm.get('clientTurnoverDomestic')) {
      this.policyForm.get('clientTurnoverDomestic').setValue(this.policy.clientTurnoverDomestic);
    }
    if (this.policyForm.get('clientTurnoverExport')) {
      this.policyForm.get('clientTurnoverExport').setValue(this.policy.clientTurnoverExport);
    }
    this.updateTurnoverValidity();
  }

  updateInsuredsValidity() {
    const calendarFormGroup = <UntypedFormGroup>this.policyForm?.controls['insureds'];
    if (calendarFormGroup) {
      calendarFormGroup.controls['premiumInstalmentCalendar'].setValue(this.policy.premiumInstalementCalendar);
      calendarFormGroup.controls['subinsured'].setValue(this.policy.subinsured);
      if (calendarFormGroup.controls['mainInsuredShare']) {
        calendarFormGroup.controls['mainInsuredShare'].setValue(this.policy.mainInsuredShare);
      }
      calendarFormGroup.updateValueAndValidity({
        onlySelf: false,
        emitEvent: false,
      });
    }
  }

  updateTurnoverValidity() {
    if (this.policyForm.get('clientTurnoverDomestic')) {
      this.policyForm.get('clientTurnoverDomestic').updateValueAndValidity();
    }
    if (this.policyForm.get('clientTurnoverExport')) {
      this.policyForm.get('clientTurnoverExport').updateValueAndValidity();
    }
    if (this.policyForm.get('clientTurnover')) {
      this.policyForm.get('clientTurnover').updateValueAndValidity();
    }
  }

  clearSilentInsuranceData(subinsured: PolicyContractSubinsuredCompanyDto) {
    if (subinsured.silentInsurance) {
      subinsured.insuredTurnover = null;
      subinsured.insuredTurnoverDomestic = null;
      subinsured.insuredTurnoverExport = null;
      subinsured.insuredIban = null;
      subinsured.insuredBic = null;
      subinsured.premiumInstalementCalendar = null;
    }
    this.updateInsuredsValidity();
  }

  get showInstallments(): boolean {
    return this.instalmentsWizardComponent && !this.instalmentsWizardComponent.generatorOn;
  }

  initializeTurnoversByProvince(
    turnoversByProvince: TurnoverByProvinceDto[],
    taxCountry: DictionaryBaseDto,
    forceUpdate = true
  ) {
    if (!this.turnoverOnRegionLevel) {
      return;
    }
    if ((turnoversByProvince.length === 0 || forceUpdate) && taxCountry != null) {
      this.geoDictService.getPostCodeGeoDict(taxCountry.id).subscribe(
        (pcs) => {
          turnoversByProvince.length = 0;
          pcs
            .filter((pc) => pc.province.taxRates.length > 0)
            .map((pc) => <TurnoverByProvinceDto>{province: pc.province})
            .forEach((t) => turnoversByProvince.push(t));
        },
        (error) => {
          console.log('failed getting provinces for country id:)', this.policy.clientTaxCountry.id, error);
        }
      );
    }
  }
}
