import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  BackendError,
  BondVersionBaseDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  ClaimSimpleDto,
  ClaimVersionDto,
  CompanyBaseDto,
  CompanyCriteriaDto,
  DictionaryBaseDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  ProductLimitListForLimitDto,
  ProductLimitListVersionCriteriaDto,
} from '../../../bonding_shared/model';
import {ClaimRiskType} from '../../../bonding_shared/model/dictionary-ids';
import {AppConfigService, ClaimService, LoggedUserService, RouterService} from '../../../bonding_shared/services';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {BondGuiService} from '../../bond/services';
import {ActivatedRoute} from '@angular/router';
import {of, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'claim-selectors',
  templateUrl: 'claim-selectors.component.pug',
})
export class ClaimSelectorsComponent extends ListEmitters {
  @Input() claim: ClaimSimpleDto;
  @Input() serverErrorHandler: (error: BackendError) => void;
  @Input() clearErrorsHandler: () => void;
  @Input() readonly: boolean;
  @Input() policyLinkSearchableInEditFieldMode: boolean;

  @Output() initialized = new EventEmitter<ClaimVersionDto>();
  @Output() claimRiskTypeChanged = new EventEmitter<DictionaryBaseDto>();
  @Output() policyContractVersionEdited = new EventEmitter<PolicyContractVersionDto>();

  readonly ClaimRiskType = ClaimRiskType;
  readonly ClaimSelectorsNames = ClaimSelectorSteps;
  policySearchCriteria: PolicyContractVersionCriteriaDto;
  bondVersionSearchCriteria: BondVersionCriteriaDto;
  policyLimitListSearchCriteria: ProductLimitListVersionCriteriaDto;
  riskSearchCriteria: CompanyCriteriaDto;
  hiddenIds: Set<number>;

  private riskTypeIsSetFromParams = false;

  constructor(
    private loggedUserService: LoggedUserService,
    private claimService: ClaimService,
    public appService: AppConfigService,
    public router: RouterService,
    private _route: ActivatedRoute
  ) {
    super();
    this.selectorNameList = Object.values(ClaimSelectorSteps);
    this.hiddenIds = new Set<number>();
    this.initializeCriteria();
    this.initializeSelectorEmitters(true);
  }

  initializeCriteria() {
    this.policySearchCriteria = <PolicyContractVersionCriteriaDto>{
      lastActivated: true,
    };
    this.bondVersionSearchCriteria = <BondVersionCriteriaDto>{bond: {}};
    this.policyLimitListSearchCriteria = <ProductLimitListVersionCriteriaDto>{limitList: {}};
    this.riskSearchCriteria = <CompanyCriteriaDto>{};
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get areSectionsSearchable(): boolean {
    return !(this.claim && this.claim.id);
  }

  arePolicySectionsSearchable(): boolean {
    if (this.policyLinkSearchableInEditFieldMode) {
      this.policySearchCriteria.linkedCompanyId = this.claim.mainBeneficiary.id;
    }
    return !(this.claim && this.claim.id) || this.policyLinkSearchableInEditFieldMode;
  }

  onSelectMainBeneficiary(company: CompanyBaseDto) {
    this.claim.mainBeneficiary = company;
    this.claim.policyContractVersion = undefined;
    this.policySearchCriteria.linkedCompanyId = company.id;
    this.openSelectorEmitters[ClaimSelectorSteps.Policy].next(true);
  }

  onSelectPolicy(c: PolicyContractVersionDto) {
    if (this.policyLinkSearchableInEditFieldMode) {
      this.tryInitialVersionFromEditedPolicy(c);
    } else {
      this.claim.policyContractVersion = c;
      if (!this.claim.risk) {
        this.openSelectorEmitters[ClaimSelectorSteps.Risk].next(true);
      }
      this.tryInitialVersionFromPolicy();
    }
  }

  onSelectBond(bondVersion: BondVersionSimpleDto) {
    this.claim.bondVersion = bondVersion;
    this.initialVersionFromBond(bondVersion.id);
  }

  onSelectRisk(c: CompanyBaseDto) {
    this.claim.risk = c;
    this.tryInitialVersionFromPolicy();
  }

  onSelectPolicyLimitList(limitList: ProductLimitListForLimitDto) {
    this.claim.policyLimitList = limitList;
    this.riskSearchCriteria.isLimitBuyerForMasterPolicyId = limitList.masterPolicyContract.id;
    this.openSelectorEmitters[ClaimSelectorSteps.Risk].next(true);
  }

  initialVersionFromBond(bondVersionId: number) {
    this.clearErrorsHandler();
    this.claimService
      .getInitialVersionFromBond(bondVersionId)
      .subscribe((v) => this.initialized.emit(v), this.serverErrorHandler);
  }

  tryInitialVersionFromEditedPolicy(c: PolicyContractVersionDto) {
    this.policyContractVersionEdited.emit(c);
  }

  tryInitialVersionFromPolicy() {
    if (this.claim.mainBeneficiary && this.claim.policyContractVersion && (this.claim.risk || this.claim.thirdParty)) {
      this.clearErrorsHandler();
      of(!!this.claim.risk)
        .pipe(
          switchMap((riskFilled) => {
            if (riskFilled) {
              return this.claimService.getInitialVersionFromPolicy(
                this.claim.riskType.id,
                this.claim.risk.id,
                this.claim.mainBeneficiary.id,
                this.claim.policyContractVersion.policyContract.id,
                this.claim.policyLimitList?.id
              );
            } else {
              // third party
              return this.claimService
                .initialize(this.claim)
                .pipe(tap((cv) => (cv.claim.thirdParty = this.claim.thirdParty)));
            }
          })
        )
        .subscribe({
          next: (claimVersion) => this.initialized.emit(claimVersion),
          error: this.serverErrorHandler,
        });
    }
  }

  newClaim() {
    this._route.queryParams.subscribe((params: ClaimInitialParams) => {
      if (+params.bondVersionId) {
        this.initialVersionFromBond(+params.bondVersionId);
      } else if (+params.claimRiskType) {
        this.claim.riskType = <DictionaryBaseDto>{id: +params.claimRiskType};
        this.riskTypeIsSetFromParams = true;
        this.onClaimRiskTypeSelected(this.claim.riskType);
      }
    });
  }

  get riskTypeSelectorDisabled(): boolean {
    return !this.areSectionsSearchable || this.riskTypeIsSetFromParams;
  }

  goToContract(id: number) {
    this.router.toContractPreview(id);
  }

  goToBond(id: number) {
    this.router.toBondDetails(id);
  }

  isBondRequest(bondVersion: BondVersionBaseDto) {
    return BondGuiService.isBondRequest(bondVersion, false);
  }

  onClaimRiskTypeSelected(riskType: DictionaryBaseDto) {
    this.initializeCriteria();
    this.claimRiskTypeChanged.emit(riskType);
    this.claim = <ClaimSimpleDto>{riskType: riskType};
    let emitter = null;
    switch (riskType.id) {
      case ClaimRiskType.CREDIT_INSURANCE:
        emitter = ClaimSelectorSteps.MainBeneficiary;
        break;
      case ClaimRiskType.SURETY:
        emitter = ClaimSelectorSteps.Bond;
        break;
    }
    this.openSelectorEmitters[emitter].next(true);
  }
}

// string when reading
export interface ClaimInitialParams {
  bondVersionId?: string | number;
  claimRiskType?: DictionaryBaseDto | number;
}

export enum ClaimSelectorSteps {
  MainBeneficiary = 'MainBeneficiary',
  Bond = 'Bond',
  Policy = 'Policy',
  Risk = 'Risk',
  PolicyLimitList = 'PolicyLimitList',
}
