import {Injectable} from '@angular/core';
import {LimitService} from '../../../../bonding_shared/services/limit.service';
import {LimitSearchCriteria} from '../../model/limit-search-criteria';
import {LoggedUserService, SearchDataProvider} from '../../../../bonding_shared/services/index';
import {LimitRequestCriteriaDto, LimitRequestDto} from '../../../../bonding_shared';

@Injectable()
export class LimitGuiService {
  /**
   * Keeps selected criteria for limit search view
   */
  searchCriteria: LimitSearchCriteria;

  justCreatedLimitRequest: LimitRequestDto;

  constructor(private limitService: LimitService, loggedUserService: LoggedUserService) {
    this.searchCriteria = new LimitSearchCriteria(limitService, loggedUserService.portal);
  }

  createDataProvider() {
    const dataProvider = new SearchDataProvider<LimitRequestCriteriaDto, LimitRequestDto>(this.limitService);
    dataProvider.searchCriteria.criteria = <LimitRequestCriteriaDto>{};
    dataProvider.searchCriteria.criteria.last = true;
    dataProvider.searchCriteria.sumBy = ['amount', '#lastDecision.amount'];
    dataProvider.searchCriteria.sumGroupBy = 'currency.id';
    dataProvider.textSearch = false;
    return dataProvider;
  }
}
