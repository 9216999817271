<a-table [pagination]="true" [pageSize]="20" [selection]="false">
  <column labelKey="clientInvoice.search.result.number" property="number" sort="number"
          [link]="loggedUserService.hasRight(ElementaryRight.PORTAL_INVOICE_DETAILS_VIEW)"
          (linkClick)="router.toClientInvoicePreview($event.category.id, $event.id)"></column>
  <column labelKey="clientInvoice.search.result.titles" property="titles" sort="titles"></column>
  <column labelKey="clientInvoice.search.result.documentType" property="numberingBag.documentType" sort="numberingBag.documentType" dictionary="FinancialDocumentType"></column>
  <column labelKey="clientInvoice.search.result.invoiceType" property="invoiceType" sort="invoiceType" dictionary="InvoiceType"></column>
  <column labelKey="clientInvoice.search.result.status" property="portalBusinessStatus" sort="status" dictionary="InvoiceStatus"></column>
  <column *ngIf="showContractNumber" labelKey="clientInvoice.search.result.contractNumber" property="contractNumber" sort="contractNumber"></column>
  <column labelKey="clientInvoice.search.result.dateFrom" property="dateFrom" sort="dateFrom" type="date"></column>
  <column labelKey="clientInvoice.search.result.dateTo" property="dateTo" sort="dateTo" type="date"></column>
  <column labelKey="clientInvoice.search.result.grossAmount" property="grossAmount" sort="netAmount" type="number"></column>
  <column labelKey="clientInvoice.search.result.currency" property="currency" sort="currency" dictionary="Currency" dictLabel="code"></column>
  <column labelKey="clientInvoice.search.result.issueDate" property="issueDate" sort="issueDate" type="date"></column>
  <column labelKey="clientInvoice.search.result.dueDate" property="dueDate" sort="dueDate" type="date"></column>
  <column title="">
    <ng-template let-item="item"><button *hasRight="'PORTAL_INVOICE_DOWNLOAD'" class="bon-btn" btnIcon="fa-file-pdf-o"
                                         (click)="this.invoiceService.downloadInvoiceDocument(item, warningCallback.bind(this))"
                                         translate="common.button.download"></button></ng-template>
  </column>
  <column title="">
    <ng-template let-item="item">
      <ng-container *hasRight="'PORTAL_INVOICE_SPEC'">
        <button *ngIf="item.reportDefinitions.length == 1" class="bon-btn" btnIcon="fa-file-excel-o"
                (click)="onReportDefinitionSelected(item.reportDefinitions[0], item.id)"
                translate="clientInvoice.search.result.singleReport"></button>
        <item-dropdown *ngIf="item.reportDefinitions.length > 1" [items]="item.reportDefinitions"
                       (menuSelect)="onReportDefinitionSelected($event, item.id)"
                       caption="clientInvoice.search.result.reports"
                       [dropup]="false" [disabled]="false">
        </item-dropdown>
      </ng-container>
    </ng-template>
  </column>
</a-table>
