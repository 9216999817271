import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  BusinessObjectDto,
  BusObjBrokerContractRelationRefDto,
  BusObjInvoiceItemRefDto,
  BusObjInvoicePaymentRefDto,
  BusObjInvoiceRefDto,
  BusObjPolicyInquiryRefDto,
  BusObjProductLimitListRefDto,
} from '../../model';
import {AppConfigService, BusinessObjectService, RouterService} from '../../services';
import {BusinessObjectType} from '../../model/dictionary-ids';

@Component({
  selector: 'business-object-details',
  template: `
    <div class="bon-row">
      <label *ngIf="showLabel" class="bon-label" translate>shared.businessObjectSelector.relatedTo</label>
      <a href="javascript:void(0)" (click)="buttonGoToObject()" style="margin-right:20px;">
        <b
          >{{ value.relatedTo.name }} <span *ngIf="value.preciseDescription">{{ value.preciseDescription }}</span>
          <span *ngIf="!value.preciseDescription">id: {{ value.relatedToId }}</span>
        </b>
      </a>
      <ng-container *ngIf="!disabled && value.relatedToId">
        <br *ngIf="showLabel" />
        <button
          *ngIf="showChange"
          type="button"
          class="bon-btn-info btn-sm"
          btnIcon="fa-edit"
          (click)="changeValue.emit()"
          translate
        >
          shared.businessObjectSelector.change
        </button>
        <button
          *ngIf="!disabled && showRemove"
          type="button"
          class="bon-btn-danger btn-sm"
          btnIcon="fa-trash-o"
          (click)="removeValue.emit()"
          translate
        >
          shared.businessObjectSelector.remove
        </button>
      </ng-container>
    </div>
  `,
})
export class BusinessObjectDetailsComponent {
  @Input() value: BusinessObjectDto;
  @Input() disabled = false;
  @Input() showChange = false;
  @Input() showLabel = false;
  @Input() showRemove = true;
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private businessObjectService: BusinessObjectService,
    public router: RouterService,
    private appService: AppConfigService
  ) {}
  buttonGoToObject() {
    if (this.value.relatedTo.id === BusinessObjectType.INVOICE) {
      const ref = <BusObjInvoiceRefDto>this.value.reference;
      this.router.toInvoiceDetails(ref.category.id, ref.id);
    } else if (this.value.relatedTo.id === BusinessObjectType.INVOICE_ITEM) {
      const ref = <BusObjInvoiceItemRefDto>this.value.reference;
      this.router.toInvoiceItemDetails(ref.category.id, ref.id);
    } else if (this.value.relatedTo.id === BusinessObjectType.INVOICE_PAYMENT) {
      const ref = <BusObjInvoicePaymentRefDto>this.value.reference;
      this.router.toInvoiceDetails(ref.invoice.category.id, ref.invoice.id);
    } else if (this.value.relatedTo.id === BusinessObjectType.POLICY_INQUIRY_VERSION) {
      const inquiry = <BusObjPolicyInquiryRefDto>this.value.reference;
      this.router.toPolicyInquiryDetails(inquiry.id);
    } else if (this.value.relatedTo.id === BusinessObjectType.BROKER_CONTRACT_RELATION) {
      const ref = <BusObjBrokerContractRelationRefDto>this.value.reference;
      this.router.toBrokerContractRelationDetails(ref.lastVersionId, 0, ref.relatedTo.id, ref.brokerContractId);
    } else if (this.value.relatedTo.id === BusinessObjectType.LIMIT_LIST) {
      const ref = <BusObjProductLimitListRefDto>this.value.reference;
      this.router.toPolicyLimitListDetails(ref.lastVersionId);
    } else {
      this.router.goToBusinessObject(this.value.relatedTo.id, this.value.relatedToId);
    }
  }
}
