import {PolicyContractVersionSearchComponent} from './policy-contract-version-search.component';
import {PolicyContractDetailsComponent} from './policy-contract-details.component';
import {PolicyContractTechnicalDetailsComponent} from './policy-contract-technical-details.component';
import {PolicyContractPreviewComponent} from './policy-contract-preview.component';
import {BonusMalusRequestDetailsComponent} from './components/premium-return/bonus-malus-request-details.component';
import {BonusMalusRequestListComponent} from './components/premium-return/bonus-malus-request-list.component';
import {
  PolicyContractDetailsGuard,
  PolicyContractPreviewGuard,
  PolicyContractTechnicalDetailsGuard,
  PolicyContractVersionSearchGuard,
} from './_guards';
import {PolicyMonitoringVersionDetailsComponent} from './policy-monitoring-version-details.component';

export const PolicyContractRoutes = [
  {
    path: 'policy-contract-preview/:id',
    component: PolicyContractPreviewComponent,
    canActivate: [PolicyContractPreviewGuard],
  },
  {
    path: 'policy-contract-details/:id',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-details/:id/:clientId',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-display/:id/:presentationMode',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-details/:id/:clientId/:offerVersionId',
    component: PolicyContractDetailsComponent,
    canActivate: [PolicyContractDetailsGuard],
  },
  {
    path: 'policy-contract-version-search',
    component: PolicyContractVersionSearchComponent,
    canActivate: [PolicyContractVersionSearchGuard],
  },
  {
    path: 'policy-contract-technical-details/:id',
    component: PolicyContractTechnicalDetailsComponent,
    canActivate: [PolicyContractTechnicalDetailsGuard],
  },
  {
    path: 'bonus-malus-request-details/:className/:id',
    component: BonusMalusRequestDetailsComponent,
  },
  {
    path: 'bonus-malus-request-list/:className/:policyContractId',
    component: BonusMalusRequestListComponent,
  },
  {
    path: 'policy-monitoring-new/:policyContractId/:policyMonitoringTypeId',
    component: PolicyMonitoringVersionDetailsComponent,
    canActivate: [PolicyContractTechnicalDetailsGuard],
  },
  {
    path: 'policy-monitoring-version/:versionId',
    component: PolicyMonitoringVersionDetailsComponent,
    canActivate: [PolicyContractTechnicalDetailsGuard],
  },
];
