import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomFormModule} from '../../../common/custom-form/custom-form.module';
import {BondingDecisionsComponent} from '../../bond/components/shared/bonding-decisions.component';
import {BondingExternalContractComponent} from './bonding-external-contract';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule, CustomFormModule],
  declarations: [BondingDecisionsComponent, BondingExternalContractComponent],
  exports: [BondingDecisionsComponent, BondingExternalContractComponent],
})
export class BondingContractCommonModule {}
