import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {ThirdPartyDetailsComponent} from './components/thirdparty-details.component';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {ThirdPartyGuiService} from './services/thirdparty-gui.service';
import {CompanyExtendedSelectorModule} from '../company/components/shared/extended-selectors/company-extended-selector.module';
import {ThirdPartyEditableListComponent} from './components/thirdparty-editable-list.component';
import {ThirdPartySectionSelectorComponent} from './components/thirdparty-section-selector.component';
import {CompanyThirdPartyAccessGuard} from '../company/_guards/company-third-party-access.guard';
import {BiReportModule} from '../bi-report/bi-report.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CompanySharedModule,
    CompanyExtendedSelectorModule,
    BiReportModule,
  ],
  exports: [ThirdPartyEditableListComponent, ThirdPartySectionSelectorComponent],
  declarations: [ThirdPartyDetailsComponent, ThirdPartyEditableListComponent, ThirdPartySectionSelectorComponent],
  providers: [ThirdPartyGuiService, CompanyThirdPartyAccessGuard],
})
export class ThirdPartyModule {}
