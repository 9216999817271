import {Component, Input, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  BondingContractInquiryVersionCriteriaDto,
  BondingContractInquiryVersionSimpleDto,
  RouterService,
  SearchDataProvider,
} from '../../../../../bonding_shared';

@Component({
  selector: 'bonding-contract-inquiry-version-list',
  templateUrl: './bonding-contract-inquiry-version-list.component.pug',
})
export class BondingContractInquiryVersionListComponent {
  @ViewChild('bondingContractInquiryTable', {static: true})
  bondingContractInquiryTable: ATableComponent<BondingContractInquiryVersionSimpleDto>;
  @Input() pageSize = 20;
  @Input() showLink = true;

  @Input() set dataProvider(
    dp: SearchDataProvider<BondingContractInquiryVersionCriteriaDto, BondingContractInquiryVersionSimpleDto>
  ) {
    this.bondingContractInquiryTable.pagination = true;
    this.bondingContractInquiryTable.dataProvider = dp;
    this.bondingContractInquiryTable.search();
  }

  constructor(public router: RouterService, public appService: AppConfigService) {}

  isEmpty(): boolean {
    if (!this.bondingContractInquiryTable) {
      return true;
    }
    return this.bondingContractInquiryTable.totalCount < 1;
  }
}
