import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  CompleteAnnexResponseDto,
  DictionaryBaseDto,
  IdDto,
  PolicyContractFeesDto,
  PolicyInquiryClientOfferSimpleDto,
  PolicyInquiryCompanyDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryPolicyDataDto,
  PolicyInquiryProductConfigurationDto,
  PolicyInquiryVersionDto,
  StateTransitionDto,
  TemplateDto,
  TemplateSimpleDto,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {BusinessObjectType, DocumentType, PolicyInquiryOfferAction} from '../model/dictionary-ids';
import {TemplateService} from './template.service';
import {DictionaryBaseService} from './dictionary-base.service';

export class InquiryAbstractService extends AbstractService {
  constructor(
    http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    protected templateService: TemplateService,
    protected dictionaryBaseService: DictionaryBaseService
  ) {
    super(http, appConfigService, loggedUserService);
  }

  initialVersion(clientId: number): Observable<PolicyInquiryVersionDto> {
    let query = '/initialVersion';
    query += clientId ? '?clientId=' + clientId : '';
    return this.get<PolicyInquiryVersionDto>(this.url + query);
  }

  @Cacheable()
  getProductConfiguration(): Observable<PolicyInquiryProductConfigurationDto> {
    return this.get<PolicyInquiryProductConfigurationDto>(this.url + '/productConfiguration');
  }

  findPolicyFromInquiry(inquiryVersionId: number): Observable<PolicyInquiryPolicyDataDto> {
    return this.get<PolicyInquiryPolicyDataDto>(this.url + '/findPolicy/' + inquiryVersionId);
  }

  createAnnexInquiry(policyContractVersionId: number): Observable<number> {
    return this.post<IdDto>({id: policyContractVersionId}, this.url + '/annexInquiry').pipe(map((idDto) => idDto.id));
  }

  isPolicyInquiry(policyInquiryId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/isPolicyInquiry/' + policyInquiryId);
  }

  completeAnnex(policyContractVersionId: number): Observable<CompleteAnnexResponseDto> {
    return this.post1<IdDto, CompleteAnnexResponseDto>({id: policyContractVersionId}, this.url + '/completeAnnex');
  }

  getTransitionsForVersion(piv: PolicyInquiryVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + piv.status.id + '/' + piv.id);
  }

  deleteInquiry(policyInquiryId: number) {
    return this.delete(this.url + '/policyInquiry/' + policyInquiryId);
  }

  registrationCompleted(inquiryId: number) {
    return this.postEmpty<void>(this.url + '/registrationCompleted?inquiryId=' + inquiryId);
  }

  getOffer(id: number): Observable<PolicyInquiryOfferEnvelopeDto> {
    return this.get<PolicyInquiryOfferEnvelopeDto>(this.url + '/offer/' + id);
  }

  getClientOffers(clientId: number) {
    return this.get<PolicyInquiryClientOfferSimpleDto>(this.url + '/clientOffers/?clientId=' + clientId);
  }

  saveOffer(
    offer: PolicyInquiryOfferDto,
    voteTask?: boolean,
    action?: DictionaryBaseDto,
    assigned?: Assigned
  ): Observable<PolicyInquiryOfferEnvelopeDto> {
    if (offer.id > 0) {
      return this.updateOffer(offer, voteTask, action, assigned);
    } else {
      return this.createOffer(offer, voteTask);
    }
  }

  private updateOffer(
    offer: PolicyInquiryOfferDto,
    voteTask?: boolean,
    action?: DictionaryBaseDto,
    assigned?: Assigned
  ): Observable<PolicyInquiryOfferEnvelopeDto> {
    const params = UrlParams.new()
      .addIf(!!voteTask, 'voteTask', () => true)
      .addIf(!!action, 'action', () => action.id)
      .addIf(!!assigned?.role, 'assignedRole', () => assigned?.role)
      .addIf(!!assigned?.group, 'assignedGroup', () => assigned?.group);
    const url = this.buildUrl(this.url + '/offer', null, params);
    return this.post1<PolicyInquiryOfferDto, PolicyInquiryOfferEnvelopeDto>(offer, url);
  }

  private createOffer(offer: PolicyInquiryOfferDto, voteTask?: boolean): Observable<PolicyInquiryOfferEnvelopeDto> {
    return this.put1<PolicyInquiryOfferDto, PolicyInquiryOfferEnvelopeDto>(
      offer,
      this.url + '/offer' + (voteTask ? '?voteTask=true' : '')
    );
  }

  public loadTemplateForWording(offer: PolicyInquiryOfferDto) {
    return this.templateService
      .findByType(DocumentType.POLICY_OFFER, BusinessObjectType.OFFER, null, this.getDictSelectorsForTemplate(offer))
      .pipe(
        map((result) => {
          console.log('TEMPLATES for wording result length = ' + result.length);
          return this.filterTemplateByGlobalLol(result, offer);
        })
      );
  }

  public loadTemplateForPolicyDraft(offer: PolicyInquiryOfferDto) {
    return this.templateService
      .findByType(DocumentType.POLICY_DRAFT, BusinessObjectType.OFFER, null, this.getDictSelectorsForTemplate(offer))
      .pipe(
        map((result) => {
          console.log('TEMPLATES for policy draft result length = ' + result.length);
          return this.filterTemplateByGlobalLol(result, offer);
        })
      );
  }

  private filterTemplateByGlobalLol(ts: TemplateSimpleDto[], offer: PolicyInquiryOfferDto) {
    if (!ts || !ts.length) {
      return null;
    }

    if (ts.length > 1) {
      // if more then one, then filter by GlobalLol
      console.assert(!!offer, 'offer empty in template filter');
      console.assert(!!offer.globalConditionsOfInsurance, 'offer globalConditionsOfInsurance empty in template filter');
      ts = ts.filter((t) => {
        const ds = (t as TemplateDto).dictionarySelectors;
        return ds && ds.findIndex((d) => d.id === offer.globalConditionsOfInsurance.id) !== -1;
      });
    }

    if (ts.length === 1) {
      return ts[0];
    }

    console.warn('more then one template for offer document: ', ts);
    return null;
  }

  private getDictSelectorsForTemplate(offer: PolicyInquiryOfferDto) {
    if (!offer) {
      console.warn('offer empty in template search');
      return;
    }
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(offer.type);
    return dictionarySelectors;
  }

  getAcceptanceWay(offer: PolicyInquiryOfferDto): string {
    if (!offer || !offer.action || !offer.action.id) {
      return null;
    }
    const actionId = offer.action.id;

    if (
      actionId === PolicyInquiryOfferAction.ACCEPTBY_CLIENT_STANDARD ||
      actionId === PolicyInquiryOfferAction.ACCEPTBY_CLIENT_ELECTRONIC_SIGNATURE
    ) {
      return offer.action.name;
    }
    return null;
  }

  setCompanyRole(company: PolicyInquiryCompanyDto, roleId: number) {
    this.dictionaryBaseService.getDictionaryEntry('PolicyInquiryCompanyRole', roleId).subscribe((entry) => {
      company.role = entry;
    });
  }

  subinsuredEmpty(inquiry: PolicyInquiryVersionDto): boolean {
    return !inquiry.subinsureds || inquiry.subinsureds.length < 1;
  }
}

export interface Assigned {
  role: number;
  group: number;
}
