import {Component, OnInit, ViewChild} from '@angular/core';
import {
  BackendError,
  BonusCalculationDto,
  BonusRequestDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  ErrorReason,
  MalusCalculationDto,
  MalusRequestDto,
  MasterPolicyContractDto,
  PolicyContractBaseDto,
  PolicyContractPreviewDto,
  PolicyContractSubinsuredCompanyDto,
  PolicyPreviewStatisticsDto,
  TemplateSimpleDto,
} from '../../../../bonding_shared/model';
import {
  AppConfigService,
  DictionaryBaseService,
  GrowlService,
  LoggedUserService,
  MasterPolicyContractService,
  PolicyContractService,
  RouterService,
  TemplateService,
} from '../../../../bonding_shared/services';
import {ActivatedRoute, Params} from '@angular/router';
import {BonusMalusRequestService} from '../../../../bonding_shared/services/bonus-malus-request.service';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  BonusMalusRequestStatus,
  BonusMalusRequestType,
  BusinessObjectType,
  DocumentType,
  ElementaryRight,
  RepoDocumentElementaryRight,
} from '../../../../bonding_shared/model/dictionary-ids';
import {RelatedObject} from '../../../../bonding_shared/components/related-object-info/model';
import {NgForm} from '@angular/forms';
import {FormDialogComponent, StringUtils} from '../../../../bonding_shared';
import {DictionaryUtils} from '../../../../bonding_shared/utils/dictionary-utils';
import {forkJoin} from 'rxjs';
import {ObjectUtils} from '../../../../bonding_shared/utils/object-utils';
import {withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'bonus-malus-request-details',
  templateUrl: 'bonus-malus-request-details.component.pug',
})
// tslint:disable-next-line:max-line-length
export class BonusMalusRequestDetailsComponent<
    T extends MalusRequestDto | BonusRequestDto,
    C extends BonusCalculationDto | MalusCalculationDto
  >
  extends DetailsView
  implements OnInit
{
  policyContractPreview: PolicyContractPreviewDto;
  bonusMalusRequestClass: string;
  bonusMalusRequest: T;
  currentStatus: DictionaryBaseDto;
  masterPolicyContract: MasterPolicyContractDto;
  bonusCalculation: C;
  policyPreviewStatistics: PolicyPreviewStatisticsDto;
  serverErrors: BackendError;
  inProgress = false;
  longLabel = false;
  isBonusRequestClass = true;
  edition = false;
  self = this;

  @ViewChild('warningsDialog', {static: true}) warningsDialog: FormDialogComponent;
  warnings: ErrorReason[];

  bonusRejectionTemplates: TemplateSimpleDto[];

  readonly DictionaryUtils = DictionaryUtils;
  readonly BonusMalusRequestStatus = BonusMalusRequestStatus;
  readonly BonusMalusRequestType = BonusMalusRequestType;
  readonly ElementaryRight = ElementaryRight;
  readonly BusinessObjectType = BusinessObjectType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  constructor(
    public bonusMalusRequestService: BonusMalusRequestService<T, C>,
    private masterPolicyContractService: MasterPolicyContractService,
    public routerService: RouterService,
    private policyContractService: PolicyContractService,
    private dictionaryBaseService: DictionaryBaseService,
    protected templateService: TemplateService,
    private route: ActivatedRoute,
    public router: RouterService,
    protected growlService: GrowlService,
    protected loggedUserService: LoggedUserService,
    public appService: AppConfigService
  ) {
    super(growlService);
    console.log('START premium return detail constructor');
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.selectorNameList = [RelatedObject.INSURED];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    this.route.url
      .pipe(withLatestFrom(this.route.params, this.route.queryParams))
      .subscribe(([url, params, queryParams]) => {
        this.initializeViewWithQP(params, queryParams, false);
      });
  }

  @ViewChild(NgForm)
  set parentForm(form: NgForm) {
    if (form) {
      this.form = form.form;
    }
  }

  initializeView(params: Params, force?: boolean) {
    this.initializeViewWithQP(params, this.route.snapshot.queryParams, force);
  }

  initializeViewWithQP(params: Params, queryParams: Params, force: boolean) {
    const bonusMalusRequestId = +params['id'];
    this.bonusMalusRequestClass = params['className'];
    this.isBonusRequestClass = this.bonusRequest;
    if (bonusMalusRequestId && bonusMalusRequestId > 0) {
      if (!force && ObjectUtils.equalsIdAndNumber(this.bonusMalusRequest, bonusMalusRequestId)) {
        return;
      }
      this.bonusMalusRequestService
        .getBonusMalusRequestById(this.bonusMalusRequestClass, bonusMalusRequestId)
        .subscribe({
          next: (bonusMalusRequest) => {
            this.setBonusMalusRequest(bonusMalusRequest);
            forkJoin([
              this.masterPolicyContractService.getMasterPolicyContract(
                this.bonusMalusRequest.policyContract.masterPolicyId
              ),
              this.policyContractService.getPreview(this.bonusMalusRequest.policyContract.id),
              this.bonusMalusRequestService.getCalculation(
                this.bonusMalusRequestClass,
                this.bonusMalusRequest.policyContract.id,
                this.bonusMalusRequest.type.id
              ),
            ]).subscribe(([masterPolicyContract, policyContractPreview, calculation]) => {
              this.masterPolicyContract = masterPolicyContract;
              this.policyContractPreview = policyContractPreview;
              this.bonusCalculation = calculation;
              this.policyPreviewStatistics = this.policyYearStatistics();
            });

            this.edition = false;
            this.updateButtons();
            this.setTemplates();
          },
          error: (error) => this.handleServerError(error),
        });
    } else {
      const policyContractId = +queryParams['policyContractId'];
      const requestTypeId: number = +queryParams['bonusMalusRequestTypeId'];
      this.policyContractService.getPreview(policyContractId).subscribe((policyContractPreview) => {
        this.policyContractPreview = policyContractPreview;
        this.masterPolicyContractService
          .getMasterPolicyContract(this.policyContractPreview.masterPolicyId)
          .subscribe((masterPolicyContract) => {
            this.masterPolicyContract = masterPolicyContract;
            this.bonusMalusRequestService
              .getNewTemplate(this.bonusMalusRequestClass, policyContractId, requestTypeId)
              .subscribe((bonusMalusRequest) => {
                this.setBonusMalusRequest(bonusMalusRequest);
                this.edition = true;
                this.updateButtons();
                this.refreshStats();
                this.setTemplates();
              });
          });
      });
    }
  }

  private setBonusMalusRequest(bonusMalusRequest: T) {
    this.bonusMalusRequest = bonusMalusRequest;
    this.currentStatus = this.bonusMalusRequest.status;

    this.currentStatus = this.bonusMalusRequest.status;
    if (this.isBonusRequestClass && this.canAccept) {
      const bonusRequest = this.asBonusRequest(this.bonusMalusRequest);
      if (bonusRequest.percentage) {
        bonusRequest.acceptedPercent = bonusRequest.requestedPercent;
      } else {
        bonusRequest.acceptedAmount = bonusRequest.requestedAmount;
      }
    }
  }

  onPolicyYearSelection(policyContract: PolicyContractBaseDto) {
    this.policyContractService.getPreview(policyContract.id).subscribe((policyContractPreview) => {
      this.policyContractPreview = policyContractPreview;
    });

    this.bonusMalusRequest.policyContract = policyContract;
    this.bonusMalusRequest.policyYearFrom = this.bonusMalusRequest.policyContract.validFrom;
    this.bonusMalusRequest.policyYearTo = this.bonusMalusRequest.policyContract.validTo;
    this.policyPreviewStatistics = this.policyYearStatistics();
    this.refreshStats();
  }

  private policyYearStatistics(): PolicyPreviewStatisticsDto {
    if (
      this.policyContractPreview &&
      this.policyContractPreview.stats &&
      this.bonusMalusRequest &&
      this.bonusMalusRequest.policyContract
    ) {
      return this.policyContractPreview.stats
        .filter((s) => s.policyYear === this.bonusMalusRequest.policyContract.policyYear)
        .shift();
    }
    return undefined;
  }

  private refreshStats(): void {
    this.bonusMalusRequestService
      .getCalculation(
        this.bonusMalusRequestClass,
        this.bonusMalusRequest.policyContract.id,
        this.bonusMalusRequest.type.id
      )
      .subscribe({
        next: (calc) => {
          this.bonusCalculation = calc;
          this.updateRequestFromCalculation();
        },
        error: (error) => {
          this.handleServerError(error);
        },
      });
  }

  checkBonusMalusRequestType(typeIds: number[]) {
    return this.appService.credendo && DictionaryUtils.inArr(this.bonusMalusRequest.type, typeIds);
  }

  checkBonusMalusRequestStatus(statusIds: number[]) {
    return DictionaryUtils.inArr(this.bonusMalusRequest.status, statusIds);
  }

  private updateRequestFromCalculation() {
    this.bonusMalusRequest.lossRatio = this.bonusCalculation.lossRatio;
    this.bonusMalusRequest.policyClauseType = this.bonusCalculation.policyClauseType;
    this.bonusMalusRequest.regularPremium = this.bonusCalculation.regularPremium;
    this.bonusMalusRequest.premiumCoverPlus = this.bonusCalculation.premiumCoverPlus;
    this.bonusMalusRequest.paidIndemnification = this.bonusCalculation.paidIndemnification;
    this.bonusMalusRequest.premiumRate = this.bonusCalculation.premiumRate;
    this.bonusMalusRequest.premiumPercent = this.bonusCalculation.premiumPercent;
    this.bonusMalusRequest.premiumBase = this.bonusCalculation.premiumBase;
    this.bonusMalusRequest.calculatedAmount = this.bonusCalculation.calculatedAmount;
    this.bonusMalusRequest.lrIncludeCoverPlus = this.bonusCalculation.lrIncludeCoverPlus;
    this.bonusMalusRequest.lrIncludeIbnr = this.bonusCalculation.lrIncludeIbnr;
    this.bonusMalusRequest.lrIncludeRecoveries = this.bonusCalculation.lrIncludeRecoveries;
    this.bonusMalusRequest.currency = this.bonusCalculation.currency;

    if (this.isBonusRequestClass) {
      const bonusRequest = <BonusRequestDto>this.bonusMalusRequest;
      const bonusCalculation = <BonusCalculationDto>this.bonusCalculation;
      bonusRequest.dmIndex = bonusCalculation.dmIndex;
      bonusRequest.bonuses = bonusCalculation.bonuses;
      bonusRequest.minPremium = bonusCalculation.minPremium;
      bonusRequest.premiumCost = bonusCalculation.premiumCost;
      bonusRequest.premiumCostAmount = bonusCalculation.premiumCostAmount;
      bonusRequest.transferBalance = bonusCalculation.transferBalance;
      bonusRequest.includeCoverPlus = bonusCalculation.includeCoverPlus;
      bonusRequest.includeBonuses = bonusCalculation.includeBonuses;
      bonusRequest.bonusBase = bonusCalculation.bonusBase;
      bonusRequest.limitToMinPremiumSurcharge = bonusCalculation.limitToMinPremiumSurcharge;
      bonusRequest.deductIndemnification = bonusCalculation.deductIndemnification;
      bonusRequest.requestedAmount = this.bonusCalculation.calculatedAmount;
      bonusRequest.invoices = bonusCalculation.invoices;
    } else {
      const malusRequest = <MalusRequestDto>this.bonusMalusRequest;
      const malusCalculation = <MalusCalculationDto>this.bonusCalculation;
      malusRequest.acceptedAmount = this.bonusCalculation.calculatedAmount;
      malusRequest.increasedPremium = malusCalculation.increasedPremium;
      malusRequest.declarationValueContractCurrency = malusCalculation.declarationValueContractCurrency;
      malusRequest.settlementPeriod = malusCalculation.settlementPeriod;
      malusRequest.settlementPeriodUnit = malusCalculation.settlementPeriodUnit;
      malusRequest.calculationPeriod = malusCalculation.calculationPeriod;
      malusRequest.calculationPeriodUnit = malusCalculation.calculationPeriodUnit;
      malusRequest.recoveryPeriod = malusCalculation.recoveryPeriod;
      malusRequest.recoveryPeriodUnit = malusCalculation.recoveryPeriodUnit;
      malusRequest.invoicePaymentTerm = malusCalculation.invoicePaymentTerm;
      malusRequest.drawdownDate = malusCalculation.drawdownDate;
      malusRequest.financialNoticeDate = malusCalculation.financialNoticeDate;
      malusRequest.paidPremium = malusCalculation.paidPremium;
      malusRequest.premiumSource = malusCalculation.premiumSource;
    }
  }

  onCalculate(): void {
    this.clearErrors();
    this.bonusMalusRequestService.calculate(this.bonusMalusRequestClass, this.bonusMalusRequest).subscribe({
      next: (bonusCalculation) => {
        this.bonusCalculation = bonusCalculation;
        this.updateRequestFromCalculation();
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  onCancel() {
    super.onCancel(this.route);
  }

  onSave(): void {
    this.clearErrors();
    this.showErrors = true;
    if (this.form.invalid) {
      return;
    }

    this.inProgress = true;
    if (this.bonusMalusRequest.percentage) {
      if (this.bonusMalusRequestService.isBonusRequest(this.bonusMalusRequestClass)) {
        (<BonusRequestDto>this.bonusMalusRequest).requestedAmount = undefined;
      }
      this.bonusMalusRequest.acceptedAmount = undefined;
    } else {
      if (this.bonusMalusRequestService.isBonusRequest(this.bonusMalusRequestClass)) {
        (<BonusRequestDto>this.bonusMalusRequest).requestedPercent = undefined;
      }
      this.bonusMalusRequest.acceptedPercent = undefined;
    }

    this.save(!this.bonusMalusRequest.id);
  }

  private save(rollbackOnWarnings: boolean) {
    this.bonusMalusRequest.rollbackOnWarnings = rollbackOnWarnings;
    this.bonusMalusRequestService.onSave(this.bonusMalusRequestClass, this.bonusMalusRequest).subscribe({
      next: (req) => {
        this.setBonusMalusRequest(req);
        this.serverErrors = req.warnings;
        this.edition = false;
        this.inProgress = false;
        this.updateButtons();
        this.showSavedMsg();
        this.router.toBonusMalusRequestDetails(this.bonusMalusRequestClass, req.id);
      },
      error: (error) => {
        if (rollbackOnWarnings && Array.isArray(error)) {
          const errorReasons = <ErrorReason[]>error;
          if (errorReasons.every((er) => er.severity === 'WARNING')) {
            this.warnings = error;
            this.inProgress = false;
            this.warningsDialog.openAndExecuteOnConfirm(
              () => this.save(false),
              () => (this.bonusMalusRequest.status = this.currentStatus)
            );
            return;
          }
        }
        this.bonusMalusRequest.status = this.currentStatus;
        this.handleServerError(error);
      },
    });
  }

  private updateButtons() {
    this.saveButton.disabled =
      !(
        this.hasCreateOrUpdateRights &&
        DictionaryUtils.equalsDictAndId(this.bonusMalusRequest.status, BonusMalusRequestStatus.REQUEST)
      ) || !this.edition;
    this.cancelButton.disabled = this.saveButton.disabled;
  }

  onSelectClient(insured: CompanySimpleDto) {
    this.bonusMalusRequest.insured = insured;
    const subinsured = this.allSubinsureds().filter((s) => s.company.id === insured.id)[0];
    this.asBonusRequest(this.bonusMalusRequest).iban = subinsured.insuredIban;
    this.asBonusRequest(this.bonusMalusRequest).bic = subinsured.insuredBic;
  }

  toAcceptance() {
    this.dictionaryBaseService
      .getById<DictionaryBaseDto>(BonusMalusRequestStatus.REQUEST)
      .subscribe((s) => (this.bonusMalusRequest.status = s));
  }

  onStateChange() {
    this.dictionaryBaseService
      .getDictionaryEntry('BonusMalusRequestStatus', this.bonusMalusRequest.status.id)
      .subscribe((s) => {
        this.bonusMalusRequest.status = s;
        if (this.bonusMalusRequest.status.id === BonusMalusRequestStatus.REJECTED) {
          this.bonusMalusRequest.acceptedAmount = 0.0;
          this.bonusMalusRequest.acceptedBy = this.loggedUserService.getLoggedUserData();
        }

        if (this.bonusMalusRequest.status.id === BonusMalusRequestStatus.ACCEPTED) {
          this.bonusMalusRequest.acceptedBy = this.loggedUserService.getLoggedUserData();
          this.growlService.notice('policyContract.bonusMalusRequest.bonusAmountAccepted');
        }
        this.onSave();
      });
  }

  onEdit() {
    this.clearErrors();
    this.saveButton.disabled = false;
    this.cancelButton.disabled = false;
    this.edition = true;
  }

  onRemove() {
    console.log('Before delete...');
    this.bonusMalusRequestService
      .onDelete(this.bonusMalusRequest.id)
      .subscribe((pr) =>
        this.router.toBonusMalusRequests(this.bonusMalusRequestClass, this.policyContractPreview.policyId)
      );
    console.log('After delete.');
  }

  allSubinsureds(): PolicyContractSubinsuredCompanyDto[] {
    const subinsureds: PolicyContractSubinsuredCompanyDto[] = [];
    if (this.policyContractPreview) {
      const client = <PolicyContractSubinsuredCompanyDto>{
        company: this.policyContractPreview.client,
        insuredIban: this.policyContractPreview.insuredIban,
        insuredBic: this.policyContractPreview.insuredBic,
      };
      subinsureds.push(client);
      this.policyContractPreview.subinsured.forEach((s) => subinsureds.push(s));
    }
    return subinsureds;
  }

  subinsuredCompanies(): CompanySimpleDto[] {
    return this.allSubinsureds().map((s) => s.company);
  }

  asBonusRequest(bonusMalusRequest: T): BonusRequestDto {
    return <BonusRequestDto>bonusMalusRequest;
  }

  get bonusRequest(): boolean {
    return this.bonusMalusRequestService.isBonusRequest(this.bonusMalusRequestClass);
  }

  get hasCreateOrUpdateRights(): boolean {
    return (
      this.loggedUserService.hasRight(ElementaryRight.PREMIUM_RETURN_CREATE) ||
      this.loggedUserService.hasRight(ElementaryRight.PREMIUM_RETURN_EDITION)
    );
  }

  get canButtonsEditOrRemove(): boolean {
    return (
      this.hasCreateOrUpdateRights &&
      DictionaryUtils.equalsDictAndId(this.bonusMalusRequest.status, BonusMalusRequestStatus.REQUEST) &&
      this.bonusMalusRequest.id &&
      this.bonusMalusRequest.id > 0
    );
  }

  get enabled(): boolean {
    return (
      DictionaryUtils.equalsDictAndId(this.bonusMalusRequest.status, BonusMalusRequestStatus.REQUEST) &&
      this.hasCreateOrUpdateRights &&
      this.edition
    );
  }

  get canAccept(): boolean {
    return (
      DictionaryUtils.inArr(this.bonusMalusRequest.status, BonusMalusRequestStatus.TO_BE_ACCEPTED) &&
      this.loggedUserService.hasRight(ElementaryRight.PREMIUM_RETURN_ACCEPT)
    );
  }

  get amountEditable(): boolean {
    return (
      (this.edition && !this.bonusRequest) ||
      (this.hasCreateOrUpdateRights &&
        DictionaryUtils.inArr(this.bonusMalusRequest.status, BonusMalusRequestStatus.TO_BE_ACCEPTED))
    );
  }

  yesOrNo(flag: boolean): string {
    return this.bonusMalusRequest && this.bonusMalusRequest.policyContract && this.policyContractPreview
      ? flag
        ? 'common.YES'
        : 'common.NO'
      : '';
  }

  formatAmount(amount: number, currency: DictionaryBaseDto): string {
    if (!amount && amount !== 0) {
      return '';
    }
    return StringUtils.formatNumber(amount) + ' ' + (currency ? currency.code : '');
  }

  premiumPaybackString(): string {
    if (!this.policyContractPreview) {
      return '';
    }
    let result = this.policyContractPreview.premiumPayback + ' ';
    if (this.policyContractPreview.bonusFixedAmount) {
      result += this.policyContractPreview.currency ? this.policyContractPreview.currency.code : '';
    } else {
      result += '%';
    }
    return result;
  }

  premiumLinkText(): string {
    return this.bonusMalusRequestService.isBonusRequest(this.bonusMalusRequestClass)
      ? 'policyContract.bonusMalusRequest.bonusRequestList'
      : 'policyContract.bonusMalusRequest.additionalPremiumList';
  }

  acceptanceTopMargin(): string {
    return this.bonusRequest ? 'padding: 0px;margin-top: 60px;' : 'padding: 0px;';
  }

  private setTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    if (this.bonusMalusRequest && this.bonusMalusRequest.status) {
      dictionarySelectors.push(this.bonusMalusRequest.status);
    }
    if (this.bonusMalusRequest && this.bonusMalusRequest.type) {
      dictionarySelectors.push(this.bonusMalusRequest.type);
    }
    this.templateService
      .findByType(DocumentType.BONUS_DOCUMENTS, BusinessObjectType.BONUS_REQUEST, null, dictionarySelectors)
      .subscribe((result) => {
        if (result !== undefined && result != null && result.length > 0) {
          this.bonusRejectionTemplates = result;
        }
      });
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.routerService.toDocumentDetailsNew(template.id, this.bonusMalusRequest.id);
  }

  getRepositoryBusinessObjectType() {
    return this.bonusRequest ? BusinessObjectType.BONUS_REQUEST : BusinessObjectType.MALUS_REQUEST;
  }
}
