import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {DictionaryBaseDto, PolicyContractVersionIdDto} from '../model/dtos';
import {ApplicationModule} from '../model/dictionary-ids';
import {Observable, of as observableOf} from 'rxjs';
import {share, tap} from 'rxjs/operators';

@Injectable()
export class DashboardService extends AbstractService {
  private _euMapUrl = this.urlPrefix + 'dashboard/countryPerformance';
  private _euMapPolicyUrl = this.urlPrefix + 'policyDashboard/countryPerformance';
  private _euMapClaimsUrl = this.urlPrefix + 'claimDashboard/countryPerformance';
  private _euMapLimitsUrl = this.urlPrefix + 'limitDashboard/countryPerformance';

  private _timeChartUrl = this.urlPrefix + 'dashboard/monthPerformance';
  private _clientTimeChartUrl = this.urlPrefix + 'clientDashboard/monthPerformance';
  private _timeChartPolicyUrl = this.urlPrefix + 'policyDashboard/monthPerformance';
  private _timeChartClaimUrl = this.urlPrefix + 'claimDashboard/monthPerformance';
  private _timeChartLimitUrl = this.urlPrefix + 'limitDashboard/monthPerformance';
  private _bondTypeChartUrl = this.urlPrefix + 'dashboard/bondTypePerformance';
  private _clientBondTypeChartUrl = this.urlPrefix + 'clientDashboard/bondTypePerformance';
  private _policyTypeChartUrl = this.urlPrefix + 'policyDashboard/policyTypePerformance';
  private _claimTypeChartUrl = this.urlPrefix + 'claimDashboard/claimTypePerformance';
  private _ratingLimitUrl = this.urlPrefix + 'limitDashboard/ratingPerformance';
  private _salesUrl = this.urlPrefix + 'salesDashboard/';

  private countryPerformance: {
    [buId: number]: {[appModuleid: number]: {[limitCountries: number]: Object[]}};
  } = {};

  private countryPerformanceObservables: {
    [buId: number]: {[appModuleid: number]: {[limitCountries: number]: Observable<Object[]>}};
  } = {};

  private getCountryPerformance(bu: DictionaryBaseDto, appModuleid: number, limitCountries: number): Object[] {
    if (bu) {
      return this.countryPerformance[bu.id] && this.countryPerformance[bu.id][appModuleid]
        ? this.countryPerformance[bu.id][appModuleid][limitCountries]
        : undefined;
    }
    return undefined;
  }

  private putCountryPerformance(
    bu: DictionaryBaseDto,
    appModuleid: number,
    limitCountries: number,
    performance: Object[]
  ) {
    const index = bu ? bu.id : 'all';
    if (!this.countryPerformance[index]) {
      this.countryPerformance[index] = {};
    }
    if (!this.countryPerformance[index][appModuleid]) {
      this.countryPerformance[index][appModuleid] = {};
    }
    this.countryPerformance[index][appModuleid][limitCountries] = performance;
  }

  private getCountryPerformanceObservable(bu: DictionaryBaseDto, appModuleid: number, limitCountries: number) {
    if (bu) {
      return this.countryPerformanceObservables[bu.id] && this.countryPerformanceObservables[bu.id][appModuleid]
        ? this.countryPerformanceObservables[bu.id][appModuleid][limitCountries]
        : undefined;
    }
    return undefined;
  }

  private putCountryPerformanceObservable(
    bu: DictionaryBaseDto,
    appModuleid: number,
    limitCountries: number,
    observable: Observable<Object[]>
  ) {
    const index = bu ? bu.id : 'all';
    if (!this.countryPerformanceObservables[index]) {
      this.countryPerformanceObservables[index] = {};
    }
    if (!this.countryPerformanceObservables[index][appModuleid]) {
      this.countryPerformanceObservables[index][appModuleid] = {};
    }
    this.countryPerformanceObservables[index][appModuleid][limitCountries] = observable;
  }

  resetCountryPerformance() {
    this.countryPerformance = {};
  }

  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getTimeChartData(
    businessUnit: DictionaryBaseDto,
    applicationModuleId: number,
    chartPath?: string,
    extraParams?: {[key: string]: string | number | null}
  ) {
    let finalUrl = '';
    if (applicationModuleId && applicationModuleId > 0) {
      switch (applicationModuleId) {
        case ApplicationModule.BONDING:
          finalUrl = this._timeChartUrl;
          break;
        case ApplicationModule.POLICY:
          finalUrl = this._timeChartPolicyUrl;
          break;
        case ApplicationModule.CLAIM:
          finalUrl = this._timeChartClaimUrl;
          break;
        case ApplicationModule.LIMIT:
          finalUrl = this._timeChartLimitUrl;
          break;
        case ApplicationModule.SALES:
          finalUrl = this._salesUrl + chartPath + 'Performance';
          break;
      }
    }

    finalUrl += '?withoutFuture=true';
    if (businessUnit !== undefined) {
      finalUrl += '&businessUnitId=' + businessUnit.id;
    }
    if (extraParams) {
      Object.entries(extraParams).forEach(([key, value]) => {
        if (value) {
          finalUrl += `&${key}=${value}`;
        }
      });
    }

    return this.http.get(finalUrl, {headers: this.defaultHeaders()}).pipe(tap((data) => console.log(data)));
  }

  getMapChartUrl(applicationModuleId: number): string {
    const urls: {[id: number]: string} = {};
    urls[ApplicationModule.BONDING] = this._euMapUrl;
    urls[ApplicationModule.POLICY] = this._euMapPolicyUrl;
    urls[ApplicationModule.CLAIM] = this._euMapClaimsUrl;
    urls[ApplicationModule.LIMIT] = this._euMapLimitsUrl;
    return urls[applicationModuleId];
  }

  getEuMapData(
    businessUnit: DictionaryBaseDto,
    applicationModuleId: number,
    limitCountries: number,
    policyVersion?: PolicyContractVersionIdDto
  ) {
    const performance = this.getCountryPerformance(businessUnit, applicationModuleId, limitCountries);
    if (performance) {
      return observableOf(performance);
    }
    let observable = this.getCountryPerformanceObservable(businessUnit, applicationModuleId, limitCountries);
    if (observable) {
      return observable;
    }
    const url = this.getMapChartUrl(applicationModuleId);
    let params = new HttpParams();
    if (businessUnit !== undefined) {
      params = params.append('businessUnitId', businessUnit.id + '');
    }
    if (policyVersion !== undefined) {
      params = params.append('policyVersionId', policyVersion.id + '');
    }
    if (limitCountries && limitCountries > 0) {
      params = params.append('limitCountries', limitCountries + '');
    }
    observable = this.get<Object[]>(url, params).pipe(
      tap(
        (entries) => {
          this.putCountryPerformance(businessUnit, applicationModuleId, limitCountries, <Object[]>entries);
          this.putCountryPerformanceObservable(businessUnit, applicationModuleId, limitCountries, undefined);
        },
        (error) => {}
      ),
      share()
    );
    this.putCountryPerformanceObservable(businessUnit, applicationModuleId, limitCountries, observable);
    return observable;
  }

  getTypeChartData(businessUnit: DictionaryBaseDto, applicationModuleId: number) {
    let finalUrl = '';
    if (applicationModuleId && applicationModuleId > 0) {
      switch (applicationModuleId) {
        case ApplicationModule.BONDING:
          finalUrl = this._bondTypeChartUrl;
          break;
        case ApplicationModule.POLICY:
          finalUrl = this._policyTypeChartUrl;
          break;
        case ApplicationModule.CLAIM:
          finalUrl = this._claimTypeChartUrl;
          break;
        case ApplicationModule.FINANCE:
          finalUrl = this._bondTypeChartUrl;
          break;
      }
    }

    if (businessUnit !== undefined) {
      finalUrl += '?businessUnitId=' + businessUnit.id;
    }

    return this.http.get(finalUrl, {headers: this.defaultHeaders()}).pipe(tap((data) => console.log(data)));
  }

  getRatingChartDataLimit(businessUnit: DictionaryBaseDto) {
    let buParam = '';
    if (businessUnit !== undefined) {
      buParam = '?businessUnitId=' + businessUnit.id;
    }
    return this.http
      .get(this._ratingLimitUrl + buParam, {headers: this.defaultHeaders()})
      .pipe(tap((data) => console.log(data)));
  }
}
