import {Injectable} from '@angular/core';
import {
  InquiryProductType,
  PolicyContractType,
  PolicyContractVersionStatus,
} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryBaseDto} from '../../../bonding_shared';

// tslint:disable:max-line-length
export enum PolicyContractViewSection {
  BASE_INSURERS,
  POLICY_CONTRACT_DATA__MULTI_FACTORS, // policy contract data section inner fields
  POLICY_CONTRACT_DATA__IN_ACC_WITH_FL_CONTACT,
  POLICY_CONTRACT_DATA__OPTION,
  POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER,
  POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER_REQUIRED,
  POLICY_CONTRACT_DATA__EXPORT_TURNOVER,
  POLICY_CONTRACT_DATA__EXPORT_TURNOVER_REQUIRED,
  POLICY_CONTRACT_DATA__COVERABLE_DESCRIPTION,
  POLICY_CONTRACT_DATA__CESSION,
  POLICY_CONTRACT_DATA__SR_DETAILS,
  POLICY_CONTRACT_DATA__MONITORING,
  POLICY_CONTRACT_DATA__GILDA_NUMBER,

  PREMIUM__MIN_PREMIUM_FIXED,
  PREMIUM__PREMIUM_RATE,
  PREMIUM__PREMIUM_FIXED,
  PREMIUM__PREMIUM_INSTALMENTS_DISABLED, // disabling premium instalments
  PREMIUM__SR_DETAILS,
  PREMIUM__AUTOMATIC_INVOICING,

  INDEMNIFICATION__NQL,
  INDEMNIFICATION__EE,
  INDEMNIFICATION__AAD,
  INDEMNIFICATION__LOSS_OCCURING,
  INDEMNIFICATION__CHANGE_IN_RISK,
  INDEMNIFICATION__GLOBAL_LOL,
  INDEMNIFICATION__MIN_RETENTION,

  INSUREDS__SUBINSURED,

  OTHER_CONDITIONS__ADVERSE_INFORMATION_DAYS,
  OTHER_CONDITIONS__REST,
  OTHER_CONDITIONS__PROTRACTED_DEFAULT,
  OTHER_CONDITIONS__POLITICAL_RISK,
  OTHER_CONDITIONS__POLITICAL_RISK_VALUE,

  COUNTRIES__MARKETABLE,
  COUNTRIES__NON_MARKETABLE,

  TREATY, // CR captive product

  BONUS_MALUS__REST,

  COLLECTION_COMPANIES_SECTION,

  RISK_ASSESSMENT, // risk-assessment-section inner section
  RISK_ASSESSMENT__TYPE_BLOCKED, // risk-assessment-section inner field

  TECHNICAL__2ND_COLUMN, // section technical inner fields
  TECHNICAL__HIGHEST_DIFF, // section technical inner fields
  TECHNICAL_DETAILS__CESSION,

  BONUS_MALUS__FIXED_AMOUNT,
  BONUS_MALUS__BONUSES,
  OTHER_CONDITIONS__CLAIM_SPECIAL_CONDITIONS,
  CONTRACT_TERMINATION__EXIT_PENALTY,
  POLICY_CONTRACT_DATA__INSURED_IBAN,
  POLICY_CONTRACT_DATA__INSURED_BIC,
  BONUS_MALUS__MALUS_FIXED_AMOUNT,
  BONUS_MALUS__MALUSES,
  BONUS_MALUS__PROFIT_SHARES,
  OTHER_CONDITIONS__RUN_IN,
  OTHER_CONDITIONS__RUN_OFF,

  OFFER__AAD,
  OFFER__PD,
  OFFER__PROTRACTED_DEFAULT,
  OFFER__MIN_RETENTION,
  OFFER__EE,
  OFFER__RISK_ASSESSMENT,
  OFFER__NQL,
  OFFER__ROL,
  OFFER__GLOBAL_LOL,
  OFFER__GLOBAL_LOL_TYPE,
  OFFER__INSURANCE_COVER,
  OFFER__DISCRETIONARY_COVER_BONUS_MALUS,
  OFFER__DISCRETIONARY_COVER,
  OFFER__FOCUS_PREMIUM,
  OFFER__MAX_PAYMENT_DATE,
  OFFER__DECLARED_TURNOVER,
  BONUS_MALUS__ADDITIONAL_PREMIUM_BUTTON,
  BONUS_MALUS__PREMIUM_RETURN_REQUEST_BUTTON,
  OFFER__SCOPE_OF_COVER,
  NEW_LIMIT_BUTTON,

  SALESFORCE_ID,
}

@Injectable()
export class PolicyContractViewService {
  readonly = false;
  sectionsVisibility: boolean[][] = [];

  reportingPeriodsEditable = false;

  static policyAtLeastActivated(businessStatus: DictionaryBaseDto) {
    return (
      businessStatus &&
      [
        PolicyContractVersionStatus.ACTIVATED,
        PolicyContractVersionStatus.BS_ACTIVE,
        PolicyContractVersionStatus.TERMINATION_ACTIVATED,
        PolicyContractVersionStatus.BS_TERMINATED_CLOSED,
        PolicyContractVersionStatus.BS_OBSOLETE,
        PolicyContractVersionStatus.BS_EXPIRED,
      ].includes(businessStatus.id)
    );
  }

  static policyAtLeastActivatedOrLocked(businessStatus: DictionaryBaseDto) {
    return (
      businessStatus &&
      [
        PolicyContractVersionStatus.ACTIVATED,
        PolicyContractVersionStatus.BS_ACTIVE,
        PolicyContractVersionStatus.TERMINATION_ACTIVATED,
        PolicyContractVersionStatus.BS_TERMINATED_CLOSED,
        PolicyContractVersionStatus.BS_OBSOLETE,
        PolicyContractVersionStatus.BS_EXPIRED,
        PolicyContractVersionStatus.ACTIVATED_LOCKED,
        PolicyContractVersionStatus.BS_ACTIVE_LOCKED,
        PolicyContractVersionStatus.BS_EXPIRED_LOCKED,
      ].includes(businessStatus.id)
    );
  }

  // prettier-ignore
  constructor() {

    // CREDENDO products
    this.sectionsVisibility[PolicyContractType.TOP_UP] = [];
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS] = [];
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER] = [];
    this.sectionsVisibility[PolicyContractType.CAPTIVE] = [];
    this.sectionsVisibility[PolicyContractType.SINGLE_RISK] = [];
    this.sectionsVisibility[PolicyContractType.TURNOVER] = [];

    this.sectionsVisibility[InquiryProductType.TOP_UP] = [];
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS] = [];
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER] = [];
    this.sectionsVisibility[InquiryProductType.CAPTIVE] = [];
    this.sectionsVisibility[InquiryProductType.SINGLE_RISK] = [];
    this.sectionsVisibility[InquiryProductType.TURNOVER] = [];

    // -------------------------------------------------------------------------------------------------------------------------------------
    // ---- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -- CREDENDO -----------
    // -------------------------------------------------------------------------------------------------------------------------------------

    const setForCredendoSingleRiskPolicy = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[PolicyContractType.SINGLE_RISK][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.TURNOVER][sectionId] = visibility;
    };

    const setForCredendoMultiRiskPolicy = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[PolicyContractType.TOP_UP][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][sectionId] = visibility;
      this.sectionsVisibility[PolicyContractType.CAPTIVE][sectionId] = visibility;
    };

    const setForCredendoPolicy = (sectionId: PolicyContractViewSection, visibility = true) => {
      setForCredendoSingleRiskPolicy(sectionId, visibility);
      setForCredendoMultiRiskPolicy(sectionId, visibility);
    };

    const setForCredendoSingleRiskInquiry = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[InquiryProductType.SINGLE_RISK][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.TURNOVER][sectionId] = visibility;
    };

    const setForCredendoMultiRiskInquiry = (sectionId: PolicyContractViewSection, visibility = true) => {
      this.sectionsVisibility[InquiryProductType.TOP_UP][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][sectionId] = visibility;
      this.sectionsVisibility[InquiryProductType.CAPTIVE][sectionId] = visibility;
    };

    const setForCredendoInquiry = (sectionId: PolicyContractViewSection, visibility = true) => {
      setForCredendoSingleRiskInquiry(sectionId, visibility);
      setForCredendoMultiRiskInquiry(sectionId, visibility);
    };

    setForCredendoPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__INSURED_IBAN);
    setForCredendoPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__INSURED_BIC);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__CLAIM_SPECIAL_CONDITIONS);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__RUN_IN);
    setForCredendoPolicy(PolicyContractViewSection.CONTRACT_TERMINATION__EXIT_PENALTY);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__FIXED_AMOUNT);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__BONUSES);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__MALUS_FIXED_AMOUNT);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__MALUSES);
    setForCredendoPolicy(PolicyContractViewSection.BONUS_MALUS__PROFIT_SHARES);
    setForCredendoPolicy(PolicyContractViewSection.COUNTRIES__MARKETABLE, false);
    setForCredendoPolicy(PolicyContractViewSection.COUNTRIES__NON_MARKETABLE, false);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__ADVERSE_INFORMATION_DAYS, false);
    setForCredendoPolicy(PolicyContractViewSection.INDEMNIFICATION__GLOBAL_LOL);
    setForCredendoPolicy(PolicyContractViewSection.PREMIUM__AUTOMATIC_INVOICING);
    setForCredendoPolicy(PolicyContractViewSection.NEW_LIMIT_BUTTON, true);
    setForCredendoPolicy(PolicyContractViewSection.OTHER_CONDITIONS__POLITICAL_RISK_VALUE, false);

    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__MIN_RETENTION, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__NQL, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__EE, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__AAD, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__LOSS_OCCURING, true);
    setForCredendoMultiRiskPolicy(PolicyContractViewSection.INDEMNIFICATION__CHANGE_IN_RISK, true);

    setForCredendoSingleRiskPolicy(PolicyContractViewSection.COLLECTION_COMPANIES_SECTION, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.RISK_ASSESSMENT, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.OTHER_CONDITIONS__REST, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__DOMESTIC_TURNOVER, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__EXPORT_TURNOVER, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.TECHNICAL__2ND_COLUMN, false);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.PREMIUM__SR_DETAILS, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__SR_DETAILS, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__MONITORING, true);
    setForCredendoSingleRiskPolicy(PolicyContractViewSection.POLICY_CONTRACT_DATA__GILDA_NUMBER, true);

    setForCredendoSingleRiskInquiry(PolicyContractViewSection.PREMIUM__SR_DETAILS, true);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT, true);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__AAD, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__MIN_RETENTION, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__EE, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__NQL, false);
    setForCredendoSingleRiskInquiry(PolicyContractViewSection.OFFER__ROL, false);

    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__AAD, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__MIN_RETENTION, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__EE, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__NQL, true);
    setForCredendoMultiRiskInquiry(PolicyContractViewSection.OFFER__ROL, true);

    setForCredendoInquiry(PolicyContractViewSection.OFFER__FOCUS_PREMIUM, true);
    setForCredendoInquiry(PolicyContractViewSection.OFFER__INSURANCE_COVER, true);

    this.sectionsVisibility[PolicyContractType.TOP_UP][PolicyContractViewSection.BASE_INSURERS] = true;
    this.sectionsVisibility[PolicyContractType.TOP_UP][PolicyContractViewSection.POLICY_CONTRACT_DATA__MULTI_FACTORS] = true;
    this.sectionsVisibility[PolicyContractType.TOP_UP][PolicyContractViewSection.TECHNICAL__HIGHEST_DIFF] = true;

    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][PolicyContractViewSection.COLLECTION_COMPANIES_SECTION] = true;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;

    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.COLLECTION_COMPANIES_SECTION] = true;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.RISK_ASSESSMENT] = false;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.RISK_ASSESSMENT__TYPE_BLOCKED] = true;
    this.sectionsVisibility[PolicyContractType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OTHER_CONDITIONS__REST] = false;

    this.sectionsVisibility[PolicyContractType.CAPTIVE][PolicyContractViewSection.TREATY] = true;

    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][PolicyContractViewSection.OFFER__PD] = true;
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OFFER__PD] = true;
    this.sectionsVisibility[InquiryProductType.CAPTIVE][PolicyContractViewSection.OFFER__PD] = true;

    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT] = true;
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT] = true;
    this.sectionsVisibility[InquiryProductType.CAPTIVE][PolicyContractViewSection.OFFER__PROTRACTED_DEFAULT] = true;

    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS][PolicyContractViewSection.OFFER__RISK_ASSESSMENT] = true;
    this.sectionsVisibility[InquiryProductType.EXCESS_OF_LOSS_PARTNER][PolicyContractViewSection.OFFER__RISK_ASSESSMENT] = true;
    this.sectionsVisibility[InquiryProductType.CAPTIVE][PolicyContractViewSection.OFFER__RISK_ASSESSMENT] = true;
  }

  public isSectionVisible(
    typeId: number,
    policyContractViewSection: PolicyContractViewSection,
    defaultVisibility: boolean,
    globalConditionsOfInsuranceId = null
  ) {
    const sv =
      this.sectionsVisibility[typeId] != null ? this.sectionsVisibility[typeId][policyContractViewSection] : null;
    return sv != null ? sv : defaultVisibility;
  }
}
