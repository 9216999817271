import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  BrokerContractVersionCriteriaDto,
  BrokerContractVersionService,
  BrokerContractVersionSimpleDto,
  ComboItem,
  ErrorReason,
  LoggedUserService,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared';
import {BrokerContractGuiService} from './services/broker-contract-gui.service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {ElementaryRight} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'broker-contract-search',
  templateUrl: 'broker-contract-search.component.html',
})
export class BrokerContractSearchComponent extends SearchView implements OnInit {
  @ViewChild(SearchViewComponent) searchView: SearchViewComponent<
    BrokerContractVersionCriteriaDto,
    BrokerContractVersionSimpleDto
  >;
  public dataProvider: SearchDataProvider<BrokerContractVersionCriteriaDto, BrokerContractVersionSimpleDto>;
  public versionPhases: ComboItem[] = [
    new ComboItem('ACTIVE', 'common.currentF'),
    new ComboItem('LAST', 'common.lastF'),
  ];

  constructor(
    public router: RouterService,
    public guiService: BrokerContractGuiService,
    public appService: AppConfigService,
    private brokerContractVersionService: BrokerContractVersionService,
    private loggedUserService: LoggedUserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.dataProvider = this.guiService.dataProvider;
  }

  public userHasBrokerContractCreateRights(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACT_CREATE);
  }

  export() {
    this.dataProvider.inProgress = true;
    this.brokerContractVersionService.export(
      this.dataProvider.searchCriteria.criteria,
      'brokers.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      () => {
        this.searchView.serverErrors = null;
        this.dataProvider.inProgress = false;
        this.exportCompleteCallback();
      }
    );
  }

  private downloadErrorCallback(err: string): void {
    const error = <ErrorReason[]>JSON.parse(err);
    console.log('Broker export error', error);
    this.searchView.serverErrors = error;
    this.dataProvider.inProgress = false;
  }

  private exportCompleteCallback(): void {
    console.log('exportCompleteCallback');
  }

  onContractChange() {
    this.dataProvider.searchCriteria.criteria.bondingContractType = undefined;
  }
}
