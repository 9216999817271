import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AddressDto,
  AppConfigService,
  ATableComponent,
  CacheService,
  CompanyDto,
  CompanyGroupVersionSimpleDto,
  CompanyIdentifierDto,
  CompanyService,
  CompanyShareholderDto,
  CompanySimpleDto,
  ConfirmDialogComponent,
  CustomValidators,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryDto,
  DictionaryService,
  FeatureService,
  GrowlService,
  isErrorReasons,
  LegalEventDto,
  LoggedUserService,
  NumberFormatDto,
  NumberFormatService,
  PhysicalPersonDto,
  RouterService,
  SettableObjectType,
  StringUtils,
  View,
} from '../../bonding_shared';
import {NgForm, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {Button} from '../../bonding_shared/components/details-view/button';
import {AddressTableComponent} from '../bond/components/shared/address-table.component';
import {
  AddressType,
  BusinessObjectType,
  CompanyElementaryRight,
  CompanyIdentifierType,
  CompanyType,
  Country,
  DataOwner,
  ElementaryRight,
  Feature,
  JOINT_VENTURES,
  LegalForm,
  NumberType,
  ServiceContactElementaryRight,
  UserRole,
} from '../../bonding_shared/model/dictionary-ids';
import {forkJoin, Observable} from 'rxjs';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import * as _ from 'lodash';
import {CompanyGuiService} from './services/company-gui.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-details',
  templateUrl: 'company-details.component.html',
})
export class CompanyDetailsComponent extends DetailsView implements OnInit {
  public readonly CompanyIdentifierType = CompanyIdentifierType;
  public readonly CompanyType = CompanyType;
  public readonly BusinessObjectType = BusinessObjectType;
  public readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  public readonly CompanyElementaryRight = CompanyElementaryRight;
  public readonly Country = Country;
  _shareholderList: ATableComponent<CompanyShareholderDto>;
  _registrationAddressTable: AddressTableComponent;
  @ViewChild('updateCompanyTypeConfirm', {static: true}) public confirmDialog: ConfirmDialogComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  public parentCompanySearchCriteria: TextSearchCriteria = <TextSearchCriteria>{};
  public company = <CompanyDto>{address: <AddressDto>{}};
  public hiddenAddressTypeIds: Set<number>;
  public showDuplicateSection = false;
  public registryNumberDisabled = false;
  public legalForms: DictionaryBaseDto[] = [];
  public biIdentifiers: (CompanyIdentifierDto & {label: string})[];
  private companyTypes: DictionaryBaseDto[];
  private viewParams: CompanyViewParams;

  public taxNumberLabel = this.translateService.instant('company.common.taxId');
  public nationalIdLabel = this.translateService.instant('company.common.nationalId');
  public registryNumberLabel = this.translateService.instant('company.common.statId');

  private taxNumberFormatValidatorData: ValidatorData;
  private nationalIdFormatValidatorData: ValidatorData;
  private registryNumberFormatValidatorData: ValidatorData;
  public showCompanyHideInPortal = false;

  set taxNumberFormat(value: NumberFormatDto) {
    this.taxNumberLabel = this.computeLabel(value, 'company.common.taxId');
    if (!this.isPhysicalPerson()) {
      this.taxNumberFormatValidatorData = this.computeValidatorData(value);
    }
  }

  set nationalIdNumberFormat(value: NumberFormatDto) {
    this.nationalIdLabel = this.computeLabel(value, 'company.common.nationalId');
    this.nationalIdFormatValidatorData = this.computeValidatorData(value);
  }

  set registryNumberFormat(value: NumberFormatDto) {
    this.registryNumberLabel = this.computeLabel(value, 'company.common.statId');
    this.registryNumberFormatValidatorData = this.computeValidatorData(value);
  }

  @ViewChild('shareholderList')
  set shareholderList(shareholderList: ATableComponent<CompanyShareholderDto>) {
    this._shareholderList = shareholderList;
  }

  get shareholderList(): ATableComponent<CompanyShareholderDto> {
    return this._shareholderList;
  }

  @ViewChild('registrationAddr')
  set registrationAddressTable(registrationAddressTable: AddressTableComponent) {
    this._registrationAddressTable = registrationAddressTable;
  }

  constructor(
    public routerService: RouterService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    public appService: AppConfigService,
    public companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private numberFormatService: NumberFormatService,
    public loggedUserService: LoggedUserService,
    private dictionaryService: DictionaryService,
    private dictionaryBaseService: DictionaryBaseService,
    private cacheService: CacheService,
    private companyGUIService: CompanyGuiService,
    private featureService: FeatureService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.newVersion2Button = new Button(
      'company.details.markAsDuplicate',
      this.onMarkAsDuplicate.bind(this),
      false,
      false,
      'thumbs-o-down',
      'bon-btn-warning'
    );
    this.selectorNameList = ['duplicateCompany', 'parentCompany', 'Shareholder'];
    this.initializeSelectorEmitters(false);
    this.showCompanyHideInPortal = false;
  }

  static trimWhitespaces(event: ClipboardEvent): string {
    const pastedText = event.clipboardData.getData('text');
    event.stopPropagation();
    event.preventDefault();
    return pastedText.trim();
  }

  public get filteredTypes(): DictionaryBaseDto[] {
    return this.filterOutCurrent(this.companyTypes);
  }

  public get companyRegistrationMode(): boolean {
    return !this.company.id;
  }

  public get companyDetailsEditingMode(): boolean {
    return !this.companyRegistrationMode;
  }

  public get isFarmer(): boolean {
    return this.company.companyType?.id === CompanyType.FARM;
  }

  public isPhysicalPerson(): boolean {
    return this.company.physicalPersonIndicator;
  }

  public get isLegalFormFarm(): boolean {
    return this.company.legalForm?.id === LegalForm.FP010 && this.company.companyType.id === CompanyType.COMPANY;
  }

  public get isLegalFormJointVenture(): boolean {
    return this.company.legalForm && JOINT_VENTURES.includes(this.company.legalForm.id);
  }

  onEdit(): void {
    this.readOnlyMode = false;
    this.handleButtons();
  }

  public get legacyId(): string {
    if (this.company.identifiers) {
      return _(this.company.identifiers)
        .filter((identifier: CompanyIdentifierDto) => identifier.type.id === CompanyIdentifierType.LEGACY_ID)
        .map((identifier: CompanyIdentifierDto) => identifier.identifier)
        .head();
    }
    return null;
  }

  public trimTaxNumberSpaces(event: ClipboardEvent): void {
    this.company.vatNumber = CompanyDetailsComponent.trimWhitespaces(event);
  }

  public trimNationalIdSpaces(event: ClipboardEvent): void {
    this.company.nationalId = CompanyDetailsComponent.trimWhitespaces(event);
  }

  public onLegalFormChange(): void {
    setTimeout(() => {
      this.updateControls();
    }, 0);
  }

  public getGroupName(c: CompanyGroupVersionSimpleDto): string {
    if (!c || !c.companyGroup) {
      return undefined;
    }
    return c.last ? c.name : '';
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.activatedRoute.params.subscribe((params) => this.initializeView(params));
    this.checkElementaryRights();
  }

  public initializeView(params: Params, force?: boolean): void {
    const id: number = +params['id'];
    if (id === this.company.id && !force) {
      return;
    }
    this.readOnlyMode = false;
    this.reloadCompanyTypes();
    this.nationalIdNumberFormat = <NumberFormatDto>{};
    this.taxNumberFormat = <NumberFormatDto>{};
    this.registryNumberFormat = <NumberFormatDto>{};

    this.hiddenAddressTypeIds = new Set<number>();
    this.hiddenAddressTypeIds.add(AddressType.REGISTRATION);
    this.legalForms = undefined;
    if (id > 0) {
      this.companyService.getCompany(id).subscribe({
        next: (company) => {
          this.setCompany(company);
          this.showDuplicateSection = company.duplicateOf !== undefined;
          this.loadLegalForms();
          this.loadPossibleBiSources();
        },
        complete: () => {
          this.updateControls();
          this.updateAddress();
        },
      });
    } else {
      this.activatedRoute.queryParams.subscribe((q: CompanyViewParams) => {
        this.viewParams = q;
        this.company = StringUtils.booleanValue(q.readFromCache)
          ? this.cacheService.companyInCreation
          : BusinessUtils.initializeCompany(+q.typeId, +q.legalFormId);
        this.updateParentCompanySearchCriteria(+q.typeId);
        this.setCreateAndReturnMode();
        this.initFarmer();
        if (!this.company.address || !this.company.address.country) {
          // if country is set the function is called by onSelectedCountry (double call makes wrong legal form shown in combo)
          this.loadLegalForms();
          // if country get biIdentifiers
          this.loadPossibleBiSources();
        }
        this.updateControls();
        this.updateAddress();
        // Set automatically as Manual the Data owner field for company when
        // the company is created manually in Cesar through the "New company" button inside the Company search module
        this.setDataOwner();
      });
    }
  }

  private setDataOwner() {
    this.company.dataOwner = <DictionaryBaseDto>{id: DataOwner.MANUAL};
  }

  initFarmer(): void {
    this.company.physicalPersonIndicator = this.isFarmer;
    this.onAddAddress(this.company.address);
  }

  public onChoosePhysicalPerson(physicalPerson: boolean): void {
    if (physicalPerson) {
      this.company.physicalPerson = <PhysicalPersonDto>{};
      if (this.company.companyType.id !== CompanyType.FARM) {
        this.company.legalForm = undefined;
      }
    } else if (this.company.companyType.id !== CompanyType.FARM) {
      this.company.physicalPerson = null;
    }
    this.updateControls();
  }

  public onSave(): void {
    this.save();
  }

  public onCancel(): void {
    super.onCancel(this.activatedRoute);
  }

  public showSavedMsg(): void {
    this.growlService.notice('Company is saved!');
    this.inProgress = false;
  }

  public showFormError(): void {
    this.growlService.error('The form has errors!');
    this.inProgress = false;
  }

  public onSelectedCountry(country: DictionaryBaseDto): void {
    if (this.company.companyType.id === CompanyType.BRANCH) {
      this.parentCompanySearchCriteria.countryId = country.id;
      this.onSelectParentCompany(undefined);
    }
    this.numberFormatService.getNumberFormat(country.id, NumberType.NATIONALID).subscribe({
      next: (numberFormat) => (this.nationalIdNumberFormat = numberFormat),
      complete: () => this.updateNationalIdValidator(),
    });
    this.numberFormatService.getNumberFormat(country.id, NumberType.TAXID).subscribe({
      next: (numberFormat) => (this.taxNumberFormat = numberFormat),
      error: () => console.log('Number format exception'),
      complete: () => this.updateTaxValidator(),
    });
    this.numberFormatService.getNumberFormat(country.id, NumberType.REGISTRYNUMBER).subscribe({
      next: (numberFormat) => (this.registryNumberFormat = numberFormat),
      complete: () => this.updateRegistryNoValidator(),
    });

    this.loadLegalForms();
    this.updateRegistryNumberControl();
    this.loadPossibleBiSources();
  }

  onSelectedType(type: DictionaryBaseDto): void {
    if (this.isFarmer) {
      this.dictionaryBaseService
        .getDictionaryEntry('Country', Country.PL)
        .subscribe((c) => (this.company.address.country = c));
    }
  }

  onAddAddress(address: AddressDto): void {
    if (this.isFarmer) {
      this.dictionaryBaseService.getDictionaryEntry('Country', Country.PL).subscribe((c) => (address.country = c));
    }
  }

  showFieldForCountry(countryId: number): boolean {
    return this.company?.address?.country?.id === countryId;
  }

  public handleButtons(): void {
    this.saveButton.hidden = this.readOnly;
    this.saveButton.disabled = !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_EDIT);
    this.cancelButton.hidden = this.readOnly;
    this.cancelButton.disabled = !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_EDIT);
    this.newVersion2Button.hidden = this.readOnly || !this.company || !this.company.id || !!this.company.duplicateOf;
    this.editButton.hidden =
      !this.readOnly ||
      (!this.loggedUserService.hasRight(ElementaryRight.SPEC_COMPANY_EDIT) &&
        this.company &&
        this.company.companyType &&
        this.company.companyType.id !== CompanyType.COMPANY) ||
      (!this.loggedUserService.hasRight(ElementaryRight.BUSINESS_UNIT_EDIT) &&
        this.company &&
        this.company.companyType &&
        this.company.companyType.id === CompanyType.BUSINESSUNIT);

    this.editButton.disabled = !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_EDIT);
  }

  public addShareholder(company: CompanySimpleDto): void {
    this.shareholderList.onAddItem(<CompanyShareholderDto>{shareholder: company});
    // Adding shareholder with a-table.addItem works better here then with shareholders.push,
    // because it leaves the item in edit mode and requires user to confirm changes,
    // thus makes user less prone to trying to save company without setting value to new shareholders.
  }

  public onUpdateCompanyType(companyType: DictionaryBaseDto): void {
    this.updateCompanyType(companyType);
  }

  public onSelectParentCompany(parentCompany: CompanySimpleDto): void {
    if (parentCompany.id === this.company.id) {
      this.growlService.error('company.details.cantBeOwnParent');
      return;
    }
    this.company.parentOfBranch = parentCompany;
  }

  public isBank(): boolean {
    return this.company.companyType && this.company.companyType.id === CompanyType.BANK;
  }

  private loadPossibleBiSources(): void {
    const companyRegistrationCountry = this.companyGUIService.getCompanyCountry(this.company);
    if (companyRegistrationCountry) {
      const possibleBI$ = this.companyGUIService.getPossibleBI(companyRegistrationCountry.id);
      const externalId$ = this.dictionaryBaseService.getDictionaryEntry(
        'CompanyIdentifierType',
        CompanyIdentifierType.EXTERNAL_ID,
        this.loggedUserService.getLoggedUserData().language?.id
      );
      forkJoin([possibleBI$, externalId$]).subscribe(([possibleBISources, externalId]) => {
        this.biIdentifiers = _(possibleBISources)
          .map(
            (biSource) =>
              this.company.identifiers.find(
                (ident) => ident.type.id === CompanyIdentifierType.EXTERNAL_ID && ident.biSource?.id === biSource.id
              ) || ({type: externalId, biSource: biSource} as CompanyIdentifierDto)
          )
          .map((biIdentifier) =>
            Object.assign(biIdentifier, {label: biIdentifier.type.name + ' (' + biIdentifier.biSource.name + ')'})
          )
          .value();
      });
    }
  }

  private save(): void {
    this.clearErrors();
    if (!this.form.valid) {
      this.showErrors = true;
      this.showFormError();
      StringUtils.logFormInvalidFields(this.form);
      return;
    }
    if (this.showBankAccountServerErrors()) {
      return;
    }
    // TODO we have on backend preprocessIdentifier
    if (this.company.address.country.id === Country.DE) {
      this.company.statNumber = null;
    }

    this.setTaskCreation();
    this.inProgress = true;
    this.companyService.saveCompany(this.company).subscribe({
      next: (company) => this.afterSave(company),
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  private updateAddress(): void {
    if (this.company.address?.country) {
      setTimeout(() => this.onSelectedCountry(this.company.address.country));
    }
  }

  private loadLegalForms(): void {
    const obs: Observable<DictionaryDto[]> = this.dictionaryService.getDictionary('LegalForm');
    obs.subscribe((allLegalForms) => {
      const farmerLegalFormsIds = [LegalForm.FP010, LegalForm.FP028];

      if (this.company.companyType.id === CompanyType.FARM) {
        this.legalForms = _.filter(allLegalForms, (availableLegalForm) =>
          _.some(farmerLegalFormsIds, (farmerLegalFormId) => farmerLegalFormId === availableLegalForm.id)
        );
        if (!this.company.legalForm) {
          this.company.legalForm = this.legalForms.filter((r) => r.id === farmerLegalFormsIds[0])[0];
        }
      } else {
        this.legalForms = allLegalForms;
      }
    });
  }

  private setCreateAndReturnMode(): void {
    if (this.viewParams && this.viewParams.returnViewId) {
      this.saveButton.name = 'common.button.saveAndReturn';
    } else {
      this.saveButton.name = 'common.button.save';
    }
  }

  private showBankAccountServerErrors(): boolean {
    if (isErrorReasons(this.serverErrors)) {
      for (const reason of this.serverErrors) {
        if (reason.severity === 'ERROR') {
          if (reason.field && reason.field.includes('iban')) {
            this.growlService.error('company.details.errorMessage.incorrectIbanNumber');
            this.inProgress = false;
            return true;
          }
          if (reason.field && reason.field.includes('bic')) {
            this.growlService.error('company.details.errorMessage.incorrectBicNumber');
            this.inProgress = false;
            return true;
          }
        }
      }
    }
    return false;
  }

  private setTaskCreation(): void {
    if (this.viewParams && this.viewParams.returnViewId === View.LIMIT_DETAILS.id) {
      this.company.createTask = true;
    }
  }

  private reloadCompanyTypes(): void {
    this.dictionaryBaseService.getDictionaryBase('CompanyType').subscribe((types) => {
      this.companyTypes = types;
    });
  }

  private afterSave(company: CompanyDto): void {
    this.setCompany(company);
    this.showErrors = false;
    this.inProgress = false;
    this.showSavedMsg();
    this.handleWarnings(company);
    this.redirectAndOptionallyStoreInCache(company);
  }

  private handleWarnings(company: CompanyDto) {
    for (const warning of company.warnings) {
      console.warn('Conpany save', warning);
      this.growlService.warning(warning.message);
    }
  }

  private redirectAndOptionallyStoreInCache(company: CompanyDto): void {
    if (this.viewParams && this.viewParams.returnViewId) {
      if (this.viewParams.settableObject && company) {
        this.cacheService.setObject(+this.viewParams.settableObject, company);
      }
      this.routerService.toCustomView(
        +this.viewParams.returnViewId,
        this.viewParams.readFromCacheOnReturn,
        this.viewParams.returnObjectId ? +this.viewParams.returnObjectId : undefined,
        company.id
      );
    } else {
      this.routerService.toCompanyDetails(this.company.id);
    }
  }

  private updateRegistryNumberControl(): void {
    this.registryNumberDisabled = this.company.address.country.id === Country.DE;
  }

  private updateTaxValidator(): void {
    const vatNumberControl = this.form.controls['vatNumber'];
    if (this.taxNumberFormatValidatorData.validator) {
      const required = !this.isLegalFormFarm && !this.taxNumberFormatValidatorData.allowEmpty;
      vatNumberControl.setValidators(
        _.compact([this.taxNumberFormatValidatorData.validator, required && Validators.required])
      );
    } else {
      vatNumberControl.setValidators([]);
    }
    vatNumberControl.updateValueAndValidity();
  }

  private updateNationalIdValidator(): void {
    const nationalIdControl = this.form.controls['nationalId'];
    if (!nationalIdControl) {
      return;
    }
    if (this.nationalIdFormatValidatorData.validator && this.company.companyType.id !== CompanyType.BRANCH) {
      const required = this.requiredNationalId();
      nationalIdControl.setValidators(
        _.compact([this.nationalIdFormatValidatorData.validator, required && Validators.required])
      );
    } else {
      nationalIdControl.setValidators([]);
    }
    nationalIdControl.updateValueAndValidity();
  }

  private requiredNationalId(): boolean {
    return (
      (!this.company.physicalPersonIndicator || this.isFarmer) &&
      !this.isLegalFormFarm &&
      !this.nationalIdFormatValidatorData.allowEmpty &&
      this.getCountryId() !== Country.IE
    );
  }

  private updateRegistryNoValidator(): void {
    const statNumberControl = this.form.controls['statNumber'];
    statNumberControl.setValidators(this.registryNumberFormatValidatorData.validator);
    statNumberControl.updateValueAndValidity();
  }

  private updateControls(): void {
    if (!this.company) {
      return;
    }
    this.updateNationalIdValidator();
    this.updateTaxValidator();
    this.handleButtons();
  }

  private onMarkAsDuplicate(): void {
    this.showDuplicateSection = true;
    this.openSelectorEmitters['duplicateCompany'].next(true);
    this.newVersion2Button.hidden = true;
  }

  private updateCompanyType(companyType: DictionaryBaseDto): void {
    if (
      companyType.id === CompanyType.FARM ||
      this.company.companyType.id === CompanyType.FARM ||
      (this.company.legalForm &&
        this.company.legalForm.id === LegalForm.FP007 &&
        companyType.id !== CompanyType.COMPANY)
    ) {
      this.company.legalForm = undefined;
    }

    this.company.companyType = companyType;
    this.loadLegalForms();
    this.save();
  }

  private filterOutCurrent(types: DictionaryBaseDto[]): DictionaryBaseDto[] {
    if (this.company && this.company.companyType && this.company.companyType.id && types) {
      return types.filter((t) => t.id !== this.company.companyType.id);
    }
    return types;
  }

  private setCompany(company: CompanyDto): void {
    this.readOnlyMode = true;
    this.handleButtons();
    if (company && !company.legalEvent) {
      company.legalEvent = <LegalEventDto>{};
    }
    if (company.companyType.id === CompanyType.BRANCH) {
      this.parentCompanySearchCriteria.countryId = company.address.country.id;
    }
    this.company = company;
    // show why company status is inconsistent
    if (this.featureService.get(Feature.COMPANY_SHOW_STATUS_VALIDATION)) {
      this.companyService.validateCompanyStatus(company.id).subscribe({
        next: (error) => {
          this.serverErrors = error;
        },
        error: (error) => {
          this.serverErrors = error;
        },
      });
    }
  }

  private updateParentCompanySearchCriteria(companyType: number): void {
    if (companyType === CompanyType.BRANCH) {
      this.parentCompanySearchCriteria.companyType = [CompanyType.COMPANY];
    } else if (companyType === CompanyType.BROKER_BRANCH) {
      this.parentCompanySearchCriteria.companyType = [CompanyType.BROKER];
    }
  }

  get readOnly() {
    return this.readOnlyMode;
  }

  sapNoEditability() {
    return this.readOnly;
  }

  checkElementaryRights() {
    if (
      !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_CREATE) &&
      !this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_UPDATE)
    ) {
      this.saveButton.disabled = true;
    }
  }

  canEdit(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_SPECIAL_CREATE);
  }

  getCountryId(): number {
    return this.company?.address?.country?.id;
  }

  preventSpaces(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  private computeLabel(value: NumberFormatDto, translationKey: string): string {
    return value.numberName
      ? this.translateService.instant(translationKey + 'WithRegionalName', {regionalName: value.numberName})
      : this.translateService.instant(translationKey);
  }

  private computeValidatorData(value: NumberFormatDto): ValidatorData {
    return {
      validator: value.regExp && CustomValidators.numberFormatValidator(value, true),
      allowEmpty: value.regExp && new RegExp(value.regExp).test(''),
    };
  }

  hasLoggedUserSuperUserRole() {
    return this.loggedUserService.hasLoggedUserRole(UserRole.SUPER_USER);
  }
}

export class CompanyViewParams {
  // string when reading
  typeId: number | string;
  legalFormId: number | string;
  returnViewId: number | string;
  settableObject: SettableObjectType | string;
  returnObjectId: number | string;
  readFromCache: boolean | string;
  readFromCacheOnReturn: boolean;
}

export class ValidatorData {
  validator: ValidatorFn;
  allowEmpty: boolean;
}
