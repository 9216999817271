<form #ngForm="ngForm" userOrGroup>
  <div class="bon-card-main-group" *ngIf="task">
    <div class="bon-card-inner">
      <div class="bon-header" translate>task.data.header</div>
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <input-row [(ngModel)]="task.title" [maxlength]="50" name="title"
                     [showErrors]="showErrors" required name="title" [presentationMode]="historyMode" labelKey="task.data.title"></input-row>
          <dict-row labelKey="task.data.type" [(ngModel)]="task.type" name="taskType" dictionary="TaskType" *ngIf="typeEditable"
                    [showErrors]="showErrors" required></dict-row>
          <text-row [presentationMode]="true" labelKey="task.data.type" [value]="task.type.name" *ngIf="!typeEditable"></text-row>
          <dict-row labelKey="task.data.status" [(ngModel)]="task.status" dictionary="TaskStatus" [disabled]="disabled"
                    [ngModelOptions]="{standalone: true}" [presentationMode]="true"></dict-row>
          <ng-container [ngSwitch]="dueDateUnlocked">
            <ng-container *ngSwitchCase="true">
              <div class="bon-row">
                <label class="bon-label" translate>task.data.dueDate</label>
                <date-picker  class="bon-input-size" #dueDateModel="ngModel" [(ngModel)]="task.dueDate" [disabled]="disabled"
                              name="dueDate" [presentationMode]="historyMode"></date-picker>
                <error-message [control]="dueDateModel" [show]="showErrors"></error-message>
              </div>
            </ng-container>
            <div class="bon-row" *ngSwitchDefault>
              <label class="bon-label" translate>task.data.dueDate</label>
              <span>{{ task.dueDate | date }}</span>
            </div>
          </ng-container>
          <checkbox-row labelKey="task.data.important" [presentationMode]="historyMode" [(ngModel)]="task.important" [ngModelOptions]="{standalone: true}"></checkbox-row>
        </div>
        <div class="bon-card-inner" style="width: 40%">
          <dict-row *ngIf="task.id" labelKey="task.data.resolution" nullLabel="-" [(ngModel)]="task.resolution"
                    [disabled]="disabled" dictionary="TaskResolution"
                    [ngModelOptions]="{standalone: true}" [presentationMode]="historyMode"></dict-row>
          <text-row *ngIf="task.id" labelKey="task.data.creationDate" [value]="task.creationDate | date"></text-row>
          <text-row *ngIf="task.closureDate" labelKey="task.data.closureDate" [value]="task.closureDate | date "></text-row>
          <text-row *ngIf="task.id" labelKey="task.data.createdBy" [value]="task.createdBy | username"><</text-row>
          <checkbox-row [presentationMode]="true" *ngIf="task.id" labelKey="task.data.automatic" [(ngModel)]="task.autoGenerated"
              [ngModelOptions]="{standalone: true}"></checkbox-row>
        </div>
      </div>
      <div class="bon-card-group" style="padding-top: 15px;">
        <form-row labelKey="task.data.description"></form-row>
        <div class="bon-row">
          <textarea [(ngModel)]='task.description' rows="3" cols="100%" [disabled]="historyMode || disabled" class="bon-input-size"
                    style="border:1px solid #ccc; width: 100%;"
                    [ngModelOptions]="{standalone: true}">
          </textarea>
        </div>
      </div>
    </div>
    <div class="bon-card-inner" style="width: 35%">
      <div class="bon-header" translate>task.data.assignedTo</div>
      <div class="bon-card-group">
        <form-row labelKey="task.data.businessUnit">
          <business-unit-id-selector #buModel="ngModel"  class="bon-input-size" required [userDefaultBU]="false"
                                     [nullLabel]="" [(ngModel)]="task.businessUnit" name="businessUnit"
                                     [readonly]="!assignmentUnlocked || historyMode" (changeItem)="onBUChange($event)">
          </business-unit-id-selector>
          <error-message [control]="buModel" [show]="showErrors"></error-message>
        </form-row>
        <form-row labelKey="task.data.user"  [showErrors]="showErrors">
          <item-combo class="bon-input-size" [items]="usersForBu" [(ngModel)]="task.user" name="user"
                      [presentationMode]="!assignmentUnlocked || historyMode"
                      nullLabel=" ">
            <ng-template let-item="item">{{item | username}}</ng-template>
          </item-combo>
        </form-row>
        <form-row labelKey="task.data.group">
          <item-combo class="bon-input-size" [items]="groupsForBu" [(ngModel)]="task.userGroup" nullLabel=" "
                      name="userGroup" [presentationMode]="!assignmentUnlocked || historyMode">
            <ng-template let-item="item">{{item.name}}</ng-template>
          </item-combo>
          <span class="inline invalid" *ngIf="ngForm.form.hasError('missingAssignee') && showErrors" translate>task.data.missingAssignee</span>
        </form-row>
      </div>
    </div>
  </div>
  <div class="bon-header" *ngIf="!disabled">
    <div translate>task.data.linkedObject</div>
    <div *ngIf="boRequired && !task.businessObject?.relatedToId && showErrors" class="invalid" translate>errorMessage.required</div>
  </div>
  <div class="bon-card-group" *ngIf="!disabled">
    <div class="bon-card-inner">
      <business-object-selector [(ngModel)]="task.businessObject"
                                name="businessObject" [codeRegExp]="relatedToRegExp"
                                [disabled]="historyMode">
      </business-object-selector>
    </div>
    <div class="bon-card-inner">
      <div *ngIf="!disabled && task.contactNote?.id > 0">
        <b class="a-link" style="margin-left: 20px" (click)="routerService.toContactNoteDetails(task.contactNote.id)" translate>task.data.contactNote</b>
      </div>
    </div>
  </div>

</form>
