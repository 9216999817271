import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppConfigService, CompanyService, LoggedUserService} from '../../../../bonding_shared/services';
import {CompanySimpleDto} from '../../../../bonding_shared/model';
import {CompanySelectorAbstractComponent} from '../../../../bonding_shared/components/company-selector/company-selector-abstract.component';

/**
 * Created by wilk on 15.05.2017.
 */

@Component({
  selector: 'company-selector-simple',
  templateUrl: './company-selector-simple.component.pug',
})
export class CompanySelectorSimpleComponent extends CompanySelectorAbstractComponent<
  CompanySimpleDto,
  CompanySimpleDto
> {
  @Input() items: CompanySimpleDto[];
  @Input() limitBuyerMasterPolicyContractId: number;
  @Input() showNewCompanyLink = false;
  @Input() showPhysicalPersonSearchCriteria = false;
  @Input() showInternalNumber = false;
  @Output() createCompanyClick = new EventEmitter<void>();
  @Input() showGroup = false;

  constructor(
    service: CompanyService,
    public appConfigService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {
    super(service, appConfigService);
  }

  onOnlyLimitBuyersForMasterPolicyClick(checked: boolean) {
    this.dataProvider.searchCriteria.criteria.isLimitBuyerForMasterPolicyId = checked
      ? this.limitBuyerMasterPolicyContractId
      : undefined;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }
}
