export class AppConfig {
  apiConfig: ApiConfig;
  appClientConfig: 'credendo';
  performInitialSearch?: boolean;
  oauth2?: boolean;
}

export class ApiConfig {
  selectedProfile: string;
  profiles: ApiConfigProfile[];
}

export class ApiConfigProfile {
  directBackendUrl: string;
  backendUrlPrefix: string;
  backendUrlInfix: string;
  backendUrlSuffix: string;
  themeColour: string;
  displayName: string;
  bondCertLink: string;
  envCode: 'DEV' | 'DEMO' | 'UAT' | 'PROD';
  helpLink: string;
  useSuffix: boolean;
}
