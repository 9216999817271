import {Injectable} from '@angular/core';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConfigService, LoggedUserService} from '../../../bonding_shared/services';
import {ElementaryRight, UserRole} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class UserAccessGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router, public apiService: AppConfigService) {
    super(loggedUserService, router);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
