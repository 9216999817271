import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {
  AmountDto,
  AppConfigService,
  ComboItem,
  CompanyDto,
  CompanyIdentifierDto,
  CompanyRatingDto,
  CompanyRatingService,
  CompanyRatingVersionDto,
  CompanyResponsiblePersonDto,
  CompanyService,
  DictionaryBaseDto,
  DictionaryBaseService,
  GrowlService,
  LoggedUserService,
  ManualRatingCalculationResultDto,
  RouterService,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BusinessObjectType,
  CompanyElementaryRight,
  CompanyIdentifierType,
  CompanyResponsiblePersonRole,
  CompanyType,
  DocumentType,
  Rating,
  RatingCategory,
  RatingSource,
  RatingStatus,
  RatingType,
  RepoDocumentElementaryRight,
  ScreeningCategory,
  ScreeningFinding,
  ScreeningResult,
} from '../../bonding_shared/model/dictionary-ids';
import * as _ from 'lodash';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-rating',
  templateUrl: './company-rating.component.pug',
})
export class CompanyRatingComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  readonly RatingStatus = RatingStatus;
  public ratingVersion: CompanyRatingVersionDto;
  public ratingVersions: CompanyRatingVersionDto[];
  public company: CompanyDto;
  public ratingType: DictionaryBaseDto;
  public ratingCategoryId: number;
  public companyId: number;
  public ratingStatuses: DictionaryBaseDto[] = [];
  public weightItems: ComboItem[];
  public pointItems: ComboItem[];
  public templates: TemplateSimpleDto[] = [];
  public ratingReadOnlyMode = false;
  public checkedCompetency = true;
  private readonly newVersionButtonLabelKey = 'company.rating.newRating';

  constructor(
    private route: ActivatedRoute,
    private ratingService: CompanyRatingService,
    public appService: AppConfigService,
    private dictionaryBaseService: DictionaryBaseService,
    private loggedUserService: LoggedUserService,
    private companyService: CompanyService,
    public router: RouterService,
    private templateService: TemplateService,
    protected translateService: TranslateService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.name = this.newVersionButtonLabelKey;
    this.weightItems = CompanyRatingComponent.createComboItems(0, 1, 0.05, 2);
    this.pointItems = CompanyRatingComponent.createComboItems(1, 10, 1, 0);
  }

  readonly BusinessObjectType = BusinessObjectType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  private static createComboItems(
    startValue: number,
    maxValue: number,
    step: number,
    fractionDigits: number
  ): ComboItem[] {
    return _(_.range(startValue, maxValue + step, step))
      .map((nextValue) => nextValue.toFixed(fractionDigits))
      .map((nextValue) => new ComboItem(nextValue.toString(), nextValue.toString()))
      .value();
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get insuranceSTMLTRating(): boolean {
    return this.ratingType?.id === RatingType.ST_INSURANCE || this.ratingType?.id === RatingType.MLT_INSURANCE;
  }

  public get insuranceMLTRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.MLT_INSURANCE;
  }

  public get gRCRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.GRC;
  }

  public get kycRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.KYC;
  }

  public get environmentalRating(): boolean {
    return this.ratingType && this.ratingType.id === RatingType.ENVIRONMENTAL;
  }

  public get sandpRating(): boolean {
    return this.ratingType?.id === RatingType.S_AND_P;
  }

  public get fitchRating(): boolean {
    return this.ratingType?.id === RatingType.FITCH;
  }

  public get moodysRating(): boolean {
    return this.ratingType?.id === RatingType.MOODY_S;
  }

  public get ccCategoryRating(): boolean {
    return this.ratingType?.id === RatingType.CC_CATEGORY;
  }

  public get ratingInactive(): boolean {
    return this.ratingVersion?.status && this.ratingVersion.status.id === RatingStatus.INACTIVE;
  }

  public get ratingInAcceptance(): boolean {
    return this.ratingVersion?.status?.id === RatingStatus.IN_ACCEPTANCE;
  }

  public get ratingHasId(): boolean {
    return this.ratingVersion && this.ratingVersion.id && this.ratingVersion.id > 0;
  }

  public get inCreateMode(): boolean {
    return !this.ratingHasId;
  }

  public get companyIsGroupHead(): boolean {
    return this.company && this.company.groupHead;
  }

  public get farmerCompany(): boolean {
    return this.company?.companyType && this.company.companyType.id === CompanyType.FARM;
  }

  public get responsiblePerson(): string {
    if (this.company?.responsiblePersons) {
      return _(this.company.responsiblePersons)
        .filter((responsiblePerson: CompanyResponsiblePersonDto) => {
          if (
            this.ratingVersion?.ratingType?.id === RatingType.BONDING ||
            this.ratingVersion?.ratingType?.id === RatingType.GSP
          ) {
            return responsiblePerson.role.id === CompanyResponsiblePersonRole.BONDING_MANAGER;
          } else {
            return responsiblePerson.role.id === CompanyResponsiblePersonRole.UNDERWRITER;
          }
        })
        .map((responsiblePerson: CompanyResponsiblePersonDto) => responsiblePerson.user.fullName)
        .head();
    }
    return null;
  }

  public get documentTypes(): RatingDocument[] {
    if (this.ratingType && this.ratingType.id) {
      return _(RATING_DOCUMENT_TYPES)
        .filter((ratingDocument: RatingDocument) =>
          _.some(ratingDocument.forRatingTypes, (ratingType: RatingType) => ratingType === this.ratingType.id)
        )
        .value();
    }
    return null;
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public initializeView(params: Params): void {
    this.objectNotFound = false;
    this.serverErrors = undefined;
    this.companyId = +params['id'];
    this.ratingCategoryId = +params['ratingCategoryId'];
    this.ratingType = <DictionaryBaseDto>{id: +params['ratingType']};
    this.initRatingType();
  }

  private initData() {
    this.ratingService.getRatingStatuses().subscribe({
      next: (statuses) => (this.ratingStatuses = statuses),
      error: (error) => this.handleServerError(error),
    });

    this.getCompanyData(this.companyId);
    this.route.queryParams.subscribe((queryParam) => {
      if (queryParam['calculate'] === 'true') {
        this.calculateRating(this.companyId);
      } else {
        this.getRating(this.companyId, true);
      }
    });

    this.loadTemplates();

    console.log('6 RATING TYPE ID = ' + this.ratingType?.id);
  }

  private getCompanyData(companyId: number) {
    this.companyService.getCompany(companyId).subscribe({
      next: (company: CompanyDto) => (this.company = company),
      error: (error) => this.handleServerError(error),
    });
  }

  initRatingType(): void {
    if (this.ratingType?.id) {
      this.dictionaryBaseService
        .getDictionaryEntry('RatingType', this.ratingType.id, undefined, 'RatingScale')
        .subscribe((ratingType) => {
          this.ratingType = ratingType;
          this.initData();
        });
    } else {
      this.ratingService.getRatingType(this.ratingCategoryId).subscribe((t) => {
        this.ratingType = t;
        // for add rating type and show responsible person for this type
        this.initData();
      });
    }
  }

  public onSave(): void {
    this.serverErrors = undefined;

    if (!this.ratingVersion.rating) {
      this.recalculateManualRating(true);
    }

    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.ratingVersion.ratingType = this.ratingType;
    this.ratingVersion.source = <DictionaryBaseDto>{id: RatingSource.UW};
    if (this.ratingHasId && (this.ratingInactive || this.ratingVersion.acceptor)) {
      this.update();
    } else {
      this.create();
    }
  }

  public onCreateNewVersion(): void {
    this.newVersionButton.disabled = true;
    if (this.ratingHasId) {
      this.ratingService
        .newRatingVersion(this.ratingVersion.parent.company.id, this.ratingType.id)
        .subscribe((rating) => {
          this.setRatingVersion(rating);
          this.handleButtons();
        });
    }
    this.ratingReadOnlyMode = false;
  }

  public onCancel(): void {
    super.onCancel();
    this.getRating(this.ratingVersion.parent.company.id, false);
    if (!this.ratingHasId) {
      this.ratingReadOnlyMode = true;
    }
    this.newVersionButton.disabled = false;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public calculateRating(companyId: number): void {
    this.calculateRatingForType(companyId, this.ratingType.id);
  }

  public ratingChanged(rating: DictionaryBaseDto): void {
    if (rating) {
      if (rating.id === Rating.X) {
        this.ratingVersion.liability = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.singleBondLimit = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.bondingFacilityLimit = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.singleLimit = <AmountDto>{value: 0, valueInRefCurrency: 0};
        this.ratingVersion.validTo = undefined;
        this.ratingVersion.nextReviewDate = undefined;
      }
    }
  }

  public onSelectVersion(selectedCompanyRatingVersion: CompanyRatingVersionDto): void {
    this.ratingVersion = selectedCompanyRatingVersion;
    this.handleButtons();
  }

  public editionDisabled(): boolean {
    const v =
      (this.credendo && this.ratingCategoryId === RatingCategory.AUTO) ||
      !this.hasEditRight() ||
      !this.hasCreateRight() ||
      (this.ratingHasId && this.ratingVersion.status && !this.ratingInactive) ||
      !this.ratingVersion.last;
    return v;
  }

  public acceptingBodyEditionDisabled(): boolean {
    return this.ratingEditionDisabled() || !!this.ratingVersion.acceptedBy;
  }

  public financialInformationDisabled(): boolean {
    return this.editionDisabled() && !!this.ratingVersion.acceptedBy;
  }

  public grcPartsDisabled(): boolean {
    return (
      this.editionDisabled() ||
      (this.ratingVersion?.grcRatingPart?.screeningResult &&
        this.ratingVersion.grcRatingPart.screeningResult.id === ScreeningResult.NO_MATCH)
    );
  }

  showSingleBondLimit(): boolean {
    return this.credendo && !(this.ratingType?.id === RatingType.ST_INSURANCE);
  }

  public showBondingFacilityLimit(): boolean {
    return this.credendo && !this.insuranceSTMLTRating;
  }

  public ratingEditionDisabled(): boolean {
    return this.editionDisabled();
  }

  public canSendProposal(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_MANAGEMENT_PROPOSAL_KRU_KPUE);
  }

  public recalculateManualRating(onSave = false): void {
    this.ratingService.calculateManualRating(this.ratingVersion).subscribe({
      next: (ratingCalculation: ManualRatingCalculationResultDto) =>
        this.onNewManualRationRecalculation(ratingCalculation, onSave),
      error: (error) => this.handleServerError(error),
    });
  }

  public ratingVersionsExists(): boolean {
    return this.ratingVersions && this.ratingVersions.length >= 1 && !!this.ratingVersions[0].id;
  }

  private onNewManualRationRecalculation(ratingCalculation: ManualRatingCalculationResultDto, onSave: boolean): void {
    this.ratingVersion.rating = ratingCalculation.rating;
    this.ratingVersion.ratingAssesed = ratingCalculation.ratingAssesed;
    this.ratingVersion.ratingCorrected = ratingCalculation.ratingCorrected;
    if (!onSave && DictionaryUtils.in(this.ratingVersion.rating)) {
      this.ratingVersion.liability.value = 0;
      this.ratingVersion.singleLimit.value = 0;
    }
  }

  // TODO: check if there is configuration of document types for offer in DB - templates must be defined
  private loadTemplates(): void {
    if (this.ratingType && this.ratingType.id) {
      const dictionarySelectors: DictionaryBaseDto[] = [];
      dictionarySelectors.push(this.ratingType);
      this.loadTemplatesByType(DocumentType.APP_RISK_ASSESSMENT, BusinessObjectType.COMPANY, dictionarySelectors);
    }
  }

  private loadTemplatesByType(
    type: DocumentType,
    boType: BusinessObjectType,
    dictionarySelectors: DictionaryBaseDto[]
  ): void {
    this.templateService.findByType(type, boType, null, dictionarySelectors).subscribe((result) => {
      if (result !== undefined && result !== null) {
        result.forEach((r) => this.templates.push(r));
        // information about templates are return singly (not in one call). Sort the result so that they are always in the same order
        this.templates.sort((a, b) => a.id - b.id);
      }
    });
  }

  private hasCreateRight(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_CREATE);
  }

  private hasEditRight(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_RATING_EDIT);
  }

  private calculateRatingForType(companyId: number, ratingTypeId: number): void {
    this.ratingService.calculate(companyId, ratingTypeId).subscribe(
      () => this.afterCompanyRatingSaved(companyId),
      (error) => this.handleServerError(error)
    );
  }

  private afterCompanyRatingSaved(companyId: number): void {
    this.afterObjectSaved();
    this.getRating(companyId, true);
  }

  private getRating(companyId: number, initial: boolean): void {
    this.ratingService.getCompanyRating(companyId, this.ratingCategoryId, this.ratingType?.id).subscribe({
      next: (rating) => {
        this.setRating(rating, initial);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  private setRating(rating: CompanyRatingDto, initial: boolean): void {
    if (initial) {
      this.ratingVersion = rating.versions.reduce(
        (max, v) => (v.versionNumber > max.versionNumber ? v : max),
        rating.versions[0]
      );
    }
    if (this.inCreateMode) {
      this.dictionaryBaseService
        .getDictionaryEntry('RatingStatus', RatingStatus.ACTIVE)
        .subscribe((status: DictionaryBaseDto) => {
          if (!this.ratingVersion.status) {
            this.ratingVersion.status = status;
          }
        });
    }
    this.ratingVersions = rating.versions;
    this.handleButtons();
  }

  private setRatingVersion(rating: CompanyRatingVersionDto): void {
    this.ratingVersion = rating;
  }

  protected handleButtons(): void {
    this.saveButton.disabled = this.editionDisabled() || !this.hasEditRight();
    this.newVersionButton.disabled = this.ratingCategoryId === RatingCategory.AUTO || !this.hasCreateRight();
    this.cancelButton.disabled = this.editionDisabled() || !this.hasEditRight() || !this.ratingVersion.last;
    this.hideButtons(false);
  }

  private create(): void {
    this.ratingService.createRatingVersion(this.ratingVersion).subscribe({
      next: (version: CompanyRatingVersionDto) => {
        this.afterCompanyRatingSaved(version.parent.company.id);
        if (version.warnings) {
          this.handleServerError(version.warnings, true);
        }
      },
      error: (error) => {
        this.checkedCompetency = this.ratingVersion.hasCompetency;
        this.handleServerError(error);
      },
    });
  }

  private update(): void {
    this.ratingService.updateRatingVersion(this.ratingVersion).subscribe({
      next: (v) => this.afterCompanyRatingSaved(this.ratingVersion.parent.company.id),
      error: (error) => {
        this.handleServerError(error);
        this.getRating(this.ratingVersion.parent.company.id, false);
      },
    });
  }

  screeningResultChanged(screeningResult: DictionaryBaseDto) {
    if (screeningResult.id === ScreeningResult.NO_MATCH) {
      this.ratingVersion.grcRatingPart.screeningFindings = [<DictionaryBaseDto>{id: ScreeningFinding.NONE}];
      this.ratingVersion.grcRatingPart.screeningFindingDetails = [];
      this.ratingVersion.grcRatingPart.screeningCategories = [<DictionaryBaseDto>{id: ScreeningCategory.NONE}];
    } else {
      this.ratingVersion.grcRatingPart.screeningFindings = [];
      this.ratingVersion.grcRatingPart.screeningFindingDetails = [];
      this.ratingVersion.grcRatingPart.screeningCategories = [];
    }
  }

  credendoRatingDepartmentAccessible(): boolean {
    return this.credendo && this.ratingType?.id === RatingType.CREDIT_INSURANCE;
  }

  ratingValueRequired(): boolean {
    return !this.insuranceMLTRating;
  }

  showDiscrtionaryRating(): boolean {
    return this.credendo && this.ratingType && this.ratingType.id === RatingType.CREDIT_INSURANCE;
  }

  showRating(): boolean {
    return !this.showDiscrtionaryRating();
  }

  get MDGNumber(): string {
    if (this.company.identifiers) {
      return _(this.company.identifiers)
        .filter((identifier: CompanyIdentifierDto) => identifier.type.id === CompanyIdentifierType.MDG_NUMBER)
        .map((identifier: CompanyIdentifierDto) => identifier.identifier)
        .head();
    }
    return null;
  }
}

class RatingDocument {
  constructor(public name: string, public forRatingTypes: RatingType[]) {}
}

const RATING_DOCUMENT_TYPES: RatingDocument[] = [
  new RatingDocument('company.rating.KRUInquiry', [
    RatingType.ST_INSURANCE,
    RatingType.MLT_INSURANCE,
    RatingType.BONDING,
    RatingType.GSP,
  ]),
  new RatingDocument('company.rating.managementInquiry', [
    RatingType.ST_INSURANCE,
    RatingType.MLT_INSURANCE,
    RatingType.BONDING,
  ]),
  new RatingDocument('company.rating.KPUECreditInquiry', [RatingType.ST_INSURANCE, RatingType.MLT_INSURANCE]),
  new RatingDocument('company.rating.KPUEBondingInquiry', [RatingType.GSP]),
  new RatingDocument('company.rating.applicantRiskAssessment', [RatingType.GSP]),
];
