import {Component} from '@angular/core';
import {ClaimDocumentDto, ClaimDocumentsDto, ClaimSimpleDto, DictionaryDto} from '../../bonding_shared/model';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  ClaimService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {ClaimDocumentsService} from '../../bonding_shared/services/claim-documents.service';
import {ValidatorFn, Validators} from '@angular/forms';
import {ClaimDocumentStatus, ClaimElementaryRight, DictionaryProperty} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'claim-documents',
  templateUrl: 'claim-documents.component.pug',
})
export class ClaimDocumentsComponent extends AuditedViewAbstract<ClaimDocumentsDto> {
  claim = <ClaimSimpleDto>{};
  questionRows: {[key: string]: number} = {};
  questionRowOrdinals: {[key: string]: number} = {};
  questionReferences: {[key: string]: number} = {};
  questionDescriptions: {[key: string]: number} = {};
  readonly ClaimElementaryRight = ClaimElementaryRight;

  constructor(
    route: ActivatedRoute,
    service: ClaimDocumentsService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    private claimService: ClaimService,
    private dictionaryService: DictionaryService
  ) {
    super(route, service, router, translateService, growlService, loggedUserService, 'parentId');
  }

  getReceiveDateValidator(x: ClaimDocumentDto): ValidatorFn {
    return (control) =>
      x && x.status && ClaimDocumentStatus.RECEIVE_DATE_REQUIRED.includes(x.status.id) && Validators.required(control);
  }

  extraInitialization() {
    super.extraInitialization();
    this.claimService.getClaimSimple(this.id).subscribe((c) => (this.claim = c));
    this.dictionaryService.getDictionary('ClaimDocumentsLetterQuestion').subscribe((q) => {
      this.questionRows = this.buildLabelsFromProperty(q, DictionaryProperty.CLAIM_DOCUMENT_LETTER_ANSWER_ROW_NUMBER);
      this.questionRowOrdinals = this.buildLabelsFromProperty(
        q,
        DictionaryProperty.CLAIM_DOCUMENT_LETTER_ANSWER_ORDINAL_NUMBER
      );
      this.questionReferences = this.buildLabelsFromProperty(
        q,
        DictionaryProperty.CLAIM_DOCUMENT_LETTER_ANSWER_REFERENCE
      );
      this.questionDescriptions = q.reduce(function (map, entry) {
        map[entry.id] = entry.name;
        return map;
      }, {});
    });
  }

  private buildLabelsFromProperty(questionsDict: DictionaryDto[], propertyId: number) {
    return questionsDict.reduce(function (map, entry) {
      map[entry.id] = entry.properties[propertyId];
      return map;
    }, {});
  }
}
