import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  BackendError,
  BrokerContractStatsDto,
  BrokerContractVersionDto,
  BrokerContractVersionService,
  ConfirmDialogComponent,
  DictionaryBaseDto,
  DocumentService,
  LoggedUserService,
  RouterService,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {
  BrokerContractStatus,
  BusinessObjectType,
  ContactNoteElementaryRight,
  DocumentType,
  ElementaryRight,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'broker-contract-preview',
  templateUrl: './broker-contract-preview.component.pug',
})
export class BrokerContractPreviewComponent implements OnInit {
  brokerContractVersion: BrokerContractVersionDto;
  brokerContractId: number;
  brokerContractStats: BrokerContractStatsDto = <BrokerContractStatsDto>{};
  serverErrors: BackendError;
  @ViewChild('confirmDialog', {static: true}) public confirmDialog: ConfirmDialogComponent;
  readonly BusinessObjectType = BusinessObjectType;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  constructor(
    private brokerContractService: BrokerContractVersionService,
    private route: ActivatedRoute,
    public router: RouterService,
    public appService: AppConfigService,
    private templateService: TemplateService,
    private documentService: DocumentService,
    private loggedUserService: LoggedUserService
  ) {}

  templates: TemplateSimpleDto[] = [];

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const versionId = +params['versionId'];
    const id = +params['brokerContractId'];
    if (versionId > 0) {
      this.loadVersion(versionId, true);
    } else {
      this.loadContract(id, true);
    }
  }

  initializeTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    if (this.brokerContractVersion.brokerContract.brokerType) {
      dictionarySelectors.push(this.brokerContractVersion.brokerContract.brokerType);
    }
    if (this.brokerContractVersion.brokerContract.company.legalForm) {
      dictionarySelectors.push(this.brokerContractVersion.brokerContract.company.legalForm);
    }
    this.templateService
      .findByType(DocumentType.BROKER_CONTRACT, BusinessObjectType.BROKER_CONTRACT_VERSION, null, dictionarySelectors)
      .subscribe((result) => (this.templates = result));
  }

  public isBrokerContractActive(): boolean {
    return (
      this.brokerContractVersion &&
      this.brokerContractVersion.status &&
      this.brokerContractVersion.status.id === BrokerContractStatus.ACTIVE
    );
  }

  public isBrokerContractClosed(): boolean {
    return (
      this.brokerContractVersion &&
      this.brokerContractVersion.status &&
      this.brokerContractVersion.status.id === BrokerContractStatus.CLOSED
    );
  }

  public isBrokerContractTerminated(): boolean {
    return (
      this.brokerContractVersion &&
      this.brokerContractVersion.status &&
      this.brokerContractVersion.status.id === BrokerContractStatus.TERMINATED
    );
  }

  public addPolicyButtonVisible(): boolean {
    return (
      this.isBrokerContractActive() &&
      this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_POLICY) &&
      this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE)
    );
  }

  public addContractButtonVisible(): boolean {
    return (
      this.isBrokerContractActive() &&
      this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE) &&
      (this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_INDIVIDUAL_CONTRACT) ||
        this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_GENERAL_CONTRACT))
    );
  }

  public userHasUpdateRights(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACT_UPDATE);
  }

  public newDocument(template: TemplateSimpleDto): void {
    this.router.toDocumentDetailsNew(template.id, this.brokerContractVersion.id);
  }

  public generateDocument(template: TemplateSimpleDto): void {
    this.documentService.generatePdf(
      template.id,
      this.brokerContractVersion.id,
      template.name + '.' + this.brokerContractVersion.id + '.pdf'
    );
  }

  private loadVersion(versionId: number, loadContract: boolean): void {
    this.brokerContractService.getById<BrokerContractVersionDto>(versionId).subscribe((bv) => {
      this.brokerContractVersion = bv;
      if (loadContract) {
        this.loadContract(this.brokerContractVersion.brokerContract.id, false);
      }
      this.initializeTemplates();
    });
  }

  private loadContract(brokerContractId: number, loadVersion: boolean): void {
    this.brokerContractService.getBrokerContract(brokerContractId).subscribe((bv) => {
      this.brokerContractId = bv.id;
      this.loadStatistics();
      if (loadVersion) {
        this.loadVersion(bv.versions[bv.versions.length - 1].id, false);
      }
    });
  }

  private loadStatistics(): void {
    this.brokerContractService.getStats(this.brokerContractId).subscribe((stats) => (this.brokerContractStats = stats));
  }
}
