import {InquiryProductType} from '../../../bonding_shared/model/dictionary-ids';

export function getInquiryVisibility(inqType: number, portalMode: boolean) {
  console.assert(!!inqType, 'getInquiryVisibility type empty');
  const v: any = {};

  v.selfRetention = false;
  v.insurableTurnover = false;
  v.countries = true;
  v.buyers = false;
  v.receivables = false;
  // Na WoP OP / SP / FK / FE / EP
  v.currentInsurer = false;
  v.lastBalances = false;

  v.countriesSumDomestic = false;
  v.countriesSumExport = false;
  v.countriesSumLastYear = false;
  v.domesticInsurable = false;
  v.exportInsurable = false;
  v.policyValidFrom = false;
  v.falconLimitAllowed = false;
  v.divisionIntoFactorers = false;

  v.subinsureds = true;

  v.statements = {};

  v.statements.consenForSharingInformation = false;

  v.statements.paperInvoice = false;

  v.ratedLimits = false;

  v.debtorBuyerUltimateUserSection = false;
  v.transactionPartiesSection = false;
  v.exportersSection = false;
  v.guarantorsSection = false;

  v.limitFeesSection = false;

  return v;
}
