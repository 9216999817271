import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  BondPaymentDto,
  ContractVersionDto,
  DictionaryBaseDto,
  DictionaryDto,
  SearchCriteria,
  TemplateDto,
  TreatyBaseDto,
  TreatyVersionCriteriaDto,
  TreatyVersionSimpleDto,
} from '../../../bonding_shared/model/dtos';
import {BondGuiService} from '../services/bond-gui.service';
import {
  AppProperty,
  BondInvoicingSchema,
  BondStatus,
  BondSubType,
  ContractType,
  ReinsuranceType,
  ReinsuranceMode,
} from '../../../bonding_shared/model/dictionary-ids';
import {DictionaryService} from '../../../bonding_shared/services/dictionary.service';
import {Country} from '../../../bonding_shared/model/country';
import {BondVersionDto, BondVersionSimpleDto} from '../../../bonding_shared/model';
import {RepositoryService} from '../../../bonding_shared/services/repository.service';
import {BondCustomFieldsComponent} from './shared/bond-custom-fields.component';
import {DocumentListComponent} from '../../document/components';
import {
  AppConfigService,
  PropertyService,
  TemplateService,
  TreatyVersionService,
} from '../../../bonding_shared/services';
import {CurrencyExchangeRateService} from '../../../bonding_shared/services/currency-exchange-rate.service';
import {BondValueListComponent} from './bond-value-list.component';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {BondPaymentListComponent} from './bond-payment-list.component';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';

@Component({
  selector: 'bond-form',
  templateUrl: 'bond-form.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondFormComponent implements OnInit {
  self = this;
  public _bond: BondVersionDto;
  private deliveryTypes: {[key: string]: DictionaryDto} = {};
  private customTemplate: TemplateDto;
  public originalSubtypeCustomTemplate: DictionaryBaseDto;

  readonly BondStatus = BondStatus;
  readonly BondInvoicingSchema = BondInvoicingSchema;
  readonly DictionaryUtils = DictionaryUtils;
  treaties: TreatyBaseDto[] = [];
  readonly SYSTEM_CURRENCY_CODE = this.propertyService.properties[AppProperty.SYSTEM_CURRENCY];

  @ViewChild('bondCustomFieldsComponent') bondCustomFieldsComponent: BondCustomFieldsComponent;
  @ViewChild('documentLists', {static: true}) documentListComponent: DocumentListComponent;

  @ViewChild('bondMultiValuesTable', {static: true}) bondMultiValuesTable: BondValueListComponent;
  @ViewChild('bondSupplementaryValuesTable', {static: true}) bondSupplementaryValuesTable: BondValueListComponent;
  @ViewChild('bondPaymentsTable', {static: true}) bondPaymentsTable: BondPaymentListComponent;

  @Input() set bond(bond: BondVersionDto) {
    this._bond = bond;
  }

  get bond() {
    return this._bond;
  }

  @Input() showDetails = false;
  @Input() showErrors = false;
  @Input() isBooster: Function;
  @Input() loadTemplates: Function;
  @Input() bondVersions: BondVersionSimpleDto[];
  @Input() bondSubtypes: {[key: number]: DictionaryDto} = {};
  @Input() isCoInsurance = false;
  @Input() contractVersion: ContractVersionDto;

  constructor(
    private dictService: DictionaryService,
    private repositoryService: RepositoryService,
    public guiService: BondGuiService,
    public appService: AppConfigService,
    private templateService: TemplateService,
    private currencyExchangeRateService: CurrencyExchangeRateService,
    private propertyService: PropertyService,
    public ngForm: NgForm,
    protected treatyService: TreatyVersionService
  ) {}

  ngOnInit() {
    this.dictService.getDictionary('DeliveryType').subscribe((entries) => {
      for (const entry of entries) {
        this.deliveryTypes[entry.id] = entry;
      }
    });

    this.customTemplate = <TemplateDto>this.guiService.getCustomTemplate();
    if (this.customTemplate && !this.customTemplate.dictionarySelectors) {
      this.templateService.getById(this.customTemplate.id).subscribe((t) => {
        this.customTemplate = <TemplateDto>t;
      });
    }

    const treatyCriteria: SearchCriteria<TreatyVersionCriteriaDto> = <SearchCriteria<TreatyVersionCriteriaDto>>{};
    treatyCriteria.criteria = <TreatyVersionCriteriaDto>{};
    treatyCriteria.criteria.lastActivated = true;
    treatyCriteria.criteria.treaty = <TreatyBaseDto>{};
    treatyCriteria.criteria.treaty.reinsuranceTypes = [ReinsuranceType.FAC];
    this.treatyService.searchByCriteria(treatyCriteria).subscribe(
      (treatiesResult) => {
        this.treaties = treatiesResult.result.map((tv) => (<TreatyVersionSimpleDto>tv).treaty);
      },
      (error) => console.log('treaty load error: ', error)
    );
  }

  onCurrencyChanged(currency: DictionaryBaseDto) {
    if (this.bondMultiValuesTable) {
      this.bondMultiValuesTable.updateAllValuesInSysCurr();
    } else {
      this.updateSingleValueInSysCurr(this.bond.values[0].value);
    }
    if (this.bondSupplementaryValuesTable) {
      this.bondSupplementaryValuesTable.updateAllValuesInSysCurr();
    }
    if (this.bondPaymentsTable) {
      this.bondPaymentsTable.updateAllValuesInSysCurr();
    }
  }

  updateSingleValueInSysCurr(newValue: number) {
    if (!this.bond.currency) {
      return;
    }
    this.bond.values[0].valueInSysCurr = this.isSysCurr(this.bond.currency)
      ? newValue
      : this.currencyExchangeRateService.calculate(newValue, this.bond.currency.id);
  }

  updateTotalValueInSysCurr(newValue: number) {
    if (!this.bond.currency) {
      return;
    }
    this.bond.coInsuranceTotalValueEUR = this.isSysCurr(this.bond.currency)
      ? newValue
      : this.currencyExchangeRateService.calculate(newValue, this.bond.currency.id);
  }

  isSysCurr(currency: DictionaryBaseDto) {
    return currency?.code === this.SYSTEM_CURRENCY_CODE;
  }

  // used on bond value field (not in value table)
  isSingleValueEditable(statusChanged: boolean) {
    return (
      this.bond &&
      !this.bond.output.showMultiValues &&
      (!this.bond.status || BondGuiService.isBondRequest(this.bond, statusChanged))
    );
  }

  onLanguageChanged(language: DictionaryBaseDto) {
    this.loadTemplates();
  }

  onCourtCountryChange(country: DictionaryBaseDto) {
    this.bond.cityOfJurisdiction = <DictionaryBaseDto>{};
    this.bond.cityOfJurisdictionText = null;

    if (this.isCityFromDict(country)) {
      this.setDefaultCityOfJurisdiction(country);
    }
  }

  isCityFromDict(country: DictionaryBaseDto): boolean {
    return this.isGermanCountry(country) || this.isItalianCountry(country);
  }

  isGermanCountry(country: DictionaryBaseDto): boolean {
    const b = this.bond;
    return b && Country.isGermany(country);
  }

  isItalianCountry(country: DictionaryBaseDto): boolean {
    const b = this.bond;
    return b && Country.isItaly(country);
  }

  onValidFromChanged(validFrom: Date) {
    // if value table is not visible there is no way to change validFrom of bond value manually
    // so it is done automatically  here
    // this must be done so the validFrom validation passes
    if (this.isFirstVersion()) {
      this.bond.values[0].validFrom = validFrom;
    }
    this.recalculateBondValidTo();
  }

  isValidToDisabled(): boolean {
    return this.isBooster() === true && this.bond.bondUnlimited === true;
  }

  canProlong(): boolean {
    return this.bond.bondValidTo !== undefined && this.bond.bondValidTo !== null;
  }

  refreshAutoprolongation() {
    if (!this.canProlong()) {
      this.bond.autoprolongation.autoprolongate = false;
    }
  }

  setDefaultCityOfJurisdiction(country: DictionaryBaseDto) {
    if (Country.isItaly(country)) {
      this.bond.cityOfJurisdiction.id = Country.MILAN;
    }

    if (Country.isGermany(country)) {
      this.bond.cityOfJurisdiction.id = Country.KOLN;
    }

    if (Country.isBelgium(country)) {
      this.bond.cityOfJurisdiction.id = Country.BRUSSELS;
    }
  }

  isFirstVersion(): boolean {
    if (!this.bond.id) {
      // unsaved bond have no versionNumber
      return !this.bondVersions || this.bondVersions.length < 1;
    }
    return this.bond.versionNumber === 1;
  }

  onValidToChanged() {
    this.refreshAutoprolongation();
  }

  bondSubtypeHasCustomFields(): boolean {
    if (this.bond.subtype && this.bondSubtypes[this.bond.subtype.id]) {
      if (this.bond.subtype.id === BondSubType.TECHNICAL_USER_TEMPLATE) {
        if (this.isSetSubtypeFromCustomTemplate()) {
          return true;
        }
      } else {
        if (this.bondSubtypes[this.bond.subtype.id].relatedDictionaries['BondCustomFieldType'].length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  isSetSubtypeFromCustomTemplate() {
    if (
      this.customTemplate &&
      this.customTemplate.dictionarySelectors &&
      this.customTemplate.dictionarySelectors.filter((bs) => bs.dictName === 'BondSubtype').length === 1
    ) {
      this.originalSubtypeCustomTemplate = this.customTemplate.dictionarySelectors.find(
        (bs) => bs.dictName === 'BondSubtype'
      );
      if (
        this.bondSubtypes[this.originalSubtypeCustomTemplate.id].relatedDictionaries['BondCustomFieldType'].length > 0
      ) {
        return true;
      }
    }
  }

  isItalianPerformanceBond() {
    return this.bond.subtype && this.bond.subtype.id === BondSubType.PERFORMANCE_BOND;
  }

  acceptedConditionsDateVisible() {
    return (
      this.bond &&
      this.bond.contractType &&
      (this.bond.contractType.id === ContractType.INDIVIDUAL ||
        this.bond.contractType.id === ContractType.INDIVIDUAL_BOOSTER)
    );
  }

  acceptedConditionsDateRequired() {
    return (
      this.bond.id &&
      this.bond.status &&
      this.bond.status.id === BondStatus.BOND &&
      this.acceptedConditionsDateVisible()
    );
  }

  recalculateBondValidTo() {
    setTimeout(() => this.doRecalculateBondValidTo(), 0);
  }

  doRecalculateBondValidTo() {
    const damageClauseTypeCustomField = this.bondCustomFieldsComponent.getDamageClauseTypeCustomField();
    if (damageClauseTypeCustomField) {
      const isDamageClause =
        damageClauseTypeCustomField.value !== this.bondCustomFieldsComponent.NO_DAMAGE_CLAUSE.value;
      const lastPaymentDate = this.getLastPaymentDate();
      if (isDamageClause) {
        this.bond.bondValidTo = DateUtils.addMonth(lastPaymentDate, 12);
        this.bond.estimatedValidTo = true;
      } else {
        this.bond.bondValidTo = lastPaymentDate;
      }
    }
  }

  getLastPaymentDate() {
    const payments = this.bond.payments;
    if (payments && payments.length > 0) {
      const sortedPayments = payments.sort(DateUtils.sorter('expectedDate'));
      return this.getBondValueDate(sortedPayments[payments.length - 1]);
    } else {
      return null;
    }
  }

  getBondValueDate(payment: BondPaymentDto) {
    if (payment.effectiveDate) {
      return payment.effectiveDate;
    }
    return DateUtils.addDay(payment.expectedDate, this.bondCustomFieldsComponent.getPaymentTermCustomFieldValue());
  }

  getExecutionAddressLabelKey() {
    if (this.bond.subtype && this.bond.subtype.id === BondSubType.PROMESSE) {
      return 'clientbond.details.beneficiary.placeOfSale';
    } else if (this.bond.subtype && this.bond.subtype.id === BondSubType.TIMBER_SALES) {
      return 'clientbond.details.beneficiary.salesAddress';
    } else {
      return 'clientbond.details.beneficiary.contractExecutionAddressOfBeneficiary';
    }
  }

  allowFacultativeTreaty(): boolean {
    return (
      this.bond &&
      this.bond.reinsuranceMode &&
      (this.bond.reinsuranceMode.id === ReinsuranceMode.FACULTATIVE ||
        this.bond.reinsuranceMode.id === ReinsuranceMode.MIXED)
    );
  }

  allowStandardTreaty(): boolean {
    return (
      this.bond &&
      this.bond.reinsuranceMode &&
      (this.bond.reinsuranceMode.id === ReinsuranceMode.MIXED ||
        this.bond.reinsuranceMode.id === ReinsuranceMode.FACULTATIVE ||
        this.bond.reinsuranceMode.id === ReinsuranceMode.STANDARD)
    );
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  get hiddenReinsuranceModeIds() {
    const ids = new Set<number>();
    if (this.credendo) {
      ids.add(ReinsuranceMode.NONE);
    }
    return ids;
  }
}
