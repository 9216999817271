import {
  CompanyFinancialDataDto,
  CompanyFinancialDataValueDto,
  CompanyFinancialDataVersionDto,
  DictionaryBaseDto,
  DictionaryDto,
} from '../../../bonding_shared/model/dtos';
import {RowFormat} from '../../../bonding_shared/components/aku-table/row-format';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

export class CompanyFinancialData {
  initialFinancialData: CompanyFinancialDataDto;
  dataTypes: DictionaryDto[];
  ratioTypes: DictionaryDto[];
  formatTypes: DictionaryDto[];

  versions: DataVersion[] = [];
  items: DataItem[] = [];
  ratioItems: DataItem[] = [];

  constructor(
    financialData: CompanyFinancialDataDto,
    dataTypes: DictionaryDto[],
    ratioTypes: DictionaryDto[],
    formatTypes: DictionaryDto[]
  ) {
    console.log('finanicial data:', financialData);
    this.dataTypes = dataTypes;
    this.ratioTypes = ratioTypes;
    this.initialFinancialData = financialData;
    this.formatTypes = formatTypes;
    // ratio doesn't have parentDictionary (problem with bidirectional hibernate mapping)
    BusinessUtils.setParentInDictEntries(this.ratioTypes, this.formatTypes, 'CompanyFinancialRatioType');
    this.initializeTableData();
  }

  reloadData(fd: CompanyFinancialDataDto) {
    this.initialFinancialData = fd;
    this.initializeTableData();
  }

  initializeTableData() {
    this.initializeVersions(this.initialFinancialData);
    this.initializeDictionaryItems(this.initialFinancialData);
  }

  initializeVersions(financialData: CompanyFinancialDataDto) {
    let index = 0;
    this.versions = [];
    financialData.versions.forEach((v) => {
      this.versions.push(new DataVersion(v, index));
      index++;
    });
  }

  filter(financialData: CompanyFinancialDataDto, type: DictionaryBaseDto, period: DictionaryBaseDto) {
    this.initializeVersions(financialData);
    this.versions = this.versions.filter(
      (v) =>
        (!type || (v.version.type && type.id === v.version.type.id)) &&
        (!period || (v.version.period && period.id === v.version.period.id))
    );
  }

  initializeDictionaryItems(financialData: CompanyFinancialDataDto) {
    this.items = [];
    this.ratioItems = [];
    const formats = this.dataFormats(financialData);
    for (const formatId of Object.keys(formats)) {
      this.insertFormatRow(formats[formatId], financialData);
      this.populateDictRows(this.items, financialData.versions, +formatId, this.dataTypes, false);
      this.populateDictRows(this.ratioItems, financialData.versions, +formatId, this.ratioTypes, true);
    }
  }
  initializeDictionaryItemsForEdition(fdv: CompanyFinancialDataVersionDto) {
    this.items = [];
    this.populateDictRows(this.items, [fdv], fdv.format.id, this.dataTypes, false);
  }

  populateDictRows(
    items: DataItem[],
    versions: CompanyFinancialDataVersionDto[],
    formatId: number,
    dictTypes: DictionaryDto[],
    ratio: boolean
  ) {
    console.log('populate rows: formatid = ' + formatId + ', ', dictTypes);
    dictTypes
      .filter((r) => r.parentDictionary && r.parentDictionary.id === formatId)
      .forEach((root) => {
        console.log('populate row:', root);
        this.insertRow(items, root, 0, versions, ratio);
        this.insertChildDictEntries(items, root, dictTypes, 1, versions, ratio);
      });
  }

  dataFormats(financialData: CompanyFinancialDataDto) {
    const formats: {[id: string]: DictionaryBaseDto} = {};
    financialData.versions.forEach((v) => (formats[v.format.id + ''] = v.format));
    return formats;
  }

  setValuesInEditedVersion(fdv: CompanyFinancialDataVersionDto) {
    fdv.values = [];
    this.items.forEach((item) => {
      const value = item.values[0];
      if (value.value || value.value === 0) {
        const valueDto = <CompanyFinancialDataValueDto>{};
        valueDto.type = <DictionaryBaseDto>{};
        valueDto.type.id = item.dictionary.id;
        valueDto.value = value.value;
        valueDto.id = value.id;
        fdv.values.push(valueDto);
      }
    });
  }

  insertRow(
    items: DataItem[],
    entry: DictionaryDto,
    indent: number,
    versions: CompanyFinancialDataVersionDto[],
    ratio: boolean
  ) {
    const values: DataValue[] = [];
    for (const ver of versions) {
      this.insertCell(ver, values, entry, ratio);
    }
    items.push(new DataItem(entry, values, indent, false));
  }

  insertCell(ver: CompanyFinancialDataVersionDto, values: DataValue[], dictItem: DictionaryDto, ratio: boolean) {
    let valueDefined = false;
    const verValues = ratio ? ver.ratios : ver.values;
    for (const val of verValues) {
      if (val.type.id === dictItem.id) {
        values.push(new DataValue(val.value, val.id));
        valueDefined = true;
      }
    }
    if (!valueDefined) {
      values.push(new DataValue(null, null));
    }
  }

  insertChildDictEntries(
    items: DataItem[],
    entry: DictionaryDto,
    entries: DictionaryDto[],
    indent: number,
    versions: CompanyFinancialDataVersionDto[],
    ratio: boolean
  ) {
    if (!entry.relatedDictionaries) {
      console.log('insertChildDictEntries ' + entry.name + ' has no relatedDictionaries.');
      return;
    }
    for (const childNameIdx of Object.keys(entry.relatedDictionaries)) {
      for (const childIdx of Object.keys(entry.relatedDictionaries[childNameIdx])) {
        const childId = entry.relatedDictionaries[childNameIdx][childIdx].id;
        const child = entries.filter((e) => e.id === childId)[0];
        this.insertRow(items, child, indent, versions, ratio);
        this.insertChildDictEntries(items, child, entries, indent + 1, versions, ratio);
      }
    }
  }

  insertFormatRow(entry: DictionaryBaseDto, financialData: CompanyFinancialDataDto) {
    const values: DataValue[] = [];
    for (const ver of financialData.versions) {
      values.push(new DataValue(null, null));
    }
    this.items.push(new DataItem(entry, values, 0, true));
  }

  rowFormat(item: DataItem): RowFormat {
    const f = new RowFormat();
    f.solidBottomBorder = item.bottomBorder;
    return f;
  }

  isShowInput(item: DataItem) {
    if (this.isFidatFinData()) {
      const rd = this.dataTypes.find((d) => d.id === item.dictionary.id).relatedDictionaries;
      if (!rd) {
        return true;
      }
      const vals = Object.values(rd);
      return !vals || vals.length === 0 || !vals[0] || vals[0].length === 0;
    } else {
      return item.indent > 0;
    }
  }

  isFidatFinData(): boolean {
    return true;
  }
}

export class DataItem {
  dictionary: DictionaryBaseDto;
  values: DataValue[];
  indent: number;
  indentPx: number;
  bottomBorder: boolean = false;

  constructor(dictionary: DictionaryBaseDto, values: DataValue[], indent: number, bottomBorder: boolean) {
    this.dictionary = dictionary;
    this.values = values;
    this.indent = indent;
    this.indentPx = this.indent * 15;
    this.bottomBorder = bottomBorder;
  }
}

export class DataVersion {
  version: CompanyFinancialDataVersionDto;
  index: number;

  constructor(version: CompanyFinancialDataVersionDto, index: number) {
    this.index = index;
    this.version = version;
  }
}

export class DataValue {
  value: number;
  id: number;

  constructor(value: number, id: number) {
    this.value = value;
    this.id = id;
  }
}
