export class BusinessUnit {
  static readonly AT: number = 12;
  static readonly BE: number = 1;
  static readonly CH: number = 13;
  static readonly DE: number = 4;
  static readonly ES: number = 7;
  static readonly FR: number = 3;
  static readonly IE: number = 11;
  static readonly IT: number = 5;
  static readonly NL: number = 8;
  static readonly PL: number = 2;
}
