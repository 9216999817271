import {
  AppConfigService,
  CompanyBaseDto,
  CompanyGroupIdDto,
  CompanyGroupVersionCriteriaDto,
  CompanyGroupVersionDto,
  RouterService,
  SearchDataProvider,
} from '../../bonding_shared';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchViewComponent} from '../../bonding_shared/components/search';
import {GroupGuiService} from './services/group-gui.service';

@Component({
  selector: 'group-search',
  templateUrl: 'group-search.component.html',
})
export class GroupSearchComponent implements OnInit {
  @ViewChild(SearchViewComponent, {static: true}) public searchView: SearchViewComponent<
    CompanyGroupVersionCriteriaDto,
    CompanyGroupVersionDto
  >;
  public dataProvider: SearchDataProvider<CompanyGroupVersionCriteriaDto, CompanyGroupVersionDto>;

  constructor(
    private routerService: RouterService,
    public guiService: GroupGuiService,
    public appConfigService: AppConfigService
  ) {
    this.dataProvider = this.guiService.searchDataProvider;
  }

  public ngOnInit(): void {
    if (!this.dataProvider.searchCriteria.criteria) {
      this.initializeCriteria();
    }
  }

  public toCompanyGroupPreview(groupVersion: CompanyGroupVersionDto): void {
    if (groupVersion.companyGroup.id) {
      this.routerService.toCompanyGroupPreview(groupVersion.companyGroup.id);
    }
  }

  private initializeCriteria(): void {
    this.dataProvider.searchCriteria.criteria = <CompanyGroupVersionCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.name = '';
    this.dataProvider.searchCriteria.criteria.groupHead = <CompanyBaseDto>{};
    this.dataProvider.searchCriteria.criteria.memberCompany = <CompanyBaseDto>{};
    this.dataProvider.searchCriteria.criteria.last = true;
    this.dataProvider.searchCriteria.criteria.companyGroup = <CompanyGroupIdDto>{};
    this.dataProvider.textSearch = false;
  }
}
