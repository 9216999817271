import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  DictionaryBaseDto,
  DictionaryDto,
  DocumentDescriptionDto,
  ErrorReason,
  LocalDateRange,
  RepositoryDocumentCriteriaDto,
  SearchResult,
} from '../../bonding_shared/model';
import {AppConfigService, RepositoryService, SearchDataProvider} from '../../bonding_shared/services';
import {ActivatedRoute} from '@angular/router';
import {ATableComponent} from '../../bonding_shared/components/aku-table';
import {TranslateService} from '@ngx-translate/core';
import {CompanyDocumentType, Feature} from '../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'repo-document-list',
  templateUrl: './repo-document-list.component.pug',
})
export class RepoDocumentListComponent implements OnInit, AfterViewInit {
  static hiddenDocumentTypeIds = [CompanyDocumentType.BROKER_LOGO];
  @Input() labelKey: string;
  @Input() businessObjectTypeId: number;
  @Input() businessObjectId: number;
  @Input() categoryId: number;
  @Input() documentTypes: DictionaryDto[];
  @Input() hideVersionNumber = true;

  documentTypeName: string;

  dataProvider: SearchDataProvider<RepositoryDocumentCriteriaDto, DocumentDescriptionDto>;

  @ViewChild(ATableComponent, {static: true}) documentTable: ATableComponent<DocumentDescriptionDto>;

  readonly Feature = Feature;

  get hiddenTypeIds() {
    return RepoDocumentListComponent.hiddenDocumentTypeIds;
  }

  ngOnInit() {
    this.repositoryService.documentTypeName(this.businessObjectTypeId).subscribe((n) => {
      this.documentTypeName = n;
    });
    this.setupCriteria();
  }

  ngAfterViewInit() {
    this.documentTable.search();
    this.documentTable.dataProviderSearchFinished.subscribe((searchResult: SearchResult<DocumentDescriptionDto>) => {
      this.onSearchFinished(searchResult);
    });
  }

  refreshDocumentList() {
    this.documentTable.search();
  }

  constructor(
    private repositoryService: RepositoryService,
    private _route: ActivatedRoute,
    protected translateService: TranslateService,
    public appConfigService: AppConfigService
  ) {
    this.dataProvider = new SearchDataProvider<RepositoryDocumentCriteriaDto, DocumentDescriptionDto>(
      repositoryService
    );
  }

  setupCriteria() {
    this.criteria = <RepositoryDocumentCriteriaDto>{};
    this.criteria.businessObjectType = <DictionaryBaseDto>{id: this.businessObjectTypeId};
    this.criteria.businessObjectId = this.businessObjectId;
    this.criteria.documentCategory = this.categoryId ? <DictionaryBaseDto>{id: this.categoryId} : undefined;
    this.criteria.documentType = <DictionaryBaseDto>{};
    this.criteria.uploaded = <LocalDateRange>{};
    this.criteria.portal = false;
    this.criteria.registrant = undefined;
  }

  get criteria(): RepositoryDocumentCriteriaDto {
    return this.dataProvider.searchCriteria.criteria;
  }

  set criteria(c: RepositoryDocumentCriteriaDto) {
    this.dataProvider.searchCriteria.criteria = c;
  }

  getDocument(document: DocumentDescriptionDto) {
    this.repositoryService.getRepositoryDocument(document.repositoryId, document.name);
  }

  filterDocumentType() {
    this.search();
  }

  search() {
    this.documentTable.search();
  }

  onRemoveDocument(doc: DocumentDescriptionDto) {
    if (confirm(this.translateService.instant('common.confirm'))) {
      this.repositoryService.deleteRepositoryDocument(doc);
      this.refreshDocumentList();
    }
  }

  private onSearchFinished(searchResult: SearchResult<DocumentDescriptionDto>): void {
    if (!searchResult.result || searchResult.result.length === 0) {
      this.addWarning(this.translateService.instant('common.noResultFound'));
    }
  }

  private addWarning(message: string): void {
    if (!Array.isArray(this.documentTable.errorMessage)) {
      this.documentTable.errorMessage = [];
    }
    this.documentTable.errorMessage.push(<ErrorReason>{
      severity: 'WARNING',
      message: message,
    });
  }

  getTypeName(document: DocumentDescriptionDto) {
    return document.repositoryDocumentTypeName || document.documentType?.name || '???';
  }
}
